import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';

import { getValue } from '@zipari/web-utils';
import { slideRight } from '../../animations';

@Component({
    selector: 'add-or-edit-modal',
    templateUrl: './add-or-edit-modal.component.html',

    animations: [slideRight],
})
export class AddOrEditModalComponent implements OnInit, OnChanges {
    @Input() config;
    @Input() successResponse;
    @Input() errorResponse;
    @Input() viewProfile;
    @Input() addRowForm = new FormGroup({});

    @Output() formBuilt = new EventEmitter();
    @Output() cancel = new EventEmitter();
    @Output() save = new EventEmitter();
    @Output() continue = new EventEmitter();

    attributes;
    view: 'form' | 'success' | 'error' = 'form';

    constructor(private formControlService: FormControlService) {}

    ngOnInit() {
        this.buildAddRowForm();
    }

    ngOnChanges() {
        if (this.successResponse) {
            this.view = 'success';
            this.setAttributes();
        } else if (this.errorResponse) {
            this.view = 'error';
        }
    }

    // flatten attributes from config for display
    setAttributes() {
        const attributes = Array.isArray(this.config.attributes) ? this.config.attributes : this.config.attributes.attributes;
        this.attributes = attributes.reduce((array, attr) => {
            const title = attr.title;
            const value = getValue(this.successResponse, attr.prop);
            if (title || value) {
                array.push({ title, value, label: attr.label });
            }

            return array;
        }, []);
    }

    buildAddRowForm() {
        const sections = getValue(this.config, 'form.groups');

        sections.forEach(section => {
            section.controls.forEach(control => {
                this.formControlService.addControlToFormGroup(this.addRowForm, control);
            });
        });

        this.formBuilt.emit();
    }

    onCancel() {
        this.cancel.emit();
    }

    // if form !valid highlight required/invalid fields
    onSave() {
        if (this.addRowForm.valid) {
            this.save.emit(this.addRowForm.value);
        } else {
            const controls = this.addRowForm.controls;
            Object.keys(controls).forEach(c => controls[c].markAsTouched());
        }
    }

    onContinue() {
        this.continue.emit(this.successResponse.id);
    }

    onRetry() {
        this.errorResponse = null;
        this.view = 'form';
    }
}
