export const coverSpouseConfig = {
    label: 'Does the subscriber want to cover a spouse / partner',
    prop: 'spouse_covered',
    type: 'toggle',
    options: [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ],
    validators: ['required'],
};

export const coverDepConfig = {
    label: 'Does the subscriber want to cover any dependents',
    prop: 'deps_covered',
    type: 'toggle',
    options: [
        {
            label: 'Yes',
            value: true,
        },
        {
            label: 'No',
            value: false,
        },
    ],
    validators: ['required'],
};

export const subscriberConfig = {
    text: {
        subscriber: 'Subscriber',
    },
    controls: [
        {
            type: 'date',
            label: 'Date of Birth',
            max: '9999-01-01',
            prop: 'date_of_birth',
            placeholder: 'YYYY-MM-DD',
            validators: [
                'required',
                'reasonableDate',
                'dateInPast',
                {
                    name: 'maxAge',
                    dependency: {
                        age: 65,
                        dateProp: 'root.value.coverage_effective_date',
                    },
                },
                {
                    name: 'minAge',
                    dependency: {
                        age: 18,
                        dateProp: 'root.value.coverage_effective_date',
                    },
                },
            ],
        },
    ],
};

export const spouseConfig = {
    text: {
        spouse: 'Spouse',
    },
    controls: [
        {
            type: 'date',
            label: 'Date of Birth',
            max: '9999-01-01',
            prop: 'date_of_birth',
            placeholder: 'YYYY-MM-DD',
            validators: [
                'required',
                'reasonableDate',
                'dateInPast',
                {
                    name: 'maxAge',
                    dependency: {
                        age: 65,
                        dateProp: 'root.value.coverage_effective_date',
                    },
                },
                {
                    name: 'minAge',
                    dependency: {
                        age: 18,
                        dateProp: 'root.value.coverage_effective_date',
                    },
                },
            ],
        },
    ],
};

export const depConfig = {
    text: {
        child: 'Child',
    },
    controls: [
        {
            label: 'Date of Birth',
            placeholder: 'YYYY-MM-DD',
            prop: 'date_of_birth',
            validators: [
                'required',
                'dateInPast',
                'reasonableDate',
                {
                    name: 'maxAgeAndPropCheck',
                    dependency: {
                        dateProp: 'parent.parent.parent.parent.controls.getStarted.controls.coverage_effective_date',
                        otherPropValue: true,
                        age: 26,
                        otherProp: 'controls.is_disabled',
                    },
                },
            ],
            type: 'date',
            errors: {
                maxAgeAndPropCheck: 'Child dependent must be under the age 26 with the exception of having a disability.',
                required: 'Please enter a valid date of birth',
                dateInPast: 'Please enter a valid date of birth',
                reasonableDate: 'Child dependent must be under the age 26',
            },
        },
    ],
};

export const childOnlyConfig = {
    text: {
        child: 'Child Only',
    },
    controls: [
        {
            type: 'date',
            prop: 'date_of_birth',
            label: 'Date of Birth',
            placeholder: 'YYYY-MM-DD',
            errors: {
                reasonableDate: 'Please enter a valid date of birth',
                required: 'Please enter a valid date of birth',
                maxAge: 'Child must be less than 18 years old for child only coverage.',
                dateInPast: 'Please enter a valid date of birth',
            },
            validators: [
                'required',
                'dateInPast',
                'reasonableDate',
                {
                    name: 'maxAge',
                    dependency: {
                        dateProp: 'root.value.coverage_effective_date',
                        age: 18,
                    },
                },
            ],
        },
    ],
    validators: ['required'],
};

export const individualsCoveredConfig = {
    prop: 'whose_covered',
    type: 'dropdown',
    label: 'Please add all individuals who will be covered under this policy',
    options: [
        {
            label: 'Subscriber',
            value: 'subscriber',
        },
        {
            label: 'Subscriber + Spouse',
            value: 'spouse',
        },
        {
            label: 'Subscriber + Child(ren)',
            value: 'parent',
        },
        {
            label: 'Subscriber + Family',
            value: 'family',
        },
        {
            label: 'Child Only',
            value: 'child_only',
        },
    ],
    validators: ['required'],
};

export const buttonConfig = {
    icon: 'add',
    level: 'text',
    content: 'Add Another Dependent',
};
