import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DatagridModule, PaginatorModule, ModalModule, BadgeModule, TabsModule, ButtonModule } from '@zipari/design-system';
import { ErrorScreenModule } from './../../../shared/error-screen/error-screen.module';
import { ViewClaimsModalComponent } from './view-claims-modal.component';

@NgModule({
    declarations: [ViewClaimsModalComponent],
    imports: [CommonModule, DatagridModule, PaginatorModule, ModalModule, BadgeModule, TabsModule, ButtonModule, ErrorScreenModule],
    exports: [ViewClaimsModalComponent],
})
export class ViewClaimsModalModule {}
