import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { tap } from 'rxjs/operators/tap';

import { ApiListResponse } from './../../models/shared/ApiListResponse.model';
import Plan from './../../models/shared/Plan.model';

@Injectable()
export class PlansAPIService {
    private plansEndpoint = 'api/member-360/plans/?ordering=display_name';
    private plansCache = new Map<string, ApiListResponse<Plan>>();

    constructor(private httpClient: HttpClient) {}

    public fetchPlans(params = {}, endpoint = this.plansEndpoint): Observable<ApiListResponse<Plan>> {
        const httpParams = new HttpParams({ fromObject: params });
        const cacheKey = endpoint + httpParams.toString();

        if (this.plansCache.has(cacheKey)) {
            // pipe the delay to simulate busy for the user
            return of(this.plansCache.get(cacheKey)).pipe(delay(200));
        }

        return this.httpClient
            .get<ApiListResponse<Plan>>(endpoint, { params: httpParams })
            .pipe(
                map((response) => {
                    // need to map the results so that none of the BE plan data comes in as selected
                    response.results = response.results.map((plan) => ({ ...plan, selected: false, comparing: false }));

                    return response;
                }),
                tap((response) => {
                    this.plansCache.set(cacheKey, response);
                })
            );
    }
}
