import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { notificationsAPI } from './notifications.constant';

@Injectable({
    providedIn: 'root',
})
export class NotificationsService {
    private _latestNotifications;
    private _notifications;
    public newNotifications = new Subject();

    public get notifications() {
        return this._notifications;
    }

    public set notifications(notifications: any) {
        this._notifications = notifications;
        this.newNotifications.next(this._notifications);
    }

    public get latestNotifications() {
        return this._latestNotifications;
    }

    public set latestNotifications(notifications: any) {
        this._latestNotifications = notifications;
        this.newNotifications.next(this._latestNotifications);
    }

    constructor(public http: HttpClient) {}

    public retrieveNotifications() {
        this.http.get(notificationsAPI).subscribe(response => {
            this.notifications = response['notifications'] || response['results'] || [];
        });
    }

    public retrieveLatestNotifications() {
        this.http.get(notificationsAPI).subscribe(response => {
            this.latestNotifications = response['notifications'] || response['results'] || [];
        });
    }

    registerNotificationAsRead(id) {
        this.http
            .put(notificationsAPI, {
                notifications: [id],
            })
            .subscribe();
    }
}
