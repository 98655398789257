import { WorkflowStep } from './WorkflowStep.model';

export enum WorkflowStates {
    COMPLETE = 'complete',
    IN_PROGRESS = 'in_progress',
    NOT_STARTED = 'not_started',
    SKIPPED = 'skipped',
}

export class Workflow<T> {
    children: any[];
    current_step: WorkflowStep;
    current_step_number: number;
    data: any;
    id: number;
    parent: number;
    relations: any;
    schema: any;
    state: WorkflowStates;
    steps: WorkflowStep[];
    type: string;
    values: T;
    web_session_id: string;
    webuser_id: number;

    constructor(init?) {
        Object.assign(this, init);
    }
}
