import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, TableModule, DatagridModule, ModelAttributeSectionsModule } from '@zipari/design-system';
import { DetailTabsModule } from 'src/app/icx/shared/modules/detail-tabs/detail-tabs.module';
import { TabViewModule } from 'src/app/icx/shared/modules/tab-view/tab-view.module';

import { PcpDetailComponent } from './pcp-detail.component';

@NgModule({
    declarations: [PcpDetailComponent],
    imports: [CommonModule, DetailTabsModule, TabViewModule, ButtonModule, TableModule, DatagridModule, ModelAttributeSectionsModule],
})
export class PcpDetailModule {}
