import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MemberSearchComponent } from './member-search.component';

@NgModule({
    declarations: [MemberSearchComponent],
    imports: [CommonModule],
})
export class MemberSearchModule {}
