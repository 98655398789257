import { Injectable } from '@angular/core';
import { PanelFilterFormAndParams, SearchFilterForm } from '../../../shared/models/shared/Claim.model';

@Injectable({
    providedIn: 'root',
})
export class SearchFilterService {
    searchFilterValue: SearchFilterForm = {
        claimNumber: null,
        memberId: null,
        npiNumber: null,
        providerId: null,
        serviceDate: {
            end: '',
            start: '',
        },
    };
    panelFilterValue: PanelFilterFormAndParams = {
        claimStatus: null,
        claimType: null,
        paymentStatus: null,
        queryParams: null,
    };
}
