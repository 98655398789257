import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class SidePanelService {
    isSidebarVisible: boolean = false;

    sidebarVisChange: Subject<boolean> = new Subject<boolean>();

    constructor() {
        this.sidebarVisChange.subscribe(value => {
            this.isSidebarVisible = value;
        });
    }

    toggleSidePanel(show: boolean) {
        this.sidebarVisChange.next(show);
    }
}
