import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { LoggingService } from './logging.service';
import Plan from './../../shared/models/shared/Plan.model';

export class PlanBenefit {
    label: string;
    value: string;
    type?: string;
}

@Injectable({
    providedIn: 'root',
})
export class PlanUtilService {
    constructor(private http: HttpClient, public loggingService: LoggingService) {}

    formatBenefitsOnPlan(plan) {
        const benefits = [];

        // create formatted benefits from the plan
        plan.formattedBenefits = this.formatBenefits(plan.benefits, benefits);

        return plan;
    }

    formatBenefits(benefits: any, acc, key = null) {
        if (typeof benefits === 'object' && Array.isArray(benefits)) {
            benefits.forEach(benefit => {
                acc.push(benefit);
            });
        } else if (typeof benefits === 'object' && !Array.isArray(benefits) && benefits.hasOwnProperty('value')) {
            if (!benefits.label && key) {
                benefits.label = key;
            }
            acc.push(benefits);
        } else {
            const benefitKeys = Object.keys(benefits || {});

            benefitKeys.forEach(benefitKey => this.formatBenefits(benefits[benefitKey], acc, benefitKey));
        }

        return acc;
    }

    formatPlansAsArr(plans) {
        let arrPlans = [];
        if (plans) {
            if (!Array.isArray(plans)) {
                const planKeys = Object.keys(plans);

                planKeys.forEach(planKey => {
                    if (Array.isArray(plans[planKey])) {
                        plans[planKey].forEach(plan => {
                            arrPlans.push(plan);
                        });
                    } else {
                        arrPlans.push(plans[planKey]);
                    }
                });
            } else {
                arrPlans = plans;
            }
        }

        return arrPlans;
    }

    sortByPlanType(plans: Plan[], order: String[]): Plan[] {
        if (!order) return plans;
        const sorted = plans.slice().sort((a, b) => order.indexOf(a.plan_type) - order.indexOf(b.plan_type));

        return sorted;
    }

    getRenewalPlan$(url): Observable<any> {
        return this.http.get(url);
    }

    retrievePriceOfPlans(plans) {
        try {
            const plansArr = this.formatPlansAsArr(plans);

            return plansArr.map(plan => Number(plan.price)).reduce((current, sum) => (sum += current), 0);
        } catch (err) {
            this.loggingService.error(err);

            return 0;
        }
    }
}
