import { Injectable } from '@angular/core';
import { APIService, APIResponse } from 'src/app/shared/services';
import { Subject, Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { PoliciesMemberCoverages } from '../policies/policies.constants';
import { LabelValuePair, BenefitsCategoryItem, BenefitsServiceItem, BenefitDetailsAPIResponse, AccumsAPIResponse } from './benefits.model';
import { PoliciesProductCoverage } from './../policies/policies.constants';

@Injectable({
    providedIn: 'root',
})
export class BenefitService {
    public currentPolicyId: string;
    public planId: string;
    public selectedPlan: LabelValuePair;
    public benefitPeriodId: string;
    public selectedBenefitPeriod: LabelValuePair;
    public categoriesList: BenefitsCategoryItem[] = [];
    public servicesList: BenefitsServiceItem[] = [];
    private updateTypeaheadList$: Subject<any> = new Subject();
    private currentSearchBy: 'service' | 'category';
    public benefitServiceList$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    constructor(private apiService: APIService) {}

    getBenefitPeriods(memberId: string, member_coverage_id?: string) {
        let endpoint = `/api/member-360/members/${memberId}/benefit-periods/`;
        if (member_coverage_id) {
            endpoint += `?member_coverage_id=${member_coverage_id}`;
        }

        return this.apiService.get(endpoint);
    }

    getAllBenefitsCategories() {
        const endpoint = `/api/member-360/plans/${this.planId}/benefit-service-categories/`;

        this.apiService.get(endpoint).subscribe((res: APIResponse<BenefitsCategoryItem>) => {
            this.categoriesList = res.results;

            this.updateTypeahead();
        });
    }

    getAllBenefitsServices() {
        const endpoint = `/api/member-360/plans/${this.planId}/benefit-services/`;

        this.apiService.get(endpoint).subscribe((res: APIResponse<BenefitsServiceItem>) => {
            this.servicesList = res.results;

            this.updateTypeahead();
        });
    }

    requestBenefitCategories(): Observable<APIResponse<BenefitsServiceItem>> {
        const endpoint = `/api/member-360/plans/${this.planId}/benefit-service-categories/`;

        return this.apiService.get(endpoint);
    }

    getBenefitServiceByCategory(categoryId: string) {
        // checking if API has already been called or not for the same caterogry id
        const tempArr = [];
        if (this.benefitServiceList$?.value?.length) {
            this.benefitServiceList$.value.forEach(function (val) {
                val.id === categoryId ? tempArr.push(true) : '';
            });
        }
        if (this.benefitServiceList$?.value?.length && tempArr.length > 0) {
            return this.benefitServiceList$.pipe(map((service) => service.find((service) => service.id === categoryId)));
        } else {
            return this.apiService
                .get(`/api/member-360/plans/${this.planId}/benefit-services/?benefit_service_category_id=${categoryId}`)
                .pipe(
                    map((data) => {
                        const currentServiceList = {
                            id: categoryId,
                            details: { ...data },
                        };
                        this.benefitServiceList$.value.push(currentServiceList);

                        return currentServiceList;
                    }),
                    catchError((error) => throwError(error))
                );
        }
    }

    getBenefitDetailForLongDescription(benefitServiceId: string) {
        const endpoint = `/api/member-360/plans/${this.planId}/benefit-services/${benefitServiceId}`;

        return this.apiService.get(endpoint);
    }

    getBenefitDetails(memberId: string, benefitServiceId: string): Observable<BenefitDetailsAPIResponse> {
        const endpoint = `/api/member-360/plans/${this.planId}/benefits/?member_id=${memberId}&benefit_service_id=${benefitServiceId}&benefit_period_id=${this.benefitPeriodId}`;

        return this.apiService.get(endpoint);
    }

    getBenefitUtilizations(memberId: string, benefitServiceId: string) {
        const endpoint = `/api/member-360/members/${memberId}/benefits/?benefit_service_id=${benefitServiceId}&benefit_period_id=${this.benefitPeriodId}`;

        return this.apiService.get(endpoint);
    }

    getAccumulators(memberId: string, benefitServiceId: string, benefitPeriodId: string): Observable<AccumsAPIResponse> {
        const endpoint = `/api/member-360/members/${memberId}/benefits/?benefit_service_id=${benefitServiceId}&benefit_period_id=${benefitPeriodId}`;

        return this.apiService.get(endpoint);
    }

    updateTypeahead(type?: 'service' | 'category') {
        if (type) {
            this.currentSearchBy = type;
        }
        if (this.currentSearchBy === 'service') {
            this.updateTypeaheadList$.next(this.servicesList);
        } else {
            this.updateTypeaheadList$.next(this.categoriesList);
        }
    }

    typeaheadListUpdated() {
        return this.updateTypeaheadList$.asObservable();
    }

    getMemeberCoverageID(planId: number, coverageList, memberID) {
        let member_coverage_id: string;
        const selectedPlan = coverageList.find((coverage: PoliciesProductCoverage) => coverage.plan_id === planId);
        if (selectedPlan) {
            selectedPlan.member_coverages.forEach((memberCoverage: PoliciesMemberCoverages) => {
                if (memberCoverage?.member?.member_number === memberID || memberCoverage?.member?.id === memberID) {
                    member_coverage_id = memberCoverage.member_coverage_id;
                }
            });
        }

        return member_coverage_id;
    }
}
