import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { validCXEvents, validGAEvents } from '../../../constants/analytics';
import { AnalyticsService } from './../../../services/analytics.service';

@Component({
    selector: 'plan-compare',
    templateUrl: './plan-compare.component.html',
    styleUrls: ['./plan-compare.component.scss'],
})
export class PlanCompareComponent implements OnInit {
    @Input() comparedPlans: any[];
    @Input() maxComparable: number;
    @Output() removePlan = new EventEmitter<any>();
    @Output() comparePlans = new EventEmitter();

    placeholders = [];

    constructor(public analyticsService: AnalyticsService) {}

    ngOnInit() {
        this.placeholders = new Array(this.maxComparable);
    }

    removePlanFromComparison(plan) {
        this.removePlan.emit(plan);
    }

    showCompareTable() {
        this.comparePlans.emit();
        this.analyticsService.dispatchAnalytics(
            {
                GAKey: validGAEvents['plans_compared'],
                CXKey: validCXEvents['plans_compared'],
            },
            undefined,
            this.createCXContext()
        );
    }

    createCXContext() {
        const planIds = [];
        const planNames = [];

        for (const plan of this.comparedPlans) {
            planIds.push(plan.external_id);
            planNames.push(plan.display_name);
        }

        const cxContext = {
            plans_ids_compared: planIds,
            plan_names_compared: planNames,
        };

        return cxContext;
    }
}
