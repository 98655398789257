import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlModule, ButtonModule, PaginatorModule, TableModule, DatagridModule } from '@zipari/design-system';
import { ResultsModule } from 'src/app/icx/shared/modules/results/results.module';

import { DetailTabsModule } from 'src/app/icx/shared/modules/detail-tabs/detail-tabs.module';
import { TabViewModule } from 'src/app/icx/shared/modules/tab-view/tab-view.module';
import { AppealsComponent } from './appeals.component';

@NgModule({
    declarations: [AppealsComponent],
    imports: [
        CommonModule,
        FormControlModule,
        ButtonModule,
        ResultsModule,
        TableModule,
        PaginatorModule,
        DetailTabsModule,
        TabViewModule,
        DatagridModule,
    ],
})
export class AppealsModule {}
