import { Injectable } from '@angular/core';
import { FormControlValidatorsService } from '@zipari/design-system';
import { FormControl, FormGroup } from '@angular/forms';

@Injectable({
    providedIn: 'root',
})
export class FormManagerService {
    constructor(private validatorService: FormControlValidatorsService) {}

    populateFormGroup(controlsConfig: any[]): FormGroup {
        const formGroup: FormGroup = new FormGroup({});
        controlsConfig?.forEach(individualControlConfig => {
            const validators = this.validatorService.getFormControlValidators(individualControlConfig);
            const control = new FormControl(individualControlConfig.value || null, { validators });
            formGroup.addControl(individualControlConfig.prop, control);
        });

        return formGroup;
    }
}
