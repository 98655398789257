import { map, catchError, share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { throwError, BehaviorSubject, of, Observable } from 'rxjs';
import { APIService, APIResponse } from '../../../shared/services/api.service';
import { PoliciesResponse } from '../../member/modules/policies/policies.constants';
import { PCPsResponse, ProviderDetailsResponse } from '../../member/modules/providers/providers.constants';

export interface MemberData {
    addresses: MemberAddress[];
    physical_address: MemberAddress;
    age: number;
    birth_date: string;
    cell_phone: string;
    email_address: string;
    ethnicity: string;
    full_name: string;
    fullName: string;
    gender: string;
    has_disability: string;
    home_phone: string;
    marital_status: string;
    member_display_identifier: string;
    member_number: string;
    name: MemberName;
    office_phone: string;
    part_a_entitlement_date: string;
    part_b_entitlement_date: string;
    primary_language: string;
    primary_phone: string;
    primary_phone_type: string;
    privacy_restriction: boolean;
    relationship_to_subscriber: string;
    secondary_language: string;
    ssn: string;
    subscriber_number: string;
    tobacco_use: boolean;
    medicare_number: string;
    is_external_plan_member: boolean;
    id?: string;
}
export interface MemberName {
    first_name: string;
    prefix: string;
    last_name: string;
    suffix: string;
    middle_name: string;
    full_name?: string;
}
export interface MemberAddress {
    address_type: string;
    city_name: string;
    state: string;
    street_name_1: string;
    street_name_2: string;
    street_name_3: string;
    zip_code: string;
}

export interface ResponseType<T> {
    id: string;
    details: T;
}

export class responseTypePC<T> {
    id: string;
    p_id: string;
    details: T;
}

@Injectable({
    providedIn: 'root',
})
export class MemberDataService {
    constructor(private api: APIService) {}

    public memberData: BehaviorSubject<ResponseType<MemberData>> = new BehaviorSubject<ResponseType<MemberData>>(null);
    public memberPolicies: BehaviorSubject<ResponseType<APIResponse<PoliciesResponse>>> = new BehaviorSubject<
        ResponseType<APIResponse<PoliciesResponse>>
    >(null);
    public policyData: BehaviorSubject<ResponseType<any>[]> = new BehaviorSubject<ResponseType<any>[]>([]);
    public memberProductCoverages: BehaviorSubject<ResponseType<any>> = new BehaviorSubject<ResponseType<any>>(null);
    public policyProductCoverages: BehaviorSubject<responseTypePC<any>[]> = new BehaviorSubject<responseTypePC<any>[]>([]);
    public memberCoverages: BehaviorSubject<responseTypePC<any>[]> = new BehaviorSubject<responseTypePC<any>[]>([]);
    public cobList: BehaviorSubject<responseTypePC<any>[]> = new BehaviorSubject<responseTypePC<any>[]>([]);
    public claimList: BehaviorSubject<ResponseType<any>> = new BehaviorSubject<ResponseType<any>>(null);
    public claimLinesList: BehaviorSubject<ResponseType<any>> = new BehaviorSubject<ResponseType<any>>(null);
    public claimLinesDetail: BehaviorSubject<ResponseType<any>> = new BehaviorSubject<ResponseType<any>>(null);
    public claimDetails: BehaviorSubject<ResponseType<any>[]> = new BehaviorSubject<ResponseType<any>[]>([]);
    public referralList: BehaviorSubject<ResponseType<any>> = new BehaviorSubject<ResponseType<any>>(null);
    public referralDetails: BehaviorSubject<ResponseType<any>[]> = new BehaviorSubject<ResponseType<any>[]>([]);
    public authorizationList: BehaviorSubject<ResponseType<any>> = new BehaviorSubject<ResponseType<any>>(null);
    public authorizationDetails: BehaviorSubject<ResponseType<any>[]> = new BehaviorSubject<ResponseType<any>[]>([]);
    public pcpList: BehaviorSubject<ResponseType<APIResponse<PCPsResponse>>> = new BehaviorSubject<ResponseType<APIResponse<PCPsResponse>>>(
        null
    );
    public pcpDetails: BehaviorSubject<responseTypePC<ProviderDetailsResponse>[]> = new BehaviorSubject<
        responseTypePC<ProviderDetailsResponse>[]
    >([]);
    public cpData: BehaviorSubject<ResponseType<any>> = new BehaviorSubject<ResponseType<any>>(null);
    public wData: BehaviorSubject<ResponseType<any>> = new BehaviorSubject<ResponseType<any>>(null);

    public policiesRequest$: Observable<ResponseType<APIResponse<PoliciesResponse>>>;
    private currentMember: ResponseType<MemberData>;

    // invoking Members API and returns response or error back
    getMemberData(member_id: string): Observable<ResponseType<MemberData>> {
        if (this.memberData.value && this.memberData.value.id && this.memberData.value.details) {
            return this.memberData.asObservable();
        }

        return this.api.get(`/api/member-360/members/${member_id}/`).pipe(
            map((data: MemberData) => {
                this.currentMember = {
                    id: member_id,
                    details: data,
                };
                this.memberData.next(this.currentMember);

                return this.currentMember;
            }),
            catchError((error: any) => of(error))
        );
    }

    // invoking Members Policies API and returns response or error back from called
    getPoliciesList(member_id: string) {
        if (this.memberPolicies.value && this.memberPolicies.value.id && this.memberPolicies.value.details) {
            return this.memberPolicies.asObservable();
        } else if (this.policiesRequest$) {
            return this.policiesRequest$;
        }

        this.policiesRequest$ = this.api.get(`/api/member-360/members/${member_id}/policies/`).pipe(
            map((data: APIResponse<PoliciesResponse>) => {
                const currentMemberPolicies = {
                    id: member_id,
                    details: { ...data },
                };
                this.memberPolicies.next(currentMemberPolicies);

                return currentMemberPolicies;
            }),
            share(),
            catchError((error: any) => throwError(error))
        );

        return this.policiesRequest$;
    }

    // invoking Policies API with Policy ID and returns response or error back
    getPolicyData(policy_id: string, member_id: string) {
        // checking if policy is already been called or not
        const tempArr = [];
        if (this.policyData?.value?.length) {
            this.policyData.value.forEach(function (val) {
                val.id === policy_id ? tempArr.push(true) : '';
            });
        }
        if (this.policyData?.value?.length && tempArr.length > 0) {
            return this.policyData.pipe(map((policy) => policy.find((policy) => policy.id === policy_id)));
        } else {
            return this.api.get(`/api/member-360/policies/${policy_id}/?member_number=${member_id}`).pipe(
                map((data) => {
                    const currentPolicy = {
                        id: policy_id,
                        details: { ...data },
                    };
                    this.policyData.value.push(currentPolicy);

                    return currentPolicy;
                }),
                catchError((error) => throwError(error))
            );
        }
    }

    // invoking Product Coverage API for specific policy and returns response or error back from called
    getPolicyProductCoverages(member_id, policy_id, current_page, paginator, sortingElement) {
        return this.api
            .get(
                `/api/member-360/policies/product-coverages/?member_number=${member_id}&policy_id=${policy_id}&page=${current_page}&page_size=${paginator}&ordering=${sortingElement}`
            )
            .pipe(
                map((data) => {
                    const currentProductCoverages = {
                        id: member_id,
                        p_id: policy_id,
                        details: { ...data },
                    };
                    this.policyProductCoverages.value.push(currentProductCoverages);

                    return currentProductCoverages;
                }),
                catchError((error) => throwError(error))
            );
    }

    // invoking Policies API with Policy ID and Product coverage ID that returns response or error back
    getMemberCoverages(end_point, policy_id, product_coverage_id, member_id) {
        const endpoint =
            `${end_point}${product_coverage_id}` +
            '/?policy_id=' +
            `${policy_id}` +
            '&current_member_id=' +
            `${member_id}` +
            '&member_number=' +
            `${member_id}`;

        return this.api.get(endpoint).pipe(
            map((data) => {
                const currentMC = {
                    id: product_coverage_id,
                    p_id: policy_id,
                    details: { ...data },
                };
                this.memberCoverages.value.push(currentMC);

                return currentMC;
            }),
            catchError((error) => of(error))
        );
    }

    // invoking claims API and returns response or error back from called
    getClaimList(member_id) {
        if (this.claimList.value && this.claimList.value.id && this.claimList.value.details) {
            return this.claimList.asObservable();
        }

        return this.api.get(`/api/member-360/claims/?member_id=${member_id}`).pipe(
            map((data) => {
                const currentClaimList = {
                    id: member_id,
                    details: { ...data },
                };
                this.claimList.next(currentClaimList);

                return currentClaimList;
            }),
            catchError((error) => throwError(error))
        );
    }

    // invoking claims lines list API and returns response or error back from called
    getClaimLinesList(claim_id: string, limitPageSize: boolean, currentPageNumber?: number, sortOrder?: string) {
        let url = `/api/member-360/claims/${claim_id}/lines/?`;
        if (currentPageNumber) {
            url += `page=${currentPageNumber}`;
        }
        if (sortOrder) {
            url += `&ordering=${sortOrder}`;
        }
        if (limitPageSize) {
            url += '&page_size=10';
        } else {
            url += '&page_size=-1';
        }

        return this.api.get(url).pipe(
            map((data) => {
                const currentClaimLinesList = {
                    id: claim_id,
                    details: { ...data },
                };
                this.claimLinesList.next(currentClaimLinesList);

                return currentClaimLinesList;
            }),
            catchError((error) => throwError(error))
        );
    }

    getClaimLinesDetail(claimId, lineNumber) {
        return this.api.get(`/api/member-360/claims/${claimId}/lines/${lineNumber}/additional-detail/`).pipe(
            map((data) => {
                const currentClaimLinesDetail = {
                    id: lineNumber,
                    details: { ...data },
                };
                this.claimLinesDetail.next(currentClaimLinesDetail);

                return currentClaimLinesDetail;
            }),
            catchError((error) => throwError(error))
        );
    }

    // invoking claimsDetails API and returns response or error back from called
    getClaimDetails(claim_id) {
        const endpoint = `/api/member-360/claims/${claim_id}/additional-detail/`;
        // This is a fixed endpoint
        // if (api_details.query_params.length === 0) {
        //     endpoint = `${api_details.endpoint}${claim_id}`;
        // }
        // if (api_details.query_params.length === 1) {
        //     endpoint = `${api_details.endpoint}${claim_id}` + `/${api_details.query_params[0]}/`;
        // }
        // checking if claim is already been called or not
        const hasClaimDetails =
            this.claimDetails.value && this.claimDetails.value.length && this.claimDetails.value.some((val) => val.id === claim_id);

        if (hasClaimDetails) {
            return this.claimDetails.pipe(map((claim) => claim.find((claim) => claim.id === claim_id)));
        }

        return this.api.get(endpoint).pipe(
            map((data) => {
                const currentClaimDetail = {
                    id: claim_id,
                    details: { ...data },
                };
                this.claimDetails.value.push(currentClaimDetail);

                return currentClaimDetail;
            }),
            catchError((error) => throwError(error))
        );
    }

    // invoking referrals API and returns response or error back from called
    getReferralsList(member_id) {
        if (this.referralList.value && this.referralList.value.id && this.referralList.value.details) {
            return this.referralList.asObservable();
        }

        return this.api.get(`/api/member-360/tocs/?member_id=${member_id}&toc_type=referral`).pipe(
            map((data) => {
                const currentRefList = {
                    id: member_id,
                    details: { ...data },
                };
                this.referralList.next(currentRefList);

                return currentRefList;
            }),
            catchError((error) => throwError(error))
        );
    }

    // invoking referralsDetails API and returns response or error back from called
    getReferralDetails(referral_id) {
        // checking if referral is already been called or not
        const tempArr = [];
        if (this.referralDetails.value.length) {
            this.referralDetails.value.forEach(function (val) {
                val.id === referral_id ? tempArr.push(true) : '';
            });
        }
        if (this.referralDetails.value.length && tempArr.length > 0) {
            return this.referralDetails.pipe(map((referral) => referral.find((referral) => referral.id === referral_id)));
        } else {
            return this.api.get(`/api/member-360/tocs/?number=${referral_id}`).pipe(
                map((data) => {
                    const currentRefDetail = {
                        id: referral_id,
                        details: { ...data },
                    };
                    this.referralDetails.value.push(currentRefDetail);

                    return currentRefDetail;
                }),
                catchError((error) => throwError(error))
            );
        }
    }

    // invoking pre-authorizations API and returns response or error back from called
    getAuthorizationsList(member_id) {
        if (this.authorizationList.value && this.authorizationList.value.id && this.authorizationList.value.details) {
            return this.authorizationList.asObservable();
        }

        return this.api.get(`/api/member-360/tocs/?member_id=${member_id}&toc_type=authorization`).pipe(
            map((data) => {
                const currentAuthList = {
                    id: member_id,
                    details: { ...data },
                };
                this.authorizationList.next(currentAuthList);

                return currentAuthList;
            }),
            catchError((error) => throwError(error))
        );
    }

    // invoking COB list API and returns response or error back from called
    getCOBList(policy_id, product_coverage_id, member_id) {
        return this.api
            .get(`/api/member-360/cobs/?policy_id=${policy_id}&member_id=${member_id}&member_coverage_id=${product_coverage_id}`)
            .pipe(
                map((data) => {
                    const currentCOB = {
                        id: product_coverage_id,
                        p_id: policy_id,
                        details: { ...data },
                    };
                    this.cobList.value.push(currentCOB);

                    return currentCOB;
                }),
                catchError((error) => of(error))
            );
    }

    getCoverageEligibilitiesList(member_coverage_id) {
        return this.api.get(`/api/member-360/coverage/eligibilities/?member_coverage_id=${member_coverage_id}`);
    }

    // invoking Providers API with configurable endpoint and member number which returns response or error back
    getPCPList(member_id: string): Observable<ResponseType<APIResponse<PCPsResponse>>> {
        if (this.pcpList.value && this.pcpList.value.id && this.pcpList.value.details) {
            return this.pcpList.asObservable();
        }

        return this.api.get(`/api/member-360/pcps/?member_id=${member_id}`).pipe(
            map((data: APIResponse<PCPsResponse>) => {
                const currentPCPList: ResponseType<APIResponse<PCPsResponse>> = {
                    id: member_id,
                    details: { ...data },
                };
                this.pcpList.next(currentPCPList);

                return currentPCPList;
            }),
            catchError((error: any) => of(error))
        );
    }

    getPCPDetails(member_id: string, provider_id: string): Observable<responseTypePC<ProviderDetailsResponse>> {
        const haspcpDetails =
            this.pcpDetails.value &&
            this.pcpDetails.value.length &&
            this.pcpDetails.value.some((val: responseTypePC<ProviderDetailsResponse>) => val.id === member_id && val.p_id === provider_id);

        if (haspcpDetails) {
            return this.pcpDetails.pipe(
                map((pc: responseTypePC<ProviderDetailsResponse>[]) =>
                    pc.find(
                        (provider: responseTypePC<ProviderDetailsResponse>) => provider.id === member_id && provider.p_id === provider_id
                    )
                )
            );
        }

        let url = `/api/member-360/providers/${provider_id}/`;
        if (member_id) {
            url += `?member_id=${member_id}`;
        }

        return this.api.get(url).pipe(
            map((data: ProviderDetailsResponse) => {
                const currentPCPDetails = {
                    id: member_id,
                    p_id: provider_id,
                    details: { ...data },
                };
                this.pcpDetails.value.push(currentPCPDetails);

                return currentPCPDetails;
            }),
            catchError((error: any) => throwError(error))
        );
    }

    // invoking pre-authorizationsDetails API and returns response or error back from called
    getAuthorizationDetails(auth_id) {
        // checking if pre-auth is already been called or not
        const tempArr = [];
        if (this.authorizationDetails.value.length) {
            this.authorizationDetails.value.forEach(function (val) {
                val.id === auth_id ? tempArr.push(true) : '';
            });
        }
        if (this.authorizationDetails.value.length && tempArr.length > 0) {
            return this.authorizationDetails.pipe(map((preAuth) => preAuth.find((preAuth) => preAuth.id === auth_id)));
        } else {
            return this.api.get(`/api/member-360/tocs/?number=${auth_id}`).pipe(
                map((data) => {
                    const currentClaimDetail = {
                        id: auth_id,
                        details: { ...data },
                    };
                    this.authorizationDetails.value.push(currentClaimDetail);

                    return currentClaimDetail;
                }),
                catchError((error) => throwError(error))
            );
        }
    }

    // invoking CEH API for communication prefences and returns response or error back
    getCommunicationPreferencesData(member_id) {
        if (this.cpData.value && this.cpData.value.id && this.cpData.value.details) {
            return this.cpData.asObservable();
        }

        return this.api.get(`api/member-360/cx/consumers/${member_id}/`).pipe(
            map((data) => {
                const currentCP = {
                    id: member_id,
                    details: { ...data },
                };
                this.cpData.next(currentCP);

                return currentCP;
            }),
            catchError((error) => of(error))
        );
    }

    // invoking CEH API for communication prefences and returns response or error back
    getWellnessData(member_id, coverage_id, policy_id, query_param) {
        let queryParam = '';
        if (query_param === 'policy_id') {
            queryParam = `policy_id=${policy_id}`;
        } else if (query_param === 'member_coverage_id') {
            queryParam = `member_coverage_id=${coverage_id}`;
        }

        return this.api.get(`api/member-360/members/${member_id}/program-rewards/?${queryParam}`).pipe(
            map((data) => {
                const currentWD = {
                    id: member_id,
                    details: { ...data },
                };
                this.wData.next(currentWD);

                return currentWD;
            }),
            catchError((error) => of(error))
        );
    }

    getMemberHomehostStatus() {
        if (this.currentMember) {
            return this.currentMember.details.is_external_plan_member;
        } else {
            return false;
        }
    }
}
