import { Injectable } from '@angular/core';

import { StringService } from '../../services/string.service';

import { Coordinates } from './coordinates.interface';
import { QueryList } from './query-list.type';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    constructor(private stringService: StringService) {}

    handleCheckboxRepeatedCommas(value: any): any {
        const stringValue = value.toString();
        if (stringValue.split(',').length > 1) {
            return value.filter(element => element !== null && element !== '');
        }
    }

    /**
     * test that a provided coordinate set is a valid location to pass to map
     * @param coordinates longitude and latitude for a given map point
     */
    areValidCoordinates(coordinates: Coordinates): boolean {
        // Everything else is not on this planet.
        const latMax = 90;
        const latMin = -latMax;
        const lngMax = 180;
        const lngMin = -lngMax;

        if (coordinates.lat < latMin || coordinates.lat > latMax) {
            return false;
        } // Invalid latitude
        if (coordinates.lng < lngMin || coordinates.lng > lngMax) {
            return false;
        } // Invalid longitude

        return true;
    }

    getDirections = (address: string) => `https://www.google.com/maps/dir/?api=1&origin=&destination=${address}`;

    /** Transforms a query list into a snake cased query string with queries separated by ampersands. */
    queryString = (queryList: QueryList): string => {
        const queryString = queryList.map(query => `${query.parameter}=${query.value}`).join('&');

        return this.stringService.snakeCase(queryString);
    };
}
