import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ConfigService, APIResponse } from 'src/app/shared/services';
import { MemberDataService, ResponseType } from 'src/app/icx/shared/services/member-data.service';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { PoliciesResponse } from './policies.constants';
export interface PoliciesPageConfig {
    [key: string]: any;
}
@Component({
    selector: 'policies',
    templateUrl: './policies.component.html',
    styleUrls: ['./policies.component.scss'],
})
export class PoliciesComponent implements OnInit {
    pageConfig: PoliciesPageConfig;
    policyList: PoliciesResponse[];
    context: any = {
        params: {},
    };

    constructor(
        private configService: ConfigService,
        private route: ActivatedRoute,
        private memberDataService: MemberDataService,
        private panelService: SidePanelService,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.context.params = this.route.snapshot.params;
        this.pageConfig = this.configService.getControlPanelConfig(this.route.snapshot.data['pageName']);

        this.memberDataService
            .getPoliciesList(this.context.params.member)
            .subscribe((resp: ResponseType<APIResponse<PoliciesResponse>>) => {
                resp.details.results ? (this.policyList = resp.details.results) : (this.policyList = []);
            });
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.PoliciesList_viewed }, this.context.params.member);
    }
}
