import { FormControlService } from '@zipari/design-system';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { TogglePanelService } from '../../services/toggle-panel.service';
import { SearchFilterService } from '../../services/search-filter.service';
import { camelCase, camelToSnakeCase } from '../../../../shared/modules/zip-busy/utils';

@Component({
    selector: 'panel-filter',
    templateUrl: './panel-filter.component.html',
    styleUrls: ['./panel-filter.component.scss'],
})
export class PanelFilterComponent implements OnInit {
    @Input() config;
    @Input() data;
    @Input() filteredData;
    @Input() isClaimRetain: boolean;

    @Output() dataChange: EventEmitter<any> = new EventEmitter();
    @Output() clearFormCalled: EventEmitter<any> = new EventEmitter();
    @Output() addQueryParams?: EventEmitter<string> = new EventEmitter();

    panelOpen = false;
    panelFilters;
    formGroup: FormGroup = new FormGroup({});

    constructor(
        private toggleSidePanel: TogglePanelService,
        private formService: FormControlService,
        public searchFilterService: SearchFilterService
    ) {}

    ngOnInit() {
        this.toggleSidePanel.change.subscribe((isOpen) => {
            this.panelOpen = isOpen;

            if (this.config) {
                this.setupFilters(this.config.filters.controls);
            }
        });
    }

    togglePanel() {
        this.toggleSidePanel.toggle();
    }

    setupFilters(filters) {
        if (this.searchFilterService?.panelFilterValue && this.isClaimRetain) {
            this.formGroup.patchValue(camelToSnakeCase(this.searchFilterService.panelFilterValue));
        }
        this.panelFilters = filters.map(this.createFormControl.bind(this));
    }

    createFormControl(config) {
        this.formService.addControlToFormGroup(this.formGroup, config);
        const control = new FormControl('', []);
        const newFormControl = Object.assign(config, {
            control: control,
        });

        if (config.value) {
            this.formGroup.get(newFormControl.prop).setValue(config.value);
        }

        return newFormControl;
    }

    clearForm() {
        this.formGroup.reset();
        this.searchFilterService.panelFilterValue = this.isClaimRetain ? camelCase(this.formGroup.value) : null;
        this.clearFormCalled.emit();
    }

    filterForm() {
        this.filteredData = Object.assign([], this.data);
        let queryString = '';
        this.searchFilterService.panelFilterValue = this.isClaimRetain ? this.formGroup.value : null;
        for (const key of Object.keys(this.formGroup.value)) {
            if (this.formGroup.value[key]) {
                const trimmedValue = this.formGroup.value[key].trim();
                queryString += `${key}=${trimmedValue}&`;
            }
        }
        this.addQueryParams.emit(queryString);

        for (const key of Object.keys(this.formGroup.value)) {
            this.filteredData = this.filteredData.filter((item) => {
                if (item[key]?.includes(this.formGroup.value[key])) {
                    this.dataChange.next(this.filteredData);

                    return item;
                }
            });
        }
        this.dataChange.next(this.filteredData);
    }
}
