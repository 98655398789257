import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, DatagridModule, PaginatorModule, TabsModule, FormGroupModule, RippleModule } from '@zipari/design-system';
import { BillingAndPaymentsComponent } from './billing-and-payments.component';

@NgModule({
    declarations: [BillingAndPaymentsComponent],
    imports: [CommonModule, TabsModule, FormGroupModule, DatagridModule, PaginatorModule, ButtonModule, RippleModule],
})
export class BillingAndPaymentsModule {}
