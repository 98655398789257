import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule, ModelAttributeModule } from '@zipari/design-system';
import { SharedModule } from './../../shared.module';
import { FormGroupModule } from './../form-group/form-group.module';
import { AddOrEditModalComponent } from './add-or-edit-modal.component';

@NgModule({
    declarations: [AddOrEditModalComponent],
    imports: [CommonModule, ButtonModule, FormGroupModule, SharedModule, ModelAttributeModule],
    exports: [AddOrEditModalComponent],
})
export class AddNewModalModule {}
