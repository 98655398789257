import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { AnalyticsService } from './analytics.service';
import { ModalService } from './modal.service';
import { WorkflowService } from './workflow.service';

@Injectable()
/**
 * Extends the workflow service when no specific funtionality is needed
 * Must provide with workflowType value
 * {provide: 'workflowType', useValue: 'aString'},
 */
export class GenericWorkflowService extends WorkflowService<any> {
    readonly workflowType;

    constructor(
        @Inject('workflowType') workflowType: string,
        public analyticsService: AnalyticsService,
        public http: HttpClient,
        public modalService: ModalService
    ) {
        super(analyticsService, http, modalService);
        this.workflowType = workflowType;
    }
}
