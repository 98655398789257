import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { HomeHostService } from './home-host.service';
import { ConfigService } from './../../../shared/services';

@Injectable({
    providedIn: 'root',
})
export class RouteGuard implements CanActivate {
    constructor(private homehostService: HomeHostService, private router: Router, private configService: ConfigService) {}
    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        // check to see if one can access routes
        if (!this.homehostService.canAccessRoute(next.data.pageName)) {
            this.router.navigate([`icx/member-360/${next.params.member}/profile`]);

            return false;
        }
        this.configService.setActivePageName(next.data.pageName);
        return true;
    }
}
