export class ProductCoverage {
    groupHeaderText: string;
    external_plan_name: string;
    product_coverage_type: string;
    product_coverage_id: string;
    termination_date: string;
    effective_date: string;
    member_id: string;
    member_type: string;
    selected_PCP: string;
    selected_PCD: string;
    selected_PCC: string;
    whosCovered: any;
    group_name: string;
    company_name: string;
    internal_plan_name: string;
    member_coverage_id: string;
    group_id: string;
    group_package_code: string;
    policy_number: string;
    member_display_identifier: string;
    isActive: boolean;

    constructor(product: any) {
        this.external_plan_name = product.external_plan_name;
        this.product_coverage_type = product.product_coverage_type;
        this.product_coverage_id = product.product_coverage_id;
        this.termination_date = product.termination_date;
        this.effective_date = product.effective_date;
    }
}

export enum Tabs {
    Overview = 'Overview',
    Household = 'Household',
    ActiveCoverages = 'Active Coverages',
    CommunicationPreferences = 'Communications Preferences',
    Contacts = 'Contacts',
}

export interface AuthContacts {
    full_name: string;
    company_name: string;
    policy_number: string;
    dates: string;
    contactList: any[];
}

export class MemberProfile {
    overview: any;
    household: any;
    selectedPolicyName: string;
    enrollment: ProductCoverage[];
    contact: AuthContacts;

    constructor() {
        this.overview = {};
        this.household = [];
        this.enrollment = new Array<ProductCoverage>();
        this.contact = {
            full_name: '',
            company_name: '',
            policy_number: '',
            dates: '',
            contactList: [],
        };
    }
}

export interface CEHData {
    e_consent: string;
    portal_user: string;
    portal_last_used_date: string;
    mobile_app_user: string;
}

export interface NoDataConfig {
    src: string;
    illustration: string;
    link_text?: string;
    illustration_text: string;
    illustration_additional_text?: string;
}
