import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule, FormGroupModule, ErrorEmptyModule, IconModule } from '@zipari/design-system';
import { PanelFilterModule } from '../../../shared/modules/panel-filter/panel-filter.module';
import { ResultsModule } from '../../../shared/modules/results/results.module';

import { ClaimsComponent } from './claims.component';

@NgModule({
    declarations: [ClaimsComponent],
    imports: [CommonModule, FormGroupModule, ButtonModule, ResultsModule, PanelFilterModule, ErrorEmptyModule, IconModule],
})
export class ClaimsModule {}
