import { Component, OnInit, Input } from '@angular/core';
import { FormattingService } from '@zipari/design-system';
import { WellnessData, WellnessRewards, Wellness } from './../coverage-details.model';

@Component({
    selector: 'wellness',
    templateUrl: './wellness.component.html',
    styleUrls: ['./wellness.component.scss'],
})
export class WellnessComponent implements OnInit {
    @Input() config: Wellness;
    @Input() data: WellnessData[];

    constructor(private formattingService: FormattingService) {}

    ngOnInit(): void {
        this.modifyData();
    }

    modifyData() {
        this.data.forEach((data) => {
            data.rewards.forEach((reward: WellnessRewards) => {
                reward.dates = `${this.formattingService.restructureValueBasedOnFormat(reward.effective_date, { format: 'DATE' })}${
                    reward.termination_date
                        ? ` - ${this.formattingService.restructureValueBasedOnFormat(reward.termination_date, { format: 'DATE' })}`
                        : ''
                }`;
            });
        });
    }
}
