import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, UrlSerializer } from '@angular/router';
import { getNewUrlWithMergedQueryParams, getValue } from '@zipari/web-utils';
import { BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';

export class BrokerAssistanceConfig {
    retrieveDetails: boolean = true;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

@Injectable({
    providedIn: 'root',
})
export class BrokerAssistanceService {
    public config: BrokerAssistanceConfig;
    private broker;
    public assistingBroker$ = new BehaviorSubject(null);

    public set assistingBroker(broker) {
        this.assistingBroker$.next(broker);
        this.broker = broker;
    }

    public get assistingBroker() {
        return this.broker;
    }

    constructor(public router: Router, public urlSerializer: UrlSerializer, private http: HttpClient, public configService: ConfigService) {
        const globalConfig: any = this.configService.getPageConfig('global');
        this.config = new BrokerAssistanceConfig(globalConfig.brokerAssistance);
    }

    checkForBrokerAssistance(route: ActivatedRoute, workflowValues) {
        let brokerId = getValue(route, 'snapshot.queryParams.broker_id');
        if (brokerId) {
            this.retrieveBrokerByBrokerId(brokerId, 'query');
        }

        if (!brokerId && workflowValues.current_broker) {
            brokerId = getValue(workflowValues, 'current_broker.broker_id');
            this.retrieveBrokerByBrokerId(brokerId, 'user');
        }
    }

    retrieveBrokerByBrokerId(brokerId: string, source: string) {
        if (this.config.retrieveDetails) {
            const endpoint = `api/member-360/brokers/search/?search=${brokerId}`;

            this.http.get(endpoint).subscribe((response: any) => {
                const broker: any = response.results[0];
                broker['source'] = source;

                this.assistingBroker = broker;
            });
        } else this.assistingBroker = { broker_id: brokerId };
    }

    formatBrokerUrl(shoppingUrl: string, broker_id: string, extraQueryParams?: any): string {
        const queryParams = {
            broker_id,
        };

        if (extraQueryParams) {
            Object.keys(extraQueryParams).forEach((key) => {
                queryParams[key] = extraQueryParams[key];
            });
        }

        return getNewUrlWithMergedQueryParams(this.urlSerializer, shoppingUrl, queryParams);
    }
}
