import {
    ComponentFactory,
    ComponentFactoryResolver,
    ComponentRef,
    Directive,
    DoCheck,
    Injector,
    Input,
    OnInit,
    ViewContainerRef,
    TemplateRef,
} from '@angular/core';

import { Subscription } from 'rxjs/Subscription';

import { ZipBusyComponent } from './zip-busy.component';
import { ZipBusyService } from './zip-busy.service';

@Directive({
    selector: '[zipBusy]',
    // Private instance of the busy tracker per directive
    providers: [ZipBusyService],
})
export class ZipBusyDirective implements OnInit, DoCheck {
    @Input('zipBusy') appBusy: (Promise<any> | Subscription)[] = [];
    @Input() position: string;
    @Input() size = 8;
    @Input() customTemplate: TemplateRef<any>;
    busyComponentFactory: ComponentFactory<any>;
    busyComponent: ComponentRef<ZipBusyComponent>;

    constructor(private promiseTracker: ZipBusyService, private resolver: ComponentFactoryResolver, private container: ViewContainerRef) {}

    ngOnInit() {
        this.busyComponentFactory = this.resolver.resolveComponentFactory<ZipBusyComponent>(ZipBusyComponent);
        // Passing the private promise tracker into the component so multiple don't spin on the same page
        const injector: Injector = Injector.create({
            providers: [
                {
                    provide: ZipBusyService,
                    useValue: this.promiseTracker,
                },
            ],
        });
        this.busyComponent = this.container.createComponent(this.busyComponentFactory, 0, injector);
        this.busyComponent.instance.position = this.position;
        this.busyComponent.instance.style = { width: `${this.size}rem`, height: `${this.size}rem` };
        this.busyComponent.instance.customTemplate = this.customTemplate;
        this.busyComponent.changeDetectorRef.detectChanges();
    }

    // run on changes to check if the promise has started or ended
    ngDoCheck() {
        this.promiseTracker.checkPromises(this.appBusy);
    }
}
