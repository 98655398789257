import { Component, OnInit } from '@angular/core';
import { BannerOptions } from 'src/app/shared/modules/authentication/authentication.constants';
import { ConfigService, AuthService } from 'src/app/shared/services';
import { Router, ActivatedRoute } from '@angular/router';
import { ZipBackendErrorResponse, ZipEndpointService } from 'src/app/shared/services/zip-endpoint.service';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { IcxService } from '../../services/icx.service';

export interface UsernameAndPassword {
    username: string;
    password: string;
}

@Component({
    selector: 'cx360-auth',
    templateUrl: './auth.component.html',
    styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
    public footerConfig: BannerOptions;
    public config: any;

    constructor(
        private configService: ConfigService,
        private authService: AuthService,
        private router: Router,
        public route: ActivatedRoute,
        public zipEndpointService: ZipEndpointService,
        private panelService: SidePanelService,
        private icxService: IcxService
    ) {
        this.panelService.toggleSidePanel(false);
    }

    ngOnInit(): void {
        this.initConfig();
    }

    initConfig() {
        this.config = this.configService.getControlPanelConfig('auth');
        this.footerConfig = this.configService.getPageConfig('login');

        if (this.config.idp === 'okta') {
            this.router.navigate(['icx/member-360/auth/okta']);
        }
    }

    submitLogin(event: UsernameAndPassword) {
        if (!event) {
            return;
        }
        this.authService.login(event).subscribe(
            response => {
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        fromEmail: null,
                    },
                    queryParamsHandling: 'merge',
                });

                new Promise<void>((resolve, reject) =>
                    this.authService
                        .getUser({})
                        .then(app_user => {
                            this.authService.setLoggedInUser(app_user);
                            this.handleAuthSuccess(response);
                            resolve();
                        })
                        .catch((error: ZipBackendErrorResponse) => {
                            console.error(error);
                            reject(error);
                        })
                );
            },
            (error: ZipBackendErrorResponse) => {
                console.error(error);
            }
        );
    }

    submitRegistration(value: any): void {
        // console.log(value);
    }

    submitForgotPassword(value: any): void {
        // console.log(value);
    }

    submitForgotUsername(value: any): void {
        // console.log(value);
    }

    handleRedirect(url: string) {
        if (url.includes('http')) {
            window.location.assign(url);
        } else {
            this.router.navigate([url]);
        }
    }
    handleAuthSuccess(response) {
        if (this.authService.replayPath) {
            this.router.navigate([this.authService.replayPath]);

            this.authService.replayPath = null;
        } else if (response['redirect_path'] && response['redirect_path'] !== '/' && new URL(response['redirect_path']).pathname !== '/') {
            this.handleRedirect(response['redirect_path']);
        } else if (this.config.routeAfterSuccessConfig && this.config.routeAfterSuccessConfig.route) {
            this.routeAfterSuccess();
        }
    }

    routeAfterSuccess(id = null) {
        if (this.route.snapshot.data.indexRoute === 'icx' && this.config.routeAfterSuccessConfig.route) {
            this.config.routeAfterSuccessConfig.route = this.icxService.setIcxRouteAfterLogin(this.config);
        }

        const routes = [this.config.routeAfterSuccessConfig.route];

        if (id) {
            routes.push(String(id));
        }

        this.router.navigate(routes);
    }
}
