import { StatusType } from '@zipari/design-system';

export class BadgeConfig {
    label: string;
    value: string;
    fields: any;
    link?: string;
    type?: BadgeTypes = BadgeTypes.priority;
    size?: BadgeSizes = BadgeSizes.large;
    color?: BadgeColors = BadgeColors.white;
    status?: StatusType;
    format?: string;

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export enum BadgeTypes {
    flat = 'flat',
    priority = 'priority',
}

export enum BadgeSizes {
    small = 'small',
    large = 'large',
}

export enum BadgeColors {
    white = 'white',
    blue = 'blue',
}
