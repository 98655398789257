import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService, ConfigService } from '../../shared/services';

@Component({
    selector: 'groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss'],
})
export class GroupsComponent implements OnInit {
    pageConfig;
    member;
    provider;
    providerCallMade;
    sidebarModelAttributeConfig = [
        {
            label: 'Status',
            value: 'Active',
        },
        {
            label: 'Effective Date',
            value: 'MM/DD/YYYY',
        },
        {
            label: 'Termination Date',
            value: 'MM/DD/YYYY',
        },
    ];

    generalInfoConfiguration = [
        {
            label: 'Status',
            value: 'Active',
        },
        {
            label: 'Group Type',
            value: 'Small',
        },
        {
            label: 'Rating Region',
            value: 'South Central',
        },
        {
            label: 'Number of Employees',
            value: '4',
        },
        {
            label: 'Covered Lives',
            value: '8',
        },
    ];

    contactInfoConfiguration = [
        {
            label: 'Mobile Phone',
            value: '(340) 403 - 3493',
            format: 'PHONE',
        },
        {
            label: 'Email',
            value: 'tina@bluemtndairy.com',
            format: 'EMAIL',
        },
        {
            label: 'Primary Address',
            value: '45 First Street Apt 575 Albany, NY 12208',
        },
    ];

    enrollmentDetailsConfiguration = [
        {
            label: 'Enrollment Date',
            value: 'MM/DD/YYYY',
        },
        {
            label: 'Effective Start Date',
            value: 'MM/DD/YYYY',
        },
        {
            label: 'Termination Date',
            value: 'MM/DD/YYYY',
        },
        {
            label: 'Renewal Date',
            value: 'MM/DD/YYYY',
        },
    ];

    teamDetailsConfiguration = [
        {
            label: 'Ricky Rhodes',
            value: 'Account Manager',
        },
        {
            label: 'Alice Mullins',
            value: 'Sales Rep',
        },
        {
            label: 'Cornelia Page',
            value: 'Cast Manager',
        },
        {
            label: 'Charlie Nash',
            value: 'Under Writing',
        },
    ];

    constructor(private configService: ConfigService, private route: ActivatedRoute, private api: APIService) {}

    ngOnInit() {
        this.pageConfig = this.configService.getPageConfig(this.route.snapshot.data['pageName']);

        const memberId = this.route.snapshot.queryParams['member_id'];

        this.api.get(`/api/member-360/members/${memberId}/pcp`).subscribe(
            (resp) => {
                const providerId = resp.provider_id;
                // this.provider = resp; // for local
                // this.provider['hmo'] = false; // for local
                // this.providerCallMade = true;
                this.getPCP(providerId);
            },
            (error) => {
                this.providerCallMade = true;
            }
        );
    }

    getPCP(id) {
        this.api.get(`/api/scapi/providers/${id}`).subscribe((resp) => {
            this.provider = resp;
            this.provider['hmo'] = false;
            this.providerCallMade = true;
        });
    }
}
