import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, EventEmitter, HostListener, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';

import { ConfirmationModalComponent, ConfirmationModalConfig } from '../components/confirmation-modal/confirmation-modal.component';

@Directive({
    selector: '[triggerConfirmationModal]',
})
export class TriggerConfirmationModalDirective implements OnDestroy {
    @Input('triggerConfirmationModal') config: ConfirmationModalConfig;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('modalContext') context;
    @Output() modalConfirm = new EventEmitter();
    @Output() modalCancel = new EventEmitter();

    cancelSubscription: Subscription;
    confirmSubscription: Subscription;
    constructor(private overlay: Overlay) {}

    @HostListener('click', ['$event'])
    onHostClick(event) {
        if (!this.config) {
            return;
        }
        // Use CDK to attach dynamic modal component to overlay
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: ['modal__mask-modal'],
        });
        const confirmationModalComponent = new ComponentPortal(ConfirmationModalComponent);
        const containerRef = overlayRef.attach(confirmationModalComponent);

        // Handle modal component inputs and outputs
        containerRef.instance.config = this.config;
        containerRef.instance.context = this.context;
        this.cancelSubscription = containerRef.instance.cancel.subscribe(() => {
            overlayRef.detach();
        });
        this.confirmSubscription = containerRef.instance.confirm.subscribe(() => {
            this.modalConfirm.next();
            overlayRef.detach();
        });
    }

    ngOnDestroy() {
        if (this.cancelSubscription) {
            this.cancelSubscription.unsubscribe();
        }

        if (this.confirmSubscription) {
            this.confirmSubscription.unsubscribe();
        }
    }
}
