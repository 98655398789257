import { animation, trigger, style, animate, transition, useAnimation } from '@angular/animations';

const slideInFromLeft = animation([
    style({ transform: 'translateX(-100%)' }),
    animate('500ms ease', style({ transform: 'translateX(0)' })),
]);

const slideOutToLeft = animation([animate('500ms ease', style({ transform: 'translateX(-100%)' }))]);

const slideInFromRight = animation([
    style({ transform: 'translateX(100%)' }),
    animate('500ms ease', style({ transform: 'translateX(0)' })),
]);

const slideOutToRight = animation([animate('500ms ease', style({ transform: 'translateX(100%)' }))]);

const slideInFromBottom = animation([
    style({ transform: 'translateY(100%)' }),
    animate('500ms ease', style({ transform: 'translateY(0)' })),
]);

const slideOutToBottom = animation([animate('500ms ease', style({ transform: 'translateY(100%)' }))]);

const slideInFromTop = animation([style({ transform: 'translateY(-100%)' }), animate('500ms ease', style({ transform: 'translateY(0)' }))]);

const slideOutToTop = animation([animate('500ms ease', style({ transform: 'translateY(-100%)' }))]);

export const slideRight = trigger('slideRight', [
    transition(':enter', [useAnimation(slideInFromRight)]),
    transition(':leave', [useAnimation(slideOutToRight)]),
]);

export const slideLeft = trigger('slideLeft', [
    transition(':enter', [useAnimation(slideInFromLeft)]),
    transition(':leave', [useAnimation(slideOutToLeft)]),
]);

export const slideUp = trigger('slideUp', [
    transition(':enter', [useAnimation(slideInFromTop)]),
    transition(':leave', [useAnimation(slideOutToTop)]),
]);

export const slideDown = trigger('slideDown', [
    transition(':enter', [useAnimation(slideInFromBottom)]),
    transition(':leave', [useAnimation(slideOutToBottom)]),
]);

export const slide = trigger('slide', [
    transition(':enter', [
        style({ transform: 'translate3d({{fromX}}, {{fromY}}, 0' }),
        animate('{{time}} {{bezier}}', style({ transform: 'translate3d({{toX}}, {{toY}}, 0)' })),
    ]),
    transition(':leave', [animate('{{time}} {{bezier}}', style({ transform: 'translate3d({{fromX}}, {{fromY}}, 0)' }))]),
]);
