import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from '@zipari/design-system';
import { HomePageComponent } from './home-page.component';
import { ProfileSearchModalModule } from './../shared/profile-search/profile-search-modal.module';

@NgModule({
    declarations: [HomePageComponent],
    imports: [CommonModule, ButtonModule, ProfileSearchModalModule],
})
export class HomePageModule {}
