import { SidePanelService } from './../../../shared/services/side-panel.service';
import { ConfigService, APIService, APIResponse } from 'src/app/shared/services';
import { ChangeDetectorRef, Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Datagrid, FormattingService, MessageBannerConfig, PaginatorConfiguration } from '@zipari/design-system';

import { PaginatorService } from './../../../shared/services/paginator.service';
import { MemberDataService } from './../../../shared/services/member-data.service';
import { LinkParamPlaceholder } from './../../../shared/constants/link-placeholder';
import { AnalyticsService } from './../../../../shared/services/analytics.service';
import { validCXEvents } from '../../../../shared/constants/analytics';
interface MemberEngagementConfig {
    banner: MessageBannerConfig;
    header: string;
    tabs: {
        live: MemberEngagementTab;
        historical: MemberEngagementTab;
    };
    subHeader: string;
    sorting: {
        key: string;
        order: string;
    };
}
interface MemberEngagementTab {
    label: string;
    datagrid: Datagrid;
    prop: string;
    paginator: number;
    pagingConfig: PaginatorConfiguration;
}

export interface Engagement {
    id: number;
    state: string;
    priority_score: string;
    engagement: {
        name: string;
        engagement_app: {
            key: string;
            name: string;
            config: {};
        };
        template: {
            key: string;
            name: string;
        };
        data: {
            title: string;
            description: string;
            service_action_keys: string[];
        };
        outcomes: {
            state: string;
            event: {
                key: string;
                name: string;
                dictionary_attribute_schema: {
                    type: string;
                    required: [];
                    properties: {};
                };
            };
        }[];
    };
    goal: {
        key: string;
        name: string;
        description: string;
        category: string;
        department: string;
    };
}

@Component({
    selector: 'member-engagement',
    templateUrl: './member-engagement.component.html',
    styleUrls: ['./member-engagement.component.scss'],
})
export class MemberEngagementComponent implements OnInit {
    pageConfig: MemberEngagementConfig;
    sortingElement: string = '';
    memberName: string;
    memberId: string;
    currentTabData: Engagement[] = [];
    allTabs: MemberEngagementTab[] = [];
    activeTab: MemberEngagementTab;
    selectedTabIndex: number = 0;
    tabLabels: string[] = [];
    pagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 1,
        showingEnd: 1,
        totalResults: 0,
        currentPage: 1,
    };
    historicalEngagements: Engagement[] = [];
    liveEngagements: Engagement[] = [];
    isLoadingData: boolean = false;
    private currentTabIndex: number = 0;
    pageSubTitle: string;
    constructor(
        private configService: ConfigService,
        private api: APIService,
        private route: ActivatedRoute,
        private panelService: SidePanelService,
        private memberDataService: MemberDataService,
        private paginatorService: PaginatorService,
        private formatService: FormattingService,
        private analyticsService: AnalyticsService,
        private elementRef: ElementRef,
        private cdref: ChangeDetectorRef
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit(): void {
        this.isLoadingData = true;
        this.pageConfig = this.configService.getControlPanelConfig(this.route.snapshot.data['pageName']);

        this.modifyConfig();

        this.getMemberName();
        this.selectedTab(0);
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.CXEngagements_viewed }, this.memberId);
    }

    getMemberName(): void {
        this.memberDataService.getMemberData(this.route.snapshot.params.member).subscribe((resp: any) => {
            if (resp && resp.details) {
                this.memberId = resp.details.id;
                this.memberName = `${resp.details.name.first_name} ${resp.details.name.last_name}'s`;
                this.pageSubTitle = this.getPageSubTitle();
                this.cdref.detectChanges();
                this.addSSoLinKClickListener();
            }
        });
    }

    selectedTab(tabIndex: number) {
        if (this.currentTabIndex !== tabIndex) {
            this.pagingConfig.currentPage = 1;
        }
        this.currentTabIndex = tabIndex;
        this.isLoadingData = true;
        this.activeTab = this.allTabs && this.allTabs[tabIndex];
        if (tabIndex === 1) {
            this.getHistoricalEngagements();
        } else {
            this.getLiveEngagements();
        }
    }

    sortColumnData(element: any) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.selectedTab(this.currentTabIndex);
    }

    setCurrentPage(page: number) {
        this.pagingConfig.currentPage = page;

        this.pagingConfig.showingStart = page === 1 ? 1 : this.activeTab?.paginator * (page - 1) + 1;

        this.pagingConfig.showingEnd =
            page === this.pagingConfig.totalPages ? this.pagingConfig.totalResults : this.activeTab?.paginator * page;
        this.selectedTab(this.currentTabIndex);
    }

    getLiveEngagements() {
        this.liveEngagements = [];
        const endpoint = 'api/member-360/cx/connectors/recos/?consumer_id=';
        const query = this.buildQueryParams(endpoint);
        this.api.get(query).subscribe(
            (res: APIResponse<any>) => {
                this.liveEngagements = res.results;
                this.currentTabData = this.liveEngagements.map((item) => {
                    return {
                        ...item,
                        engagement_name: item.engagement.data?.title,
                        goal_name: item.goal.name,
                        engagement_app_name: item.engagement.engagement_app.name,
                    };
                });
                this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, res.count, this.activeTab.paginator);
                this.isLoadingData = false;
            },
            () => {
                this.isLoadingData = false;
                this.currentTabData = [];
                this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, 0, this.activeTab.paginator);
            }
        );
    }

    getHistoricalEngagements() {
        this.historicalEngagements = [];
        const endpoint = 'api/cx/engagement/connectors/historical-recos/?consumer_id=';
        const query = this.buildQueryParams(endpoint);

        this.api.get(query).subscribe(
            (res: APIResponse<any>) => {
                this.historicalEngagements = res.results;
                this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, res.count, this.activeTab.paginator);
                this.currentTabData = this.historicalEngagements;
                this.isLoadingData = false;
            },
            () => {
                this.isLoadingData = false;
                this.currentTabData = [];
                this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, 0, this.activeTab.paginator);
            }
        );
    }

    buildQueryParams(endpoint: string) {
        let initialSort = '';

        endpoint += `${this.route.snapshot.params.member}&`;

        endpoint += `page=${this.pagingConfig.currentPage}&`;
        if (this.activeTab?.paginator) {
            endpoint += `page_size=${this.activeTab.paginator}&`;
        }

        if (this.pageConfig?.sorting && this.pageConfig?.sorting.key && this.pageConfig?.sorting.order && !this.sortingElement) {
            if (this.pageConfig?.sorting.order === 'asc') {
                initialSort = this.pageConfig?.sorting.key;
            } else if (this.pageConfig?.sorting.order === 'desc') {
                initialSort = `-${this.pageConfig?.sorting.key}`;
            }
            endpoint += `ordering=${initialSort}&`;
        } else if (this.sortingElement) {
            endpoint += `ordering=${this.sortingElement}&`;
        }

        return endpoint.slice(0, -1);
    }

    modifyConfig() {
        this.allTabs =
            this.pageConfig?.tabs &&
            Object?.keys(this.pageConfig?.tabs).map((key: string) => {
                if (this.pageConfig?.tabs[key]) {
                    return this.pageConfig?.tabs[key];
                }
            });
        this.allTabs = this.allTabs?.filter((tab) => !!tab);

        this.tabLabels = this.allTabs?.map((tab: MemberEngagementTab) => tab.label);
    }

    getPageSubTitle(): string {
        let updatedTitle = this.formatService.formatConfigValueAsTemplate(
            { value: this.pageConfig?.subHeader },
            { name: this.memberName }
        ).value;

        if (updatedTitle && updatedTitle.includes(LinkParamPlaceholder.MemberId)) {
            updatedTitle = updatedTitle.replace(LinkParamPlaceholder.MemberId, this.memberId);
        }

        return updatedTitle ? updatedTitle : null;
    }

    ssoLinkClicked(): void {
        this.analyticsService.sendSSOCxEvent(this.memberId);
    }

    addSSoLinKClickListener(): void {
        this.elementRef.nativeElement.querySelector('#subTitle a')?.addEventListener('click', () => {
            this.ssoLinkClicked();
        });
    }
}
