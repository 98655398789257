import { Injectable } from '@angular/core';
import { FormControlConfiguration } from '@zipari/design-system';
import { getValue } from '@zipari/web-utils';
import { SSOProp } from '../../member/modules/claims/claims.constant';

@Injectable({
    providedIn: 'root',
})
export class ClaimsService {
    getSSOQueryParams(datagrid, target, member_id): string {
        let queryParams = '';

        const queryParamProps = target.options.filter((option: { label: string }) => {
            if (option.label === SSOProp.member_id) {
                queryParams += `${SSOProp.member_id}=${member_id}&`;
            }
            return datagrid?.columns?.find((column: FormControlConfiguration) => column.prop === option.label);
        });

        queryParamProps.map((queryParamProp: { label: string }) => {
            let queryParamValue = getValue(target, queryParamProp.label);
            queryParams += `${queryParamProp.label}=${queryParamValue}&`;
        });

        return queryParams.slice(0, queryParams.length - 1);
    }
}
