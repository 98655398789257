import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ButtonModule, ModelAttributeModule } from '@zipari/design-system';
import { SharedModule } from '../../shared.module';
import { MobileMenuComponent } from './mobile-menu/mobile-menu.component';

import { NavigationComponent } from './navigation.component';
import { TabletToolbarComponent } from './tablet-toolbar/tablet-toolbar.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

@NgModule({
    imports: [CommonModule, FormsModule, RouterModule, ButtonModule, SharedModule, ModelAttributeModule],
    declarations: [NavigationComponent, ToolbarComponent, MobileMenuComponent, TabletToolbarComponent],
    exports: [NavigationComponent],
})
export class NavigationModule {}
