import { Injectable } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';

import 'rxjs/operator/finally';

@Injectable()
export class ZipBusyService {
    promiseList: (Promise<any> | Subscription)[] = [];

    /**
     * Accepts an array or a single busy subscription
     * */
    checkPromises(promiseList: (Promise<any> | Subscription)[] | Promise<any> | Subscription) {
        if (promiseList instanceof Array) {
            promiseList.forEach(promise => {
                this.testPromise(promise);
            });
        } else {
            this.testPromise(promiseList);
        }
    }

    /**
     * Checking to see if the promise has already been fulfilled
     * If not adding promise to promise list
     * */
    private testPromise(promise: Promise<any> | Subscription) {
        if (!promise || promise['busyFulfilled']) {
            return;
        }
        // if (!equals(promise, this.promiseList)) {
        this.addPromise(promise);
        // }
    }

    /**
     * Checks if promise is and instance of Sub or Promise
     * Then adds a finishPromise call to the end of each
     * So that finishPromise will be called when the Promise/Subscription resolves
     */
    public addPromise(promise: Promise<any> | Subscription) {
        if (this.promiseList.indexOf(promise) !== -1) {
            return;
        }

        this.promiseList.push(promise);

        if (promise instanceof Promise) {
            promise.then.call(
                promise,
                () => this.finishPromise(promise),
                () => this.finishPromise(promise)
            );
        } else if (promise instanceof Subscription) {
            promise.add(() => {
                this.finishPromise(promise);
            });
        }
    }

    /**
     * Marks the promise as finished and removes it from the array
     */
    private finishPromise(promise: Promise<any> | Subscription) {
        promise['busyFulfilled'] = true;
        const index = this.promiseList.indexOf(promise);
        if (index === -1) {
            return;
        }
        this.promiseList.splice(index, 1);
    }

    /**
     * Flag to determine if we should show spinner
     */
    public isActive() {
        return this.promiseList.length > 0;
    }
}
