import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MessageBannerModule, DatagridModule, PaginatorModule, TabsModule, IconModule } from '@zipari/design-system';
import { MemberEngagementComponent } from './member-engagement.component';

@NgModule({
    declarations: [MemberEngagementComponent],
    imports: [CommonModule, MessageBannerModule, DatagridModule, PaginatorModule, TabsModule, IconModule],
})
export class MemberEngagementModule {}
