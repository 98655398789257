import { Injectable } from '@angular/core';

import { cloneObject } from '@zipari/web-utils';
import { validGAEvents } from './../constants/analytics';

export class GAEventOptions {
    eventCategory: string;
    eventAction: string;
    eventLabel: string = null;
    eventValue: any = null;
    hitType = 'event';

    constructor(options) {
        if (options) {
            const newOptions = cloneObject(options);
            if (newOptions.eventLabel) {
                this.eventLabel = newOptions.eventLabel;
            } else {
                console.warn('No key provided for GA');
            }

            if (newOptions.dictionary_attributes.eventCategory) {
                this.eventCategory = newOptions.dictionary_attributes.eventCategory;
                delete newOptions.dictionary_attributes.eventCategory;
            } else {
                this.eventCategory = this.eventLabel;
            }

            if (newOptions.dictionary_attributes.eventAction) {
                this.eventAction = newOptions.dictionary_attributes.eventAction;
                delete newOptions.dictionary_attributes.eventAction;
            } else {
                this.eventAction = this.eventLabel;
            }

            this.eventValue = 0;
        } else {
            console.warn('No options provided for GA call');
        }
    }
}

@Injectable({
    providedIn: 'root',
})
export class GoogleAnalyticsService {
    constructor() {}

    public emitGAEvent(key: validGAEvents, dictionary_attributes: any = {}) {
        if (typeof gtag !== 'undefined') {
            gtag('event', key, dictionary_attributes);
        }

        if (typeof ga !== 'undefined') {
            const formattedOptions = new GAEventOptions({
                eventLabel: key,
                dictionary_attributes: dictionary_attributes,
            });

            ga('send', formattedOptions);
        }
    }
}
