import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { GenericType } from 'src/app/icx/shared/services/icx.service';
import { FormattingService, DropdownConfiguration } from '@zipari/design-system';

import { HomeHostConfig } from 'src/app/icx/shared/services/home-host.service';
import { sortTabs } from 'src/app/icx/shared/utils/sorting-tabs';
import { ConfigService, AuthService, APIResponse } from '../../../../shared/services';
import { MemberDataService, ResponseType, MemberData, MemberAddress } from '../../../shared/services/member-data.service';
import { PoliciesResponse, PoliciesProductCoverage, PoliciesMemberCoverages } from '../policies/policies.constants';
import { PoliciesContact } from './../policies/policies.constants';
import { MemberProfile, Tabs, ProductCoverage, CEHData, NoDataConfig } from './member-profile.constants';

interface MemberProfilePageConfig {
    tabs: {
        overview: MemberProfileTabsConfig;
        actviceCoverages: MemberProfileTabsConfig;
        communicationPreferences: MemberProfileTabsConfig;
        household?: MemberProfileTabsConfig;
    };
    header: string;
}

interface MemberProfileTabsConfig {
    tabLabel: string;
    sections: MemberProfileSections[];
    position: number;
}

type MemberProfileSections = GenericType;

interface MemberProfileDisplayConfig {
    tabConfigs: MemberProfileSections;
    labels: Tabs[];
}

@Component({
    selector: 'member-profile',
    templateUrl: './member-profile.component.html',
    styleUrls: ['./member-profile.component.scss'],
})
export class MemberProfileComponent implements OnInit {
    pageConfig: MemberProfilePageConfig;
    memberId;
    context;
    isPageLoading: boolean = true;
    profile: MemberProfile = new MemberProfile();
    selectedTab: Tabs;
    CEH_Data: CEHData;
    pageLevelControls: DropdownConfiguration[] = [];
    selectedPolicyAuthContacts: string = '';
    policyList: PoliciesResponse[] = [];
    noDataConfig: NoDataConfig;

    displayConfig: MemberProfileDisplayConfig = {
        tabConfigs: [],
        labels: [],
    };
    isHomeHostMember: boolean = false;
    homeHostConfig: HomeHostConfig;

    constructor(
        private configService: ConfigService,
        public route: ActivatedRoute,
        private router: Router,
        private authService: AuthService,
        private panelService: SidePanelService,
        private memberDataService: MemberDataService,
        private formattingService: FormattingService,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(false);
        this.selectedTab = Tabs.Overview;
    }

    ngOnInit() {
        this.pageConfig = this.configService.getControlPanelConfig('profile');
        this.homeHostConfig = this.configService.getControlPanelConfig('home-host');
        this.memberId = this.route.snapshot.params.member;
        this.loadMemberOverview();
        if (this.pageConfig?.tabs?.communicationPreferences) {
            this.loadCEH();
        }

        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Profile_viewed }, this.memberId, {
            member_profile_tab_viewed: [this.selectedTab],
        });
    }

    loadMemberOverview() {
        if (!this.authService.loggedInUser) return;

        this.memberDataService.getMemberData(this.memberId).subscribe((resp: any) => {
            this.isPageLoading = false;
            if (resp.ok === false) {
                this.router.navigate(['/icx/member-360/home']);
            }
            this.isHomeHostMember = resp.details.is_external_plan_member;

            this.profile.overview = resp.details;
            this.profile.overview.physical_address = this.profile.overview?.addresses?.filter(
                (address: MemberAddress) => address.address_type.toLowerCase() === 'physical'
            )[0];
            this.profile.overview.mailing_address = this.profile.overview?.addresses?.filter(
                (address: MemberAddress) => address.address_type.toLowerCase() === 'mailing'
            )[0];
            this.context = this.profile.overview;
            this.modifyPageConfig();
        });
    }

    tabSelected(selectedTabIndex: number) {
        this.selectedTab = this.displayConfig.labels[selectedTabIndex];
        if (this.displayConfig?.tabConfigs[selectedTabIndex]?.noData) {
            this.noDataConfig = this.displayConfig.tabConfigs[selectedTabIndex].noData;
        }
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Profile_viewed }, this.memberId, {
            member_profile_tab_viewed: [this.selectedTab],
        });

        switch (this.selectedTab) {
            case Tabs.Household:
                this.context = this.profile.household;
                break;

            case Tabs.ActiveCoverages:
                this.context = this.profile.enrollment;
                this.groupCoveragesByCoverageType(this.context);
                break;

            case Tabs.CommunicationPreferences:
                this.context = this.CEH_Data;
                break;

            case Tabs.Contacts:
                this.context = this.profile.contact;
                break;

            default:
                this.context = this.profile.overview;
                break;
        }
    }

    loadPolicies() {
        this.memberDataService.getPoliciesList(this.memberId).subscribe((resp: ResponseType<APIResponse<PoliciesResponse>>) => {
            if (resp.details.results) {
                this.policyList = [...resp.details.results];
                this.selectedPolicyAuthContacts = this.policyList[0].policy_number;
                this.loadCoverageData(resp.details.results);
                this.loadAuthContacts();
            }
        });
    }

    loadCoverageData(policies: PoliciesResponse[]) {
        this.profile.enrollment = new Array<ProductCoverage>();

        policies
            .filter((p: PoliciesResponse) => p.policy_status === 'active')
            .forEach((policy: PoliciesResponse) => {
                policy.product_coverages.forEach((product: PoliciesProductCoverage) => {
                    // load household tab data
                    this.loadHouseholdData(product);

                    if (this.isCoverageActive(product)) {
                        // if logged in member present in member_cov load Enrollment tab data
                        if (
                            product.member_coverages.find(
                                (mcov) => mcov?.member?.member_number === this.memberId || mcov?.member?.id === this.memberId
                            )
                        ) {
                            this.loadEnrollmentData(product, policy);
                        }
                    }
                });
            });
    }

    loadHouseholdData(productCoverage: PoliciesProductCoverage) {
        productCoverage.member_coverages.forEach((member_coverage: PoliciesMemberCoverages) => {
            const existingMember = this.profile.household.find(
                (member: MemberData) =>
                    member.member_number === member_coverage?.member?.member_number || member.member_number === member_coverage?.member?.id
            );

            // if member does not exist in household list then add it
            if (!existingMember) {
                this.profile.household.push(member_coverage.member);
            }
        });
    }

    loadEnrollmentData(product: PoliciesProductCoverage, currentPolicy: PoliciesResponse) {
        const productCoverage = new ProductCoverage(product);
        const covered_member_names = [];
        const memberIdMappings = [];

        // policy level mappings
        productCoverage.policy_number = currentPolicy.id;
        productCoverage.company_name = currentPolicy?.group?.company_name || '';
        productCoverage.group_name = currentPolicy?.group?.group_name || '';

        // product_coverage level mappings
        productCoverage.internal_plan_name = product?.plan_data?.internal_plan_name || '';
        productCoverage.group_id = product?.group_id || '';

        // member_coverage level mappings

        // TODO Ankit: Please take a look at the structure for
        // member_cov.member.pcp_details and member_cov.member.pcd_details to make sure this is still valid

        product.member_coverages.forEach((member_cov: any) => {
            if (member_cov?.member?.member_number === this.memberId || member_cov?.member?.id === this.memberId) {
                productCoverage.member_id = member_cov.member.member_number || member_cov.member.id;
                productCoverage.member_display_identifier = member_cov.member?.member_display_identifier;
                productCoverage.member_coverage_id = member_cov.member_coverage_id;
                productCoverage.member_type =
                    member_cov.member.relationship_to_subscriber === 'Self' ? 'Subscriber' : member_cov.member.relationship_to_subscriber;
                productCoverage.group_package_code = member_cov.benefit_package_code || '';
                productCoverage.selected_PCP = member_cov.member?.pcp_details || '';
                productCoverage.selected_PCD = member_cov.member?.pcd_details || '';
                productCoverage.selected_PCC = member_cov.member?.pcc_details || '';
                productCoverage.isActive = this.isCoverageActive(member_cov);
            }

            covered_member_names.push(
                `${member_cov.member.name.first_name} ${member_cov.member.name.last_name} (${member_cov.member.age})`
            );

            const mc_start_date = this.formattingService.restructureValueBasedOnFormat(member_cov.effective_date, { format: 'DATE' });
            const mc_end_date = this.formattingService.restructureValueBasedOnFormat(member_cov.termination_date, { format: 'DATE' });

            memberIdMappings.push({
                id: member_cov.member.member_number || member_cov.member.id,
                nameText: `${member_cov.member.name.first_name} ${member_cov.member.name.last_name} (${member_cov.member.age}) 
                    ${mc_start_date} - ${mc_end_date}`,
            });
        });

        productCoverage.whosCovered = {
            nameTexts: covered_member_names,
            nameIdMappings: memberIdMappings,
        };

        if (productCoverage.isActive) {
            this.profile.enrollment.push(productCoverage);
        }
    }

    loadAuthContacts() {
        if (!this.policyList || !this.policyList.length) {
            this.loadPolicies();

            return;
        }

        this.profile.contact = {
            full_name: '',
            company_name: '',
            policy_number: '',
            dates: '',
            contactList: [],
        };
        this.pageLevelControls[0] = {
            ...this.pageLevelControls[0],
            options: [],
        };
        this.policyList.forEach((policy: PoliciesResponse) => {
            const currentName = this.profile.overview.name;
            const policy_start_date = this.formattingService.restructureValueBasedOnFormat(policy.effective_date, { format: 'DATE' });
            const policy_end_date = this.formattingService.restructureValueBasedOnFormat(policy.termination_date, { format: 'DATE' });
            const company_name = policy?.group?.company_name || '';
            const full_name = `${currentName?.first_name} ${currentName?.middle_name || ''} ${currentName?.last_name}`;
            this.pageLevelControls[0]?.options.push({
                label: `${company_name} No. ${policy?.policy_number} \n ${policy_start_date} - ${policy_end_date}`,
                value: policy?.policy_number,
            });

            if (policy.policy_number === this.selectedPolicyAuthContacts) {
                this.profile.contact.policy_number = policy.policy_number;
                this.profile.contact.company_name = company_name;
                this.profile.contact.full_name = full_name;
                if (policy?.policy_contacts && policy.policy_contacts.length !== 0) {
                    policy.policy_contacts.forEach((policyContact: PoliciesContact) => {
                        policyContact.physical_address = policyContact.addresses?.filter(
                            (address: MemberAddress) => address.address_type === 'physical'
                        )[0];
                        policyContact.full_name = `${policyContact.name.first_name} ${policyContact.name.middle_name || ''} ${
                            policyContact.name.last_name
                        }`;
                        const contact_start_date = this.formattingService.restructureValueBasedOnFormat(policyContact.effective_date, {
                            format: 'DATE',
                        });
                        const contact_end_date = this.formattingService.restructureValueBasedOnFormat(policyContact.termination_date, {
                            format: 'DATE',
                        });
                        policyContact.contact_dates = `${contact_start_date} - ${contact_end_date}`;
                        this.profile.contact.contactList.push(policyContact);
                    });
                } else {
                    this.profile.contact.contactList = [];
                }
            }
        });

        if (this.selectedTab === Tabs.Contacts) {
            this.context = this.profile.contact;
        }
    }

    groupCoveragesByCoverageType(coverages: any) {
        const groupedCoverages = coverages.reduce((group: any, obj: any) => {
            const key = obj['product_coverage_type'];
            if (!group[key]) {
                group[key] = [];
            }

            group[key].push(obj);

            return group;
        }, {});

        Object.keys(groupedCoverages).forEach((coverage_type: any) => {
            const filteredCovs = coverages.filter((coverage: any) => coverage.product_coverage_type === coverage_type);
            filteredCovs[0].groupHeaderText = coverage_type;
        });
    }

    loadCEH() {
        this.memberDataService.getCommunicationPreferencesData(this.memberId).subscribe((res) => {
            let is_mobile_user = '';
            let is_portal_user = '';
            let last_used_date = '';
            if (res?.details?.attributes) {
                is_mobile_user =
                    res.details.attributes.event_mappandroid_homepage_viewed_overall_count > 0 ||
                    res.details.attributes.event_mappios_homepage_viewed_overall_count > 0
                        ? 'Yes'
                        : 'No';

                is_portal_user = res.details.attributes.event_mp_homepage_viewed_overall_count > 0 ? 'Yes' : 'No';

                last_used_date = res.details.attributes.event_mp_homepage_viewed_last_occurrence_date || '';
            }
            // todo: E-Consent field mapping is yet to be decided.
            this.CEH_Data = {
                e_consent: '',
                portal_user: is_portal_user,
                portal_last_used_date: last_used_date,
                mobile_app_user: is_mobile_user,
            };
        });
    }
    private modifyPageConfig() {
        // remove undefined/null tabs
        Object.keys(this.pageConfig.tabs).forEach((tabKey: string) => {
            if (!this.pageConfig.tabs[tabKey]) {
                delete this.pageConfig.tabs[tabKey];
            }

            // specific to homehost
            if (this.isHomeHostMember && tabKey !== Tabs.Overview.toLowerCase()) {
                delete this.pageConfig.tabs[tabKey];
            }
        });
        this.displayConfig.tabConfigs = Object.keys(this.pageConfig.tabs).map((tabKey: string, index) => {
            // Convert sections objects to sections list
            this.pageConfig.tabs[tabKey].sections = Object.keys(this.pageConfig.tabs[tabKey].sections).map(
                (sectionKey: string) => this.pageConfig.tabs[tabKey].sections[sectionKey]
            );

            if (this.pageConfig.tabs[tabKey].sections?.length) {
                this.pageConfig.tabs[tabKey].sections = this.pageConfig.tabs[tabKey].sections.sort(sortTabs);
            }

            if (!!this.memberId && this.pageConfig.tabs[tabKey].sections) {
                this.pageConfig.tabs[tabKey].sections.forEach((section) => {
                    if (section?.length > 0) {
                        this.pageLevelControls = [...section];
                    }
                });
            }

            // invoking policies api on the last tab config modification
            if (index + 1 === Object.keys(this.pageConfig.tabs).length && !this.isHomeHostMember) {
                this.loadPolicies();
            }

            return this.pageConfig.tabs[tabKey];
        });
        this.displayConfig.tabConfigs = this.displayConfig.tabConfigs.sort(sortTabs);
        this.displayConfig.labels = this.displayConfig?.tabConfigs?.map((tab: MemberProfileTabsConfig) => tab.tabLabel);
    }

    updateCardData(selectedPolicy) {
        this.selectedPolicyAuthContacts = selectedPolicy.policy_dropdown_list;
        this.loadAuthContacts();
    }

    isCoverageActive(coverage) {
        if (coverage?.status_code) {
            return coverage.status_code === 'active';
        } else {
            return new Date(coverage.termination_date).getTime() > new Date().getTime();
        }
    }
}
