import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelAttributeModule, MessageBannerModule } from '@zipari/design-system';
import { DetailTabsModule } from 'src/app/icx/shared/modules/detail-tabs/detail-tabs.module';
import { TabViewModule } from 'src/app/icx/shared/modules/tab-view/tab-view.module';
import { RippleModule } from '@zipari/design-system';
import { MemberProfileComponent } from './member-profile.component';

@NgModule({
    declarations: [MemberProfileComponent],
    imports: [CommonModule, ModelAttributeModule, DetailTabsModule, TabViewModule, RippleModule, MessageBannerModule],
})
export class MemberProfileModule {}
