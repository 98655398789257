import { getValue } from '@zipari/web-utils';

export function isDate(value: any) {
    return Object.prototype.toString.call(value) === '[object Date]';
}

export function isRegExp(value: any) {
    return Object.prototype.toString.call(value) === '[object RegExp]';
}

export function isWindow(obj: any) {
    return obj && obj.window === obj;
}

export function isFunction(value: any) {
    return typeof value === 'function';
}

export function isDefined(value: any) {
    return typeof value !== 'undefined';
}

export function equals(o1: any, o2: any): boolean {
    if (o1 === o2) {
        return true;
    }
    if (o1 === null || o2 === null) {
        return false;
    }
    if (o1 !== o1 && o2 !== o2) {
        return true; // NaN === NaN
    }
    const t1 = typeof o1;
    const t2 = typeof o2;
    let length;
    let key;
    let keySet;
    if (t1 === t2 && t1 === 'object') {
        if (Array.isArray(o1)) {
            if (!Array.isArray(o2)) {
                return false;
            }
            if ((length = o1.length) === o2.length) {
                for (key = 0; key < length; key++) {
                    if (!equals(o1[key], o2[key])) {
                        return false;
                    }
                }

                return true;
            }
        } else if (isDate(o1)) {
            if (!isDate(o2)) {
                return false;
            }

            return equals(o1.getTime(), o2.getTime());
        } else if (isRegExp(o1)) {
            if (!isRegExp(o2)) {
                return false;
            }

            return o1.toString() === o2.toString();
        } else {
            if (isWindow(o1) || isWindow(o2) || Array.isArray(o2) || isDate(o2) || isRegExp(o2)) {
                return false;
            }
            keySet = Object.create(null);
            for (key in o1) {
                if (key.charAt(0) === '$' || isFunction(o1[key])) {
                    continue;
                }
                if (!equals(o1[key], o2[key])) {
                    return false;
                }
                keySet[key] = true;
            }
            for (key in o2) {
                if (!(key in keySet) && key.charAt(0) !== '$' && isDefined(o2[key]) && !isFunction(o2[key])) {
                    return false;
                }
            }

            return true;
        }
    }

    return false;
}

/** Converts provided string into camelCase
 * @return converted camelCase string
 */
export function toCamel(s: string): string {
    return s.replace(/([-_][a-z0-9])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
}

/** Checks the array and object keys to convert into
 * camelCase format
 * @return converted camelCase keys
 */
export function camelCase(o: any): any {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n = {};
        Object.keys(o).forEach((k) => {
            n[toCamel(k)] = camelCase(o[k]);
        });

        return n;
    } else if (Array.isArray(o)) {
        return o.map((i) => camelCase(i));
    }

    return o;
}

/** Converts provided string into snakeCase
 * @return converted snakeCase string
 */
export function toSnakeCase(s: string): string {
    return (
        s &&
        s
            .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
            .map((x) => x.toLowerCase())
            .join('_')
    );
}

/** Checks the array and object keys to convert into
 * snakeCase format
 * @return converted snakeCase keys
 */
export function camelToSnakeCase(o: any): any {
    if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
        const n = {};
        Object.keys(o).forEach((k) => {
            n[toSnakeCase(k)] = o[k];
        });

        return n;
    } else if (Array.isArray(o)) {
        return o.map((i) => {
            const n = {};
            Object.keys(i).forEach((k) => {
                n[toSnakeCase(k)] = i[k];
            });

            return n;
        });
    }

    return o;
}

export function sortArrayData(prop: string, data: any, sortingElement): any {
    const descData = data.sort((a, b) => (getValue(b, prop) < getValue(a, prop) ? -1 : 1));
    if (sortingElement.includes('-')) {
        return (data = [...descData.reverse()]);
    } else {
        return (data = [...descData]);
    }
}
