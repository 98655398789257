import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthModule } from '@zipari/design-system';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { AuthService } from 'src/app/shared/services';
import { AuthComponent } from './auth.component';
import { AuthInterceptor } from './auth.interceptor';
import { AuthGuard } from './auth.guard';

@NgModule({
    imports: [CommonModule, AuthModule],
    declarations: [AuthComponent],
})
export class NewAuthModule {
    static forRoot(): ModuleWithProviders<AuthModule> {
        return {
            ngModule: NewAuthModule,
            providers: [{ multi: true, provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor }, AuthGuard, AuthService],
        };
    }
}
