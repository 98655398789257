import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { getValue } from '@zipari/web-utils';
import { ConfigService } from '../../services';

import { customFormElements } from './custom-form-element.constants';

@Component({
    selector: 'custom-form-element',
    templateUrl: './custom-form-element.component.html',
    styleUrls: ['./custom-form-element.component.scss'],
})
export class CustomFormElementComponent implements OnInit {
    /**
     * NEW FORM ELEMENTS MUST BE ADDED TO THIS COMPONENT
     * Make sure to add a unique type in the 'customFormElements' enum
     * During configuration... provide that custom type and it will display your custom form element.
     */

    @Input() direction;
    @Input() config: any;
    @Input() form = new FormGroup({});

    @Output() formCreated = new EventEmitter();
    customFormElements = customFormElements;

    constructor(private configService: ConfigService) {}

    ngOnInit() {
        if (!Object.values(customFormElements).includes(this.config.type)) {
            console.warn(`Your provided type ${this.config.type} is not valid`);
        }

        /** merge global config for a control type into @Input config  */
        const globalConfig = this.configService.getPageConfig('global');
        const globalControlConfig = getValue(globalConfig, `formControl.${this.config.type}`);

        if (globalControlConfig) {
            this.config = { ...this.config, ...globalControlConfig };
        }
    }
}
