import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TableModule, TabsModule, ModelAttributeModule, DatagridModule, PaginatorModule } from '@zipari/design-system';
import { DetailTabsModule } from 'src/app/icx/shared/modules/detail-tabs/detail-tabs.module';
import { TabViewModule } from 'src/app/icx/shared/modules/tab-view/tab-view.module';
import { PcpDetailModule } from '../pcp-detail/pcp-detail.module';
import { ProvidersComponent } from './providers.component';

@NgModule({
    declarations: [ProvidersComponent],
    imports: [
        CommonModule,
        TabsModule,
        ModelAttributeModule,
        PcpDetailModule,
        DetailTabsModule,
        TabViewModule,
        TableModule,
        DatagridModule,
        PaginatorModule,
    ],
})
export class ProvidersModule {}
