import { Injectable } from '@angular/core';
import { ConfigService } from '../../../shared/services';
import { ActivatedRoute } from '@angular/router';
import { IcxAppRoutes } from '../../icx.constants';

export interface GenericType {
    [key: string]: any;
}
@Injectable({
    providedIn: 'root',
})
export class IcxService {
    constructor(private configService: ConfigService, private route: ActivatedRoute) {}

    getIcxUserId(): string {
        switch (this.configService.activeRoute) {
            case IcxAppRoutes.Member360:
                return this.route.snapshot.children[0].children[0].params.member;
            case IcxAppRoutes.Provider360:
                return this.route.snapshot.children[0].children[0].params.provider;
        }
    }

    setIcxRouteAfterLogin(config) {
        const id = this.getIcxUserId();

        switch (this.configService.activeRoute) {
            // wtf?
            case IcxAppRoutes.Member360:
                return config.routeAfterSuccessConfig.route.replace(':member', id);
            case IcxAppRoutes.Provider360:
                return config.routeAfterSuccessConfig.route.replace(':provider', id);
        }
    }
}
