import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { CardConfig } from '@zipari/design-system/lib/notes/card/card.constants';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { BenefitService } from '../benefits.service';
import { BenefitsCategoryItem, BenefitsServiceItem } from '../benefits.model';

@Component({
    selector: 'benefits-categories',
    templateUrl: './benefits-categories.component.html',
    styleUrls: ['./benefits-categories.component.scss'],
})
export class BenefitsCategoriesComponent implements OnInit {
    private categoryId: string = '';
    public servicesList: BenefitsServiceItem[];
    public categoryData: BenefitsCategoryItem;
    public cardConfig: CardConfig = {
        hideHeader: true,
        hideSubHeader: true,
        hideFooter: true,
        stretch: false,
        static: false,
        ignorePadding: false,
    };
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private benefitsService: BenefitService,
        private panelService: SidePanelService,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit(): void {
        this.categoryId = this.route.snapshot.params.categoryId;
        this.benefitsService.requestBenefitCategories().subscribe((res) => {
            this.categoryData = res.results.find((category) => category.id === this.categoryId);
        });
        this.benefitsService.getBenefitServiceByCategory(this.categoryId).subscribe((res) => {
            this.servicesList = res?.details?.results;
        });
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.BenefitsCategory_viewed }, this.route.snapshot.params.member);
    }

    routeBack() {
        this.router.navigate([`/icx/member-360/${this.route.snapshot.params.member}/benefits`]);
    }

    routeToServiceDetails(service) {
        this.router.navigate([`/icx/member-360/${this.route.snapshot.params.member}/benefits`, this.categoryId, service.id]);
    }
}
