import { APIService } from 'src/app/shared/services';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PaginatorConfiguration, ButtonConfig, ButtonLevels, ModalConfig, FormattingService, formatTypes } from '@zipari/design-system';
import { PaginatorService } from 'src/app/icx/shared/services/paginator.service';
import Claim from 'src/app/shared/models/shared/Claim.model';
import { MemberDataService, MemberData, ResponseType } from 'src/app/icx/shared/services/member-data.service';
import { Badges, InitialPagingConfig, TabLabels } from './view-claims-modal.constants';

@Component({
    selector: 'view-claims-modal',
    templateUrl: './view-claims-modal.component.html',
    styleUrls: ['./view-claims-modal.component.scss'],
})
export class ViewClaimsModalComponent implements OnInit {
    @Input() config;
    @Input() currentAccumulatorData;
    @Input() showClaimsModal;
    @Output() closeClaimsModal: EventEmitter<boolean> = new EventEmitter();

    pageConfig;
    modalConfig: ModalConfig;
    sortingElement: string = '';
    public paginatorConfig: PaginatorConfiguration = InitialPagingConfig;
    public claimList: Claim[] = [];
    loadingDatagrid: boolean = true;
    showErrorScreen: boolean = false;
    tabLabels: string[] = TabLabels;
    pageData;
    modalHeader: string = '';
    currentParticipationLevel: string = '';
    badges = Badges;
    error;

    doneButtonConfig: ButtonConfig = {
        level: ButtonLevels.high,
        content: 'Done',
    };

    constructor(
        private route: ActivatedRoute,
        private paginatorService: PaginatorService,
        private api: APIService,
        private memberDataService: MemberDataService,
        private formattingService: FormattingService
    ) {}

    ngOnInit() {
        this.pageConfig = this.config;
        this.pageData = Object.assign({}, this.currentAccumulatorData);
        this.setHeaderTabLabel();
        this.setTabData(0);
    }

    getClaimsList() {
        this.loadingDatagrid = true;
        const endpoint = this.buildQueryParams();
        this.api.get(endpoint).subscribe(
            (resp) => {
                this.paginatorConfig = this.paginatorService.setPagingConfig(this.paginatorConfig, resp.count, this.pageConfig.paginator);
                this.claimList = Object.assign([], resp.results);
                this.loadingDatagrid = false;

                // modifying claims response for fields
                this.claimList.forEach((claim) => {
                    if (claim.provider?.name) {
                        claim.provider_name = [
                            claim.provider.name.prefix,
                            claim.provider.name.first_name,
                            claim.provider.name.middle_name,
                            claim.provider.name.last_name,
                            claim.provider.name.suffix,
                        ].join(' ');
                    }
                    claim.service_dates = `${this.formattingService.restructureValueBasedOnFormat(claim.service_date, { format: 'DATE' })}${
                        claim.service_end_date
                            ? ` - ${this.formattingService.restructureValueBasedOnFormat(claim.service_end_date, { format: 'DATE' })}`
                            : ''
                    }`;
                });
            },
            (err) => {
                this.showErrorScreen = true;
                this.error = { ...err };
            }
        );
    }

    buildQueryParams() {
        let query = `/api/member-360/claims/?member_id=${this.route.snapshot.params.member}&`;
        query += `page=${this.paginatorConfig.currentPage}&`;
        if (this.pageConfig.paginator) {
            query += `page_size=${this.pageConfig.paginator}&`;
        }
        if (this.sortingElement) {
            query += `ordering=${this.sortingElement}&`;
        }
        query +=
            `policy_id=${this.currentAccumulatorData?.policy_id || ''}&` +
            `benefit_period_id=${this.currentAccumulatorData?.benefit_period_id || ''}&` +
            `accumulator_type_id=${this.currentAccumulatorData?.accumulator_type_id || ''}&` +
            `benefit_service_id=${this.currentAccumulatorData?.benefit_service_id || 'none'}&` +
            `accumulator_type=${this.currentAccumulatorData?.accumulator_type || ''}&` +
            `benefit_applies_to=${this.currentAccumulatorData?.benefit_applies_to || ''}&` +
            `participation_level=${this.currentParticipationLevel || ''}&`;

        return query.slice(0, -1);
    }

    navigateToDetails(event) {
        const claimId = this.claimList.filter((claim) => claim.claim_number === event.claim_number)[0]?.id;
        window.open(`${window.location.origin}/icx/member-360/${this.route.params['value'].member}/claims/${claimId}`, '_blank');
    }

    setCurrentPage(page: number) {
        this.paginatorConfig.currentPage = page;
        this.paginatorConfig.showingStart = page === 1 ? 1 : this.pageConfig.paginator * (page - 1) + 1;
        this.paginatorConfig.showingEnd =
            page === this.paginatorConfig.totalPages ? this.paginatorConfig.totalResults : this.pageConfig.paginator * page;
        this.getClaimsList();
    }

    sortTable(element: any) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.getClaimsList();
    }

    setHeaderTabLabel() {
        this.tabLabels = this.pageData.accumulators?.map((accumulator) => accumulator.participation_level);

        this.memberDataService.getMemberData(this.route.snapshot.params.member).subscribe((res: ResponseType<MemberData>) => {
            const full_name = this.formattingService.restructureValueBasedOnFormat(res.details.name, { format: formatTypes.FULL_NAME });
            const name = res.details.name.full_name || full_name;
            this.modalHeader = `${name}'s `;
            if (this.pageData.accumulator_type) {
                this.modalHeader += this.pageData.accumulator_type;
            }
        });
    }

    setTabData(tabIndex: number) {
        this.pageData.accumulators?.forEach((accumulator) => {
            if (accumulator.participation_level.toLowerCase() === this.tabLabels[tabIndex].toLowerCase()) {
                this.currentParticipationLevel = accumulator.participation_level;
                this.badges = this.badges.map((badge) => ({
                    ...badge,
                    value: accumulator[badge.label.toLowerCase()],
                }));
            }
        });
        this.setCurrentPage(1);
    }

    closeModal() {
        this.showClaimsModal = false;
        this.closeClaimsModal.emit(true);
    }
}
