import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BadgeConfig, Datagrid } from '@zipari/design-system';

import { AttributeList } from '../../../../control-panel/portal-builder/models/attribute-list';
import { validCXEvents } from '../../../../shared/constants/analytics';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { ConfigService, APIService } from '../../../../shared/services';
import { SidePanelService } from '../../../shared/services/side-panel.service';
export interface AuthorizationPageConfig {
    details: AttributeList;
    endpoint: string;
    diagnosis_grid: {
        label: string;
        datagrid: Datagrid;
    };
    dates: AttributeList;
    procedure_grid: {
        label: string;
        datagrid: Datagrid;
    };
    procedure: AttributeList;
    header: string;
    badgeConfig: BadgeConfig;
    illustrationSrc: string;
}

export interface PreauthDetailsAPIResponse {
    number: string;
    requesting_provider_name: string;
    effective_date: string;
    termination_date: string;
    decision_date: string;
    status: string;
    member_display_identifier: string;
    member_name: string;
    services: PreauthDetailServices[];
    documents: PreauthDetailDocuments;
    type: string;
    service_type: string;
    service_category: string;
    priority: string;
    level_of_care: string;
    place_of_service_code: string;
    diagnoses: PreauthDetailDiagnoses[];
    primary_service: PreauthDetailServices;
}
export interface PreauthDetailDiagnoses {
    code: string;
    description: string;
}
export interface PreauthDetailDocuments {
    file_type: string;
    file_name: string;
    document_category: string;
    type: string;
}
export interface PreauthDetailServices {
    servicing_provider_name: string;
    procedure_code: string;
    procedure_description: string;
    servicing_facility_name: string;
    length_of_stay_admit_date: string;
    length_of_stay_discharge_date: string;
    unit_type: string;
    is_primary: boolean;
    quantity: string;
}

@Component({
    selector: 'preauthorization-detail',
    templateUrl: './preauthorization-detail.component.html',
    styleUrls: ['./preauthorization-detail.component.scss'],
})
export class PreauthorizationDetailComponent implements OnInit {
    public pageConfig: AuthorizationPageConfig;
    public authId: string;
    public authDetails: PreauthDetailsAPIResponse;
    public memberId: string;
    public isLoading: boolean = true;

    constructor(
        private configService: ConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private panelService: SidePanelService,
        private api: APIService,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.pageConfig = this.configService.getControlPanelConfig('preauthorization-details');
        this.authId = this.route.snapshot.params.id;
        this.memberId = this.route.snapshot.params.member;
        this.getPreAuthDetails();
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.AuthorizationsDetail_viewed }, this.memberId);
    }

    getPreAuthDetails(): void {
        this.isLoading = true;
        const query = `${this.pageConfig.endpoint}${this.authId}/`;
        this.api.get(query).subscribe(
            (res) => {
                this.isLoading = false;
                this.authDetails = res;
                this.authDetails.primary_service =
                    this.authDetails.services.find((service: PreauthDetailServices) => service.is_primary) || ({} as PreauthDetailServices);
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    routeBack(): void {
        this.router.navigate([`/icx/member-360/${this.memberId}/preauthorizations`]);
    }
}
