import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';

declare const google: any;

@Injectable({
    providedIn: 'root',
})
export class ZipGoogleMapService {
    promises: any[] = [];
    timeout: any;
    apiKey = 'AIzaSyDUMOMthq4FX9Fd7P8nwPkjOpO_1WstkMQ';

    constructor(private http: HttpClient) {
        this.checkIfMapScriptIsLoaded = this.checkIfMapScriptIsLoaded.bind(this);
        this.appendScriptElement();
    }

    public IsGoogleMapAPILoaded(): Promise<any> {
        if (window['google'] && google.maps) {
            return Promise.resolve();
        }

        return this.getPromise();
    }

    checkIfMapScriptIsLoaded() {
        const timeOut = 250;

        if (window['google'] && google.maps) {
            return this.resolvePromises();
        }
        setTimeout(this.checkIfMapScriptIsLoaded, timeOut);
    }

    appendScriptElement() {
        if (!window['google']) {
            const googleMapsScript = document.createElement('script');
            googleMapsScript.setAttribute('src', `https://maps.googleapis.com/maps/api/js?key=${this.apiKey}&libraries=places`);
            document.head.appendChild(googleMapsScript);
            this.checkIfMapScriptIsLoaded();
        }
    }

    resolvePromises() {
        this.promises.forEach(q => q.resolve());
    }

    getPromise() {
        const q: any = {};
        q.promise = new Promise((resolve, reject) => {
            q.resolve = resolve;
            q.reject = reject;
        });
        this.promises.push(q);

        return q.promise;
    }

    public GetLatLngFromAddress(addressString: string): Promise<any> {
        const geocoder = new google.maps.Geocoder();

        return new Promise((resolve, reject) => {
            geocoder.geocode({ address: addressString }, function (results, status) {
                if (status === google.maps.GeocoderStatus.OK) {
                    return resolve(results[0].geometry.location);
                }
                console.warn(`Geocode service returned error status: ${status}`);
                console.log(results);
                reject();
            });
        });
    }

    public GetAddressFromLatLng(latlng: any): Promise<string> {
        const geocoder = new google.maps.Geocoder();

        return new Promise((resolve, reject) => {
            geocoder.geocode({ location: latlng }, function (results, status) {
                if (status !== google.maps.GeocoderStatus.OK) {
                    return reject();
                }
                if (!results.length) {
                    return resolve('');
                }
                resolve(results[0].formatted_address);
            });
        });
    }

    /*
        administrative_area_level_1 is a U.S. state
        Source: https://developers.google.com/maps/documentation/geocoding/intro#Types
    */
    public checkIsState(placeResponse): boolean {
        const stateTypeValue = 'administrative_area_level_1';

        return placeResponse.types && placeResponse.types.indexOf(stateTypeValue) > -1;
    }

    public currentLocation(): Observable<any> {
        return this.http.post(`https://www.googleapis.com/geolocation/v1/geolocate?key=${this.apiKey}`, {});
    }
}
