import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule } from '@zipari/design-system';
import { TableModule } from '../../modules';

import { DetailComponent } from './detail.component';

@NgModule({
    declarations: [DetailComponent],
    imports: [CommonModule, ButtonModule, TableModule],
})
export class DetailModule {}
