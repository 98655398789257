import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { FormControlConfiguration, Datagrid, PaginatorConfiguration } from '@zipari/design-system';
import { TableConfig } from '@zipari/design-system/lib/chords/table/models';
import { WindowService } from '@zipari/web-services';
import { ClaimsService } from 'src/app/icx/shared/services/claims.service';
import { FormManagerService } from 'src/app/icx/shared/services/form-manager.service';
import { validCXEvents, validSSOCXEventDictonaryValue } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { APIService, ConfigService } from '../../../../shared/services';
import { SidePanelService } from './../../../shared/services/side-panel.service';

export interface ReferralsConfig {
    header: string;
    endpoint: string;
    paginator: number;
    filters: { controls: FormControlConfiguration[] };
    datagrid: Datagrid;
    sorting: {
        key: string;
        order: string;
    };
    table: TableConfig;
    pagingConfig: PaginatorConfiguration;
}

export interface Referral {
    id: string;
    member_full_name: string;
    referring_provider_full_name?: string;
    requesting_provider_full_name?: string;
    servicing_provider_full_name: string;
    effective_date: string;
    termination_date: string;
    status: 'approved' | 'pended' | 'denied' | 'partially_approved';
    document_id: string;
}

@Component({
    selector: 'referrals',
    templateUrl: './referrals.component.html',
    styleUrls: ['./referrals.component.scss'],
})
export class ReferralsComponent implements OnInit {
    config: ReferralsConfig;
    referrals: Referral[] = [];
    endpoint: string;
    memberId;
    referralFilters;
    queryParams;
    sortingElement: string = '';
    formGroup: FormGroup;
    public pagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 1,
        showingEnd: 1,
        totalResults: 0,
        currentPage: 1,
    };

    constructor(
        private configService: ConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private api: APIService,
        private formManagerService: FormManagerService,
        private panelService: SidePanelService,
        private analyticsService: AnalyticsService,
        private claimsService: ClaimsService,
        private window: WindowService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.config = this.configService.getControlPanelConfig(this.route.snapshot.data['pageName']);
        this.formGroup = this.formManagerService.populateFormGroup(this.config.filters.controls);
        this.memberId = this.route.snapshot.params.member;
        this.getReferralsList();
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.ReferralsList_viewed }, this.memberId);
    }

    getReferralsList(): void {
        const query = this.buildQueryParams();
        this.api.get(query).subscribe(
            (res) => {
                this.referrals = res.results;
                this.pagingConfig.totalPages = Math.ceil(res.count / this.config.paginator);
                this.pagingConfig.totalResults = res.count;
            },
            (err) => {
                this.referrals = [];
            }
        );
    }

    buildQueryParams(): string {
        let queryString = `${this.config.endpoint}/?member_id=${this.memberId}&`;
        let initialSort = '';
        const start_date = 'effective_date_gte';
        const end_date = 'effective_date_lte';

        for (const key of Object.keys(this.formGroup.value)) {
            if (this.formGroup.value[key]) {
                if (key === 'dates' && (this.formGroup.value[key].start || this.formGroup.value[key].end)) {
                    queryString += this.formGroup.value[key].start !== '' ? `${start_date}=${this.formGroup.value[key].start}&` : '';
                    queryString += this.formGroup.value[key].end !== '' ? `${end_date}=${this.formGroup.value[key].end}&` : '';
                } else if (key !== 'dates') {
                    const trimmedValue = this.formGroup.value[key].trim();
                    queryString += `${key}=${trimmedValue}&`;
                }
            }
        }

        queryString += `page=${this.pagingConfig.currentPage}&`;
        if (this.config.paginator) {
            queryString += `page_size=${this.config.paginator}&`;
        }

        if (this.config.sorting && this.config.sorting.key && this.config.sorting.order && !this.sortingElement) {
            if (this.config.sorting.order === 'asc') {
                initialSort = this.config.sorting.key;
            } else if (this.config.sorting.order === 'desc') {
                initialSort = `-${this.config.sorting.key}`;
            }
            queryString += `ordering=${initialSort}&`;
        } else if (this.sortingElement) {
            queryString += `ordering=${this.sortingElement}&`;
        }

        return queryString.slice(0, -1);
    }

    clearForm(): void {
        this.formGroup.reset();
    }

    submitForm(): void {
        this.getReferralsList();
    }

    openSSODetails(target): void {
        let queryParams = this.claimsService.getSSOQueryParams(this.config.datagrid, target, this.route.snapshot.params?.member);
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Sso_initiated }, this.route.snapshot.params?.member, {
            SSO_initiated: validSSOCXEventDictonaryValue.Referrals,
        });
        if (target?.route) {
            const ssoUrl = `${target.route}?${queryParams}`;
            this.window.nativeWindow.open(ssoUrl, '_blank');
        }
    }

    setCurrentPage(page: number): void {
        this.pagingConfig.currentPage = page;
        this.pagingConfig.showingStart = page === 1 ? 1 : this.config.paginator * (page - 1) + 1;
        this.pagingConfig.showingEnd =
            page === this.pagingConfig.totalPages ? this.pagingConfig.totalResults : this.config.paginator * page;

        this.getReferralsList();
    }

    navigateToDetails(event: Referral): void {
        const ref_id = event.id;
        this.router.navigate([`/icx/member-360/${this.memberId}/referrals/${ref_id}`]);
    }

    sortTable(element: string): void {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.referrals = [];
        this.getReferralsList();
    }

    handleKeySearch(event): void {
        if (event.keyCode === 13) {
            this.getReferralsList();
        }
    }
}
