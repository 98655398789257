import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, UrlSerializer, UrlTree } from '@angular/router';
import { AllControlsConfiguration } from '@zipari/design-system';
import { getValue, stringBuilder } from '@zipari/web-utils';
import { Subscription } from 'rxjs';
import { IcxService } from 'src/app/icx/shared/services/icx.service';
import { AuthService, ConfigService } from '../../services';
import { ZipBackendErrorResponse, ZipEndpointService } from '../../services/zip-endpoint.service';

import {
    AuthCardOptions,
    AuthenticationConfig,
    AuthenticationLayouts,
    AuthenticationPages,
    authenticationRoutes,
    BannerOptions,
    defaultErrorMessages,
    RouteAfterSuccessConfig,
    StepOptions,
    validConfigs,
} from './authentication.constants';

@Component({
    selector: 'authentication',
    templateUrl: './authentication.component.html',
    styleUrls: ['./authentication.component.scss'],
})
export class AuthenticationComponent implements OnInit {
    @Input()
    set layout(layout: AuthenticationLayouts) {
        this.config.layout = layout;
    }

    @Input()
    set page(page: AuthenticationPages) {
        this.config.page = page;
    }

    @Input()
    set layoutOptions(layoutOptions: StepOptions | BannerOptions) {
        this.config.layoutOptions = layoutOptions;
    }

    @Input()
    set authCardOptions(authCardOptions: AuthCardOptions) {
        this.config.authCardOptions = authCardOptions;
    }

    @Input()
    set routeAfterSuccessConfig(routeAfterSuccessConfig: RouteAfterSuccessConfig) {
        this.config.routeAfterSuccessConfig = routeAfterSuccessConfig;
    }

    @Output() registerCompleted = new EventEmitter();
    @Output() completeStep = new EventEmitter();

    AuthenticationLayouts = AuthenticationLayouts;

    config: AuthenticationConfig = {};
    formGroup = new FormGroup({});
    busy: Promise<any> | Subscription;
    errorMessage: string[];
    workflowId: string | number;
    success: boolean;
    token: string;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        // when enter is clicked submit the form as a filter
        if (event.which === 13 && this.formGroup.valid) {
            this.handleButtonClicked();
        }
    }

    public get disableButton() {
        return getValue(this.config, 'routeAfterSuccessConfig.disableAfterSuccess') && this.success;
    }

    constructor(
        private urlSerializer: UrlSerializer,
        public http: HttpClient,
        public zipEndpointService: ZipEndpointService,
        public authService: AuthService,
        public configService: ConfigService,
        public route: ActivatedRoute,
        public router: Router,
        private icxService: IcxService
    ) {}

    ngOnInit() {
        // tries to retrieve data from a route if added as a route
        this.retrieveDataForPage();

        // initializes page if needed
        this.initializePage();
    }

    /** core functionality */
    initializePage() {
        this.token = this.route.snapshot.queryParams.token;
        this.workflowId = this.route.snapshot.queryParams.workflowId;

        if (this.token) {
            this.navigateToAuthenticationRoute('reset-password');
        }

        switch (this.config.page) {
            case 'reset-password':
                // handle reset password specific intialization logic
                break;
            case 'forgot-password':
                // handle forgot password specific intialization logic
                break;
            case 'auth':
                // handle login specific intialization logic
                break;
            case 'register':
                // handle register specific intialization logic
                if (this.route.snapshot.queryParams.fromEmail) {
                    this.router.navigate([this.configService.appRoute, 'auth']);
                }
                break;
        }
    }

    handleLinkClicked(event) {
        // handle specific link clicking scenarios
        // if you need to handle a new page having a link clicked just create a new case in the switch statement
        switch (this.config.page) {
            case 'auth':
                this.handleLoginLinkClicked(event);
                break;
            case 'register':
                this.handleRegisterLinkClicked(event);
                break;
        }
    }

    handleButtonClicked() {
        // handle specific button clicking scenarios per page
        switch (this.config.page) {
            case 'reset-password':
                this.handleResetPasswordButtonClicked();
                break;
            case 'forgot-password':
                this.handleForgotPasswordButtonClicked();
                break;
            case 'register':
                this.handleRegisterButtonClicked();
                break;
            case 'auth':
                this.handleLoginButtonClicked();
                break;
        }
    }

    /** core functionality */

    /** page specific logic */
    handleResetPasswordButtonClicked() {
        const payload = { ...this.formGroup.value, token: this.token };
        this.busy = this.authService.resetPassword(payload).subscribe(
            response => {
                this.success = true;
                this.handleAuthSuccess(response);
            },
            (error: ZipBackendErrorResponse) => {
                if (error.status === 200) {
                    this.success = true;
                    this.handleAuthSuccess({});
                } else {
                    this.success = false;
                    this.errorMessage = this.zipEndpointService.handleErrorMessages(
                        error,
                        'Failed to reset password! Please try again later'
                    );
                    console.error(error);
                }
            }
        );
    }

    handleForgotPasswordButtonClicked() {
        const payload = {
            ...this.formGroup.value,
            user_name: this.formGroup.value.username,
            question_text: this.formGroup.value.questionId,
            answer_text: this.formGroup.value.answerText,
            endpoint: this.config.endpoint ? this.config.endpoint : null,
        };

        const next = this.router.url.replace('forgot-password', 'reset-password');

        this.busy = this.authService.sendForgotPasswordEmail({ ...payload, next }).subscribe(
            response => {
                this.success = true;
                this.handleAuthSuccess(response);
            },
            error => {
                window.scroll(0, 0);
                this.errorMessage = this.zipEndpointService.handleErrorMessages(error, 'Something went wrong, please try again.');
                console.error(error);
            }
        );
    }

    handleRegisterButtonClicked() {
        // This correctly handles adding fromEmail queryParam
        const nextUrlTree: UrlTree = this.router.parseUrl(this.router.url);
        nextUrlTree.queryParams.fromEmail = 'true';
        const nextUrl = this.urlSerializer.serialize(nextUrlTree);

        const registerPayload = {
            ...this.formGroup.value,
            next: nextUrl,
        };

        this.busy = this.authService.register(registerPayload).subscribe(
            response => {
                window.scroll(0, 0);
                this.registerCompleted.emit(this.formGroup.value.email);

                this.success = true;

                this.handleAuthSuccess(response);
            },
            error => {
                window.scroll(0, 0);
                this.errorMessage = this.zipEndpointService.handleErrorMessages(error, 'Failed to register, please try again.');
                console.error(error);
            }
        );
    }

    handleLoginButtonClicked() {
        this.busy = this.authService.login(this.formGroup.value).subscribe(
            response => {
                this.errorMessage = null;
                // remove fromemail query param after login if exists
                this.router.navigate([], {
                    relativeTo: this.route,
                    queryParams: {
                        fromEmail: null,
                    },
                    queryParamsHandling: 'merge',
                });

                this.busy = new Promise<void>((resolve, reject) =>
                    this.authService
                        .getUser({})
                        .then(app_user =>
                            this.configService
                                .initializeAPIConfig(this.configService.app, {
                                    makeAPICall: true,
                                })
                                .then(() => {
                                    this.retrieveDataForPage(true);
                                    this.authService.setLoggedInUser(app_user);
                                    this.completeStep.emit();
                                    this.success = true;
                                    this.handleAuthSuccess(response);
                                    resolve();
                                })
                        )
                        .catch((error: ZipBackendErrorResponse) => {
                            this.errorMessage = this.zipEndpointService.handleErrorMessages(error, defaultErrorMessages[this.config.page]);
                            console.error(error);
                            reject(error);
                        })
                );
            },
            (error: ZipBackendErrorResponse) => {
                this.errorMessage = this.zipEndpointService.handleErrorMessages(error, defaultErrorMessages[this.config.page]);
                console.error(error);
            }
        );
    }

    handleRegisterLinkClicked(event) {
        switch (event.prop) {
            case 'login':
                this.navigateToAuthenticationRoute('auth');
                break;
        }
    }

    handleLoginLinkClicked(event) {
        switch (event.prop) {
            case 'member-linking':
                this.router.navigate(['member-portal', 'member-linking']);
                break;
            case 'register':
                this.navigateToAuthenticationRoute('register');
                break;
            case 'password':
                this.navigateToAuthenticationRoute('forgot-password');
                break;
        }
    }

    /** page specific logic */

    /** utility functions */
    navigateToAuthenticationRoute(page) {
        let route = this.formatAuthenticationRoute(page);

        if (this.route.snapshot.queryParams.id) {
            route = `${route}/${this.route.snapshot.queryParams.id}`;
        }

        this.router.navigate([route], { queryParamsHandling: 'merge' });
    }

    retrieveDataForPage(force = false) {
        this.retrieveDataFromRoute(this.route);
        this.retrieveDataFromConfig(force);
    }

    retrieveDataFromConfig(force: boolean) {
        const routeFromConfig = this.configService.getPageConfig(this.config.page);

        if (routeFromConfig) {
            const keys = Object.keys(routeFromConfig);
            keys.forEach(key => {
                if (routeFromConfig[key] && (!this.config[key] || force)) {
                    this.config[key] = routeFromConfig[key];
                }
            });
        }
    }

    retrieveDataFromRoute(route: ActivatedRoute) {
        const data = route.snapshot.data;

        validConfigs.forEach((key) => {
            if (data[key] && !this.config[key]) {
                this.config[key] = data[key];
            }
        });
    }

    routeAfterSuccess(id = null) {
        if (this.route.snapshot.data.indexRoute === 'icx' && this.config.routeAfterSuccessConfig.route) {
            this.config.routeAfterSuccessConfig.route = this.icxService.setIcxRouteAfterLogin(this.config);
        }

        const routes = [this.config.routeAfterSuccessConfig.route];

        if (id) {
            routes.push(String(id));
        }

        this.router.navigate(routes);
    }

    formatAuthenticationRoute(page: string) {
        return stringBuilder(authenticationRoutes[page], {
            app: this.configService.appRoute,
        });
    }

    handleRedirect(url: string) {
        if (url.includes('http')) {
            window.location.assign(url);
        } else {
            this.router.navigate([url]);
        }
    }

    handleAuthSuccess(response) {
        if (this.authService.replayPath) {
            this.router.navigate([this.authService.replayPath]);

            this.authService.replayPath = null;
        } else if (response['redirect_path'] && response['redirect_path'] !== '/' && new URL(response['redirect_path']).pathname !== '/') {
            this.handleRedirect(response['redirect_path']);
        } else if (this.config.routeAfterSuccessConfig) {
            if (this.config.routeAfterSuccessConfig.checkApplication && this.config.routeAfterSuccessConfig.route) {
                // if workflow id param, then in workflow. Otherwise login page
                if (this.config.routeAfterSuccessConfig.id && this.route.snapshot.queryParams[this.config.routeAfterSuccessConfig.id]) {
                    this.routeAfterSuccess(this.route.snapshot.queryParams.id);
                } else {
                    const applicationsObs$ = this.http.get('api/member-360/applications/individuals/');

                    this.busy = applicationsObs$.subscribe(
                        applicationResponse => {
                            if (applicationResponse['results'][0]) {
                                this.routeAfterSuccess(applicationResponse['results'][0].workflow_id);
                            } else {
                                this.routeAfterSuccess();
                            }
                        },
                        err => {
                            this.routeAfterSuccess();
                        }
                    );
                }
            } else if (this.config.routeAfterSuccessConfig.route) {
                this.routeAfterSuccess();
            }

            // this code is ran when register is completed on shopping
            if (this.config.routeAfterSuccessConfig.disableAfterSuccess) {
                // add the email to the success title
                this.config.authCardOptions.successTitle = stringBuilder(this.config.authCardOptions.successTitle, {
                    email: this.formGroup.value.email,
                });

                this.config.authCardOptions.form.controls = this.config.authCardOptions.form.controls.map(
                    (formConfig: AllControlsConfiguration) => {
                        formConfig.isDisabled = true;

                        return formConfig;
                    }
                );
            }
        }
    }
}
