import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelAttributeModule, CardModule, IconModule } from '@zipari/design-system';
import { LinkListModule } from './../link-list/link-list.module';
import { ResultCardComponent } from './result-card.component';

@NgModule({
    declarations: [ResultCardComponent],
    imports: [CommonModule, ModelAttributeModule, CardModule, LinkListModule,IconModule],
    exports: [ResultCardComponent],
})
export class ResultCardModule {}
