import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { CardConfig } from '@zipari/design-system/lib/notes/card/card.constants';
import { PaginatorConfiguration, FormattingService, formatTypes } from '@zipari/design-system';
import { ConfigService, APIService } from 'src/app/shared/services';
import { sortTabs } from 'src/app/icx/shared/utils/sorting-tabs';
import { BillingDetailsPageConfig, BillingDetailsModifiedResponse, BillingDetailsApiResponse } from './billing-details.model';

@Component({
    selector: 'billing-details',
    templateUrl: './billing-details.component.html',
    styleUrls: ['./billing-details.component.scss'],
})
export class BillingDetailsComponent implements OnInit {
    public config: BillingDetailsPageConfig;
    public cardConfig: CardConfig = {
        hideHeader: false,
        hideSubHeader: true,
        hideFooter: true,
        stretch: false,
        static: false,
        ignorePadding: false,
    };
    public billingDetails: BillingDetailsModifiedResponse;

    public tabLabels: string[] = [];
    public comparisonPaginatorConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    public leftSideLabel: string = '';
    public leftSideValue: string = '';
    public comparisonData: any = [];
    public isLoading: boolean = true;
    illustrationSrc: string = 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/error-1.svg';
    private billId: string;
    private comparisons: string[] = ['Subscriber', 'Dependent', 'Total'];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private panelService: SidePanelService,
        private configService: ConfigService,
        private api: APIService,
        private formattingService: FormattingService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit(): void {
        this.config = this.configService.getControlPanelConfig('billing-details');
        this.billId = this.route.snapshot.params.bill_id;

        this.modifyConfig();
        this.getBillingDetails();
    }

    routeBack() {
        this.router.navigate([`/icx/member-360/${this.route.snapshot.params.member}/billing`]);
    }

    modifyConfig() {
        this.tabLabels = Object.keys(this.config.tabs)
            .map((key) => this.config.tabs[key])
            .sort(sortTabs)
            .map((tab) => tab.tabLabel);
    }

    getBillingDetails() {
        this.isLoading = true;
        this.api.get(`/api/member-360/bills/${this.billId}/`).subscribe(
            (res: BillingDetailsApiResponse) => {
                this.billingDetails = this.modifyBillingDetails(res);
                this.isLoading = false;
            },
            () => {
                // error block
                this.isLoading = false;
            }
        );
    }

    keepOrder(a, b) {
        return a.value.position - b.value.position;
    }

    public modifyBillingDetails(apiResponse: BillingDetailsApiResponse): BillingDetailsModifiedResponse {
        this.comparisonData = [];
        this.leftSideValue = this.formattingService.restructureValueBasedOnFormat(apiResponse[this.config.card.leftSide.prop], {
            format: formatTypes.CURRENCY,
        });

        apiResponse.products_billed?.forEach((product) => {
            this.comparisons.forEach((comparison) => {
                this.comparisonData.push({
                    comparisonTitle: comparison,
                    lives: product[`covered_${comparison.toLowerCase()}_lives`],
                    conventionalPremium: product[`${comparison.toLowerCase()}_conventional_premium`],
                });
            });
        });

        return apiResponse;
    }
}
