import { ProfileSearchService } from 'src/app/icx/shared/services/profile-search.service';
import { Component, OnInit, Input, Output, EventEmitter, AfterViewChecked } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import qs from 'qs';
import { ConfigService, APIService } from 'src/app/shared/services';
import {
    ModalConfig,
    FormControlValidatorsService,
    AllControlsConfiguration,
    FormControlConfiguration,
    Datagrid,
    PaginatorConfiguration,
} from '@zipari/design-system';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { distinctUntilChanged, debounceTime, finalize } from 'rxjs/operators';
import { ApiListResponse } from 'src/app/shared/models/shared/ApiListResponse.model';
import { MemberData, MemberAddress } from 'src/app/icx/shared/services/member-data.service';
import { PaginatorService } from 'src/app/icx/shared/services/paginator.service';

interface QueryObject {
    [key: string]: any;
}

enum FilterLevels {
    PRIMARY = 'Primary',
    SECONDARY_PLUS = 'Secondary Plus',
}
export interface ProfileSearchPageConfig {
    header_text1: string;
    header_text2: string;
    header_text3: string;
    header_text4: string;
    illustration_text: string;
    illustration: string;
    src: string;
    filters: { controls: FormControlConfiguration[] };
    modalConfig: ModalConfig;
    table: Datagrid;
    paginator: number;
    sorting: {
        key: string;
        order: string;
    };
}

@Component({
    selector: 'profile-search-modal',
    templateUrl: './profile-search-modal.component.html',
    styleUrls: ['./profile-search-modal.component.scss'],
})
export class ProfileSearchModalComponent implements OnInit, AfterViewChecked {
    @Input() openModal: boolean = true;
    @Output() openModalChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    allfieldEmpty: boolean = true;
    formGroup: FormGroup;
    config: ProfileSearchPageConfig;
    modalConfig: ModalConfig;
    members: MemberData[];
    searchFilters: AllControlsConfiguration[];
    sortingElement: string = '';
    primaryFields: string[];
    secondaryPlusFields: string[];
    filtersChanged: boolean = false;
    public loadingMembers: boolean = true;
    public pagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };

    constructor(
        private configService: ConfigService,
        private api: APIService,
        private profileSearchService: ProfileSearchService,
        private analyticsService: AnalyticsService,
        private validatorService: FormControlValidatorsService,
        private paginatorService: PaginatorService
    ) {}

    ngOnInit() {
        this.config = this.configService.getControlPanelConfig<any>('profile-search');
        this.modalConfig = this.config.modalConfig;
        this.setupFilters(this.config.filters.controls);
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.MemberSearch_viewed });
    }

    ngAfterViewChecked() {
        this.profileSearchService.show$.subscribe((show: boolean) => {
            show ? (this.openModal = true) : (this.openModal = false);
        });
    }

    handleCancel() {
        this.clearForm();
        this.openModal = false;
        this.members = null;
        this.openModalChange.emit(false);
    }

    setupFilters(filters: AllControlsConfiguration[]) {
        this.formGroup = new FormGroup({});
        this.searchFilters = filters.map(this.createFormControl.bind(this));
        this.primaryFields = filters
            .filter((field: AllControlsConfiguration) => field.hint && field.hint === FilterLevels.PRIMARY)
            .map((p_field: AllControlsConfiguration) => p_field.prop);
        this.secondaryPlusFields = filters
            .filter((field: AllControlsConfiguration) => field.hint && field.hint === FilterLevels.SECONDARY_PLUS)
            .map((p_field: AllControlsConfiguration) => p_field.prop);
        this.toggleSearchButton();
    }

    createFormControl(config: any) {
        const validators = this.validatorService.getFormControlValidators(config);
        const control = new FormControl('', { validators });
        const newFormControl = Object.assign(config, {
            control: control,
        });

        this.formGroup.addControl(newFormControl.prop, control);

        if (config.value) {
            this.formGroup.get(newFormControl.prop).setValue(config.value);
        }

        return newFormControl;
    }

    setCurrentPage(page: number) {
        this.pagingConfig.currentPage = page;

        this.pagingConfig.showingStart = page === 1 ? 1 : this.config.paginator * (page - 1) + 1;

        this.pagingConfig.showingEnd =
            page === this.pagingConfig.totalPages ? this.pagingConfig.totalResults : this.config.paginator * page;
        this.searchMembers();
    }

    searchMembers() {
        this.loadingMembers = true;
        if (this.filtersChanged) {
            this.pagingConfig.currentPage = 1;
            this.pagingConfig.showingStart = 0;
        }
        const query = this.buildQueryParams();
        this.members = [];
        this.api
            .get(query)
            .pipe(
                finalize(() => {
                    this.loadingMembers = false;
                })
            )
            .subscribe((res: ApiListResponse<MemberData>) => {
                this.members = res.results;
                this.filtersChanged = false;
                this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, res.count, this.config.paginator);
                this.members.forEach((member: MemberData) => {
                    member.physical_address = member?.addresses?.filter((address: MemberAddress) => address.address_type === 'physical')[0];
                    member.fullName = `${member?.name?.first_name || ''} ${member?.name?.middle_name || ''} ${
                        member?.name?.last_name || ''
                    }`;
                });
            });
    }

    navigateToDetails(event: MemberData) {
        // temporarily changing id to member_number that should go back to id
        const memberId = event.member_number;
        window.open(`${window.location.origin}/icx/member-360/${memberId}/profile`, '_blank');
    }

    clearForm() {
        this.formGroup.reset();
        this.allfieldEmpty = true;
    }

    buildQueryParams() {
        const endpoint = '/api/member-360/members/?';
        const queryObject: QueryObject = {
            page: this.pagingConfig.currentPage,
            page_size: this.config.paginator ? this.config.paginator : null,
        };
        let initialSort = '';
        const searchParamsUsed: string[] = [];

        let formattedPhone = this.formGroup.value.phone_number;
        if (formattedPhone && formattedPhone.indexOf('-') === -1) {
            formattedPhone = `${formattedPhone.substring(0, 3)}-${formattedPhone.substring(3, 6)}-${formattedPhone.substring(6, 10)}`;
        }
        this.formGroup.value.phone_number = formattedPhone;

        for (const key of Object.keys(this.formGroup.value)) {
            if (this.formGroup.value[key]) {
                const trimmedValue = this.formGroup.value[key].trim();
                queryObject[key] = trimmedValue;
                searchParamsUsed.push(key);
            }
        }

        if (this.config.sorting && this.config.sorting.key && this.config.sorting.order && !this.sortingElement) {
            if (this.config.sorting.order === 'asc') {
                this.config.sorting.key === 'full_name' ? (initialSort = 'first_name') : (initialSort = this.config.sorting.key);
            } else if (this.config.sorting.order === 'desc') {
                this.config.sorting.key === 'full_name' ? (initialSort = '-first_name') : (initialSort = `-${this.config.sorting.key}`);
            }
            queryObject.ordering = initialSort;
        } else if (this.sortingElement) {
            queryObject.ordering = this.sortingElement;
        }

        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.MemberSearch_searched }, undefined, {
            keys_searched: searchParamsUsed,
        });

        return endpoint + qs.stringify(queryObject, { skipNulls: true });
    }

    toggleSearchButton() {
        this.formGroup.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value: { [key: string]: string }) => {
            this.allfieldEmpty = true;
            this.filtersChanged = true;
            this.sortingElement = '';
            let filtersUsedCount = 0;
            let secondaryPlusSelected = false;
            if (!this.formGroup.valid) {
                this.allfieldEmpty = true;

                return;
            }

            Object.keys(value).forEach((key: string) => {
                if (value[key]) {
                    filtersUsedCount++;
                    if (this.primaryFields.includes(key)) {
                        this.allfieldEmpty = false;
                    }
                    if (this.secondaryPlusFields.includes(key)) {
                        secondaryPlusSelected = true;
                    }
                }
            });

            if (filtersUsedCount > 1 && secondaryPlusSelected) {
                this.allfieldEmpty = false;
            }
        });
    }

    sortTable(element: any) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.members = [];
        this.searchMembers();
    }

    handleKeySearch(event: KeyboardEvent) {
        if (!this.allfieldEmpty && event.keyCode === 13) {
            this.searchMembers();
        }
    }
}
