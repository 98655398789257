import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelAttributeModule } from '@zipari/design-system';
import { PcpComponent } from './pcp.component';

@NgModule({
    declarations: [PcpComponent],
    imports: [CommonModule, ModelAttributeModule],
})
export class PcpModule {}
