import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControlModule, ButtonModule, TableModule, PaginatorModule, DatagridModule } from '@zipari/design-system';
import { TabViewModule } from 'src/app/icx/shared/modules/tab-view/tab-view.module';
import { DetailTabsModule } from 'src/app/icx/shared/modules/detail-tabs/detail-tabs.module';
import { DocumentCenterComponent } from './document-center.component';

@NgModule({
    declarations: [DocumentCenterComponent],
    imports: [CommonModule, FormControlModule, ButtonModule, TableModule, DetailTabsModule, TabViewModule, DatagridModule, PaginatorModule],
    exports: [DocumentCenterComponent],
})
export class DocumentCenterModule {}
