import { Injectable } from '@angular/core';
import { ActivatedRoute, CanActivate, Router } from '@angular/router';
import { AuthService } from './auth.service';
import { ConfigService } from './config.service';

@Injectable()
export class AuthGuardService implements CanActivate {
    constructor(
        public configService: ConfigService,
        public authService: AuthService,
        public router: Router,
        public route: ActivatedRoute
    ) {}

    canActivate(): boolean {
        const globalConfig = this.configService.getPageConfig<any>('global');
        const handleAuthInAppbase = globalConfig.handleAuth;

        if (handleAuthInAppbase && !this.authService.loggedInUser) {
            this.authService.replayPath = window.location.pathname;

            this.router.navigate([this.configService.appRoute, 'auth']);

            return false;
        }

        return true;
    }
}
