import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelAttributeModule, TabsModule } from '@zipari/design-system';
import { MapModule } from '../../shared/modules';
import { GroupsComponent } from './groups.component';

@NgModule({
    declarations: [GroupsComponent],
    imports: [CommonModule, TabsModule, ModelAttributeModule, MapModule],
})
export class GroupsModule {}
