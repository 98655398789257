import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { finalize } from 'rxjs/operators';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';

import { ExpandingArticleConfig } from '@zipari/design-system/lib/notes/expanding-article/expanding-article.model';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ButtonConfig, controlTypes, TypeaheadConfiguration } from '@zipari/design-system';
import { FormGroup } from '@angular/forms';
import { FormManagerService } from 'src/app/icx/shared/services/form-manager.service';
import { ConfigService } from 'src/app/shared/services';
import { BenefitService } from '../benefits.service';
import { BenefitsServiceItem, BenefitDetailsAPIResponse, BenefitDetailsPlacesOfService, BenefitDetailsPageConfig } from '../benefits.model';

@Component({
    selector: 'benefits-details',
    templateUrl: './benefits-details.component.html',
    styleUrls: ['./benefits-details.component.scss'],
})
export class BenefitsDetailsComponent implements OnInit {
    public config: BenefitDetailsPageConfig;
    public data: BenefitDetailsAPIResponse;
    public placesOfServiceTabLabels: string[] = [];
    public currentTab: BenefitDetailsPlacesOfService;
    public backButtonLabel: string = 'Benefits';
    public currentService: BenefitsServiceItem;
    public expandingTextConfig: ExpandingArticleConfig = { lines: 3, shrinkText: ' View Less', expandText: ' View More' };
    public loadingData: boolean = true;
    public illustrationSrc: string = 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-4.svg';
    public showUsageModal: boolean = false;
    public categoryServices: BenefitsServiceItem[] = [];
    viewRelatedServicesButton: ButtonConfig = {
        content: 'View Related Services',
        icon: 'chevron_down',
        isIconRight: true,
        level: 'medium',
    };
    showVRSPopup: boolean = false;
    viewRelatedServiceForm: FormGroup = new FormGroup({});
    vrsControls: TypeaheadConfiguration[] = [
        {
            placeholder: 'Enter Search Term',
            prop: 'serviceId',
            type: controlTypes.typeahead,
            icon: 'search',
            showOptionsOnOpen: true,
            options: [],
        },
    ];

    private memberId: string;
    public benefitServiceId: string;
    public categoryId: string;
    private relatedServices: any[] = [];

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private configService: ConfigService,
        private benefitService: BenefitService,
        private panelService: SidePanelService,
        private analyticsService: AnalyticsService,
        private formManagerService: FormManagerService
    ) {
        this.panelService.toggleSidePanel(false);
    }

    ngOnInit(): void {
        this.config = this.configService.getControlPanelConfig('benefits-details');
        this.categoryId = this.route.snapshot.params.categoryId;
        this.benefitServiceId = this.route.snapshot.params.serviceId;
        this.memberId = this.route.snapshot.params.member;

        this.setupBenefitsData();

        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.BenefitsDetails_viewed }, this.memberId);
    }

    setupBenefitsData() {
        if (this.categoryId) {
            const currentCategory = this.benefitService.categoriesList.find((cat) => cat.id === this.categoryId);
            if (currentCategory) {
                this.backButtonLabel = currentCategory.display_name;
            }
            this.benefitService.getBenefitServiceByCategory(this.categoryId).subscribe((res) => {
                this.currentService = res?.details?.results.find((service: BenefitsServiceItem) => service.id === this.benefitServiceId);
                this.categoryServices = [...res?.details?.results];
                if (!this.currentService) {
                    this.routeBack();
                } else {
                    this.configureViewRelatedServices();
                    this.getBenefitData();
                }
            });
        } else {
            this.currentService = this.benefitService.servicesList.find(
                (service: BenefitsServiceItem) => service.id === this.benefitServiceId
            );
            if (!this.currentService) {
                this.routeBack();
            } else {
                this.configureViewRelatedServices();
                this.getBenefitData();
            }
        }
        if (this.config?.useBenefitsDetailsAPIForLongDescription) {
            this.benefitService.getBenefitDetailForLongDescription(this.benefitServiceId).subscribe((res) => {
                if (this.currentService) {
                    this.currentService.long_description = res.long_description;
                }
            });
        }
    }

    getBenefitData() {
        this.benefitService
            .getBenefitDetails(this.memberId, this.benefitServiceId)
            .pipe(
                finalize(() => {
                    this.loadingData = false;
                })
            )
            .subscribe((res: BenefitDetailsAPIResponse) => {
                this.data = res;
                this.createDisplayConfig();
            });
    }

    configureViewRelatedServices() {
        if (!this.categoryServices.length) {
            this.vrsControls[0].options = [
                {
                    label: 'No results',
                    value: 'No results',
                    name: null,
                },
            ];
        } else {
            this.vrsControls[0].options = this.categoryServices
                .filter((service) => service.id !== this.benefitServiceId)
                .map((service) => ({
                    label: service.display_name,
                    name: service.id,
                    value: service.display_name,
                }));
        }
        this.viewRelatedServiceForm = this.formManagerService.populateFormGroup(this.vrsControls);
    }

    routeBack() {
        if (this.categoryId) {
            this.router.navigate([`/icx/member-360/${this.memberId}/benefits/categories/${this.categoryId}`]);
        } else {
            this.router.navigate([`/icx/member-360/${this.memberId}/benefits/`]);
        }
    }

    viewUsage() {
        this.showUsageModal = true;
    }

    selectedPOSTab(index: number) {
        this.currentTab = this.data.places_of_service[index];
    }

    createDisplayConfig() {
        if (this.data.places_of_service.length) {
            this.placesOfServiceTabLabels = this.data.places_of_service.map((pos: BenefitDetailsPlacesOfService) => pos.display_name);
            this.currentTab = this.data.places_of_service[0];
        }
    }

    typeaheadSelected(typeaheadEvent) {
        if (!typeaheadEvent.event?.name) {
            return;
        }
        this.benefitServiceId = typeaheadEvent.event.name;
        this.loadingData = true;
        this.showVRSPopup = !this.showVRSPopup;

        if (this.categoryId) {
            this.router.navigate([`/icx/member-360/${this.memberId}/benefits`, this.categoryId, this.benefitServiceId]);
        } else {
            this.router.navigate([`/icx/member-360/${this.memberId}/benefits/services/${this.benefitServiceId}`]);
        }

        this.setupBenefitsData();
    }

    hideUsageModal() {
        this.showUsageModal = false;
    }

    viewRelatedServices() {
        this.showVRSPopup = !this.showVRSPopup;
    }
}
