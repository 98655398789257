import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { slideRight } from '../../../../shared/animations';

@Component({
    selector: 'message-filter',
    templateUrl: './message-filter.component.html',
    styleUrls: ['./message-filter.component.scss'],
    animations: [slideRight],
})
export class MessageFilterComponent implements OnInit {
    @Input() filter;
    @Input() filterConfig;
    @Output() filtering = new EventEmitter<boolean>();

    formGroup: FormGroup;

    constructor() {}

    ngOnInit() {
        if (!!this.filterConfig) {
            this.formGroup = new FormGroup({});

            this.filterConfig.map(filter => {
                filter.options.map(option => {
                    this.formGroup.addControl(option.key, new FormControl(option.value));
                });
            });
        }
    }

    goBack() {
        this.filtering.emit();
    }
}
