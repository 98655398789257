/* eslint-disable @angular-eslint/no-input-rename */
import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { map, takeUntil } from 'rxjs/operators';
import { getValue } from '@zipari/web-utils';

import { BaseAddressDropdownComponent } from '../base-address-dropdown.component';

import { AddressService } from './../../../services/address.service';

@Component({
    selector: 'counties',
    templateUrl: './counties.component.html',
    styleUrls: ['./counties.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CountiesComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CountiesComponent),
            multi: true,
        },
    ],
})
export class CountiesComponent extends BaseAddressDropdownComponent implements OnInit, OnDestroy, Validator {
    @Input('group') _addressFormGroup;
    @Input('config') _config;

    constructor(formControlService: FormControlService, addressService: AddressService) {
        super(addressService, formControlService);
    }

    ngOnInit() {
        this.initCountyOption();
        this.baseInit(this._addressFormGroup, this._config, 'county');
    }

    private getCountyName(countyCode: string): string {
        const countyOption = this.config.options.find(option => option.value === countyCode);

        return countyOption && countyOption.label;
    }

    /*
        If persisted value - create dropdown option for display
        This avoids lag when waiting for initial API response
        Set before form control && placeholder initialization to avoid lifecycle errors
    */
    initCountyOption() {
        const value = getValue(this._addressFormGroup.value, 'county');
        if (value) {
            this._config.options = [
                {
                    label: value.county_name,
                    value: value.county_code,
                },
            ];
        }
    }

    registerOnChange(fn: any) {
        this.formGroup.valueChanges
            .pipe(
                takeUntil(this.destroy),
                map(({ county_code }) => {
                    const name = this.getCountyName(county_code);

                    return {
                        county_code: county_code,
                        county_name: name,
                        county_display: name,
                    };
                })
            )
            .subscribe(fn);
    }

    //  Propagate invalidity to parent form group
    public validate(control): ValidationErrors {
        if (control.controls) {
            return this.formGroup.valid ? null : [{ county: true }];
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
