import { DropdownConfiguration, FormattingService, ErrorEmptyConfig } from '@zipari/design-system';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MemberDataService } from 'src/app/icx/shared/services/member-data.service';
import { FormManagerService } from 'src/app/icx/shared/services/form-manager.service';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { httpErrorToErrorEmpty } from '@zipari/web-services';
import { PoliciesResponse, PoliciesProductCoverage, PoliciesMemberCoverages } from '../policies/policies.constants';
import { UtilizationsAPIResponse, AccumsAPIResponse } from '../benefits/benefits.model';
import { BenefitService } from '../benefits/benefits.service';
import { ConfigService } from '../../../../shared/services';
import { BenefitParticipationLevel } from '../benefits/benefits-details/benefits-table/benefits-table.component';
import { SidePanelService } from './../../../shared/services/side-panel.service';
import { AccumulatorData, SingleTabData, Accumulator } from './finanicial-accumulator.model';

@Component({
    selector: 'financial-accumulator',
    templateUrl: './financial-accumulator.component.html',
    styleUrls: ['./financial-accumulator.component.scss'],
})
export class FinancialAccumulatorComponent implements OnInit {
    pageConfig: any;
    public pageForm: FormGroup = new FormGroup({});
    accumulatorsData: AccumulatorData;
    @Output() selectedTab: EventEmitter<any> = new EventEmitter<any>();
    selectedData: SingleTabData;
    activeTab: string;
    data: UtilizationsAPIResponse[];
    benefitServiceId: string;
    updatedData;
    public loadedPeriods: boolean = false;
    public loadedCoverage: boolean = false;
    private memberId: string = '';
    private policiesList: PoliciesResponse[] = [];
    public filteredPolicies: PoliciesResponse[] = [];
    private coverageList: PoliciesProductCoverage[] = [];
    public benefitPeriods: any[] = [];
    private onDestroy$: Subject<any> = new Subject();
    public illustrationSrc: string = 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-4.svg';
    showClaimsModal: boolean = false;
    modalText: string = '';
    claimsModalData;
    showErrorScreen: boolean = false;
    errorConfig: ErrorEmptyConfig;

    constructor(
        private configService: ConfigService,
        private route: ActivatedRoute,
        private panelService: SidePanelService,
        private benefitsService: BenefitService,
        private memberDataService: MemberDataService,
        private formManagerService: FormManagerService,
        private formattingService: FormattingService,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.pageConfig = this.configService.getControlPanelConfig(this.route.snapshot.data['pageName']);
        this.modalText = this.pageConfig.textToOpenModal;
        this.memberId = this.route.snapshot.params.member;
        this.getPolicyCoverageList();
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Accumulators_viewed }, this.memberId);
    }

    getPolicyCoverageList() {
        this.policiesList = [];
        this.filteredPolicies = [];
        this.coverageList = [];
        this.memberDataService
            .getPoliciesList(this.memberId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (res) => {
                    this.policiesList = res.details?.results;

                    // filtering policies list based on configuration
                    if (this.policiesList?.length) {
                        if (this.pageConfig?.filterOnPolicyStatus) {
                            this.filteredPolicies = this.policiesList.filter(
                                (p: PoliciesResponse) => p.policy_status === this.pageConfig.filterOnPolicyStatus
                            );
                        } else {
                            this.filteredPolicies = [...this.policiesList];
                        }
                    }

                    this.filteredPolicies.forEach((policy: PoliciesResponse) => {
                        policy.product_coverages.forEach((product_coverage: PoliciesProductCoverage) => {
                            const foundMemberCoverage = product_coverage.member_coverages.find(
                                (memberCoverage: PoliciesMemberCoverages) =>
                                    memberCoverage.member?.member_number === this.memberId || memberCoverage.member?.id === this.memberId
                            );
                            if (foundMemberCoverage) {
                                product_coverage.policy_id = policy.policy_number;
                                this.coverageList.push(product_coverage);
                            }
                        });
                    });
                    this.populateCoverageDropdownOptions();
                },
                (err) => {
                    this.handleError(err);
                }
            );
    }

    populateCoverageDropdownOptions() {
        this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
            if (control.prop === 'coverage_options') {
                this.coverageList.forEach((coverage: PoliciesProductCoverage) => {
                    if (coverage.external_plan_name) {
                        control.options.push({
                            label: `${coverage.external_plan_name}`,
                            value: coverage.plan_id,
                            name: coverage.policy_id,
                        });
                    }
                });
            }
        });

        this.pageForm = this.formManagerService.populateFormGroup(this.pageConfig.pageLevelFilters.controls);
        this.setupListeners();
        this.loadedCoverage = true;
    }

    populateBenefitPeriodsDropdowns() {
        this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
            if (control.prop === 'benefit_periods') {
                control.isDisabled = false;
                control.options = this.benefitPeriods.map((period) => ({
                    label: `${this.formattingService.restructureValueBasedOnFormat(period.start_date, {
                        format: 'DATE',
                    })} - ${this.formattingService.restructureValueBasedOnFormat(period.end_date, { format: 'DATE' })}`,
                    value: period.id,
                }));
            }
        });
        this.pageConfig.pageLevelFilters.controls = [...this.pageConfig.pageLevelFilters.controls];
    }

    setupListeners() {
        this.pageForm.controls['benefit_periods'].valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
            this.getAccumulatorsData();
        });
        this.pageForm.controls['coverage_options'].valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((planId) => {
            // clear benefits dropdown
            this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
                if (control.prop === 'benefit_periods') {
                    control.isDisabled = true;
                }
            });
            this.pageConfig.pageLevelFilters.controls = [...this.pageConfig.pageLevelFilters.controls];
            this.pageForm.controls['benefit_periods'].reset(null, { emitEvent: false });
            if (planId) {
                this.requestBenefitPeriods(planId);
            }
        });
    }

    requestBenefitPeriods(planId: number) {
        const mcovid = this.benefitsService.getMemeberCoverageID(planId, this.coverageList, this.memberId);

        this.benefitsService
            .getBenefitPeriods(this.memberId, mcovid)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (res) => {
                    this.benefitPeriods = res.results;
                    this.populateBenefitPeriodsDropdowns();
                },
                (err) => {
                    this.handleError(err);
                }
            );
    }

    getAccumulatorsData() {
        if (this.pageForm.controls['coverage_options'].value && this.pageForm.controls['benefit_periods'].value) {
            this.benefitsService.getAccumulators(this.memberId, 'none', this.pageForm.controls['benefit_periods'].value).subscribe(
                (res: AccumsAPIResponse) => {
                    this.data = res.results;
                    this.formatData();
                },
                (err) => {
                    this.handleError(err);
                }
            );
        }
    }

    formatData() {
        this.accumulatorsData = {
            Individual: {
                category: 'Individual',
                cards: [],
            },
            Family: {
                category: 'Family',
                cards: [],
            },
            tabLabels: ['Individual', 'Family'],
        };

        this.data?.forEach((row: UtilizationsAPIResponse) => {
            const AccumsArray = [];
            const percent = (parseInt(row.used) / parseInt(row.total)).toString();
            const tempData: Accumulator = {
                participation_level: BenefitParticipationLevel[row.participation_level] || row.participation_level,
                used: this.formattingService.restructureValueBasedOnFormat(row.used, { format: 'CURRENCY' }),
                remaining: this.formattingService.restructureValueBasedOnFormat(row.remaining, { format: 'CURRENCY' }),
                percent_spent: percent,
                total: this.formattingService.restructureValueBasedOnFormat(row.total, { format: 'CURRENCY' }),
            };
            AccumsArray.push(tempData);
            if (row.benefit_applies_to === 'self') {
                const cards = this.accumulatorsData.Individual.cards.filter((card) => card.accumulator_type === row.accumulator_type);
                if (cards?.length) {
                    cards[0].accumulators.push(tempData);
                } else {
                    this.accumulatorsData.Individual.cards.push({
                        recipient: row.title,
                        accumulator_type: row.accumulator_type,
                        benefit_applies_to: row.benefit_applies_to,
                        accumulator_type_id: row.accumulator_type_id,
                        benefit_service_id: 'none',
                        benefit_period_id: this.pageForm.controls['benefit_periods'].value,
                        policy_id: this.getPolicyId(),
                        accumulators: AccumsArray,
                    });
                }
            } else if (row.benefit_applies_to === 'family') {
                const cards = this.accumulatorsData.Family.cards.filter((card) => card.accumulator_type === row.accumulator_type);
                if (cards?.length) {
                    cards[0].accumulators.push(tempData);
                } else {
                    this.accumulatorsData.Family.cards.push({
                        recipient: row.title,
                        accumulator_type: row.accumulator_type,
                        benefit_applies_to: row.benefit_applies_to,
                        accumulator_type_id: row.accumulator_type_id,
                        benefit_service_id: 'none',
                        benefit_period_id: this.pageForm.controls['benefit_periods'].value,
                        policy_id: this.getPolicyId(),
                        accumulators: AccumsArray,
                    });
                }
            }
        });
        this.tabSelected(0);
    }

    handleError(err) {
        this.loadedCoverage = true;
        this.showErrorScreen = true;
        this.errorConfig = httpErrorToErrorEmpty(err);
        this.errorConfig.title = 'Accumulator Information not available';
    }

    tabSelected(index: number) {
        this.activeTab = this.accumulatorsData.tabLabels[index];
        this.selectedData = this.accumulatorsData[this.activeTab];
    }

    showClaims(event) {
        this.showClaimsModal = true;
        this.claimsModalData = event;
    }

    hideClaims() {
        this.showClaimsModal = false;
    }

    getPolicyId() {
        let policy_id;
        this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
            if (control.prop === 'coverage_options') {
                policy_id = control.options.filter((option) => option.value === this.pageForm.controls['coverage_options'].value)[0]?.name;
            }
        });

        return policy_id;
    }
}
