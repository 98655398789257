export class CXCaptureConfig {
    source_system_name: string = '';
    endpoint: string = '';

    constructor(options = {}) {
        Object.assign(this, options);
    }
}

export class GAConfig {
    mapping?: any;

    // use either gtag or ga based on the requested google analytics library... provide the id
    gtag?: string;
    ga?: string;
}

export enum validCXEvents {
    'plan-details_viewed' = 'plan-details_viewed',
    'plan_added-to-cart' = 'plan_added-to-cart',
    'plan_removed-from-cart' = 'plan_removed-from-cart',
    'contact-us_viewed' = 'contact-us_viewed',
    'plans_compared' = 'plans_compared',
    'application_printed' = 'application_printed',
    'claims_searched' = 'claims_searched',
    'pcp_selection' = 'pcp_selection',
    'pcp_remove_selection' = 'pcp_remove_selection',
    Claims_searched = 'Claims_searched',
    ClaimsList_viewed = 'ClaimsList_viewed',
    ClaimsDetail_viewed = 'ClaimsDetail_viewed',
    ClaimsLineDetail_viewed = 'ClaimsLineDetail_viewed',
    ClaimDoc_downloaded = 'ClaimDoc_downloaded',
    CXEngagements_viewed = 'CXEngagements_viewed',
    Documents_viewed = 'Documents_viewed',
    Documents_searched = 'Documents_searched',
    Documents_downloaded = 'Documents_downloaded',
    Homepage_viewed = 'Homepage_viewed',
    Profile_viewed = 'Profile_viewed',
    MemberSearch_searched = 'MemberSearch_searched',
    MemberSearch_viewed = 'MemberSearch_viewed',
    PoliciesList_viewed = 'PoliciesList_viewed',
    PolicyDetail_viewed = 'PolicyDetail_viewed',
    CoverageDetail_viewed = 'CoverageDetail_viewed',
    CoverageList_viewed = 'CoverageList_viewed',
    ProvidersList_viewed = 'ProvidersList_viewed',
    ProvidersDetail_viewed = 'ProvidersDetail_viewed',
    AppealGrievanceList_viewed = 'AppealGrievanceList_viewed',
    AppealGrievanceList_downloaded = 'AppealGrievanceList_downloaded',
    ReferralsList_viewed = 'ReferralsList_viewed',
    ReferralsDetail_viewed = 'ReferralsDetail_viewed',
    AuthorizationsList_viewed = 'AuthorizationsList_viewed',
    AuthorizationsDetail_viewed = 'AuthorizationsDetail_viewed',
    Accumulators_viewed = 'Accumulators_viewed',
    Benefits_searched = 'Benefits_searched',
    BenefitsCategory_viewed = 'BenefitsCategory_viewed',
    BenefitsDetails_viewed = 'BenefitsDetails_viewed',
    BenenfitsUtilizations_viewed = 'BenenfitsUtilizations_viewed',
    Sso_initiated = 'SSO_initiated',
    CoverageDetails_viewed = 'CoverageDetail_viewed',
}

export enum validGAEvents {
    'workflow_step' = 'workflow_step',
    'plan-details_viewed' = 'plan-details_viewed',
    'plan_added-to-cart' = 'plan_added-to-cart',
    'plan_removed-from-cart' = 'plan_removed-from-cart',
    'contact-us_viewed' = 'contact-us_viewed',
    'plans_compared' = 'plans_compared',
    'application_printed' = 'application_printed',
    'pcp_selection' = 'pcp_selection',
    'pcp_remove_selection' = 'pcp_remove_selection',
}

export class AnalyticsContext {
    CXKey?: validCXEvents;
    GAKey?: validGAEvents;
}

export const ProductPrefixes = {
    zshop: 'shp_',
    scout: 'ps_',
    'provider-portal': 'pp_',
    'member-portal': 'mp_',
    'broker-portal': 'bp_',
    'member-360': 'member360_',
};

export class workflowContext {
    title: string;
    key: string;
    webuser_id?: number;
    web_session_id?: string;
}

export enum validSSOCXEventDictonaryValue {
    Navigation = 'Top Level Navigation',
    Claims = 'Claims',
    FinancialAccumulator = 'Financial Accumulator',
    BillingAndPayment = 'Billing and Payment',
    Benefits = 'Benefits',
    Policies = 'Policies',
    Coverages = 'Coverages',
    Providers = 'Providers',
    Referrals = 'Referrals',
    Authorizations = 'Authorizations',
    Documents = 'Documents',
    CXEngagements = 'CX Engagements',
    AppealAndGrievance = 'Appeal and Grievance',
}

export const PageCXEventMap = new Map([
    [validSSOCXEventDictonaryValue.Claims, ['claims', 'claim-detail', 'claim-line']],
    [validSSOCXEventDictonaryValue.Benefits, ['benefits-search', 'benefits-category', 'benefits-details']],
    [validSSOCXEventDictonaryValue.FinancialAccumulator, ['financial-accumulator']],
    [validSSOCXEventDictonaryValue.BillingAndPayment, ['billing-payments', 'billing-details', 'billing-payments', 'payment-details']],
    [validSSOCXEventDictonaryValue.Policies, ['policies', 'policy-details', 'policy-coverage']],
    [validSSOCXEventDictonaryValue.Coverages, ['coverages', 'coverage-details']],
    [validSSOCXEventDictonaryValue.Providers, ['providers', 'pcp-detail']],
    [validSSOCXEventDictonaryValue.Referrals, ['referrals', 'referral-details']],
    [validSSOCXEventDictonaryValue.Authorizations, ['preauthorizations', 'preauthorization-details']],
    [validSSOCXEventDictonaryValue.Documents, ['document-center']],
    [validSSOCXEventDictonaryValue.CXEngagements, ['member-engagement']],
    [validSSOCXEventDictonaryValue.AppealAndGrievance, ['appeals']],
]);
