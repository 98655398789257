/* eslint-disable @angular-eslint/no-output-native */
import { EventEmitter, Injectable, Output } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TogglePanelService {
    isOpen = false;

    @Output() change: EventEmitter<boolean> = new EventEmitter();

    constructor() {}

    toggle() {
        this.isOpen = !this.isOpen;
        this.change.emit(this.isOpen);
    }
}
