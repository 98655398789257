import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormControlModule,
    FormGroupModule,
    ModelAttributeModule,
    RippleModule,
    ModelAttributeSectionsModule,
    TabsModule,
} from '@zipari/design-system';

import { ClaimLineComponent } from './claim-line.component';

@NgModule({
    declarations: [ClaimLineComponent],
    imports: [
        CommonModule,
        ModelAttributeModule,
        FormGroupModule,
        ModelAttributeSectionsModule,
        TabsModule,
        FormControlModule,
        RippleModule,
    ],
})
export class ClaimLineModule {}
