import { Component, OnInit } from '@angular/core';

import { accordion, fade, slideLeft } from '../../../../shared/animations';

import { NavigationService } from '../navigation.service';

@Component({
    selector: 'mobile-menu',
    templateUrl: './mobile-menu.component.html',
    styleUrls: ['./mobile-menu.component.scss'],
    animations: [accordion, fade, slideLeft],
})
export class MobileMenuComponent implements OnInit {
    toolbarMenuOpen: boolean = false;

    constructor(public navigationService: NavigationService) {}

    ngOnInit() {}
}
