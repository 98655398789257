import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableModule, TabsModule, ModelAttributeModule, ButtonModule, DatagridModule, PaginatorModule } from '@zipari/design-system';
import { ResultsModule } from '../../../shared/modules/results/results.module';
import { BadgeModule } from '../../../shared/modules/badge/badge.module';
import { PolicyDetailsComponent } from './policy-details.component';

@NgModule({
    declarations: [PolicyDetailsComponent],
    imports: [
        CommonModule,
        TabsModule,
        ModelAttributeModule,
        TableModule,
        ResultsModule,
        ButtonModule,
        PaginatorModule,
        BadgeModule,
        DatagridModule,
    ],
})
export class PolicyDetailsModule {}
