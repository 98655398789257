import { validEnrollmentTypes } from '../../../services/coverage-effective-date.service';

export const whichEnrollment = {
    prop: 'whichEnrollment',
    label: '',
    type: 'radio',
    options: [
        {
            label: 'See if I qualify for Special Enrollment.',
            value: 'SEP',
        },
        {
            label: 'Continue with standard Open Enrollment.',
            value: 'OE',
        },
    ],
    validators: ['required'],
};

export enum coverageEffectiveDisplayTypes {
    form = 'form',
}

export class CoverageEffectiveConfig {
    multipleEffectiveDates?: boolean;
    disableSEP?: boolean;
    disableOE?: boolean;
    displayType?: coverageEffectiveDisplayTypes;
    isDisabled?: boolean;
}

export const effectiveDateDropdownConfig = {
    isDisabled: false,
    placeholder: 'Select one',
    label: 'Coverage Effective Date',
    prop: 'coverage_effective_date',
    type: 'dropdown',
    options: [],
    validators: ['required'],
};

export const intitialEnrollmentPeriods = {
    'dental/vision': validEnrollmentTypes.OE,
};

export const disableSEPForCoverageTypes = {
    'dental/vision': true,
};
