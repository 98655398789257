import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';

import { BaseCVAComponent } from './../base-cva.component';
import {
    address1Config,
    address2Config,
    addressConfigs,
    cityConfig,
    stateConfig,
    zipCodeConfig,
    STATE_OPTIONS,
    ABBREVIATED_STATE_OPTIONS,
} from './address.constants';

@Component({
    selector: 'address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => AddressComponent),
            multi: true,
        },
    ],
})
export class AddressComponent extends BaseCVAComponent implements OnInit, OnDestroy {
    @Input() config: any = {};
    @Input() direction = '';

    addressConfigs = addressConfigs;
    address1Config = address1Config;
    address2Config = address2Config;
    cityConfig = cityConfig;
    zipCodeConfig = zipCodeConfig;
    stateConfig = stateConfig;

    constructor(private formControlService: FormControlService) {
        super();
    }

    ngOnInit() {
        this.formGroup = new FormGroup({});

        if (this.config.configs) {
            this.address1Config = { ...address1Config, ...this.mergeConfig('address1') };
            this.address2Config = { ...address2Config, ...this.mergeConfig('address2') };
            this.cityConfig = { ...cityConfig, ...this.mergeConfig('city') };
            this.zipCodeConfig = { ...zipCodeConfig, ...this.mergeConfig('zipCode') };
            this.stateConfig = {
                ...stateConfig,
                options:
                    this.config.configs['state'] && this.config.configs['state'].abbreviated ? ABBREVIATED_STATE_OPTIONS : STATE_OPTIONS,
                ...this.mergeConfig('state'),
            };
        }

        [this.address1Config, this.address2Config, this.cityConfig, this.zipCodeConfig, this.stateConfig].forEach(config => {
            this.formControlService.addControlToFormGroup(this.formGroup, config);
        });
    }

    private mergeConfig(configSection): any {
        if (!this.config.configs[configSection]) {
            return {};
        }

        return this.config.configs[configSection];
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
