import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { pipe } from 'rxjs';
import { debounceTime, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

import { BaseCVAComponent } from './../base-cva.component';
import { industryConfigs } from './industries.constants';
import { IndustriesService } from './industries.service';

@Component({
    selector: 'industries',
    templateUrl: './industries.component.html',
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => IndustriesComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => IndustriesComponent),
            multi: true,
        },
        IndustriesService,
    ],
})
export class IndustriesComponent extends BaseCVAComponent implements OnInit, OnDestroy {
    @Input() direction;
    @Input() config;

    private sicCodeInput = new Subject<string>();
    private industryTitleInput = new Subject<string>();

    public loadingSicCodes = false;
    public loadingIndustryTitle = false;
    public showSicCodeError = false;
    public showIndustryTitleError = false;
    private debounceTime = 250;

    public industryTitleConfig = industryConfigs.industryTitle;
    public sicCodeConfig = industryConfigs.sicCode;

    public get industryTitleProp() {
        return this.industryTitleConfig.prop;
    }

    public get sicCodeProp() {
        return this.sicCodeConfig.prop;
    }

    constructor(private industriesService: IndustriesService, public formControlService: FormControlService) {
        super();
    }

    ngOnInit() {
        this.setConfig();

        this.formGroup = new FormGroup({});
        this.formControlService.addControlToFormGroup(this.formGroup, this.industryTitleConfig);
        this.formControlService.addControlToFormGroup(this.formGroup, this.sicCodeConfig);

        this.setDisabledState();

        // Subscribe to sic code changes to build industry title typeahead options
        this.sicCodeInput
            .pipe(
                tap(() => {
                    this.loadingSicCodes = true;
                    this.formGroup.get(this.industryTitleProp).reset();
                }),
                this.pipeIndustryInput()
            )
            .subscribe(response => {
                this.showIndustryTitleError = false;
                this.loadingSicCodes = false;
                if (!response.results.length) {
                    this.showSicCodeError = true;
                    this.formGroup.get(this.industryTitleProp).reset();
                } else {
                    this.showSicCodeError = false;
                    this.sicCodeConfig = {
                        ...this.sicCodeConfig,
                        options: this.buildIndustryOptions(response.results, this.sicCodeProp),
                    };
                }
            });

        // Subscribe to industry title changes to build siccode typeahead options
        this.industryTitleInput
            .pipe(
                tap(() => {
                    this.loadingIndustryTitle = true;
                    this.formGroup.get(this.sicCodeProp).reset();
                }),
                this.pipeIndustryInput()
            )
            .subscribe(response => {
                this.loadingIndustryTitle = false;
                this.showSicCodeError = false;
                if (!response.results.length) {
                    this.showIndustryTitleError = true;
                    this.formGroup.get(this.sicCodeProp).reset();
                } else {
                    this.showIndustryTitleError = false;
                    this.industryTitleConfig = {
                        ...this.industryTitleConfig,
                        options: this.buildIndustryOptions(response.results, this.industryTitleProp),
                    };
                }
            });
    }

    setConfig() {
        this.industryTitleConfig = {
            ...industryConfigs.industryTitle,
            validators: this.config.validators || industryConfigs.industryTitle.validators,
            messages: this.config.messages || industryConfigs.industryTitle.messages,
        };
        this.sicCodeConfig = {
            ...industryConfigs.sicCode,
            validators: this.config.validators || industryConfigs.sicCode.validators,
            messages: this.config.messages || industryConfigs.sicCode.messages,
        };
    }

    setDisabledState() {
        if (this.config.isDisabled) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }

    onSICCodeInput(value: string) {
        this.sicCodeConfig = {
            ...this.sicCodeConfig,
            options: [],
        };
        this.sicCodeInput.next(value);
    }

    onIndustryTitleInput(value: string) {
        this.industryTitleConfig = {
            ...this.industryTitleConfig,
            options: [],
        };
        this.industryTitleInput.next(value);
    }

    onSICCodeSelect(selected) {
        this.formGroup.get(this.industryTitleProp).patchValue(selected.industry.industry_title);
    }

    onIndustryTitleSelect(selected) {
        this.formGroup.get(this.sicCodeProp).patchValue(selected.industry.sic_code);
    }

    private pipeIndustryInput() {
        return pipe(
            debounceTime(this.debounceTime),
            takeUntil(this.destroy),
            map(value => this.buildParams(value)),
            mergeMap(params => this.industriesService.getIndustries(params))
        );
    }

    private buildParams(value): any {
        const sicCodeNumbers = /[0-9]+/.exec(value);
        if (sicCodeNumbers) {
            return { sic_code: sicCodeNumbers };
        } else {
            return { industry_title: value };
        }
    }

    private buildIndustryOptions(industries, valueParam) {
        return industries.map(industry => ({
            label: `${industry.sic_code} - ${industry.industry_title}`,
            value: String(industry[valueParam]),
            industry: industry,
        }));
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.formGroup.valid ? null : { custom: true };
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
