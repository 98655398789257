import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { BaseCVAComponent } from './../base-cva.component';

@Component({
    selector: 'coverage-effective-control',
    templateUrl: './coverage-effective-control.component.html',
    styleUrls: ['./coverage-effective-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CoverageEffectiveControlComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CoverageEffectiveControlComponent),
            multi: true,
        },
    ],
})
export class CoverageEffectiveControlComponent extends BaseCVAComponent implements OnInit, OnDestroy {
    @Input() group;
    @Input() config;

    @Output() formCreated = new EventEmitter();

    enrollmentPeriod;
    coverageGroup = new FormGroup({});
    prefillSub;
    prefillVal;
    coverageTypeControl;

    constructor(private formControlService: FormControlService, public cdr: ChangeDetectorRef) {
        super();
    }

    ngOnInit() {
        this.formControlService.addControlToFormGroup(this.group, {
            type: 'text',
            prop: 'coverage_effective_date',
        });

        this.formControlService.addControlToFormGroup(this.group, {
            type: 'text',
            prop: 'enrollment_period',
        });

        if (this.config.valueOnRoot) {
            this.formControlService.addControlToFormGroup(this.group.root, {
                type: 'text',
                prop: 'coverage_effective_date',
            });
        }

        this.checkForPrefillFromParent();
        this.formCreated.emit();

        this.coverageTypeControl = this.group.root.get(this.config.coverageTypePath);
    }

    checkForPrefillFromParent() {
        this.prefillSub = this.group.root.controls.coverage_effective_date.valueChanges.subscribe(prefillVal => {
            this.prefillVal = prefillVal;
            if (this.cdr && !this.cdr['destroyed']) {
                this.cdr.detectChanges();
            }
        });
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }

    handleNewEnrollmentPeriod(enrollmentPeriod) {
        this.enrollmentPeriod = enrollmentPeriod;

        this.group.get('enrollment_period').patchValue(this.enrollmentPeriod);
    }

    handleNewEffectiveDate(effective_date) {
        this.group.get('coverage_effective_date').patchValue(effective_date);

        if (this.config.valueOnRoot) {
            this.group.root.get('coverage_effective_date').patchValue(effective_date);
        }
    }
}
