import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';

import { Subscription } from 'rxjs';

import { ThemeBuilderStore } from '../../../../control-panel/theme-builder/theme-builder-store.service';

@Component({
    selector: 'brand-color',
    templateUrl: './brand-color.component.html',
    styleUrls: ['./brand-color.component.scss'],
})
export class BrandColorComponent implements ControlValueAccessor, OnInit, OnDestroy {
    @Input() group: FormGroup;
    @Input() config;

    private subscription: Subscription = new Subscription();

    public value: string;
    public selectedColor;
    public menuOpen: boolean;
    public palette: any[] = [
        {
            label: 'Base',
            colors: [
                {
                    label: 'No Color',
                    value: 'transparent',
                },
                {
                    label: 'White',
                    value: '#ffffff',
                },
                {
                    label: 'Black',
                    value: '#000000',
                },
            ],
        },
    ];

    constructor(private themeBuilderStore: ThemeBuilderStore, public formControlService: FormControlService) {}

    propagateChange = (_: any) => {
        return;
    };

    onTouched = () => {
        return;
    };

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    registerOnChange(fn: any): void {
        this.propagateChange = fn;
    }

    writeValue(value: any): void {
        this.value = value;
    }

    ngOnInit() {
        this.subscription.add(
            this.themeBuilderStore.theme$.subscribe(response => {
                this.mapColorVariables(response);
            })
        );
    }

    mapColorVariables(response) {
        Object.keys(response.color).forEach((group, index) => {
            this.palette.push({
                label: this.getLabel(group),
                prop: group,
                colors: [],
            });

            Object.keys(response.color[group]).forEach(color => {
                this.palette[index + 1].colors.push({
                    label: this.getLabel(color),
                    prop: color,
                    value: response.color[group][color],
                });
            });
        });

        this.palette.forEach(palette => {
            palette.colors.forEach(color => {
                if (color.value === this.group.get(this.config.prop).value) {
                    this.selectedColor = color;
                }
            });
        });
    }

    getLabel(key) {
        if (typeof key === 'string') {
            return key
                .replace(/([A-Z]+)/g, ' $1')
                .replace(/([A-Z][a-z])/g, ' $1')
                .replace(/--/g, ' ')
                .replace(/\w\S*/g, txt => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
        }
    }

    selectColor(color) {
        this.selectedColor = color;
        this.group.patchValue({
            [this.config.prop]: color.value,
        });
        this.group.markAsDirty();
        this.menuOpen = false;
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
}
