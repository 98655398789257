import { Injectable, isDevMode } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoggingService {
    log(message: string, isDev = true) {
        if (isDev && isDevMode()) {
            console.log(`${new Date().toISOString()} + ' - ' + ${message}`);
        }
    }

    error(error: Error | string, extraMessage: string = '') {
        let formattedError = error;

        if (typeof error === 'string') {
            formattedError = new Error(error);
        }

        if (extraMessage) {
            console.error(extraMessage, formattedError);
        } else {
            console.error(formattedError);
        }
    }
}
