import { AuthService, ConfigService } from 'src/app/shared/services';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { NavigationService } from '../navigation.service';

@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit {
    dropdownOpen: boolean;
    isLoggedIn = false;
    @Output() linkClicked = new EventEmitter<any>();

    constructor(
        public navigationService: NavigationService,
        public router: Router,
        public authService: AuthService,
        private route: ActivatedRoute,
        public configService: ConfigService
    ) {}

    ngOnInit() {
        this.authService.appUserData.subscribe(response => {
            this.isLoggedIn = !!response;
        });
    }

    handleLogout() {
        if (this.route.snapshot.data.indexRoute === 'icx') {
            window.location.assign(`/logout?next=${window.location.origin}/icx/${this.configService.activeRoute}/auth`);
        } else {
            window.location.assign(`/logout?next=${window.location.origin}/${this.configService.appRoute}/auth`);
        }
    }

    toggleIconMenu(icon) {
        event.stopPropagation();

        if (!!icon.items) {
            if (!icon.open) {
                this.navigationService.config.toolbar.icons.forEach(_icon => {
                    _icon.open = false;
                });
            }

            icon.open = !icon.open;
        }
    }

    closeIcon(icon) {
        icon.open = false;
    }

    handleIconClick(icon) {
        if (icon.route) {
            this.router.navigate([icon.route]);
        }

        /* This is hack to log user out before assigning new url. It does not log user out if setTimeout is not used */
        if (!!icon.logout && icon.link) {
            setTimeout(() => {
                window.location.assign(icon.link);
            }, 250);
        }

        if (icon.link) {
            window.location.assign(icon.link);
        }

        if (icon.logout) {
            window.location.assign(`/logout?next=${window.location.origin}/broker-portal/login`);
        }
    }

    handleLinkClick(link) {
        if (!!link.openTargetAsModal) {
            this.linkClicked.emit(link);
        }

        if (link.route) {
            this.router.navigate([link.route]);
        }
    }
}
