import { Component, EventEmitter, Input, Output } from '@angular/core';

import { fade } from '../../animations';

export interface ConfirmationModalConfig {
    header?: string;
    body: string;
    cancel?: string;
    confirm?: string;
}

@Component({
    selector: 'confirmation-modal',
    templateUrl: './confirmation-modal.component.html',
    animations: [fade],
})
export class ConfirmationModalComponent {
    @Input() config: ConfirmationModalConfig = <ConfirmationModalConfig>{};
    @Input() context = <any>{};
    @Output() cancel = new EventEmitter();
    @Output() confirm = new EventEmitter();
}
