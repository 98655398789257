import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelAttributeModule, TabsModule } from '@zipari/design-system';
import { MapModule } from '../../shared/modules';
import { ProviderComponent } from './provider.component';
import { PcpModule } from './pcp/pcp.module';

@NgModule({
    declarations: [ProviderComponent],
    imports: [CommonModule, TabsModule, ModelAttributeModule, MapModule, PcpModule],
})
export class ProviderModule {}
