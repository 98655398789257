import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormattingService, ModelAttributeSectionsConfig, ModelAttributeConfig } from '@zipari/design-system';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ProfileSearchService } from 'src/app/icx/shared/services/profile-search.service';
import { ConfigService, APIResponse } from '../../../../shared/services';
import { PCPsResponse, ProviderDetailsResponse, ProviderSpecialties } from '../providers/providers.constants';
import { MemberDataService, ResponseType, responseTypePC } from './../../../shared/services/member-data.service';
export interface PCPDetailPageConfig {
    attributeConfig: ModelAttributeSectionsConfig;
    [key: string]: any;
}
export interface ProviderDetailsPageData {
    pcp_list: PCPsResponse;
    pcp_detail: ProviderDetailsResponse;
}

enum ViewMode {
    MEMBER_CONTEXT = 'member_context',
    STANDALONE = 'standalone',
}

@Component({
    selector: 'pcp-detail',
    templateUrl: './pcp-detail.component.html',
    styleUrls: ['./pcp-detail.component.scss'],
})
export class PcpDetailComponent implements OnInit {
    public config: PCPDetailPageConfig;
    private provider_id: string;
    showPcpDetails: boolean;
    // todo: when BH fields gets added
    data: ProviderDetailsPageData = {
        pcp_detail: {} as ProviderDetailsResponse,
        pcp_list: {} as PCPsResponse,
    };
    currentViewMode: ViewMode = ViewMode.MEMBER_CONTEXT;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private configService: ConfigService,
        private formatService: FormattingService,
        private panelService: SidePanelService,
        private memberDataService: MemberDataService,
        private analyticsService: AnalyticsService,
        private profileSearchService: ProfileSearchService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.provider_id = this.route.snapshot.params.id;
        this.config = this.configService.getControlPanelConfig(this.route.snapshot.data['pageName']);
        const memberId = this.route.snapshot.params.member;

        if (!memberId) {
            this.profileSearchService.show(false);
            this.currentViewMode = ViewMode.STANDALONE;
            this.panelService.toggleSidePanel(false);
        } else {
            this.memberDataService.getPCPList(memberId).subscribe((res: ResponseType<APIResponse<PCPsResponse>>) => {
                this.data.pcp_list = Object.assign(
                    {},
                    res.details.results.find((pcp: PCPsResponse) => pcp.provider_id === this.provider_id)
                );

                const eDate = this.formatService.restructureValueBasedOnFormat(this.data.pcp_list.effective_date, { format: 'DATE' });
                const tDate = this.formatService.restructureValueBasedOnFormat(this.data.pcp_list.termination_date, { format: 'DATE' });

                this.data.pcp_list.dates = `${eDate} - ${tDate}`;
                this.modifyConfig();
            });
        }

        this.memberDataService.getPCPDetails(memberId, this.provider_id).subscribe((res: responseTypePC<ProviderDetailsResponse>) => {
            this.data.pcp_detail = Object.assign({}, res.details);
            this.data.pcp_detail.specialties_names = [];
            this.data.pcp_detail.plan_network = [];
            this.data.pcp_detail.business_entities = [this.data.pcp_detail.facility_data];
            this.addModifiedData(this.data.pcp_detail);
            this.modifyConfig();
            this.showPcpDetails = true;
        });
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.ProvidersDetail_viewed }, this.route.snapshot.params.member);
    }

    addModifiedData(data: ProviderDetailsResponse) {
        // Assign Specialties
        if (data.specialties && data.specialties.length > 0) {
            data.specialties_names = data.specialties.map((sp: ProviderSpecialties) => {
                const e_date = this.formatService.restructureValueBasedOnFormat(sp.effective_date, { format: 'DATE' });
                const t_date = this.formatService.restructureValueBasedOnFormat(sp.termination_date, { format: 'DATE' });

                return `${sp.name}                                  ${e_date} - ${t_date}`;
            });
        }
        // Assign Specialities
        const specialtiesConfig = this.config?.attributeConfig?.sections[0]?.attributes?.find(
            (field: ModelAttributeConfig) => field.value === 'specialties'
        );

        if (specialtiesConfig) {
            specialtiesConfig.value = data.specialties_names.length ? data.specialties_names : 'N/A';
        }

        // Assign Hospital Affiliation
        const hospitalAffiliationsConfig = this.config?.attributeConfig?.sections[0]?.attributes?.find(
            (field: ModelAttributeConfig) => field.value === 'hospital_affiliation'
        );

        if (hospitalAffiliationsConfig) {
            hospitalAffiliationsConfig.value = data.hospital_affiliation.length ? data.hospital_affiliation : 'N/A';
        }
    }

    routeBack() {
        this.router.navigate([`/icx/member-360/${this.route.snapshot.params.member}/providers`]);
    }

    formatOptionsData(config: string, data: ProviderDetailsPageData) {
        if (config.indexOf('$') === -1) {
            return config;
        }
        const configObj = { value: config };

        return this.formatService.formatConfigValueAsTemplate(configObj, data).value;
    }

    modifyConfig() {
        this.config.attributeConfig.sections = this.config?.attributeConfig?.sections?.map((section) => {
            if (section.title?.includes('$')) {
                section.title = this.formatOptionsData(section.title, this.data) || '';
            }

            return section;
        });
    }
}
