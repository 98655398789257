import { Router, ActivatedRoute } from '@angular/router';
import { FormattingService } from '@zipari/design-system';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TogglePanelService } from '../../services/toggle-panel.service';
import { FormManagerService } from '../../services/form-manager.service';

@Component({
    selector: 'results',
    templateUrl: './results.component.html',
    styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit, OnChanges {
    constructor(
        private togglePanelService: TogglePanelService,
        private formatService: FormattingService,
        private formManagerService: FormManagerService,
        private route: ActivatedRoute,
        public router: Router
    ) {}

    @Input() resultData: any[];
    @Input() resultCardConfig;
    @Input() options;
    @Input() cardOptions;
    @Input() params;
    @Input() table;
    @Input() grid;
    @Input() pagingConfig?;
    @Input() pageLevelControls?;
    @Input() noDataConfig?;
    @Input() loadingDatagrid: boolean = false;
    @Output() addTableIcons?: EventEmitter<string> = new EventEmitter<string>();
    @Output() setTableData?: EventEmitter<void> = new EventEmitter<void>();
    @Output() updatePagination?: EventEmitter<number> = new EventEmitter<number>();
    @Output() sortingTable?: EventEmitter<string> = new EventEmitter<string>();
    @Output() navigateToDetails?: EventEmitter<any> = new EventEmitter<any>();
    @Output() navigateToSsoDetails?: EventEmitter<any> = new EventEmitter<any>();
    @Output() changeCardData?: EventEmitter<string> = new EventEmitter<string>();

    viewType;
    viewTypes;
    targetDetail;
    buttonConfig;
    headerValue: string;
    sortingElement: string = '';
    pageLevelFilters: FormGroup = new FormGroup({});

    ngOnInit(): void {
        this.targetDetail = `${this.options.targetDetailPageLocation}`;
        this.viewType = this.options.defaultViewType;
        this.setButtonContent(this.viewType);
        this.viewTypes = this.options.viewTypes;
        this.setTableData.emit();
        this.formatOptionsData();
        if (this.pageLevelControls) {
            this.pageLevelFilters = this.formManagerService.populateFormGroup(this.pageLevelControls);
            // handle setting default for member profile contacts
            if (this.pageLevelFilters.controls['policy_dropdown_list']) {
                if (this.pageLevelControls[0].options[0] && this.pageLevelControls[0].options[0].value) {
                    this.pageLevelFilters.controls['policy_dropdown_list'].setValue(this.pageLevelControls[0].options[0].value, {
                        emitEvent: false,
                    });
                }
            }
        }
    }

    ngOnChanges(changes) {
        if (changes && changes.resultData && !changes.resultData.firstChange) {
            this.formatOptionsData();
        }
    }

    setButtonContent(viewType: string): void {
        this.addTableIcons.emit(viewType);
        this.buttonConfig = {
            content: `Switch to ${this.viewType} View`,
            level: 'medium',
        };
    }

    toggleSidePanel(): void {
        this.togglePanelService.toggle();
    }

    toggleViewType(type: string): void {
        this.viewType = { Card: 'Table', Table: 'Card' }[type];
        this.setButtonContent(this.viewType);
    }

    openDetails(target): void {
        this.navigateToDetails.emit(target);
    }

    openSSODetails(target): void {
        this.navigateToSsoDetails.emit(target);
    }

    sortTable(target) {
        this.sortingElement = this.sortingElement === target ? `-${this.sortingElement}` : target;
        this.sortingTable.emit(this.sortingElement);
    }

    setCurrentPage(page) {
        this.updatePagination.emit(page);
    }

    formatOptionsData() {
        if (this.options && this.options.header) {
            if (this.options.header.indexOf('$') !== -1) {
                const value = this.formatService.formatConfigValueAsTemplate({ value: this.options.header }, this.resultData).value;
                if (value) {
                    this.headerValue = this.formatService.restructureValueBasedOnFormat(value, this.options.header);
                }
            } else {
                this.headerValue = this.options.header;
            }
            // eslint-disable-next-line quotes
            if (this.headerValue.charAt(0) === "'") {
                this.options.header = '';
            }
        }
        if (this.cardOptions && this.cardOptions.targetlocation && !this.resultData.length) {
            this.resultData = this.resultData[this.cardOptions.targetlocation];
        }
    }

    updateData(event) {
        this.changeCardData.emit(event);
    }

    routeLink(page: string) {
        switch (page) {
            case 'Coverages':
                this.router.navigate([`/icx/member-360/${this.route.snapshot.params.member}/coverages`]);
                break;

            default:
                this.router.navigate([`/icx/member-360/${this.route.snapshot.params.member}/profile`]);
        }
    }
}
