import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { BaseCVAComponent } from './../../custom-form-element/base-cva.component';

@Component({
    selector: 'form-group-array',
    templateUrl: './form-group-array.component.html',
    styleUrls: ['./form-group-array.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormGroupArrayComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => FormGroupArrayComponent),
            multi: true,
        },
    ],
})
export class FormGroupArrayComponent extends BaseCVAComponent implements OnInit, OnDestroy {
    @Input() config: any;
    formArray: FormArray;

    constructor(private formService: FormControlService) {
        super();
    }

    ngOnInit() {
        this.formArray = new FormArray([]);
    }

    addGroup(values = {}) {
        const formGroup = new FormGroup({});
        this.config.controls.forEach(control => {
            const value = values[control.prop] !== undefined ? values[control.prop] : null;
            this.formService.addControlToFormGroup(formGroup, control, { [control.prop]: value });
        });
        this.formArray.push(formGroup);
    }

    removeGroup(index) {
        this.formArray.removeAt(index);
    }

    writeValue(values: any) {
        if (values && Array.isArray(values)) {
            values.forEach((value, index) => {
                if (this.formArray.get([index])) {
                    this.formArray.at(index).patchValue(value);
                } else {
                    this.addGroup(value);
                }
            });
        } else {
            this.addGroup();
        }
    }

    registerOnChange(fn: any) {
        this.formArray.valueChanges.pipe(debounceTime(0), takeUntil(this.destroy)).subscribe(fn);
    }

    setDisabledState(disabled: boolean) {
        disabled ? this.formArray.disable() : this.formArray.enable();
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.formArray.valid ? null : { custom: true };
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
