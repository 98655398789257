import { Injectable } from '@angular/core';
import { ConfigService } from 'src/app/shared/services';
import { MessageBannerConfig } from '@zipari/design-system';
import { MemberDataService } from './member-data.service';

export interface HomeHostConfig {
    messageBanner: MessageBannerConfig;
    allowedPages: string;
}

@Injectable({
    providedIn: 'root',
})
export class HomeHostService {
    private homeHostConfig: HomeHostConfig;
    constructor(private configService: ConfigService, private memberDataService: MemberDataService) {}

    canAccessRoute(currentPage: string) {
        this.homeHostConfig = this.configService.getControlPanelConfig('home-host');
        const allowedPages = this.homeHostConfig.allowedPages.split(',');
        const isHomeHost = this.memberDataService.getMemberHomehostStatus();
        if (currentPage && isHomeHost && allowedPages.length) {
            return allowedPages.includes(currentPage);
        } else {
            return true;
        }
    }
}
