import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigService } from '../../shared/services';

import { PortalBuilderAPIService } from './portal-builder-api.service';

export interface Tenant {
    display_name?: string;
    id?: number;
    name?: string;
    noreply_email_address?: string;
    logo_url?: string;
}

export interface Configuration {
    app_base_version?: string;
    app_name?: string;
    comments?: string;
    config?: any;
    id?: number;
    tenant_name?: string;
    version?: string;
}

export interface CurrentLocation {
    application?: string;
    market_segment?: string;
    template?: string;
}

export const appConfigMap = {};

@Injectable()
export class PortalBuilderStore {
    // Behavior Subjects
    private _currentLocation: BehaviorSubject<CurrentLocation> = new BehaviorSubject<CurrentLocation>({});
    private _tenants: BehaviorSubject<Tenant[]> = new BehaviorSubject<Tenant[]>([]);
    private _tenant: BehaviorSubject<Tenant> = new BehaviorSubject<Tenant>({});
    private _configuration: BehaviorSubject<Configuration> = new BehaviorSubject<Configuration>(null);
    private _noTenantConfiguration: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

    // Observables
    public get configuration$(): Observable<Configuration> {
        return this._configuration.asObservable();
    }

    public get currentLocation$(): Observable<CurrentLocation> {
        return this._currentLocation.asObservable();
    }

    public get noTenantConfiguration$(): Observable<boolean> {
        return this._noTenantConfiguration.asObservable();
    }

    public get tenants$(): Observable<Tenant[]> {
        return this._tenants.asObservable();
    }

    public get tenant$(): Observable<Tenant> {
        return this._tenant.asObservable();
    }

    // Value Getters
    public get configuration(): Configuration {
        return this._configuration.getValue();
    }

    public get currentLocation(): CurrentLocation {
        return this._currentLocation.getValue();
    }

    public get tenants(): Tenant[] {
        return this._tenants.getValue();
    }

    public get tenant(): Tenant {
        return this._tenant.getValue();
    }

    public get noTenantConfiguration(): boolean {
        return this._noTenantConfiguration.getValue();
    }

    private allConfigs = appConfigMap;

    public getDefaultConfiguration(appName: string): any {
        return this.allConfigs[appName];
    }

    constructor(
        public configService: ConfigService,
        private portalBuilderAPI: PortalBuilderAPIService,
        private route: ActivatedRoute,
        private router: Router
    ) {
        // initialize tenant list
        this.portalBuilderAPI.getTenants().subscribe(response => {
            // filter, sort, & map --> NOTE: these should come from the API
            const tenants = response.results.filter(
                item => !!item.display_name && item.name !== 'internal' && item.name !== 'Ziparitest'
            );
            tenants.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0));
            tenants.map(tenant => (tenant.logo_url = `https://d32ul9oyxvd2n5.cloudfront.net/logos/${tenant.name}.png`));
            // push tenant list
            this._tenants.next(tenants);

            // get tenant from URL queryParams or set Democare as the default tenant
            const qpTenant = this.route.snapshot.queryParams.tenant_name;
            const tenant = this.tenants.find(tenant => (!!qpTenant ? tenant.name === qpTenant : tenant.name === 'democare'));
            // push initial tenant
            this.pushTenant(tenant);
        });

        // push application location reference
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                // DEBT: don't climb trees
                const urlTree = this.router['currentUrlTree']['root']['children']['primary']['segments'];
                this.pushCurrentLocation({
                    application: urlTree[1]['path'],
                    template: urlTree[2] ? urlTree[2]['path'] : '',
                });
            }
        });
    }

    // push application location reference
    pushCurrentLocation(location: CurrentLocation) {
        if (location.application !== this.currentLocation.application) {
            this._currentLocation.next(location);
            this.getPushTenantConfiguration();
        } else {
            this._currentLocation.next(location);
        }
    }

    // push selected tenant
    pushTenant(tenant: Tenant) {
        if (tenant.name !== this.tenant.name) {
            // update queryParams to new tenant
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: {
                    tenant_name: tenant.name,
                },
            });
            this._tenant.next(tenant);
            this.getPushTenantConfiguration();
        } else {
            this._tenant.next(tenant);
        }
    }

    // get default democare config, initialize current app for current tenant & push configuration
    postPushConfiguration() {
        const newConfig = {
            app_name: this.currentLocation.application,
            tenant_name: this.tenant.name,
            variation: 'v3',
            config: {
                global: {
                    title: this.tenant.display_name,
                },
            },
        };

        this.configService.postConfig(newConfig).subscribe((response: Configuration) => {
            this.setTenantConfiguration(response);
        });
    }

    mergeTenantConfiguration(tenantConfig) {
        this.configService.handleOverrideOfDefaultConfig(tenantConfig, this.currentLocation.application).then(success => success);
    }

    setTenantConfiguration(config: Configuration) {
        if (!!config) {
            this.configService.handleOverrideOfDefaultConfig(config.config, this.currentLocation.application).then(success => {
                config.config = success;
                this._configuration.next(config);
            });
        } else {
            this._noTenantConfiguration.next(true);
            this._configuration.next(null);
        }
    }

    // get & push configuration by tenant and application
    getPushTenantConfiguration() {
        this.configService.getAppConfigs(this.tenant.name, this.currentLocation.application).subscribe(response => {
            this.setTenantConfiguration(response.results[0]);
        });
    }

    // update configuration for a given tenant and application
    patchPushConfiguration(newConfig: any): Observable<Configuration> {
        const payload = this.configuration;
        payload.config = newConfig;

        const patch = this.configService.updateConfig(payload);

        return patch;
    }
}
