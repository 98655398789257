import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService, ConfigService } from '../../shared/services';

@Component({
    selector: 'broker',
    templateUrl: './broker.component.html',
    styleUrls: ['./broker.component.scss'],
})
export class BrokerComponent implements OnInit {
    pageConfig;
    member;
    provider;
    providerCallMade;
    sidebarModelAttributeConfig = [
        {
            label: 'Agency',
            value: 'Morgan Planning Group',
        },
        {
            label: 'Phone',
            value: '(403) 403-3493',
            format: 'PHONE',
        },
        {
            label: 'Email',
            value: 'lydia.ortiz@yahoo.com',
            format: 'EMAIL',
        },
        {
            label: 'Agent ID',
            value: '34211',
        },
        {
            label: 'Status',
            value: 'Active',
        },
        {
            label: 'TIN',
            value: '154678',
        },
    ];

    generalInfoConfiguration = [
        {
            label: 'Status',
            value: 'Active',
        },
        {
            label: 'License Number',
            value: 'NB3948459384',
        },
        {
            label: 'License State',
            value: 'NY',
        },
        {
            label: 'Start Date',
            value: 'MM/DD/YY',
        },
        {
            label: 'Expiration Date',
            value: 'MM/DD/YY',
        },
        {
            label: 'Market Segment',
            value: 'Medicare, Small Group',
        },
    ];

    contactInfoConfiguration = [
        {
            label: 'Mobile Phone',
            value: '(403) 403-3493',
            format: 'PHONE',
        },
        {
            label: 'Email',
            value: 'lydia.ortiz@yahoo.com',
            format: 'EMAIL',
        },
        {
            label: 'Work Phone',
            value: '(903) 348-3493',
        },
        {
            label: 'Primary Address',
            value: '792 Glennie Cliff\n' + 'Apt 575\n' + 'New York, NY 10039',
        },
    ];

    constructor(private configService: ConfigService, private route: ActivatedRoute, private api: APIService) {}

    ngOnInit() {
        this.pageConfig = this.configService.getPageConfig(this.route.snapshot.data['pageName']);

        const memberId = this.route.snapshot.queryParams['member_id'];

        this.api.get(`/api/member-360/members/${memberId}/pcp`).subscribe(
            (resp) => {
                const providerId = resp.provider_id;
                // this.provider = resp; // for local
                // this.provider['hmo'] = false; // for local
                // this.providerCallMade = true;
                this.getPCP(providerId);
            },
            (error) => {
                this.providerCallMade = true;
            }
        );
    }

    getPCP(id) {
        this.api.get(`/api/scapi/providers/${id}`).subscribe((resp) => {
            this.provider = resp;
            this.provider['hmo'] = false;
            this.providerCallMade = true;
        });
    }
}
