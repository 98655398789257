import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ResultsModule } from 'src/app/icx/shared/modules/results/results.module';
import { ButtonModule, DatagridModule, PaginatorModule, FormGroupModule } from '@zipari/design-system';
import { PreauthorizationsComponent } from './preauthorizations.component';

@NgModule({
    declarations: [PreauthorizationsComponent],
    imports: [CommonModule, ButtonModule, ResultsModule, FormGroupModule, DatagridModule, PaginatorModule],
})
export class PreauthorizationsModule {}
