import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule } from '@zipari/design-system';
import {
    AddressPipe,
    BuildQueryPipe,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    MaskPipe,
    PhoneNumberPipe,
    PipesModule,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
} from '@zipari/web-utils';

import { SharedModule } from '../../shared.module';
import { FormGroupModule } from './../form-group/form-group.module';
import { PlanCardComponent } from './plan-card/plan-card.component';
import { PlanCompareComponent } from './plan-compare/plan-compare.component';
import { PlanFiltersComponent } from './plan-filters/plan-filters.component';
import { PlansAPIService } from './plans-api.service';

@NgModule({
    declarations: [PlanFiltersComponent, PlanCompareComponent, PlanCardComponent],
    imports: [CommonModule, FormGroupModule, ButtonModule, SharedModule, PipesModule],
    providers: [
        PlansAPIService,
        StringBuilderPipe,
        AddressPipe,
        GetValuePipe,
        EqualityFilterPipe,
        BuildQueryPipe,
        DoesListHaveValuesPipe,
        FullNamePipe,
        PhoneNumberPipe,
        MaskPipe,
        ReadMorePipe,
        SafeNumberPipe,
    ],
    exports: [PlanFiltersComponent, PlanCompareComponent, PlanCardComponent],
})
export class PlansModule {}
