import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

import { ApiListResponse } from '../../models/shared/ApiListResponse.model';

@Component({
    selector: 'zip-paginator',
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
})
export class PaginatorComponent implements OnChanges {
    @Input() data: ApiListResponse<any>;
    @Input() pageSize = 10;
    @Output() goToPage = new EventEmitter<number>();

    currentPage: number;
    totalCount: number;

    totalResultCount: number;
    firstShowing: number;
    lastShowing: number;

    ngOnChanges() {
        if (this.data) {
            this.totalResultCount = this.data.count;
            // total page count
            this.totalCount = Math.ceil(this.data.count / this.pageSize);
            if (this.totalCount > 0) {
                this.currentPage = this.data.next ? this.data.next - 1 : this.data.previous + 1;
                this.firstShowing = this.pageSize * (this.currentPage - 1) + 1;
                this.lastShowing = this.firstShowing + this.data.results.length - 1;
            } else {
                this.currentPage = 0;
                this.firstShowing = 0;
                this.lastShowing = 0;
            }
        }
    }

    onPageButtonClick(page) {
        this.goToPage.emit(page);
    }
}
