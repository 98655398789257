import { configs as tenantConfigs } from 'customConfigs/configuration';

const defaultEnvConfigs = {
    indexRoute: 'member-360',
    useLocalConfig: useLocalConfig,
};

const configs = Object.assign(defaultEnvConfigs, tenantConfigs['member-360']);

export const environment = {
    app: 'member-360',
    configs: configs,
    production: false,
};
