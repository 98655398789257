/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { FormControlService, RadioConfiguration } from '@zipari/design-system';
import { Subscription } from 'rxjs';
import { cloneObject, getValue, stringBuilder } from '@zipari/web-utils';
import { CoverageEffectiveDateService, validEnrollmentTypes } from '../../../services/coverage-effective-date.service';

import { AuthService, FormattingService } from '../../../services/index';

import {
    CoverageEffectiveConfig,
    disableSEPForCoverageTypes,
    effectiveDateDropdownConfig,
    intitialEnrollmentPeriods,
    whichEnrollment,
} from './coverage-effective-date.constants';

@Component({
    selector: 'coverage-effective',
    templateUrl: './coverage-effective-date.component.html',
    styleUrls: ['./coverage-effective-date.component.scss'],
})
export class CoverageEffectiveDateComponent implements OnInit, OnChanges, OnDestroy, DoCheck {
    @Output() enrollmentPeriodChosen = new EventEmitter();
    @Output() effectiveYearFound = new EventEmitter();

    @Input() prefilledValue: string;
    @Input() initialEnrollmentPeriod: string;
    @Input() _config: CoverageEffectiveConfig;
    @Input() form: FormGroup;
    @Input() productType: string;
    @Input() coverageTypeControl: FormControl;

    busy: Subscription;
    userChoiceSub: Subscription;
    coverageEffectiveSub: Subscription;
    productTypeSub: Subscription;
    coverageTypeSub: Subscription;
    coverageType: string;

    showCoverageDateSelector: boolean;

    whichEnrollment: RadioConfiguration = cloneObject(whichEnrollment);

    textTemplates = {
        title: {
            OE: 'You are eligible for coverage starting on ${date}',
            SEP: 'You may be eligible for coverage starting on ${date}',
        },
        subtitle: {
            OE: '',
            SEP: 'You will be asked to submit optional documents to verify your QLE',
        },
    };

    formattedText = {
        title: '',
        subtitle: '',
    };

    effectiveDateDropdownConfig = effectiveDateDropdownConfig;
    storedEffectiveDate = [];

    constructor(
        public cdr: ChangeDetectorRef,
        public formattingService: FormattingService,
        public formControlService: FormControlService,
        public coverageEffectiveDateService: CoverageEffectiveDateService,
        public http: HttpClient,
        public authService: AuthService
    ) {}

    ngOnInit() {
        this.coverageType = this.productType;
        this.formControlService.addControlToFormGroup(this.form, this.whichEnrollment);
        this.handleFormCreated();
        this.onChangeCoverageType();
    }

    ngOnChanges(changes) {
        if ('_config' in changes && this.config) {
            if (this.config.disableSEP) {
                this.whichEnrollment.options = this.whichEnrollment.options.filter((option) => option.value !== validEnrollmentTypes.SEP);
            }
        }

        if ('prefilledValue' in changes && changes.prefilledValue.currentValue) {
            this.storedEffectiveDate.push(changes.prefilledValue.currentValue);
        }

        if ('coverageTypeControl' in changes && changes.coverageTypeControl.currentValue) {
            this.onChangeCoverageType();
        }

        if ('initialEnrollmentPeriod' in changes && changes.initialEnrollmentPeriod.currentValue) {
            this.prefillEnrollmentPeriod(this.initialEnrollmentPeriod);
        }
    }

    ngDoCheck() {
        if (
            this.storedEffectiveDate.length > 0 &&
            this.storedEffectiveDate[0] &&
            this.form &&
            this.form.get(this.effectiveDateDropdownConfig.prop)
        ) {
            while (this.storedEffectiveDate.length > 0) {
                const effectiveDate = this.storedEffectiveDate.pop();
                this.coverageEffectiveDateService.emitDate(effectiveDate);
                this.coverageEffectiveDateService.storedDate = effectiveDate;
                this.effectiveYearFound.emit(effectiveDate);
                this.form.get(this.effectiveDateDropdownConfig.prop).patchValue(effectiveDate);
            }

            this.cdr.detectChanges();
        }

        if (this.config.isDisabled !== this.effectiveDateDropdownConfig.isDisabled) {
            this.effectiveDateDropdownConfig.isDisabled = this.config.isDisabled;
        }
    }

    ngOnDestroy() {
        const subs: Subscription[] = [this.coverageEffectiveSub, this.userChoiceSub, this.busy, this.productTypeSub, this.coverageTypeSub];

        subs.forEach((sub) => {
            if (sub) {
                sub.unsubscribe();
            }
        });
    }

    prefillEnrollmentPeriod(enrollmentPeriod) {
        this.form.get(this.whichEnrollment.prop).patchValue(enrollmentPeriod);
        this.handleNewEnrollmentPeriod(enrollmentPeriod);
    }

    handleFormCreated() {
        if (this.initialEnrollmentPeriod) {
            this.prefillEnrollmentPeriod(this.initialEnrollmentPeriod);
        }

        if (this.whichEnrollment.options.length === 1) {
            this.prefillEnrollmentPeriod(this.whichEnrollment.options[0].value);
        }

        this.userChoiceSub = this.form
            .get(this.whichEnrollment.prop)
            .valueChanges.subscribe((enrollmentPeriodChosen: validEnrollmentTypes) =>
                this.handleNewEnrollmentPeriod(enrollmentPeriodChosen)
            );
    }

    handleNewEnrollmentPeriod(enrollmentPeriodChosen: validEnrollmentTypes) {
        this.enrollmentPeriodChosen.emit(enrollmentPeriodChosen);

        this.busy = this.coverageEffectiveDateService.retrieveCoverageEffectiveDateTest(enrollmentPeriodChosen, this.productType).subscribe(
            (result) => {
                const effectiveDates = getValue(result, 'results.0.effective_dates');

                if (effectiveDates.length > 0) {
                    if (this.config.multipleEffectiveDates) {
                        this.handleEffectiveDateOptions(effectiveDates);
                    } else {
                        this.handleEffectiveDatePrefillingText(effectiveDates[0]);
                    }
                } else {
                    this.handleErrorRetrievingEffectiveDate();
                }
            },
            (err) => {
                this.handleErrorRetrievingEffectiveDate();
            }
        );
    }

    handleEffectiveDateOptions(dates: string[]) {
        this.formControlService.addControlToFormGroup(this.form, this.effectiveDateDropdownConfig);

        this.effectiveDateDropdownConfig.options = dates.map((dateString) => ({
            label: this.formattingService.restructureValueBasedOnFormat(dateString, { format: 'DATE' }),
            value: dateString,
        }));

        this.effectiveDateDropdownConfig.placeholder = 'Select One';

        this.coverageEffectiveSub = this.form.get(this.effectiveDateDropdownConfig.prop).valueChanges.subscribe((val) => {
            if (val) {
                this.effectiveYearFound.emit(val);
            }
        });

        this.showCoverageDateSelector = true;

        // options change potentially in the middle of lifecycle hooks
        // this fixes ExpressionChangedAfterItHasBeenCheckedError
        if (this.cdr) {
            this.cdr.detectChanges();
        }
    }

    handleEffectiveDatePrefillingText(date: string) {
        this.effectiveYearFound.emit(date);
        this.formatTextBasedOnChoice(date);
    }

    handleErrorRetrievingEffectiveDate() {
        this.formattedText = {
            title: '',
            subtitle: '',
        };
        this.effectiveYearFound.emit(null);
    }

    formatTextBasedOnChoice(date) {
        this.formattedText.title = stringBuilder(this.textTemplates.title[this.enrollmentChosen], {
            date: this.formattingService.restructureValueBasedOnFormat(date, { format: 'DATE' }),
        });

        this.formattedText.subtitle = stringBuilder(this.textTemplates.subtitle[this.enrollmentChosen], {
            date: this.formattingService.restructureValueBasedOnFormat(date, { format: 'DATE' }),
        });
    }

    onChangeCoverageType() {
        // FormControl to subscribe to, either coverage_type on the current form
        // or a FormControl passed in to coverageTypeControl
        const cTypeControl = this.form.get('coverage_type') || this.coverageTypeControl;

        if (cTypeControl) {
            this.coverageTypeSub = cTypeControl.valueChanges.subscribe((val) => {
                if (val) {
                    const productInitialEnrollmentPeriod = intitialEnrollmentPeriods[val];

                    // Prefill the enrollment period for this coverage type
                    if (productInitialEnrollmentPeriod) this.prefillEnrollmentPeriod(productInitialEnrollmentPeriod);
                    this.coverageType = val;
                }
            });
        }
    }

    get shouldDisableSEP() {
        const disabledInConfig = getValue(this.config, 'disableSEP');
        const disableBasedOnCoverageType = disableSEPForCoverageTypes[this.coverageType];

        return disabledInConfig || disableBasedOnCoverageType;
    }

    public get enrollmentChosen() {
        return this.form.get(this.whichEnrollment.prop).value;
    }

    public get config() {
        return this._config || {};
    }
}
