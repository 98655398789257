import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { isInEnum } from '@zipari/web-utils';
import { ConfigService } from './../../../shared/services';
import { NavigationConfig, NavItem, Toolbar } from './navigation.configuration';

export enum validNavigationSegments {
    individual = 'individual',
    medicare = 'medicare',
    'small-group' = 'small-group',
    'member-360' = 'member-360',
    'provider-360' = 'provider-360',
}

@Injectable({
    providedIn: 'root',
})
export class NavigationService {
    public buttonClicked = new Subject();
    private toolbarConfig: Toolbar;
    public config: NavigationConfig;
    private navigationConfig: any;

    public mobileMenuOpen = false;

    public activeRoute: NavItem;
    public activeRouteIdx = 0;

    private isNavHidden = false;

    constructor(private configService: ConfigService, private router: Router, private route: ActivatedRoute) {
        this.initConfig();
    }

    public getNavHidden() {
        return this.isNavHidden;
    }

    public setNavHidden(value: boolean) {
        this.isNavHidden = value;
    }

    initConfig() {
        this.toolbarConfig = this.getToolbarConfig();

        if (!!this.toolbarConfig) {
            const routeSegment: string = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;

            if (isInEnum(validNavigationSegments, routeSegment)) {
                this.toolbarConfig.routes.forEach((route, index) => {
                    if (route.prop === routeSegment) {
                        this.selectRoute(route, index);
                    }
                });
            } else {
                const globalConfig: any = this.configService.getPageConfig('global') || {};
                this.config = globalConfig.navigation;
            }
        } else {
            this.setConfig();
        }
    }

    setConfig() {
        let navConfig : NavigationConfig = this.configService.getControlPanelConfig('navigation');
        navConfig.links = this.modifyConfig(navConfig.links);
        this.config = navConfig;
        if (!!this.toolbarConfig) {
            this.config.toolbar = this.toolbarConfig;
        }
    }

    selectNavLink(event, link) {
        event.stopPropagation();

        if (!!link.route) {
            this.mobileMenuOpen = false;

            this.router.navigate([link.route]);
        }

        if (!!link.items) {
            if (!link.open) {
                this.config.links.forEach(_link => {
                    _link.open = false;
                });
            }
            link.open = !link.open;
        }
    }

    navigateSelected(route, index) {
        this.router.navigate([route.route]);
        this.selectRoute(route, index);
    }

    selectRoute(route, index) {
        this.activeRoute = route;
        this.activeRouteIdx = index;

        this.configService.activeRoute = route.prop;
        this.setConfig();
    }

    isActiveRoute(route) {
        return this.router.isActive(route, true);
    }

    toggleMobileMenu() {
        this.mobileMenuOpen = !this.mobileMenuOpen;
    }

    getToolbarConfig() {
        const mainSegment: string = this.router.parseUrl(this.router.url).root.children.primary.segments[0].path;
        let initialToolbarConfig: Toolbar = this.configService.getControlPanelConfig('toolbar');
        initialToolbarConfig.links = this.modifyConfig(initialToolbarConfig.links);
        initialToolbarConfig.icons = this.modifyConfig(initialToolbarConfig.icons);
        initialToolbarConfig.routes = Object.keys(initialToolbarConfig.routes).map(route => {
            return initialToolbarConfig.routes[route]
        });

        if (mainSegment === 'icx') {
            const appSegment = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;

            return initialToolbarConfig;
        } else {
            return this.configService.configs['global']['toolbar'];
        }
    }

    modifyConfig(config) {
        const linksConfig = Object.keys(config).map((link: string) => {
            if (config[link].hasSubItems) {
                const items = Object.keys(config[link].items).map(item => {
                    return config[link].items[item];
                });
                config[link].items = items.sort((a,b) => a.position - b.position );
                return config[link];
            } else if (!config[link].hasSubItems) {
                return config[link]
            }
        });

        return linksConfig.sort((a,b) => a.position - b.position );
    }
}
