/**
 * Returns a combination of codes and description
 * @param dataObj object with code and description fields
 * @param param1 first api field to get the value in form of a string
 * @param param2 second api field to get the value in form of a string
 * @returns a string with all codes and descriptions combined 
 */
 export function getSingleCodeDescription(dataObj, param1, param2) {
    let code_description = '';
       if (dataObj[param1]) { code_description += `${dataObj[param1]} - `; }
       if (dataObj[param2]) { code_description += `${dataObj[param2]}`; }
    return code_description;
}

/**
 * Returns a combination of codes and description with new line between each
 * @param dataList list of objects with code and description fields
 * @param param1 first api field to get the value in form of a string
 * @param param2 second api field to get the value in form of a string
 * @returns a string with all codes and descriptions combined 
 */
 export function getAllCodeDescription(dataList, param1, param2) {
     let code_descriptions = '';
     dataList.forEach(data => {
        if (data[param1]) { code_descriptions += `${data[param1]} - `; }
        if (data[param2]) { code_descriptions += `${data[param2]}`; }
        if (code_descriptions) { code_descriptions+= '\n'; }
     });
     return code_descriptions;
}