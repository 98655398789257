import { Injectable } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

@Injectable()
export class SessionTimeoutInterceptor implements HttpInterceptor {
    constructor() {}

    globalErrorCodes = [
        400, 404, 405, 406, 407, 408, 409, 410, 411, 412, 413, 414, 415, 416, 417, 419, 420, 422, 500, 501, 502, 503, 504, 505, 550,
    ];

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).do(
            (event) => {},
            (err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status === 403 || err.status === 401) {
                        if (err.error && err.error.error === 'user_unauthenticated') {
                            window.location.assign('/logout');
                        }
                        if (err.error && err.error.errors) {
                            err.error.errors.forEach((error) => {
                                if (error.user_error && error.user_error === 'user_unauthenticated') {
                                    window.location.assign('/logout');
                                }
                            });
                        }
                    } else if (this.globalErrorCodes.indexOf(err.status)) {
                        window.location.assign('/global-error');
                    }
                }
            }
        );
    }
}

export const SESSION_TIMEOUT_PROVIDER: any = {
    provide: HTTP_INTERCEPTORS,
    useClass: SessionTimeoutInterceptor,
    multi: true,
};
