import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableModule, TabsModule } from '@zipari/design-system';
import { ModelAttributeModule } from '@zipari/design-system';

import { ResultsModule } from '../../../shared/modules/results/results.module';
import { PoliciesComponent } from './policies.component';

@NgModule({
    declarations: [PoliciesComponent],
    imports: [CommonModule, TabsModule, ModelAttributeModule, TableModule, ResultsModule],
})
export class PoliciesModule {}
