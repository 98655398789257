/* eslint-disable @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { controlTypes, formControlDirections, FormControlService } from '@zipari/design-system';

import { isInEnum } from '@zipari/web-utils';
import { customFormElements } from '../custom-form-element/custom-form-element.constants';

@Component({
    selector: 'form-group',
    templateUrl: './form-group.component.html',
    styleUrls: ['./form-group.component.scss'],
})
export class FormGroupComponent implements OnInit {
    @Output() formCreated = new EventEmitter<any>();
    @Output() inputChanged = new EventEmitter<any>();
    @Output() linkClicked = new EventEmitter<any>();
    @Output() iconClicked = new EventEmitter<any>();
    @Output() selected = new EventEmitter<any>();

    @Input() context: any = {};
    @Input() configs: any;
    @Input() form = new FormGroup({});

    @Input('direction')
    public set direction(direction) {
        if (isInEnum(formControlDirections, direction)) {
            this._direction = direction;
        }
    }

    public get direction() {
        return this._direction;
    }

    _direction;

    constructor(public formControlService: FormControlService) {}

    ngOnInit() {
        if (!this.configs) {
            throw new Error('You must provide a config');
        }

        this.configs && this.configs.forEach(controlConfig => {
            if (isInEnum(controlTypes, controlConfig.type)) {
                // The "onlyIf" config here is used by at least shopping for a dynamic form
                if (!controlConfig.onlyIf) {
                    this.formControlService.addControlToFormGroup(this.form, controlConfig, this.context);
                }

                controlConfig.customFormElement = false;
            } else {
                if (controlConfig.type === 'whoseCovered') {
                    this.formControlService.addFormGroupToFormGroup(this.form, controlConfig, this.context);
                }

                if (controlConfig.type === 'coverageEffective') {
                    this.formControlService.addControlToFormGroup(this.form, controlConfig, this.context);
                }

                controlConfig.customFormElement = true;
            }
        });

        this.formCreated.emit();
    }

    handleFormCreated(event, config) {
        if (config.type === customFormElements.whoseCovered && this.context[config.prop]) {
            this.form.get(config.prop).patchValue(this.context[config.prop]);

            const subscriber = this.form.get('whoseCovered.subscriber');
            subscriber.patchValue(this.context.subscriber);

            const spouse = this.form.get('whoseCovered.spouse');
            if (spouse) {
                spouse.patchValue(this.context.spouse);
            }

            const deps = this.form.get('whoseCovered.dependents');
            if (deps) {
                this.context.dependents.forEach((depControl, ind) => {
                    deps[ind].patchValue(this.context.dependents[ind]);
                });
            }

            this.form.get(config.prop).patchValue(this.context[config.prop]);
        }

        this.formCreated.emit(event);
    }
}
