export class NotificationModel {
    id?: string | number;
    start_date: any;
    notification_subtype: string;
    title: string;
    content: string;
    is_read: boolean;
    open?: boolean;
}

// todo if we set finite subtypes then we can do a mapping to show icons
export const notificationSubtypeToIconMapping = {
    general: 'person',
    payment: 'payments',
    enrollment: 'feedback',
    document: 'document_upload',
};

export const notificationsAPI = 'api/notifications/?notification_type=web_notification';

export const NOTIFICATIONS: NotificationModel[] = [
    {
        start_date: new Date(2019, 6, 11),
        notification_subtype: 'hra',
        title: 'Complete Your Health Risk Assessment (HRA) Survey',
        content:
            'Take a moment to complete your Health Risk Assessment Survey so we can better serve your needs. <a class="t-data t-bold t-link" href="/">Click here to get started!</a>',
        open: false,
        is_read: false,
    },
    {
        start_date: new Date(2019, 6, 9),
        notification_subtype: 'wellness',
        title: 'It’s Time to Kick the Habit!',
        content:
            ' Celebrate success right, the only way, apple. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. Let me be clear, you have to make it through the jungle to make it to paradise, that’s the key, <a class="t-data t-bold t-link" href="/">Lion!</a>',
        open: false,
        is_read: false,
    },
    {
        start_date: new Date(2019, 6, 1),
        notification_subtype: 'hra',
        title: 'Complete Your Health Risk Assessment (HRA) Survey',
        content:
            'Take a moment to complete your Health Risk Assessment Survey so we can better serve your needs. <a class="t-data t-bold t-link" href="/">Click here to get started!</a>',
        open: false,
        is_read: false,
    },
    {
        start_date: new Date(2019, 6, 1),
        notification_subtype: 'wellness',
        title: 'It’s Time to Kick the Habit!',
        content:
            ' Celebrate success right, the only way, apple. A major key, never panic. Don’t panic, when it gets crazy and rough, don’t panic, stay calm. Let me be clear, you have to make it through the jungle to make it to paradise, that’s the key, <a class="t-data t-bold t-link" href="/">Lion!</a>',
        open: false,
        is_read: false,
    },
    {
        start_date: new Date(2019, 5, 3),
        notification_subtype: 'costs',
        title: 'Formatting for read notification that hasn’t been dismissed.',
        content:
            'Surround yourself with angels, positive energy, beautiful people, beautiful souls, clean heart, angel. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth.',
        open: false,
        is_read: true,
    },
    {
        start_date: new Date(2019, 4, 11),
        notification_subtype: 'benefits',
        title: 'Formatting for read notification that hasn’t been dismissed.',
        content:
            'Stop with all that pork. Lay off the swine. This and that and the third. It was the beauty that caught me and held my soul hostage. Things change. Nothing ever stays the same, yo. <a class="t-data t-bold t-link" href="/">Diversify!</a>',
        open: false,
        is_read: true,
    },
    {
        start_date: new Date(2019, 3, 17),
        notification_subtype: 'costs',
        title: 'Formatting for read notification that hasn’t been dismissed.',
        content:
            'Surround yourself with angels, positive energy, beautiful people, beautiful souls, clean heart, angel. I’m giving you cloth talk, cloth. Special cloth alert, cut from a special cloth.',
        open: false,
        is_read: true,
    },
    {
        start_date: new Date(2019, 1, 7),
        notification_subtype: 'benefits',
        title: 'Formatting for read notification that hasn’t been dismissed.',
        content:
            'Stop with all that pork. Lay off the swine. This and that and the third. It was the beauty that caught me and held my soul hostage. Things change. Nothing ever stays the same, yo. <a class="t-data t-bold t-link" href="/">Diversify!</a>',
        open: false,
        is_read: true,
    },
];
