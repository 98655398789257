import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';

export interface PlanFilter {
    prop: string;
    type: string;
    label: string;
    default?: any;
    options?: any[];
}

@Component({
    selector: 'plan-filters',
    templateUrl: './plan-filters.component.html',
    styleUrls: ['./plan-filters.component.scss'],
})
export class PlanFiltersComponent implements OnChanges {
    @Input() filters: PlanFilter[];
    @Output() filtersOut = new EventEmitter();
    @Output() closeFilters = new EventEmitter();
    filterForm: FormGroup;

    constructor(private formService: FormControlService) {}

    ngOnChanges() {
        if (this.filters) {
            this.filterForm = new FormGroup({});
            this.filters.forEach(filter => {
                this.formService.addControlToFormGroup(this.filterForm, filter);
            });
        }
    }

    applyFilters() {
        let filterFormVal = { ...this.filterForm.value };

        Object.keys(filterFormVal).forEach(key => {
            if (filterFormVal[key] === null) {
                delete filterFormVal[key];
            }

            // some values are objects bc they require multilple query params
            if (typeof filterFormVal[key] === 'object') {
                filterFormVal = { ...filterFormVal, ...filterFormVal[key] };
                delete filterFormVal[key];
            }
        });

        this.filtersOut.emit(filterFormVal);
    }

    clearFilters() {
        const defaultValues = this.filters.map(filter => ({ [filter.prop]: filter.default })).reduce((_, filter) => ({ ...filter }), {});
        this.filterForm.reset(defaultValues);

        this.applyFilters();
    }

    close() {
        this.closeFilters.emit();
    }
}
