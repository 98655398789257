import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    Inject,
    Input,
    OnInit,
    Output,
    Renderer2,
    TemplateRef,
    ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { getValue } from '@zipari/web-utils';
import { fade, slideRight } from '../../animations';
import { ZipTable2Component } from '../../modules/table/components';
import { ConfigService } from '../../services';
import { ZipEndpointService } from '../../services/zip-endpoint.service';

import { listComponentViews } from './list.constants';
import { LIST_SERVICE, ListService, ListServiceInterface } from './list.service';

@Component({
    selector: 'list',
    templateUrl: './list.component.html',
    animations: [fade, slideRight],
})
export class ListComponent implements OnInit, AfterViewInit {
    @Input() detailModal: TemplateRef<any>;
    @Input() view: listComponentViews = listComponentViews.standalone;
    @Input() pageName: string;

    @Output() cellClicked = new EventEmitter();
    @ViewChild(ZipTable2Component) table: ZipTable2Component;

    pageConfigs;
    showAddRowModal: boolean;
    showRowDetailModal: boolean;
    activeDetailRow: any;
    listComponentViews = listComponentViews;

    addSuccessResponse;
    addErrorResponse;

    @HostListener('window:resize', [])
    onResize() {
        // this is used to make sure the allowed number of columns gets reset
        // any other thoughts on how to do this without do a change detection would be appreciated
        // tslint:disable-next-line: no-unused-expression
        this.setTableBodyHeight();
    }

    constructor(
        public zipEndpointService: ZipEndpointService,
        private renderer: Renderer2,
        public genericListService: ListService,
        @Inject(LIST_SERVICE) private listService: ListServiceInterface,
        private configService: ConfigService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    ngOnInit() {
        this.pageName = this.pageName ? this.pageName : this.route.snapshot.data['pageName'];
        this.pageConfigs = this.configService.getPageConfig(this.pageName);

        this.defaultTableOptions();
    }

    defaultTableOptions() {
        // default spinner to all tables that are in list component
        if (!this.pageConfigs.table.hasOwnProperty('showSpinner')) {
            this.pageConfigs.table.showSpinner = true;
        }
    }

    tableButtonClicked(event) {
        event.stopPropagation();
        const relevantButtonClickConfig = getValue(this.pageConfigs, `buttonClick.${event.target.innerText}`);

        if (relevantButtonClickConfig) {
            if (relevantButtonClickConfig.route) {
                this.router.navigate([relevantButtonClickConfig.route]);
            }
        } else {
            this.showAddRowModal = true;
        }
    }

    onCancelClick(): void {
        this.cancelAddRow();
    }

    cancelAddRow() {
        this.addSuccessResponse = null;
        this.addErrorResponse = null;
        this.showAddRowModal = false;
        this.showRowDetailModal = false;
    }

    onViewProfile(id = this.activeDetailRow.id) {
        this.showRowDetailModal = false;
        this.router.navigate([id], { relativeTo: this.route });
    }

    onSaveNewUserClick(formValue: any): void {
        this.listService.addToList(this.pageConfigs.endpoint, formValue).subscribe(
            response => {
                this.addSuccessResponse = response;
                // Refresh table to get newly added row
                this.table.refresh(true);
            },
            response => {
                this.addErrorResponse = response;
            }
        );
    }

    cellClick(event) {
        this.cellClicked.next(event);
        this.activeDetailRow = event.row;

        // store the detail in a service
        if (this.pageConfigs.detailPageName) {
            this.zipEndpointService.setDetail(event.row, this.pageConfigs.detailPageName);
        }

        if (this.pageConfigs.showDetailModal) {
            this.showRowDetailModal = true;
        } else if (this.pageConfigs.customListAction) {
            // isolated custom functionality for when a cell is clicked on table in list component
            switch (this.pageConfigs.customListAction.type) {
                case 'checkApplicationForSubmittedWorkflow':
                    const result = this.genericListService.handleCheckingApplicationForUnfinishedWorkflow(
                        this.configService,
                        this.router,
                        this.route,
                        event,
                        this.pageConfigs.customListAction.statuses
                    );

                    if (result) {
                        result.then();

                        return;
                    }
                    break;
            }

            this.defaultRoute();
        } else {
            this.defaultRoute();
        }
    }

    defaultRoute() {
        // usually the key we will use is just `id` however there are choice times when a unique identifier
        // needs to be dynamic
        const uniqueIdentifierKey = this.pageConfigs.uniqueIdentifier || 'id';

        // once we know what key to look for... we need to get the unique identifier
        const uniqueIdentifier = getValue(this.activeDetailRow, uniqueIdentifierKey);

        // navigate to this specific detail page
        this.router.navigate([uniqueIdentifier], { relativeTo: this.route });
    }

    onClosed = () => {
        this.showAddRowModal = false;
    };

    ngAfterViewInit() {
        this.setTableBodyHeight();
    }

    setTableBodyHeight() {
        if (this.table) {
            const bodyTop = this.table.tableBody.nativeElement.getBoundingClientRect().top;
            const windowHeight = window.innerHeight;
            const paddingBottom = 80;

            this.renderer.setStyle(this.table.tableBody.nativeElement, 'height', `${windowHeight - bodyTop - paddingBottom}px`);
        }
    }
}
