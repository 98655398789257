import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { cloneObject, getValue, isInEnum } from '@zipari/web-utils';

import { Observable } from 'rxjs/Observable';

import { Configuration } from '../../control-panel/portal-builder/portal-builder-store.service';

import { ApiListResponse } from '../models/shared/ApiListResponse.model';

import { RolePermissions } from '../models/shared/RolePermissions.model';

export class ConfigApiResponse {
    ANGULAR_PARAMS: any;
    APP: any;
    APP_NAME: string;
    CSRF_TOKEN: string;
    SESSION_COOKIE_AGE: number;
    TENANT_NAME: string;
}

export enum nonMarketSegmentConfigs {
    messages = 'messages',
    notifications = 'notifications',
    profile = 'profile',
    global = 'global',
    register = 'register',
    forgot_password = 'forgot-password',
    reset_password = 'reset-password',
    'session-expired' = 'session-expired',
}

export enum validBrokerPortalMarketSegments {
    'large-group' = 'large-group',
    'small-group' = 'small-group',
    individual = 'individual',
    medicare = 'medicare',
}

export enum validShoppingMarketSegments {
    individual = 'individual',
    medicare = 'medicare',
    'small-group' = 'small-group',
}

@Injectable()
export class ConfigService {
    public initialConfigResponse;
    private readonly configEndpoint = 'api/admin/frontend/';
    public appRoute: string;
    public app: string;
    public configs: any;
    public permissions: RolePermissions = {};
    public activeRoute;
    newMarketSegment;
    public activePageName;
    public getMarketSegment(route: ActivatedRouteSnapshot) {
        return getValue(route, 'root.children.0.children.0.children.0.data.marketSegment');
    }

    constructor(private http: HttpClient) {}

    public getConfig(): Observable<ConfigApiResponse> {
        return this.http.get<ConfigApiResponse>('config/');
    }

    /*
        CONFIG here is a global variable provided by django-frontend
        This config comes from config-services for a given app and tenant
    */
    public getPreloadedConfig(): ConfigApiResponse {
        return CONFIG;
    }

    // Get config object as promise needed for Angular's APP_INITIALIZER
    public getPreloadedConfigPromise(): Promise<ConfigApiResponse> {
        return new Promise((resolve, reject) => {
            resolve(this.getPreloadedConfig());
        });
    }

    public initializeAPIConfig(appName: string, { makeAPICall = false }): Promise<ConfigApiResponse> {
        const configPromise: Promise<ConfigApiResponse> = !makeAPICall ? this.getPreloadedConfigPromise() : this.getConfig().toPromise();

        return configPromise.then((configResponse: ConfigApiResponse) => {
            this.initialConfigResponse = configResponse.APP;

            this.app = configResponse.APP_NAME;
            this.appRoute = this.determineAppRoute(this.app);
            this.configs = this.initialConfigResponse;
            this.permissions = this.configs['role-permissions'];

            return this.initialConfigResponse;
            // })
        });
    }
    private getConfigJSON(): any {
        try {
            return require('../../icx/icx.config.json');
        } catch {
            return {};
        }
    }

    // Gets config from dev, in order to replace the theme config
    public initializeLocalConfig(): Promise<ConfigApiResponse> {
        const configPromise: Promise<ConfigApiResponse> = this.getConfig().toPromise();

        return configPromise.then((configResponse: ConfigApiResponse) => this.setConfig(configResponse));
    }

    private setConfig(data: ConfigApiResponse): Promise<any> {
        this.initialConfigResponse = data;
        this.app = data.APP_NAME;
        this.appRoute = this.determineAppRoute(this.app);

        const configPromise = this.handleOverrideOfDefaultConfig(data.APP, this.app);

        configPromise.then((config) => {
            this.configs = config;
            this.permissions = this.configs['role-permissions'];
        });

        return configPromise;
    }

    // only used for local, mainly to override theme config
    public handleOverrideOfDefaultConfig(overrideConfig: any, appName: string) {
        const configJson = this.getConfigJSON();
        const finalAppConfig = cloneObject(configJson);
        // only add the theme from the override config
        finalAppConfig['theme'] = overrideConfig['theme'];

        return Promise.resolve(finalAppConfig);
    }

    determineAppRoute(app) {
        switch (app) {
            case 'json-manager':
                return 'configuration-manager';
            case 'docs':
                return 'styleguide';
            default:
                return app;
        }
    }

    public getPageConfig<T>(page: string): T {
        if (isInEnum(nonMarketSegmentConfigs, page) || !this.activeRoute) {
            return this.configs && cloneObject(this.configs[page]);
        } else if (this.activeRoute) {
            return this.configs && cloneObject(this.configs[this.activeRoute][page]);
        } else {
            return <T>{};
        }
    }

    // Since Control Panel only updates configs that live on the root level
    public getControlPanelConfig<T>(page: string): T {
        return this.configs && cloneObject(this.configs[page]);
    }

    checkIfMarketSegmentIsProvidedInConfig(marketSegment) {
        let inEnum;
        let keys;
        const relevantConfig = this.configs[marketSegment];
        switch (this.app) {
            case 'zshop':
                inEnum = isInEnum(validBrokerPortalMarketSegments, marketSegment);
                keys = Object.keys(validBrokerPortalMarketSegments);

                if (relevantConfig && inEnum) {
                    return true;
                } else if (!relevantConfig && inEnum) {
                    for (let i = 0; i < keys.length; i++) {
                        if (!!this.configs[keys[i]]) {
                            this.newMarketSegment = keys[i];
                        }
                    }

                    return false;
                } else {
                    return true;
                }
        }
    }

    validateMarketSegment(route: ActivatedRouteSnapshot) {
        const marketSegment = this.getMarketSegment(route);

        if (marketSegment) {
            const valid = this.marketSegmentIsValidInApp(route) && this.checkIfMarketSegmentIsProvidedInConfig(marketSegment);

            if (!valid && !!this.newMarketSegment) {
                return false;
            }
        }

        return true;
    }

    marketSegmentIsValidInApp(route: ActivatedRouteSnapshot) {
        switch (this.app) {
            case 'zshop':
                return isInEnum(validBrokerPortalMarketSegments, this.getMarketSegment(route));
            case 'broker-portal':
                return isInEnum(validBrokerPortalMarketSegments, this.getMarketSegment(route));
        }
    }

    public getAppConfigs(tenant: string, application: string): Observable<ApiListResponse<Configuration>> {
        const params = new HttpParams().set('app_name', application).set('tenant_name', tenant);

        return this.http.get<ApiListResponse<Configuration>>(this.configEndpoint, { params: params });
    }

    public updateConfig(configuration: Configuration): Observable<Configuration> {
        delete configuration['version_number'];

        return this.http.put<Configuration>(`${this.configEndpoint}/${configuration.id}/`, configuration);
    }

    public patchConfig(configuration: Configuration): Observable<Configuration> {
        delete configuration['version_number'];

        return this.http.patch<Configuration>(`${this.configEndpoint}${configuration.id}/`, configuration);
    }

    public postConfig(configuration: Configuration): Observable<Configuration> {
        return this.http.post<Configuration>(this.configEndpoint, configuration);
    }

    public setActivePageName(pageName: string) {
        this.activePageName = pageName;
    }
}
