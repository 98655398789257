import { Component, OnInit, AfterViewInit } from '@angular/core';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { ConfigService, AuthService } from '../../../../shared/services';
import { ProfileSearchService } from '../../../shared/services/profile-search.service';

export interface HomeConfig {
    src: string;
    illustration: string;
    illustrationTitle: string;
    illustrationText: string;
    searchButtonText: string;
}

@Component({
    selector: 'home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, AfterViewInit {
    pageConfig: HomeConfig;

    constructor(
        private configService: ConfigService,
        private profileSearchService: ProfileSearchService,
        private analyticsService: AnalyticsService,
        private authService: AuthService,
        private route: ActivatedRoute,
        private panelService: SidePanelService
    ) {
        this.panelService.toggleSidePanel(false);
    }

    ngOnInit(): void {
        this.pageConfig = this.configService.getPageConfig('home-page');
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Homepage_viewed }, this.route.snapshot.params.member);
    }

    ngAfterViewInit() {
        if (!this.authService.loggedInUser) return;
        setTimeout(() => {
            this.openModal();
        });
    }

    openModal() {
        this.profileSearchService.show(true);
    }
}
