import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';

import { deepCompare, getValue } from '@zipari/web-utils';
import { accordion, fade } from '../../../animations';

import { ZipTable2 } from '../models/zip-table.model';
import { ZipTable2Service } from '../services/zip-table2.service';

import { TableFilterComponent } from './filters/table-filter.component';
import { SelectionTypes } from './zip-table2.constants';

@Component({
    selector: 'zip-table',
    templateUrl: './zip-table2.component.html',
    providers: [ZipTable2Service],
    animations: [accordion, fade],
})
export class ZipTable2Component implements OnInit, OnChanges {
    deepCompareFn: Function;

    showMobileActionMenu: boolean;

    /** Reference to the table container */
    @ViewChild(TableFilterComponent) tableFilterComponent: TableFilterComponent;
    @ViewChild('tableWrapper') tableWrapper;
    @ViewChild('tableBody') tableBody;

    /** If we dont have an endpoint this data will be used to provide data to this component */
    @Input() data: any;

    /** Raw configuration options... (will get defaults if certain properties were not provided) */
    @Input() options: ZipTable2;

    @Input() fullScreen: boolean;

    /** Emitter so that outside components know that pagination has occurred */
    @Output() pagination = new EventEmitter<any>();

    /** Emitter so that outside components know that pagination has occurred */
    @Output() rowClicked = new EventEmitter<any>();

    /** Emitter so that outside components know that pagination has occurred */
    @Output() rowDeselected = new EventEmitter<any>();

    /** Emitter so that outside components know that pagination has occurred */
    @Output() rowSelected = new EventEmitter<any>();

    // Emit current selected rows
    @Output() selectedRows = new EventEmitter<any[]>();

    /** Emitter for when an individual cell gets clicked */
    @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();

    /** Emitter for when the button gets clicked */
    @Output() buttonClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output() editClicked: EventEmitter<any> = new EventEmitter<any>();

    @Output() deleteClicked: EventEmitter<any> = new EventEmitter<any>();

    /** Emitter for when the data gets changed */
    @Output() dataChanged: EventEmitter<any> = new EventEmitter<any>();

    currentPage = 1;

    @HostListener('window:resize', [])
    onResize() {
        // this is used to make sure the allowed number of columns gets reset
        // any other thoughts on how to do this without do a change detection would be appreciated
        // tslint:disable-next-line: no-unused-expression
        this.ztService.allowedColumns;
    }

    constructor(public ztService: ZipTable2Service) {
        this.deepCompareFn = deepCompare;
    }

    ngOnInit() {
        this.initializeTable();
    }

    ngOnChanges(changes) {
        if ('data' in changes && changes.data.currentValue && changes.options && changes.options.currentValue) {
            this.ztService.setupOptions(changes.options.currentValue);
            this.ztService.retrieveData(changes.data.firstChange && !this.ztService.tableInit, changes.data.currentValue);
        } else if ('data' in changes && changes.data.currentValue) {
            this.ztService.retrieveData(changes.data.firstChange && !this.ztService.tableInit, changes.data.currentValue);
            if (!getValue(this.options, 'selection.persist')) {
                this.ztService.unselectAllRows();
            }
        }

        if ('options' in changes && changes.options.currentValue) {
            this.ztService.setupOptions(changes.options.currentValue);
        }
    }

    clickEvent(row, ind) {
        this.rowClicked.emit(row);

        const result = this.ztService.handleRowSelection(row, ind);
        result === SelectionTypes.selected ? this.rowSelected.emit(row) : this.rowDeselected.emit(row);
    }

    initializeTable() {
        this.ztService.tableInit = false;

        this.ztService.tableWrapper = this.tableWrapper;
        this.ztService.setupOptions(this.options);
        this.ztService.retrieveData(!this.ztService.tableInit, this.data);
        this.ztService.rowSelected.subscribe((selected: any) => {
            if (selected) {
                this.clickEvent(selected.row, selected.ind);
            }
        });

        this.ztService.dataChanged.subscribe(data => {
            this.setCurrentPage(this.ztService.zipTableOptions.paging.pageIndex + 1);
            this.dataChanged.emit(data);
        });

        this.ztService.selectedRowSubject.subscribe(() => {
            this.selectedRows.emit(this.ztService.selectedRow);
        });
    }

    refresh(resetFilters = null) {
        if (resetFilters) {
            this.tableFilterComponent.resetFilters();
        }

        this.ztService.refreshExternalData();
    }

    setCurrentPage(page: number) {
        this.currentPage = page;
    }
}
