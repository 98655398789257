import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, forkJoin, of, range, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs/Observable';
import { catchError, debounceTime, flatMap, map } from 'rxjs/operators';

import { cloneObject, getValue } from '@zipari/web-utils';
import { validGAEvents, workflowContext } from '../constants/analytics';
import { Workflow } from '../models/shared/Workflow.model';
import { WorkflowStepDetails } from '../models/shared/WorkflowStep.model';

import { AnalyticsService } from './analytics.service';
import { ModalService } from './modal.service';

/*
    Base class for workflow functionality. e.g. complete step, back step
    Meant to be extended by business specific workflows. Do not inject this class directly
    Note the ts generic allows you to type the workflow values
*/
export class WorkflowService<T> {
    private analyticsDebounce = 500;
    private _workflow = new BehaviorSubject<Workflow<T>>(new Workflow<T>());
    private _loading = new Subject<boolean>();
    private _error = new Subject<Error>();
    private errorSubscription: Subscription;
    private showingErrorModal: boolean = false;
    private _errorConfigs: any;

    public get workflow$(): Observable<Workflow<T>> {
        return this._workflow.asObservable();
    }

    public get workflowData$(): Observable<T> {
        return this._workflow.pipe(map(workflow => workflow.data));
    }

    public get workflowValues$(): Observable<T> {
        return this._workflow.pipe(map(workflow => workflow.values));
    }

    public get loading(): Observable<boolean> {
        return this._loading.asObservable();
    }

    public get error(): Observable<Error> {
        return this._error.asObservable();
    }

    public get workflow(): Workflow<T> {
        return this._workflow.getValue();
    }

    public get errorConfigs() {
        return this._errorConfigs || {};
    }

    public set errorConfigs(errorConfigs) {
        this._errorConfigs = errorConfigs;
    }

    public getWorkflow(workflowId = this.workflow.id): Observable<Workflow<T>> {
        return this.http.get<Workflow<T>>(`/api/workflows/${workflowId}/`);
    }

    public setWorkflow(workflowStepResponse: WorkflowStepDetails<T>): void {
        this._workflow.next(workflowStepResponse.workflow);
    }

    private setCurrentStep(stepNumber: number) {
        this._workflow.next({
            ...this.workflow,
            current_step_number: stepNumber,
        });
    }

    constructor(public analyticsService: AnalyticsService, public http: HttpClient, public modalService: ModalService) {
        this._workflow.pipe(debounceTime(this.analyticsDebounce)).subscribe(() => {
            this.analyticsService.setWorkflowContext(this.CXWorkflowContext);

            if (this.CXWorkflowContext) {
                const dictionary_attributes = {
                    eventLabel: this.CXWorkflowContext.title,
                    eventCategory: this.CXWorkflowContext.key,
                };

                this.analyticsService.dispatchAnalytics(
                    {
                        GAKey: validGAEvents['workflow_step'],
                    },
                    undefined,
                    dictionary_attributes
                );
            }
        });

        this.errorSubscription = this._error.subscribe(errorConfig => {
            this.handleError(errorConfig);
        });
    }

    handleError(errorConfig: any) {
        const errorMessage = errorConfig.message ? errorConfig.message : getValue(errorConfig, 'error.message');
        if (errorConfig.modal) {
            if (!this.showingErrorModal) {
                // protects from multiple notifications from cascading errors
                this.showingErrorModal = true;
                const clearHasError = () => {
                    this.showingErrorModal = false;
                };
                const confirmMessage = errorConfig.confirm ? errorConfig.confirm : 'Okay';
                this.modalService.presentModal(
                    {
                        body: errorMessage,
                        confirm: confirmMessage,
                    },
                    {},
                    clearHasError
                );
            }
        } else {
            console.error(errorMessage);
        }
    }

    public startWorkflow(workflowType: string, customBody: any = {}): Observable<Workflow<T>> {
        return this.http
            .post<Workflow<T>>('/api/workflows/', { type: workflowType, ...customBody })
            .pipe(
                tap(response => {
                    this._workflow.next(response);
                })
            );
    }

    public continueWorkflow(id: number): Observable<Workflow<T>> {
        this._workflow.next(null);

        return this.getWorkflow(id).pipe(
            tap(response => {
                this._workflow.next(response);
            })
        );
    }

    public previousStep() {
        const extendedTimeout = 120000;
        this._loading.next(true);

        let lastNotSkippedStep;

        // make sure we don't go back to a step that was skipped
        // start -2... 1 for zero index and 1 to start at most previous step
        for (let i = this.workflow.current_step_number - 2; i >= 0; i--) {
            const currentStep = this.workflow.steps[i];
            if (currentStep.state !== 'skipped') {
                lastNotSkippedStep = i;
                break;
            }
        }

        // check to make sure that `lastNotSkippedStep` was set within for loop
        // if lastNotSkippedStep is still undefined it means someone is calling previous step on the first step....
        // why would one do that?... the world may never know
        if (typeof lastNotSkippedStep === 'number') {
            this.http
                .patch<WorkflowStepDetails<T>>(`/api/workflows/${this.workflow.id}/steps/${lastNotSkippedStep + 1}/`, {
                    state: 'in_progress',
                    headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
                })
                .subscribe(response => {
                    this._loading.next(false);
                    this.setWorkflow(response);
                });
        } else {
            console.error('Previous step called on first step. This is an error');
        }
    }

    public completeStep(data?) {
        const extendedTimeout = 120000;
        this._loading.next(true);

        this.http
            .put<WorkflowStepDetails<T>>(`/api/workflows/${this.workflow.id}/steps/${this.workflow.current_step_number}/`, {
                values: data ? data : {},
                state: 'complete',
                headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
            })
            .subscribe(
                workflowStepResponse => {
                    this._workflow.next({
                        ...workflowStepResponse.workflow,
                    });

                    this._loading.next(false);
                    window.scroll(0, 0);
                },
                error => {
                    const errorConfig = Object.assign({}, this.errorConfigs.completeStep, { error });
                    this._error.next(errorConfig);
                }
            );
    }

    public completeStepObs$(data?): Observable<any> {
        const extendedTimeout = 120000;
        this._loading.next(true);

        return this.http
            .put<WorkflowStepDetails<T>>(`/api/workflows/${this.workflow.id}/steps/${this.workflow.current_step_number}/`, {
                values: data ? data : {},
                state: 'complete',
                headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
            })
            .pipe(
                tap(
                    workflowStepResponse => {
                        this._workflow.next({
                            ...workflowStepResponse.workflow,
                        });

                        this._loading.next(false);

                        window.scroll(0, 0);
                    },
                    catchError(err => {
                        console.error('err caught', err);

                        return of(err);
                    })
                )
            );
    }

    public patchWorkflow(data, stepNumber: number, setWorkflow = true): Observable<any> {
        const extendedTimeout = 120000;

        if (this.workflow) {
            return this.http
                .patch<WorkflowStepDetails<T>>(`/api/workflows/${this.workflow.id}/steps/${stepNumber}/`, {
                    values: data,
                    headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
                })
                .pipe(
                    tap(response => {
                        if (setWorkflow) {
                            this.setWorkflow(response);
                        }
                    })
                );
        } else {
            return of();
        }
    }

    public skipToStep(stepNumber: number) {
        const numberStepsToSkip = stepNumber - this.workflow.current_step_number;
        if (numberStepsToSkip > 0) {
            range(this.workflow.current_step_number, numberStepsToSkip)
                .pipe(
                    flatMap(stepToSkip =>
                        this.workflow.steps[stepToSkip].state !== 'complete'
                            ? this.http.patch(`/api/workflows/${this.workflow.id}/steps/${stepToSkip}/`, { state: 'skipped' })
                            : of(null)
                    )
                )
                .subscribe();
        }
        this.setCurrentStep(stepNumber);
    }

    public skipCurrentAndGoToNext() {
        const currentStep = this.workflow.current_step_number;

        this.setCurrentStep(currentStep + 1);
        this.http.patch(`/api/workflows/${this.workflow.id}/steps/${currentStep}/`, { state: 'skipped' }).subscribe(null, error => {
            const errorConfig = Object.assign({}, this.errorConfigs.skipCurrentAndGoToNext, { error });
            this._error.next(errorConfig);
        });
    }

    public goToStep(stepId: number) {
        this.goToStep$(stepId).subscribe(
            response => this.setWorkflow(response),
            error => {
                const errorConfig = Object.assign({}, this.errorConfigs.goToStep, { error });
                this._error.next(errorConfig);
            }
        );
    }

    public goToStep$(stepId) {
        this._loading.next(true);
        const extendedTimeout = 120000;

        return this.http
            .patch<WorkflowStepDetails<T>>(`/api/workflows/${this.workflow.id}/steps/${stepId}/`, {
                state: 'in_progress',
                headers: new HttpHeaders({ timeout: `${extendedTimeout}` }),
            })
            .pipe(
                tap(() => {
                    this._loading.next(false);
                })
            );
    }

    public setLoading(isLoading: boolean) {
        // we can't do this with a real setter
        this._loading.next(isLoading);
    }

    public sendError(config: any, error: Error) {
        const errorConfig = Object.assign({}, config, { error });
        this._error.next(errorConfig);
    }

    public get CXWorkflowContext(): workflowContext | null {
        const currentStepSchema = getValue(this.workflow, 'current_step.schema');

        return currentStepSchema
            ? {
                  web_session_id: this.workflow.web_session_id,
                  webuser_id: this.workflow.webuser_id,
                  title: currentStepSchema.label,
                  key: currentStepSchema.description,
              }
            : null;
    }

    replaceValuesOnOtherStepsIfTheyExist(currentStepData: any, keysToCheck: string[], currentStepIndex: number): Observable<any> {
        const stepValuesToAdjust: { newValue: any; ind: number; stepInd: number }[] = [];
        this._loading.next(true);

        this.workflow.steps.forEach((step, ind) => {
            const stepValues = cloneObject(this.workflow.steps[ind].values);

            const filteredKeys = keysToCheck.filter(valueKey => !!stepValues[valueKey] && ind !== currentStepIndex);

            filteredKeys.forEach(key => {
                stepValues[key] = currentStepData[key];
            });

            if (filteredKeys.length > 0) {
                stepValuesToAdjust.push({ newValue: stepValues, ind, stepInd: ind + 1 });
            }
        });

        const result = { currentStepData, stepValuesToAdjust };

        // if there are steps to be changed then make the patches OR stub out the forkjoin in the case where there are
        // no steps where we need to change the keys provided
        let observableArr: Observable<any>[] = [];
        if (result.stepValuesToAdjust.length > 0) {
            observableArr = result.stepValuesToAdjust.map((stepValueToAdjust: { newValue: any; ind: number; stepInd: number }) =>
                this.patchWorkflow(stepValueToAdjust.newValue, stepValueToAdjust.stepInd, false)
            );
        } else {
            observableArr.push(of(true));
        }

        return forkJoin(observableArr).pipe(
            map(() => result),
            tap(() => {
                this._loading.next(false);
                this.completeStep(result.currentStepData);
            })
        );
    }

    getWorkflowsByPolicyNumber(policy_number) {
        const options = policy_number ? { params: new HttpParams().set('data__policy_number', policy_number) } : {};

        return this.http.get<any>('api/workflows/', options);
    }
}
