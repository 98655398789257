import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {
    AddressPipe,
    BuildQueryPipe,
    DirectivesModule,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    GridCellDirective,
    MaskPipe,
    OutsideClickDirective,
    PhoneNumberPipe,
    PipesModule,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
} from '@zipari/web-utils';

import { AsideMenuComponent } from './components/aside-menu/aside-menu.component';
import { ZipCardComponent } from './components/card/zip-card.component';
import { AddressSelectModalComponent } from './components/modals/address-select-modal.component';
import { SlideOutComponent } from './components/slide-out/slide-out.component';
import { StateAutoCompleteDirective } from './directives/state-autocomplete.directive';
import { TranslateDirective } from './directives/translate.directive';
import { TriggerConfirmationModalDirective } from './directives/trigger-confirmation-modal.directive';
import { VerifyAddressDirective } from './directives/verify-address.directive';
import { ZipBusyModule } from './modules/zip-busy/zip-busy.module';
import { PipeModule } from './pipes/pipe.module';
import { ModalService } from './services/modal.service';

export const SHARED_ELEMENTS = [
    ZipCardComponent,
    AddressSelectModalComponent,
    TriggerConfirmationModalDirective,
    SlideOutComponent,
    AsideMenuComponent,
    StateAutoCompleteDirective,
    VerifyAddressDirective,
    TranslateDirective,
];

export const pipes = [
    AddressPipe,
    BuildQueryPipe,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    MaskPipe,
    PhoneNumberPipe,
    PipesModule,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
];

@NgModule({
    declarations: SHARED_ELEMENTS,
    exports: [SHARED_ELEMENTS, GridCellDirective, OutsideClickDirective, pipes],

    imports: [CommonModule, FormsModule, OverlayModule, ZipBusyModule, PipeModule, PipesModule, DirectivesModule],
    providers: [
        CurrencyPipe,
        DatePipe,
        ModalService,
        StringBuilderPipe,
        AddressPipe,
        GetValuePipe,
        EqualityFilterPipe,
        BuildQueryPipe,
        DoesListHaveValuesPipe,
        FullNamePipe,
        PhoneNumberPipe,
        MaskPipe,
        ReadMorePipe,
        SafeNumberPipe,
        GridCellDirective,
        OutsideClickDirective,
    ],
})
export class SharedModule {}
