import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { validCXEvents, validSSOCXEventDictonaryValue } from '../../../../shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { PaginatorConfiguration } from '@zipari/design-system';
import { GenericType } from 'src/app/icx/shared/services/icx.service';
import { PaginatorService } from 'src/app/icx/shared/services/paginator.service';
import { APIService, ConfigService } from '../../../../shared/services';
import { SidePanelService } from './../../../shared/services/side-panel.service';
import { CoveragesPageConfig } from './coverages.model';
import { ClaimsService } from '../../../shared/services/claims.service';
import { WindowService } from '@zipari/web-services';
import { SSOLinkValue } from '../claims/claims.constant';
export type CoverageItem = GenericType;
@Component({
    selector: 'coverages',
    templateUrl: './coverages.component.html',
    styleUrls: ['./coverages.component.scss'],
})
export class CoveragesComponent implements OnInit {
    public pageConfig: CoveragesPageConfig;
    public paginatorConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    public coverageList: CoverageItem[] = [];
    loadingDatagrid: boolean = true;
    sortingElement: string = '';
    private member: string;
    constructor(
        private configService: ConfigService,
        private route: ActivatedRoute,
        private api: APIService,
        private panelService: SidePanelService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private paginatorService: PaginatorService,
        private claimsService: ClaimsService,
        private window: WindowService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.member = this.route.snapshot.params['member'];
        this.pageConfig = this.configService.getControlPanelConfig(this.route.snapshot.data['pageName']);

        this.getCoverages();
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.CoverageList_viewed }, this.member);
    }

    getCoverages() {
        this.loadingDatagrid = true;
        const endpoint = this.buildQueryParams();
        this.api.get(endpoint).subscribe(
            (resp) => {
                this.paginatorConfig = this.paginatorService.setPagingConfig(this.paginatorConfig, resp.count, this.pageConfig.paginator);
                this.coverageList = Object.assign([], resp.results);
                this.coverageList = this.coverageList.map((pc, index) => {
                    pc.member_coverages.forEach((mc) => {
                        if (mc?.member?.member_number === this.member || mc?.member?.id === this.member) {
                            this.coverageList[index].member_display_identifier = mc.member.member_display_identifier;
                        }
                    });
                    return (pc = {
                        ...pc,
                        sso_link: SSOLinkValue,
                    });
                });
                this.loadingDatagrid = false;
            },
            () => {
                this.loadingDatagrid = false;
            }
        );
    }

    buildQueryParams() {
        let query = `/api/member-360/policies/product-coverages/?member_number=${this.member}&`;
        query += `page=${this.paginatorConfig.currentPage}&`;
        if (this.pageConfig.paginator) {
            query += `page_size=${this.pageConfig.paginator}&`;
        }
        if (this.sortingElement) {
            query += `ordering=${this.sortingElement}&`;
        }

        return query.slice(0, -1);
    }

    navigateToDetails(event: CoverageItem) {
        const mcov_id = this.getMemberCoverageId(event);
        this.router.navigate([
            `/icx/member-360/${this.route.params['value'].member}/coverages/${event.policy_id}/${event.product_coverage_id}/${mcov_id}`,
        ]);
    }

    public openSSODetails(target): void {
        let queryParams = this.claimsService.getSSOQueryParams(this.pageConfig.datagrid, target, this.route.snapshot.params?.member);
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Sso_initiated }, this.route.snapshot.params?.member, {
            SSO_initiated: validSSOCXEventDictonaryValue.Coverages,
        });
        if (target?.route) {
            const ssoUrl = `${target.route}?${queryParams}`;
            this.window.nativeWindow.open(ssoUrl, '_blank');
        }
    }

    setCurrentPage(page: number) {
        this.paginatorConfig.currentPage = page;

        this.paginatorConfig.showingStart = page === 1 ? 1 : this.pageConfig.paginator * (page - 1) + 1;

        this.paginatorConfig.showingEnd =
            page === this.paginatorConfig.totalPages ? this.paginatorConfig.totalResults : this.pageConfig.paginator * page;
        this.getCoverages();
    }

    sortTable(element: any) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.getCoverages();
    }

    getMemberCoverageId(coverage) {
        const mcov_id = coverage.member_coverages.filter(
            (mcov) => mcov.member?.member_number === this.member || mcov.member?.id === this.member
        )[0]?.member_coverage_id;

        return mcov_id || '';
    }
}
