/* eslint-disable @typescript-eslint/typedef */
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { ConfigService } from 'src/app/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { FormattingService } from '@zipari/design-system';
import { sortTabs } from 'src/app/icx/shared/utils/sorting-tabs';
import { MemberDataService } from './../../../shared/services/member-data.service';
import { COBResults } from './../policies/policies.constants';
import {
    CoverageDetailsConfig,
    CoverageEligibility,
    GroupStructureDatagridData,
    WellnessData,
    WellnessResponseWrapper,
} from './coverage-details.model';
import { sortArrayData } from '../../../../shared/modules/zip-busy/utils';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { validCXEvents } from '../../../../shared/constants/analytics';

@Component({
    selector: 'coverage-details',
    templateUrl: './coverage-details.component.html',
    styleUrls: ['./coverage-details.component.scss'],
})
export class CoverageDetailsComponent implements OnInit {
    memberCoverageID: string;
    productCoverageID: string;
    policyNo: string;
    pageConfig: CoverageDetailsConfig;
    memberId: string;
    tableData = [];
    // todo: change the format to an interface once API response is corrected
    data: any;
    titleValue: string;
    cobData: COBResults[] = [];
    wellnessData: WellnessData[] = [];
    tab_names = {
        covInfo: 'covInfo',
        whosCovered: 'whosCovered',
        cobInfo: 'cobInfo',
        wellness: 'wellness',
    };
    allTabs: any = [];
    currentTab: any;
    pageName: string;
    backButtonLabel: string = 'All Coverages';
    coverageDetailsTabLabels: string[] = [];
    groupStructureData: GroupStructureDatagridData[] = [];
    public groupStructureLoading: boolean = true;
    sortingElement: string;

    constructor(
        private route: ActivatedRoute,
        private configService: ConfigService,
        private memberDataService: MemberDataService,
        private panelService: SidePanelService,
        private formatService: FormattingService,
        private router: Router,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.setConfig();
        this.setPageData();
        this.selectedTab(0);
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.CoverageDetails_viewed }, this.memberId);
    }

    setConfig() {
        this.pageName = this.route.snapshot.data['pageName'];
        this.pageConfig = this.configService.getControlPanelConfig(this.pageName);
        this.setBackButtonLabel();
        this.allTabs = Object.keys(this.pageConfig.tabs).map((key: string) => this.pageConfig.tabs[key]);
        this.allTabs = this.allTabs.sort(sortTabs);
        this.coverageDetailsTabLabels = this.allTabs.map((tab) => tab.tabLabel);
    }

    setPageData() {
        this.memberCoverageID = this.route.params['value'].mcov_id;
        this.productCoverageID = this.route.params['value'].pcov_id;
        this.memberId = this.route.params['value'].member;
        this.policyNo = this.route.params['value'].policy_number;
        this.memberDataService
            .getMemberCoverages(this.pageConfig.endpoint, this.policyNo, this.productCoverageID, this.memberId)
            .subscribe((resp) => {
                this.data = Object.assign({}, resp.details);
                this.titleValue = this.formatService.formatConfigValueAsTemplate(this.pageConfig.header, this.data).value;
                this.tableData = resp.details?.member_coverages?.length ? [...resp.details.member_coverages] : [];
                this.getWellnessData();
            });
        this.memberDataService
            .getCOBList(this.route.params['value'].policy_number, this.memberCoverageID, this.memberId)
            .subscribe((res) => {
                if (res.details?.results?.length > 0) {
                    this.cobData = [
                        ...res.details.results.map((data) => {
                            const updatedData = {
                                ...data,
                                covered_names:
                                    data.covered_persons_names && data.covered_persons_names.length
                                        ? data.covered_persons_names.map((person) => `${person.first_name} ${person.last_name}`)
                                        : null,
                            };

                            return updatedData;
                        }),
                    ];
                    this.cobData.sort((data1: COBResults, data2: COBResults) => (data1.display_order > data2.display_order ? 1 : -1));
                }
            });

        this.memberDataService.getCoverageEligibilitiesList(this.memberCoverageID).subscribe(
            (res) => {
                this.groupStructureLoading = false;
                if (res?.results) {
                    this.setupCoverageEligibilitiesData(res.results);
                }
            },
            () => {
                this.groupStructureLoading = false;
            }
        );
    }

    getWellnessData() {
        const wellnessObject = this.allTabs.filter((tab) => tab.tabKey === this.tab_names.wellness)[0];
        const coverage_id = this.data?.current_member_coverage?.member_coverage_id;
        this.memberDataService
            .getWellnessData(this.memberId, coverage_id, this.data?.policy_id, wellnessObject?.query_param)
            .subscribe((res: WellnessResponseWrapper) => {
                if (res?.details?.results) {
                    this.wellnessData = res.details.results;
                }
            });
    }

    formatOptionsData(config, data) {
        const value = this.formatService.formatConfigValueAsTemplate(config, data).value;

        if (value) {
            return this.formatService.restructureValueBasedOnFormat(value, config);
        }
    }

    routeBack(): void {
        if (this.pageName === 'policy-coverage') {
            this.router.navigate([`/icx/member-360/${this.route.params['value'].member}/policies/${this.policyNo}`]);
        } else {
            this.router.navigate([`/icx/member-360/${this.route.params['value'].member}/coverages`]);
        }
    }

    navigateToDetail(event) {
        window.open(`/icx/member-360/${event.member?.member_number}/profile`, '_blank');
    }

    handleLinkClicked() {
        window.open(this.data.external_plan_name_sbc_url, '_blank');
    }

    selectedTab(index: number) {
        this.currentTab = this.allTabs[index];
    }

    public sortDataItem(prop: string): void {
        this.sortingElement = this.sortingElement === prop ? `-${this.sortingElement}` : prop;
        this.tableData = sortArrayData(prop, this.tableData, this.sortingElement);
    }

    setBackButtonLabel(): void {
        if (this.pageName === 'policy-coverage') this.backButtonLabel = 'Policy Detail';
    }

    setupCoverageEligibilitiesData(covElig: CoverageEligibility[]) {
        this.groupStructureData = covElig.map((singleElig) => {
            const singleRow: GroupStructureDatagridData = {} as GroupStructureDatagridData;

            singleRow['member_type'] = singleElig.member_relationship_type_display_name;
            singleElig.eligible_product_coverage_types.forEach((type: string) => {
                singleRow[type] = true;
            });

            return singleRow;
        });
    }
}
