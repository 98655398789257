import { Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Directive, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { forkJoin, of, Subject } from 'rxjs';
import { concatMap, distinctUntilChanged, filter, takeUntil, tap } from 'rxjs/operators';

import { deepCompare, cloneObject } from '@zipari/web-utils';
import { ConfirmationModalComponent } from '../components/confirmation-modal/confirmation-modal.component';

import { SpinnerService } from '../modules/zip-busy/spinner.service';
import { ConfigService } from '../services';
import { AddressSelectModalComponent } from './../components/modals/address-select-modal.component';
import { AddressService } from './../services/address.service';

@Directive({
    selector: '[verifyAddress]',
})
export class VerifyAddressDirective implements OnInit, OnDestroy {
    @Input('verifyAddress') addressFormGroup: FormGroup;
    @Input() disableVerifyAddress: boolean;

    @Output() addressSelect = new EventEmitter();

    private destroy = new Subject();
    private errorModalDefault = {
        body: 'We could not verify the address you provided. Please update the address and try again.',
        confirm: 'Close',
    };

    private errorModalMessage;

    constructor(
        private addressService: AddressService,
        private overlay: Overlay,
        private spinnerService: SpinnerService,
        private configService: ConfigService
    ) {}

    ngOnInit() {
        const configs: any = this.configService.getPageConfig('enrollment') || this.configService.configs.enrollment;

        if (configs && configs.addressSelectionModalMessage) {
            if (configs && configs.addressSelectionModalMessage && configs.addressSelectionModalMessage.errorModalNotVerified) {
                this.errorModalMessage = cloneObject(configs.addressSelectionModalMessage.errorModalNotVerified);
            }
        } else {
            this.errorModalMessage = this.errorModalDefault;
        }

        if (this.addressFormGroup && !this.disableVerifyAddress) {
            this.addressFormGroup.valueChanges
                .pipe(
                    takeUntil(this.destroy),
                    distinctUntilChanged((a, b) => deepCompare(a, b)),
                    filter(() => this.addressFormGroup.valid),
                    tap(() => {
                        this.spinnerService.addSpinner();
                    }),
                    concatMap(addressValues => forkJoin([of(addressValues), this.addressService.verifyAddress(addressValues)]))
                )
                .subscribe(
                    ([formAddress, verifiedResponse]) => {
                        this.spinnerService.removeSpinner();
                        if (verifiedResponse.errors) {
                            this.openErrorModal();
                        } else {
                            this.openModal(formAddress, verifiedResponse.address);
                        }
                    },
                    error => {
                        this.spinnerService.removeSpinner();
                        console.log(error);
                    }
                );
        }
    }

    private openErrorModal() {
        // Use CDK to attach dynamic modal component to overlay
        const overlayRef = this.overlay.create({
            hasBackdrop: true,
            backdropClass: ['modal__mask-modal'],
        });
        const confirmationModalComponent = new ComponentPortal(ConfirmationModalComponent);
        const containerRef = overlayRef.attach(confirmationModalComponent);

        // Handle modal component inputs and outputs
        containerRef.instance.config = this.errorModalMessage;
        containerRef.instance.confirm.pipe(takeUntil(this.destroy)).subscribe(() => {
            overlayRef.detach();
        });
    }

    private openModal(formAddress, verifiedAddress) {
        // Use CDK to attach dynamic modal component to overlay
        const overlayRef = this.overlay.create();
        const addressModalComponent = new ComponentPortal(AddressSelectModalComponent);
        const containerRef = overlayRef.attach(addressModalComponent);

        // Handle modal component inputs and outputs
        containerRef.instance.current = formAddress;
        containerRef.instance.verified = verifiedAddress;
        containerRef.instance.canCancel = false;
        containerRef.instance.cancel.pipe(takeUntil(this.destroy)).subscribe(() => {
            overlayRef.detach();
        });
        containerRef.instance.resolve.pipe(takeUntil(this.destroy)).subscribe(val => {
            this.addressSelect.next(val);
            overlayRef.detach();
        });
    }

    ngOnDestroy() {
        this.destroy.next();
        this.destroy.complete();
    }
}
