import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { ZipGoogleMapService } from './map.service';

declare const google: any;

@Component({
    selector: 'map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.scss'],
})
export class MapComponent implements AfterViewInit, OnChanges {
    @Input() centerLat: number;
    @Input() centerLng: number;
    @Input() mapZoom: number;
    @Input() mapConfig: any;
    @Input() pins: any[] = [];
    @Input() directionsStart: any;
    @Input() directionsEnd: any;
    @Input() directionsPanel: any;
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('fit-markers') fitMarkers = true;
    @Input() mapStyles: any;
    @Output('pin-selected') pinSelectedEmitter = new EventEmitter<any>();
    @Output('directions-ready') directionsReadyEmitter = new EventEmitter<any>();
    directionsDisplay: any;
    markers: any[] = [];
    map: any;
    @ViewChild('directions') directions;

    constructor(private zipMapService: ZipGoogleMapService, private elementRef: ElementRef) {}

    initializeMap() {
        this.map = new google.maps.Map(this.elementRef.nativeElement, this.getMapProperties());
        this.setMapMarkers();

        if (this.directionsStart && this.directionsEnd) {
            this.getTurnByTurn();
        }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (!this.map) {
            return;
        }
        this.resize();
    }

    public resize() {
        google.maps.event.trigger(this.map, 'resize');
    }

    ngAfterViewInit() {
        this.zipMapService.IsGoogleMapAPILoaded().then(() => this.initializeMap());
    }

    ngOnChanges(changes) {
        if (!this.map) {
            return;
        }
        if (changes.mapZoom) {
            this.map.setZoom(this.mapZoom);
        }
        if (changes.centerLat || changes.centerLng) {
            this.map.setCenter({ lat: this.centerLat, lng: this.centerLng });
        }
        if (changes.pins) {
            this.setMapMarkers();
        }
        if (changes.directionsStart || changes.directionsEnd) {
            this.getTurnByTurn();
        }
    }

    setMapMarkers() {
        this.markers.forEach(marker => marker.gMarker.setMap(null));
        this.markers = [];
        this.pins.forEach(pin => {
            if (pin.latlng) {
                this.addMarker(pin, pin.latlng);
            }
            if (pin.address) {
                this.zipMapService.GetLatLngFromAddress(pin.address).then(latlng => {
                    this.addMarker(pin, latlng);
                });
            }
        });
    }

    fitBounds() {
        const bounds = new google.maps.LatLngBounds();
        this.markers.forEach(m => bounds.extend(m.gMarker.getPosition()));
        this.map.fitBounds(bounds);
    }

    addMarker(pin, latlng) {
        const pinImage =
            'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIyNCIgdmlld0JveD0iMCAwIDI0IDI0Ij48cGF0aCBkPSJNMTIgMkM4LjEzIDIgNSA1LjEzIDUgOWMwIDUuMjUgNyAxMyA3IDEzczctNy43NSA3LTEzYzAtMy44Ny0zLjEzLTctNy03em0wIDkuNWMtMS4zOCAwLTIuNS0xLjEyLTIuNS0yLjVzMS4xMi0yLjUgMi41LTIuNSAyLjUgMS4xMiAyLjUgMi41LTEuMTIgMi41LTIuNSAyLjV6Ii8+PHBhdGggZD0iTTAgMGgyNHYyNEgweiIgZmlsbD0ibm9uZSIvPjwvc3ZnPg==';
        const marker = { pin, gMarker: new google.maps.Marker({ map: this.map, position: latlng, icon: pinImage }) };
        this.markers.push(marker);
        google.maps.event.addListener(marker.gMarker, 'click', () => {
            this.pinSelectedEmitter.emit(marker.pin);
            if (marker.pin.infoWindow) {
                this._createInfoWindow(marker.pin.infoWindow, marker.gMarker);
            }
        });
        if (this.fitMarkers) {
            this.fitBounds();
        }
    }

    _createInfoWindow(content, marker) {
        const infoWindow = new google.maps.InfoWindow({
            content,
        });
        infoWindow.open(this.map, marker);
    }

    getTurnByTurn() {
        if (this.directionsDisplay) {
            this.directionsDisplay.setMap(null);
        }
        if (this.directionsPanel && this.directionsDisplay) {
            this.directionsDisplay.setPanel(null);
        }
        const directionsService = new google.maps.DirectionsService();
        this.directionsDisplay = new google.maps.DirectionsRenderer();

        this.directionsDisplay.setMap(this.map);
        if (this.directionsPanel) {
            this.directionsDisplay.setPanel(this.directionsPanel);
        }
        directionsService.route(
            {
                origin: this.directionsStart,
                destination: this.directionsEnd,
                travelMode: google.maps.TravelMode['DRIVING'],
            },
            (res, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.directionsDisplay.setDirections(res);
                    this.directionsReadyEmitter.emit(res);
                } else {
                    console.warn(status);
                }
            }
        );
    }

    getMapProperties() {
        return (
            this.mapConfig || {
                center: {
                    lat: this.centerLat,
                    lng: this.centerLng,
                },
                zoom: this.mapZoom,
                styles: this.mapStyles,
                disableDefaultUI: true,
            }
        );
    }
}
