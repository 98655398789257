import { Injectable } from '@angular/core';
import { PaginatorConfiguration } from '@zipari/design-system';

@Injectable({
    providedIn: 'root',
})
export class PaginatorService {
    /**
     * Use this to set paginator config when API response is updated
     * @param pagingConfig: original paging config
     * @param count: number of results from API
     * @param resultsPerPage: number of results to show per page
     */
    setPagingConfig(pagingConfig: PaginatorConfiguration, count: number, resultsPerPage: number) {
        pagingConfig.showingStart = !count ? 0 : pagingConfig.currentPage === 1 ? 1 : resultsPerPage * (pagingConfig.currentPage - 1) + 1;
        pagingConfig.showingEnd = pagingConfig.showingStart + (resultsPerPage - 1);
        pagingConfig.totalPages = count ? Math.ceil(count / resultsPerPage) : 1;
        pagingConfig.totalResults = count;

        return pagingConfig;
    }
}
