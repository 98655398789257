import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ErrorEmptyModule } from '@zipari/design-system';
import { ErrorScreenComponent } from './error-screen.component';

@NgModule({
    declarations: [ErrorScreenComponent],
    imports: [CommonModule, ErrorEmptyModule],
    exports: [ErrorScreenComponent],
})
export class ErrorScreenModule {}
