import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IcxModule } from './icx/icx.module';

export const routes = [
    {
        path: 'icx',
        loadChildren: () => IcxModule,
        data: {
            pageName: 'global',
            indexRoute: 'icx',
        },
    },
    {
        path: '**',
        redirectTo: '/icx/member-360',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
