import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModelAttributeModule, TableModule, ProgressBarModule, DatagridModule, PaginatorModule } from '@zipari/design-system';

import { ResultsModule } from '../results/results.module';
import { BadgeModule } from '../badge/badge.module';
import { TabViewComponent } from './tab-view.component';

@NgModule({
    declarations: [TabViewComponent],
    imports: [
        CommonModule,
        ModelAttributeModule,
        TableModule,
        ResultsModule,
        BadgeModule,
        ProgressBarModule,
        DatagridModule,
        PaginatorModule,
    ],
    exports: [TabViewComponent],
})
export class TabViewModule {}
