import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { CardConfig } from '@zipari/design-system/lib/notes/card/card.constants';
import { PaginatorConfiguration, FormattingService, formatTypes } from '@zipari/design-system';
import { ConfigService, APIService } from 'src/app/shared/services';

import { PaymentDetailsPageConfig, PaymentDetailsApiResponse } from './payment-details.model';

@Component({
    selector: 'payment-details',
    templateUrl: './payment-details.component.html',
    styleUrls: ['./payment-details.component.scss'],
})
export class PaymentDetailsComponent implements OnInit {
    public config: PaymentDetailsPageConfig;
    public cardConfig: CardConfig = {
        hideHeader: false,
        hideSubHeader: true,
        hideFooter: true,
        stretch: false,
        static: false,
        ignorePadding: false,
    };
    public paymentDetails: PaymentDetailsApiResponse;

    public tabLabels: string[] = [];
    public receiptTrackingPaginatorConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    public leftSideLabel: string = '';
    public leftSideValue: string = '';
    public isLoading: boolean = true;
    public illustrationSrc: string = 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/error-1.svg';

    private paymentId: string;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private panelService: SidePanelService,
        private configService: ConfigService,
        private api: APIService,
        private formattingService: FormattingService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit(): void {
        this.config = this.configService.getControlPanelConfig('payment-details');
        this.paymentId = this.route.snapshot.params.payment_id;

        this.getPaymentDetails();
    }

    routeBack() {
        this.router.navigate([`/icx/member-360/${this.route.snapshot.params.member}/payments`]);
    }

    getPaymentDetails() {
        this.isLoading = true;
        this.api.get(`/api/member-360/payments/${this.paymentId}/`).subscribe(
            (res) => {
                this.paymentDetails = res;

                this.leftSideValue = this.formattingService.restructureValueBasedOnFormat(
                    this.paymentDetails[this.config.card.leftSide.prop],
                    {
                        format: formatTypes.CURRENCY,
                    }
                );
                this.isLoading = false;
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    setReceiptTrackingCurrentPage() {
        // TODO implement pagination
    }
}
