import { Directive, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { concat, of } from 'rxjs';
import { distinctUntilChanged, filter, mergeMap } from 'rxjs/operators';

import { AddressService } from './../services/address.service';

@Directive({
    selector: '[stateAutoComplete]',
})
export class StateAutoCompleteDirective implements OnInit {
    @Input('stateAutoComplete') addressFormGroup: FormGroup;

    get zipCodeControl() {
        return this.addressFormGroup.get('zip_code');
    }

    constructor(private addressService: AddressService) {}

    ngOnInit() {
        if (this.zipCodeControl) {
            concat(of(this.zipCodeControl.value), this.zipCodeControl.valueChanges)
                .pipe(
                    distinctUntilChanged(),
                    filter(zipcodeValue => zipcodeValue && zipcodeValue.length && zipcodeValue.length === 5),
                    mergeMap(zipcodeValue => this.addressService.getStateForZipCode(zipcodeValue))
                )
                .subscribe(stateCode => {
                    this.addressFormGroup.patchValue({ state: stateCode });
                });
        }
    }
}
