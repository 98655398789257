import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';

export interface ListServiceInterface {
    getList: (endpoint: string, params?: any) => Observable<any>;
    addToList: (endpoint: string, item: any) => Observable<any>;
}

export const LIST_SERVICE = new InjectionToken<ListServiceInterface>('LIST_SERVICE');

@Injectable()
export class ListService {
    constructor(private http: HttpClient) {}

    getList(endpoint: string, params = {}): Observable<any> {
        return this.http.get(endpoint, { params });
    }

    addToList(endpoint: string, item: any): Observable<any> {
        return this.http.post(endpoint, item);
    }

    public handleCheckingApplicationForUnfinishedWorkflow(
        configService,
        router: Router,
        route: ActivatedRoute,
        event,
        statuses?
    ): Promise<any> {
        const nonApplicationSubmitStatus = statuses || ['submitted', 'completed', 'complete'];

        if (
            nonApplicationSubmitStatus.filter(status => event.row.status === status).length === 0 &&
            event.row.application_source === configService.app
        ) {
            return router.navigate([
                configService.appRoute,
                configService.getMarketSegment(route.snapshot),
                'enrollment',
                event.row.workflow_id,
            ]);
        }
    }
}
