import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Title, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, RouteConfigLoadEnd, Router, RouterEvent } from '@angular/router';
import { Keepalive } from '@ng-idle/keepalive';
import { filter, tap } from 'rxjs/operators';
import { GlobalConfig, KeepaliveConfig, TimeoutConfig } from './app.constants';
import { AuthService, ConfigService, IdleService } from './shared/services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    public isAppLoading = true;

    public idleState: string;
    public lastPing: Date;
    public typeface: SafeResourceUrl;

    constructor(
        public authService: AuthService,
        private keepalive: Keepalive,
        public idleService: IdleService,
        public configService: ConfigService,
        public title: Title,
        private router: Router,
        private http: HttpClient,
        private sanitizer: DomSanitizer
    ) {
        const globalConfig: GlobalConfig = this.configService.getPageConfig<GlobalConfig>('global');
        globalConfig.timeout = this.configService.getControlPanelConfig('timeout');

        this.applyTheme();
        this.setupTimeouts(globalConfig.timeout);
        this.setTitle(globalConfig.title);
        this.setupRouterCheckForAuth();
    }

    setTitle(titleConfig: string): void {
        if (titleConfig) {
            this.title.setTitle(titleConfig);
        }
    }

    setupTimeouts(timeoutConfig: TimeoutConfig): void {
        const config: TimeoutConfig = new TimeoutConfig(timeoutConfig);

        this.idleService.setupIdle(config.idle);
        this.setupKeepalive(config.keepalive);
    }

    /** documentation can be found @ https://hackedbychinese.github.io/ng2-idle/ */
    setupKeepalive(keepaliveConfig: KeepaliveConfig): void {
        if (keepaliveConfig.enabled) {
            this.keepalive.onPing.subscribe(() => {
                this.lastPing = new Date();

                this.http.get(keepaliveConfig.endpoint).subscribe(
                    () => {},
                    (err: Error) => {
                        if (keepaliveConfig.error.link) {
                            window.location.assign(keepaliveConfig.error.link);
                        } else if (keepaliveConfig.error.route) {
                            this.router.navigate([keepaliveConfig.error.route]);
                        }
                    }
                );
            });

            // Sets the ping keepaliveInterval to 15 seconds
            this.keepalive.interval(keepaliveConfig.interval);
        }
    }

    setupRouterCheckForAuth(): void {
        // Subscribe to router events to show loading indicator when app modules (e.g. broker, shopping portals) are
        // compiling
        // navigate to app route when url redirect fails
        this.router.events
            .pipe(
                tap((event: RouterEvent) => {
                    if (event instanceof NavigationEnd && this.checkForNonAppUrl(event)) {
                        this.router.navigate([this.configService.appRoute], {
                            queryParamsHandling: 'merge',
                        });
                    }
                    if (
                        this.authService.loggedInUser &&
                        this.authService.loggedInUser.app_user_data &&
                        this.idleService.enabled &&
                        !this.idleService.isIdling
                    ) {
                        this.idleService.resetTimeout();
                    } else if (this.idleService.isIdling) {
                        this.idleService.stopTimeout();
                    }
                }),
                filter((event: RouterEvent) => event instanceof RouteConfigLoadEnd)
            )
            .subscribe(() => {
                this.isAppLoading = false;
            });
    }

    private checkForNonAppUrl(event: NavigationEnd): boolean {
        return event.urlAfterRedirects.split('?')[0] === '/';
    }

    private applyTheme() {
        const root = document.documentElement;
        const theme = this.configService.getControlPanelConfig('theme');

        if (!theme) return;
        Object.keys(theme).forEach((component: string) => {
            Object.keys(theme[component]).forEach((group: string) => {
                Object.keys(theme[component][group]).forEach((variable: string) => {
                    const value = theme[component][group][variable];

                    root.style.setProperty(variable, value);

                    if (variable === '--typeface-1-url') {
                        this.typeface = this.sanitizer.bypassSecurityTrustResourceUrl(value);
                        document.body.style.fontFamily = theme[component][group]['--typeface-1-name'];
                    }

                    if (variable === '--icon-source-system' || variable === '--icon-source-product') {
                        const styleTag = document.createElement('style');

                        styleTag.appendChild(
                            document.createTextNode(`
                                    @font-face {
                                        font-family: '${variable === '--icon-source-system' ? 'System' : 'Product'} Icons';
                                        font-weight: var(--regular);
                                        font-style: normal;
                                        src: url('${value}') format('woff');
                                    }`)
                        );

                        document.head.appendChild(styleTag);
                    }
                });
            });
        });
    }
}
