import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from './../../../../shared/services/config.service';

interface Config_404 {
    src: string;
    illusrtation: string;
    illusrtation_text1: string;
    illusrtation_text2: string;
}

@Component({
    selector: 'not-found',
    templateUrl: './not-found.component.html',
    styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
    memberId: string;
    pageConfig: Config_404;

    constructor(private configService: ConfigService, private router: Router, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.pageConfig = this.configService.getPageConfig(this.route.snapshot.data['pageName']);
        this.memberId = this.route.snapshot.params.member;
    }

    goToProfile() {
        this.router.navigate([`/icx/member-360/${this.memberId}/profile`]);
    }
}
