/* eslint-disable @angular-eslint/no-conflicting-lifecycle */
import { ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { cloneObject, deepMerge, getValue } from '@zipari/web-utils';
import { Subscription } from 'rxjs';

import {
    buttonConfig,
    childOnlyConfig,
    depConfig,
    individualsCoveredConfig,
    spouseConfig,
    subscriberConfig,
} from './whose-covered.constants';

@Component({
    selector: 'whose-covered',
    templateUrl: './whose-covered.component.html',
    styleUrls: ['./whose-covered.component.scss'],
})
export class WhoseCoveredComponent implements OnInit, DoCheck, OnChanges, OnDestroy {
    @Input() group;
    @Input() config;
    @Input() direction;

    @Output() formCreated = new EventEmitter();

    childOnlyConfig = cloneObject(childOnlyConfig);
    subscriberConfig = cloneObject(subscriberConfig);
    spouseConfig = cloneObject(spouseConfig);
    depConfig = cloneObject(depConfig);
    individualsCoveredConfig = cloneObject(individualsCoveredConfig);
    childOnlySub;
    spouseCoveredSub;
    depCoveredSub;
    dependentVal;
    childOnlyVal;
    whoseCoveredSelected;
    childLimit;
    buttonConfig = buttonConfig;

    subscriberDateOfBirth;
    spouseDateOfBirth;

    prevDisabled;
    patchedValue;

    whoseCoveredGroup$: Subscription;
    whoseCoveredSelect$: Subscription;

    constructor(private formControlService: FormControlService, private cdr: ChangeDetectorRef) {}

    ngOnInit() {
        this.handleConfigOverrides();

        this.addExtraConfigs();

        this.formControlService.addControlToFormGroup(this.whoseCoveredGroup, this.individualsCoveredConfig);

        this.prefillForm();
        this.handleDisablingComponent();

        this.whoseCoveredGroup$ = this.whoseCoveredGroup.valueChanges.subscribe(allTheValues => {
            if (this.group['prefillVal']) {
                this.patchedValue = this.group['prefillVal'];
            }
        });

        this.whoseCoveredSelect$ = this.whoseCoveredGroup.controls.whose_covered.valueChanges.subscribe(value => {
            this.prefillForm();
        });
    }

    ngDoCheck() {
        this.handleDisablingComponent();
    }

    ngOnChanges(changes) {
        if (
            'group' in changes &&
            changes.group.currentValue.controls.whoseCovered &&
            changes.group.currentValue.controls.whoseCovered.controls.whose_covered &&
            changes.group.currentValue.controls.whoseCovered.controls.whose_covered.value
        ) {
            this.setupForms(changes.group.currentValue.controls.whoseCovered.controls.whose_covered.value);
        }
    }

    ngOnDestroy() {
        if (this.whoseCoveredGroup$) {
            this.whoseCoveredGroup$.unsubscribe();
        }

        if (this.whoseCoveredSelect$) {
            this.whoseCoveredSelect$.unsubscribe();
        }
    }

    handleDisablingComponent() {
        if (this.config.isDisabled !== this.prevDisabled) {
            const newDisabledValue = this.config.isDisabled;

            const configs = [this.spouseConfig, this.subscriberConfig, this.childOnlyConfig, this.depConfig];

            this.individualsCoveredConfig.isDisabled = newDisabledValue;
            configs.map(memberConfig => {
                memberConfig.controls = memberConfig.controls.map(controlConfig => {
                    controlConfig.isDisabled = newDisabledValue;

                    return controlConfig;
                });
            });

            this.prevDisabled = this.config.isDisabled;
        }
    }

    handleConfigOverrides() {
        if (this.config.childLimit) {
            this.childLimit = this.config.childLimit;
        }

        if (this.config.button) {
            this.buttonConfig = this.config.button;
        }

        if (this.config.individualsCoveredOptions) {
            this.individualsCoveredConfig = this.config.individualsCoveredOptions;
        }

        if (this.config.childOnlyOptions) {
            this.childOnlyConfig = deepMerge(this.childOnlyConfig, this.config.childOnlyOptions);
        }

        if (this.config.depOptions) {
            this.depConfig = deepMerge(this.depConfig, this.config.depOptions);
        }
    }

    addExtraConfigs() {
        const extraConfigs = this.config.extraConfigs || {};

        if (extraConfigs.all) {
            extraConfigs.all.forEach(config => {
                this.subscriberConfig.controls.push(cloneObject(config));
                this.spouseConfig.controls.push(cloneObject(config));
                this.depConfig.controls.push(cloneObject(config));
                this.childOnlyConfig.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.subscriber) {
            extraConfigs.subscriber.forEach(config => {
                this.subscriberConfig.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.spouse) {
            extraConfigs.spouse.forEach(config => {
                this.spouseConfig.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.dependents) {
            extraConfigs.dependents.forEach(config => {
                this.depConfig.controls.push(cloneObject(config));
            });
        }

        if (extraConfigs.child_only) {
            extraConfigs.child_only.forEach(config => {
                this.childOnlyConfig.controls.push(cloneObject(config));
            });
        }
    }

    public get whoseCoveredGroup(): FormGroup {
        return this.group.controls[this.config.prop];
    }

    public get whoseCoveredValue(): any {
        return this.group.controls[this.config.prop].value;
    }

    public addDependent(persistValue, numToAdd = null) {
        if (!this.whoseCoveredGroup.controls.dependents) {
            this.whoseCoveredGroup.addControl('dependents', new FormArray([]));
        }

        if (this.whoseCoveredGroup.controls.dependents instanceof FormArray) {
            if (persistValue.dependents && persistValue.dependents.length > 0 && numToAdd === null) {
                for (let i = 0; i < persistValue.dependents.length; i++) {
                    this.whoseCoveredGroup.controls.dependents.push(new FormGroup({}));
                }
            } else {
                this.whoseCoveredGroup.controls.dependents.push(new FormGroup({}));
            }

            for (let currDepInd = 0; currDepInd < this.whoseCoveredGroup.controls.dependents.length; currDepInd++) {
                this.depConfig.controls.forEach(config => {
                    if (!this.whoseCoveredGroup.controls.dependents['controls'][currDepInd].controls[config.prop]) {
                        this.formControlService.addControlToFormGroup(
                            this.whoseCoveredGroup.controls.dependents['controls'][currDepInd],
                            config,
                            persistValue.dependents ? persistValue.dependents[currDepInd] : {}
                        );
                    }
                });
            }
        }
        this.dependentVal = this.whoseCoveredValue.dependents;
    }

    public addChildOnly(persistValue, numToAdd = null) {
        if (!this.whoseCoveredGroup.controls.dependents) {
            this.whoseCoveredGroup.addControl('dependents', new FormArray([]));
        }

        if (this.whoseCoveredGroup.controls.dependents instanceof FormArray) {
            if (persistValue.dependents && numToAdd === null) {
                for (let i = 0; i < persistValue.dependents.length; i++) {
                    this.whoseCoveredGroup.controls.dependents.push(new FormGroup({}));
                }
            } else {
                this.whoseCoveredGroup.controls.dependents.push(new FormGroup({}));
            }

            for (let currDepInd = 0; currDepInd < this.whoseCoveredGroup.controls.dependents.length; currDepInd++) {
                this.childOnlyConfig.controls.forEach(config => {
                    if (!this.whoseCoveredGroup.controls.dependents['controls'][currDepInd].controls[config.prop]) {
                        this.formControlService.addControlToFormGroup(
                            this.whoseCoveredGroup.controls.dependents['controls'][currDepInd],
                            config,
                            persistValue.dependents ? persistValue.dependents[currDepInd] : {}
                        );
                    }
                });
            }
        }

        this.dependentVal = this.whoseCoveredValue.dependents;
    }

    public addSubscriber(persistValue) {
        if (!this.whoseCoveredGroup.controls.subscriber) {
            this.whoseCoveredGroup.addControl('subscriber', new FormGroup({}));
        }

        this.subscriberConfig.controls.forEach(config => {
            this.formControlService.addControlToFormGroup(
                this.whoseCoveredGroup.controls.subscriber,
                config,
                persistValue.subscriber || {}
            );
        });

        this.subscriberDateOfBirth = getValue(this.whoseCoveredGroup, 'controls.subscriber.controls.date_of_birth');
    }

    public get maxAgeForSpouse() {
        return !!this.spouseDateOfBirth && !!this.spouseDateOfBirth.errors && !!this.spouseDateOfBirth.errors.maxAge;
    }

    public get maxAgeForSubscriber() {
        return (
            !!this.subscriberDateOfBirth &&
            !!this.subscriberDateOfBirth.errors &&
            !!this.subscriberDateOfBirth.errors.maxAge &&
            (this.subscriberDateOfBirth.touched || this.subscriberDateOfBirth.dirty)
        );
    }

    public addSpouse(persistValue) {
        if (!this.whoseCoveredGroup.controls.spouse) {
            this.whoseCoveredGroup.addControl('spouse', new FormGroup({}));
        }

        this.spouseConfig.controls.forEach(config => {
            this.formControlService.addControlToFormGroup(this.whoseCoveredGroup.controls.spouse, config, persistValue.spouse || {});
        });

        this.spouseDateOfBirth = getValue(this.whoseCoveredGroup, 'controls.spouse.controls.date_of_birth');
    }

    public get multipleDependents() {
        return getValue(this.whoseCoveredValue, 'dependents.length') > 1;
    }

    removeDependent(ind) {
        if (this.whoseCoveredGroup.controls.dependents instanceof FormArray) {
            this.whoseCoveredGroup.controls.dependents.removeAt(ind);
        }

        this.dependentVal = this.whoseCoveredValue.dependents;
    }

    onChange(event) {
        const whosCovered = event.target.value;
        this.setupForms(whosCovered);
        this.whoseCoveredSelected = whosCovered;
        this.dependentVal = this.whoseCoveredValue.dependents;
        this.childOnlyVal = this.whoseCoveredValue.child_only;

        this.cdr.detectChanges();
    }

    prefillForm() {
        if (
            this.whoseCoveredGroup.controls &&
            this.whoseCoveredGroup.controls.whose_covered &&
            this.whoseCoveredGroup.controls.whose_covered.value
        ) {
            this.onChange({ target: { value: this.whoseCoveredGroup.controls.whose_covered.value } });
            this.cdr.detectChanges();
        } else {
            this.formCreated.emit();
        }
    }

    setupForms(whosCovered) {
        const persistValue = this.patchedValue || cloneObject(this.whoseCoveredValue || {});

        this.formControlService.removeAllControls(this.whoseCoveredGroup, { whose_covered: true });
        switch (whosCovered) {
            case 'subscriber':
                this.addSubscriber(persistValue);
                break;
            case 'spouse':
                this.addSubscriber(persistValue);
                this.addSpouse(persistValue);
                break;
            case 'parent':
                this.addSubscriber(persistValue);
                this.addDependent(persistValue);
                break;
            case 'family':
                this.addSubscriber(persistValue);
                this.addSpouse(persistValue);
                this.addDependent(persistValue);
                break;
            case 'child_only':
                const childLimit = getValue(this.childOnlyConfig, 'childLimit');
                this.addChildOnly(persistValue, childLimit);
                break;
            default:
                break;
        }

        this.patchedValue = null;
    }
}
