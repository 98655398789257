/* eslint-disable @angular-eslint/no-output-native */
import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { accordion, fade, slideRight } from '../../../animations';
import { validCXEvents, validGAEvents } from '../../../constants/analytics';
import { AnalyticsService } from '../../../services/analytics.service';
import { PlanUtilService } from '../../../services/plan-utils.service';

@Component({
    selector: 'plan-card',
    templateUrl: './plan-card.component.html',
    styleUrls: ['./plan-card.component.scss'],
    animations: [fade, slideRight, accordion],
})
export class PlanCardComponent implements OnInit, OnChanges {
    @Input() plan;
    @Input() planDetailConfigs: any;
    @Input() canCompare = true;
    @Input() isCompareDisabled = false;
    @Input() isSelectDisabled = false;
    @Input() hideSelection;
    @Input() showActions = true;
    @Input() showPrimaryBenefits = true;
    @Input() onlyPrice;

    @Output('select') select = new EventEmitter<any>();
    @Output('compare') compare = new EventEmitter<any>();

    showLinks = false;
    showDetails = false;
    CXContext;

    constructor(public analyticsService: AnalyticsService, public plansService: PlanUtilService) {}

    ngOnInit() {
        this.CXContext = {
            plan_type: this.plan.plan_type,
            metal_tier: this.plan.metal_tier,
            max_out_of_pocket: this.plan.max_out_of_pocket,
            deductible: this.plan.deductible,
            external_id: this.plan.external_id,
            display_name: this.plan.display_name,
        };
    }

    ngOnChanges(changes) {
        if ('plan' in changes && this.plan) {
            this.plan = this.plansService.formatBenefitsOnPlan(this.plan);
        }
    }

    toggleSelection() {
        if (this.plan.selected) {
            this.analyticsService.dispatchAnalytics(
                {
                    GAKey: validGAEvents['plan_removed-from-cart'],
                    CXKey: validCXEvents['plan_removed-from-cart'],
                },
                undefined,
                this.CXContext
            );
        } else {
            this.analyticsService.dispatchAnalytics(
                {
                    GAKey: validGAEvents['plan_added-to-cart'],
                    CXKey: validCXEvents['plan_added-to-cart'],
                },
                undefined,
                this.CXContext
            );
        }

        this.select.emit(this.plan);
    }

    toggleComparison() {
        this.compare.emit(this.plan);
    }

    toggleDetails() {
        this.showDetails = !this.showDetails;

        // dispatch analytics
        if (this.showDetails) {
            this.analyticsService.dispatchAnalytics(
                {
                    GAKey: validGAEvents['plan-details_viewed'],
                    CXKey: validCXEvents['plan-details_viewed'],
                },
                undefined,
                this.CXContext
            );
        }
    }
}
