import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule, FormControlModule, ButtonModule, TableModule, DatagridModule, PaginatorModule } from '@zipari/design-system';
import { ProfileSearchModalComponent } from './profile-search-modal.component';

@NgModule({
    declarations: [ProfileSearchModalComponent],
    imports: [CommonModule, ModalModule, FormControlModule, ButtonModule, TableModule, DatagridModule, PaginatorModule],
    exports: [ProfileSearchModalComponent],
})
export class ProfileSearchModalModule {}
