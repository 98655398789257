import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { deepCompare } from '@zipari/web-utils';

export function initControlPanelForm(form: FormGroup, resetForm: FormGroup, config: any) {
    if (!!config) {
        buildFormFromJSON(form, config);
        buildFormFromJSON(resetForm, config);

        form.markAsPristine();
    }
}

export function resetControlPanelForm(form: FormGroup, resetForm: FormGroup) {
    form.setValue(resetForm.value);
    form.markAsPristine();
}

export function buildFormFromJSON(form: FormGroup, config: any) {
    for (const key in config) {
        if (config.hasOwnProperty(key)) {
            addConfigObjectToForm(config[key], form, key);
        }
    }
}

export function addConfigObjectToForm(config, form, key = null) {
    if (typeof config === 'object' && !Array.isArray(config)) {
        const formGroup = new FormGroup({});

        !!key ? form.addControl(key, formGroup) : form.push(formGroup);

        buildFormFromJSON(formGroup, config);
    } else if (typeof config === 'object' && Array.isArray(config)) {
        const formArray = new FormArray([]);

        !!key ? form.addControl(key, formArray) : form.push(formArray);

        config.forEach(element => {
            addConfigObjectToForm(element, formArray);
        });
    } else {
        const formControl = new FormControl(config);

        !!key ? form.addControl(key, formControl) : form.push(formControl);
    }
}

export function getFormOverrides(updated, initial) {
    const isObject = x => Object(x) === x;
    const isArray = Array.isArray;

    const overrides = (newValues = {}, oldValues = {}) =>
        Object.entries(newValues)
            .map(([key, value]) => {
                if (isArray(oldValues) && isArray(newValues)) {
                    if (deepCompare(oldValues, newValues)) {
                        return [key, {}];
                    } else {
                        return [key, value];
                    }
                } else if (isObject(value) && isObject(oldValues[key])) {
                    return [key, overrides(value, oldValues[key])];
                } else {
                    return !!value
                        ? oldValues[key] !== value
                            ? [key, value]
                            : [key, {}]
                        : oldValues[key] === value
                        ? [key, {}]
                        : [key, value];
                }
            })
            .filter(([key, value]) => (!!value && Object.keys(value).length !== 0) || (!value && value === ''))
            .reduce((object, [key, value]) => ((object[key] = value), object), isArray(newValues) && isArray(oldValues) ? [] : {});

    return overrides(updated, initial);
}
