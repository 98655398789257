import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ResultsModule } from 'src/app/icx/shared/modules/results/results.module';

import { ButtonModule, TableModule, DatagridModule, PaginatorModule, FormGroupModule } from '@zipari/design-system';

import { ReferralsComponent } from './referrals.component';

@NgModule({
    declarations: [ReferralsComponent],
    imports: [CommonModule, ButtonModule, TableModule, ResultsModule, FormGroupModule, DatagridModule, PaginatorModule],
})
export class ReferralsModule {}
