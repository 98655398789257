/* eslint-disable @angular-eslint/no-input-rename */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Column2 } from '../../../models/column.model';
import { ZipTable2Service } from '../../../services/zip-table2.service';
import { formatTypes } from '../../../../../services/formatting.service';

@Component({
    selector: 'zip-table-generic-cell-2',
    templateUrl: './generic-cell.component.html',
})
export class GenericCellComponent {
    @Output() cellClicked: EventEmitter<any> = new EventEmitter<any>();
    @Input() column: Column2;

    @Input('data')
    set data(data) {
        this._cellValue = this.ztService.getValueIncludingFormat(data, this.column);
        this._context = { col: this.column, val: this._cellValue, row: data };
    }

    _context;
    _cellValue = '';
    cellFormats = formatTypes;

    constructor(public ztService: ZipTable2Service) {}

    ngOnInit() {}
}
