/* eslint-disable @angular-eslint/no-input-rename */
import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, AbstractControl } from '@angular/forms';
import { FormControlService, FormControlValidatorsService } from '@zipari/design-system';
import { map, takeUntil } from 'rxjs/operators';

import { BaseCVAComponent } from '../base-cva.component';

import { configConstants, ssnControls } from './ssnConstants';

@Component({
    selector: 'ssn-multi-field',
    templateUrl: './ssn-multi-field.component.html',
    styleUrls: ['./ssn-multi-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SsnMultiFieldComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SsnMultiFieldComponent),
            multi: true,
        },
    ],
})
export class SsnMultiFieldComponent extends BaseCVAComponent implements OnInit {
    @Input('config') _config;
    @Input('group') parentForm: FormGroup;

    config; // merged configs
    ssnControls = ssnControls; // constants

    get parentControl() {
        // full ssn control
        return this.parentForm.get(this.config.prop);
    }

    get required() {
        return this.config.validators && this.config.validators.includes('required');
    }

    get errors() {
        return this.parentControl.errors && this.parentControl.dirty;
    }

    constructor(private formControlService: FormControlService, private formControlValidatorsService: FormControlValidatorsService) {
        super();
    }

    ngOnInit() {
        this.setConfig();
        this.buildForm();
    }

    /** Merge constants w/ config, use both validators */
    setConfig() {
        this.config = {
            ...configConstants,
            ...this._config,
            validators: [...configConstants.validators, ...this._config.validators],
        };
    }

    buildForm() {
        this.formGroup = new FormGroup({});
        this.ssnControls.forEach(ctrl => {
            this.formControlService.addControlToFormGroup(this.formGroup, ctrl);
        });
    }

    /**
     * splice string at configured indices & parse into 3 section form for display
     * @param fn
     */
    writeValue(value) {
        if (value) {
            const ssnFormValues = this.ssnControls.reduce((ssnValueAcc, ssnCtrl) => {
                ssnValueAcc[ssnCtrl.prop] = value.slice(...ssnCtrl.slice);

                return ssnValueAcc;
            }, {});

            this.formGroup.patchValue(ssnFormValues);
        }
    }

    /**
     * on value change
     * take ssn form group object (3 separate ssn controls)
     * reduce to single ssn string to be used as control value
     * @param fn
     */
    registerOnChange(fn) {
        this.formGroup.valueChanges
            .pipe(
                takeUntil(this.destroy),
                map(val => Object.keys(val).reduce((ssn, key) => `${ssn}${val[key]}`, ''))
            )
            .subscribe(fn);
    }

    validate(control: AbstractControl): ValidationErrors | null {
        const validators = this.formControlValidatorsService.getFormControlValidators(this.config, this.parentForm);
        const errorArr = validators.reduce((acc, curr) => {
            acc.push(curr(control));

            return acc;
        }, []);

        return errorArr.some(err => err) ? errorArr : null;
    }
}
