import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CXCaller } from 'zipari-cx/dist';

import { cloneObject } from '@zipari/web-utils';
import {
    AnalyticsContext,
    CXCaptureConfig,
    GAConfig,
    PageCXEventMap,
    ProductPrefixes,
    validCXEvents,
    validGAEvents,
    workflowContext,
} from '../constants/analytics';

import { AuthService } from './auth.service';
import { ConfigService } from './config.service';
import { GoogleAnalyticsService } from './google-analytics.service';

const { version } = require('../../../../package.json');

export class UTM_PARAMS {
    utm_source?: string = '';
    utm_medium?: string = '';
    utm_campaign?: string = '';
    utm_term?: string = '';
    utm_content?: string = '';

    constructor(params) {
        const keys = Object.keys(this);

        keys.forEach((key) => {
            if (params[key]) {
                this[key] = params[key];
            } else {
                delete this[key];
            }
        });
    }
}

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    CXCaller: CXCaller;

    CX_Config: CXCaptureConfig;
    GA_Config: GAConfig;

    workflowContext: workflowContext;

    constructor(
        public googleAnalyticsService: GoogleAnalyticsService,
        public configService: ConfigService,
        public authService: AuthService,
        public http: HttpClient
    ) {
        const globalConfig = this.configService.getPageConfig<any>('global');

        if (globalConfig?.analytics) {
            if (globalConfig.analytics.cx_capture && this.checkForCXCaptureConfigs(globalConfig.analytics.cx_capture)) {
                this.CX_Config = globalConfig.analytics.cx_capture;
                this.CXCaller = new CXCaller(
                    { endpoint: this.CX_Config.endpoint },
                    { source_system_name: this.CX_Config.source_system_name }
                );
            } else {
                console.warn('No CX Capture configuration provided');
            }

            if (globalConfig.analytics.google_analytics) {
                this.GA_Config = globalConfig.analytics.google_analytics;
            } else {
                console.warn('No Google analytics configuration provided');
            }
        } else {
            console.warn('No analytics configuration provided');
        }
    }

    private checkForCXCaptureConfigs(config: CXCaptureConfig) {
        const newConfig = new CXCaptureConfig(config);
        const validConfigs = Object.keys(newConfig);

        let valid = true;

        validConfigs.forEach((key) => {
            if (!config[key]) {
                console.warn(`${key} not provided in config`);

                valid = false;
            }
        });

        return valid;
    }

    private formatBrowserContext() {
        const context = {};

        context['timestamp'] = new Date().toISOString();

        // attempt to retrieve timezone
        try {
            context['timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
        } catch (err) {
            // intentionally swallow the timezone catch if we can't retrieve the timezone
        }

        context['page'] = Object.assign({
            url: window.location.href,
        });

        context['app'] = {
            name: this.configService.app,
            version: version,
        };

        return context;
    }

    private formatBody(name: validCXEvents | validGAEvents, consumer_id: string | number, dictionary_attributes: any) {
        let result = {};

        try {
            const authentication_flag = !!this.authService.loggedInUser;
            if (!consumer_id) {
                if (authentication_flag) {
                    consumer_id =
                        this.authService.loggedInUser.app_user_data.member_id ||
                        this.authService.loggedInUser.app_user_data.user_name ||
                        this.authService.loggedInUser.app_user_data.broker_id ||
                        null;
                } else {
                    consumer_id = this.workflowContext ? this.workflowContext.webuser_id : null;
                }
            }
            const session_id = this.workflowContext ? this.workflowContext.web_session_id : null;
            const source_system_name = this.CX_Config.source_system_name;
            const context = this.formatBrowserContext();

            result = {
                name: `${this.determineProductPrefix(this.configService.app)}${name}`,
                consumer_id,
                source_system_name,
                authentication_flag,
                dictionary_attributes,
            };

            if (context) {
                result['context'] = context;
            }

            if (session_id) {
                result['session_id'] = session_id;
            }
        } catch (err) {
            console.error(err);
        }

        return result;
    }

    private sendCXEvent(event_key: validCXEvents, consumer_id: string | number, dictionary_attributes: any) {
        const body: any = this.formatBody(event_key, consumer_id, dictionary_attributes);

        this.http
            .post('api/cx/capture/events/', {
                payload: body,
                key: `${this.determineProductPrefix(this.configService.app)}${event_key}`,
            })
            .subscribe();
    }

    private sendGAEvent(key: validGAEvents, dictionary_attributes) {
        const body = {
            ...dictionary_attributes,
            context: this.formatBrowserContext(),
        };

        // handles mapping key to a custom google analytics key required by client
        const mappedKey = this.GA_Config.mapping && this.GA_Config[key] ? this.GA_Config[key] : key;

        this.googleAnalyticsService.emitGAEvent(mappedKey, body);
    }

    /** A product prefix is used to indicate to cx capture team which product is being used...
     * our code generically utilizes these keys so that we do not have to concern ourselves
     * with adding the product prefix within our code */
    private determineProductPrefix(product) {
        let productPrefix = '';

        if (ProductPrefixes[product]) {
            productPrefix = ProductPrefixes[product];
        } else {
            console.warn('No configuration for product');
        }

        return productPrefix;
    }

    /**
     *
     * @param consumer_id: optional, send member_id for consumer or undefined for CSR info
     */
    public dispatchAnalytics(keys: AnalyticsContext, consumer_id?: string | number, dictionary_attributes: any = {}) {
        if (keys) {
            if (keys.CXKey && this.CX_Config) {
                this.sendCXEvent(keys.CXKey, consumer_id, dictionary_attributes);
            }
            if (keys.GAKey && this.GA_Config) {
                if (typeof ga !== 'undefined' || typeof gtag !== 'undefined') {
                    this.sendGAEvent(keys.GAKey, dictionary_attributes);
                } else {
                    console.warn('Trying to call google analytics without ga available. Please provide google analytics tag');
                }
            }
        }
    }

    public setWorkflowContext(context: workflowContext) {
        this.workflowContext = context;
    }

    retrieveUTMParamsFromUrl(params = {}): UTM_PARAMS {
        return new UTM_PARAMS(cloneObject(params));
    }

    public sendSSOCxEvent(memberId: string): void {
        const dictionaryAttributeValue = this.setDictionaryAttributeValue();

        if (dictionaryAttributeValue) {
            this.dispatchAnalytics({ CXKey: validCXEvents.Sso_initiated }, memberId, {
                SSO_initiated: dictionaryAttributeValue,
            });
        }
    }

    private setDictionaryAttributeValue(): string {
        const CXkey = [...PageCXEventMap].find(([_, val]) => val.includes(this.configService.activePageName))?.[0];

        return CXkey ? CXkey : null;
    }
}
