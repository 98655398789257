import { OverlayModule } from '@angular/cdk/overlay';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';

import {
    AddressPipe,
    BuildQueryPipe,
    DoesListHaveValuesPipe,
    EqualityFilterPipe,
    FullNamePipe,
    GetValuePipe,
    MaskPipe,
    PhoneNumberPipe,
    PipesModule,
    ReadMorePipe,
    SafeNumberPipe,
    StringBuilderPipe,
} from '@zipari/web-utils';

import { RippleModule } from '@zipari/design-system';
import { OktaAuthModule, OKTA_CONFIG } from '@okta/okta-angular';
import { environment } from '../environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ConfirmationModalComponent } from './shared/components/confirmation-modal/confirmation-modal.component';

import { CoreModule } from './shared/core.module';
import { PipeModule } from './shared/pipes/pipe.module';
import { TimeoutModalModule } from './shared/modules/timeout-modal/timeout-modal.module';
import { AuthService, ConfigService } from './shared/services';
import { AnalyticsService } from './shared/services/analytics.service';
import { SentryErrorHandlerService } from './shared/services/sentry.service';
import { ConfigApiResponse } from './shared/services/config.service';

@NgModule({
    declarations: [AppComponent, ConfirmationModalComponent],
    imports: [
        OktaAuthModule,
        AppRoutingModule,
        RouterModule,
        BrowserModule,
        BrowserAnimationsModule,
        OverlayModule,
        PipeModule,
        CoreModule.forRoot(),
        PipesModule,
        NgIdleKeepaliveModule.forRoot(),
        TimeoutModalModule,
        RippleModule,
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: resolveConfigUserData,
            deps: [ConfigService, AuthService],
            multi: true,
        },
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandlerService,
        },
        {
            provide: OKTA_CONFIG,
            useFactory: (configService: ConfigService) => {
                const authConfig: any = configService.getControlPanelConfig('auth');
                let oktaConfig: any;
                if (authConfig) {
                    oktaConfig = authConfig.okta;
                }

                return {
                    issuer: oktaConfig.issuer,
                    clientId: oktaConfig.clientId,
                    redirectUri: oktaConfig.redirectUri,
                    postLogoutRedirectUri: oktaConfig.postLogoutRedirectUri,
                    pkce: oktaConfig.pkce,
                    responseType: oktaConfig.responseType,
                    responseMode: oktaConfig.responseMode,
                };
            },
            deps: [ConfigService],
        },
        Title,
        AnalyticsService,
        StringBuilderPipe,
        AddressPipe,
        GetValuePipe,
        EqualityFilterPipe,
        BuildQueryPipe,
        DoesListHaveValuesPipe,
        FullNamePipe,
        PhoneNumberPipe,
        MaskPipe,
        ReadMorePipe,
        SafeNumberPipe,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}

export function resolveConfigUserData(configService: ConfigService, authService: AuthService) {
    let configPromise: Promise<ConfigApiResponse>;

    const useLocalConfig = environment['configs']['useLocalConfig'];

    if (useLocalConfig) {
        configPromise = configService.initializeLocalConfig();
    } else {
        configPromise = configService.initializeAPIConfig(environment['app'], {
            makeAPICall: true,
        });
    }
    const userPromise = environment['configs']['ignoreAuth'] ? Promise.resolve({}) : authService.getUser(environment['configs']);

    return () =>
        // Ensures logged in user set only after config and user data fetched
        Promise.allSettled([configPromise, userPromise]).then(([configResponse, userResponse]: PromiseSettledResult<any>[]) => {
            if (configResponse.status === 'fulfilled') {
                authService.setAppConfig(configResponse.value);
            }

            if (userResponse.status === 'fulfilled') {
                if (!environment['configs']['ignoreAuth']) {
                    authService.setLoggedInUser(userResponse.value, environment['configs']);
                }
            }
        });
}
