import { Component, OnInit } from '@angular/core';
import { APIService, ConfigService } from 'src/app/shared/services';
import { FormControlValidatorsService, PaginatorConfiguration, Datagrid, AllControlsConfiguration } from '@zipari/design-system';
import { TableConfig } from '@zipari/design-system/lib/chords/table/models';
import { FormGroup, FormControl } from '@angular/forms';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { validCXEvents, validSSOCXEventDictonaryValue } from '../../../../shared/constants/analytics';
import { PaginatorService } from '../../../shared/services/paginator.service';
import { Paginator } from '../../../shared/constants/paginator';
import { WindowService } from '@zipari/web-services';
import { ClaimsService } from '../../../shared/services/claims.service';

export interface RequestBody {
    member_id: string;
    appealandgrievance_number?: string;
    subscriber_id?: string;
    appealandgrievance_status?: string[];
    appealandgrievance_category?: string[];
    appealandgrievance_category_reference_number?: string;
    appealandgrievance_received_date_gte?: string;
    appealandgrievance_received__date_lte?: string;
    policy_id?: string;
}

export interface AppealsConfig {
    header: string;
    endpoint: string;
    paginator: number;
    filters: { controls: FormControlConfiguration[] };
    datagrid: Datagrid;
    sorting: {
        key: string;
        order: string;
    };
    table: TableConfig;
    pagingConfig: PaginatorConfiguration;
}

export interface LabelValueConfig {
    value: string;
    label: string;
}
export interface FormControlConfiguration {
    type: string;
    label: string;
    prop: string;
    options: LabelValueConfig[];
}

@Component({
    selector: 'appeals',
    templateUrl: './appeals.component.html',
    styleUrls: ['./appeals.component.scss'],
})
export class AppealsComponent implements OnInit {
    public config: AppealsConfig;
    public appealsFormGroup: FormGroup;
    public filters: any;
    public appeals: any[] = [];
    public filteredAppeals: any[] = [];
    public sortingElement: string = '';
    public memberId: string = '';
    public pagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 1,
        showingEnd: 1,
        totalResults: 0,
        currentPage: 1,
    };
    public requestBody = {} as RequestBody;

    constructor(
        private configService: ConfigService,
        private validatorService: FormControlValidatorsService,
        private panelService: SidePanelService,
        private api: APIService,
        private router: Router,
        private route: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private paginatorService: PaginatorService,
        private claimsService: ClaimsService,
        private window: WindowService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit(): void {
        this.config = this.configService.getControlPanelConfig('appeals');
        this.memberId = this.route.snapshot.params.member;
        if (this.config?.filters) {
            this.setupFilters(this.config?.filters.controls);
        }
        this.getAppeals();
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.AppealGrievanceList_viewed }, this.memberId);
    }

    setupFilters(filters: FormControlConfiguration[]) {
        this.appealsFormGroup = new FormGroup({});
        this.filters = filters.map(this.createFormControl.bind(this));
    }

    createFormControl(config: AllControlsConfiguration) {
        const validators = this.validatorService.getFormControlValidators(config);
        const control = new FormControl('', { validators });
        const newFormControl = Object.assign(config, {
            control: control,
        });

        this.appealsFormGroup.addControl(newFormControl.prop, control);

        return newFormControl;
    }

    getAppeals() {
        this.appeals = [];
        const query = this.buildQueryParams();
        this.buildRequestBody();

        // requestBody should be sent as request body in post call
        this.api.post(query, this.requestBody).subscribe(
            (res) => {
                this.appeals = res.results;
                this.filteredAppeals = Object.assign([], this.appeals);
                this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, res.count, this.config.paginator);
            },
            (err) => {
                this.appeals = [];
            }
        );
    }

    buildQueryParams() {
        let queryString = `${this.config?.endpoint}/?`;

        queryString += `page=${this.pagingConfig.currentPage}&`;
        if (this.config?.paginator) {
            queryString += `page_size=${this.config?.paginator}&`;
        }

        return queryString.slice(0, -1);
    }

    buildRequestBody() {
        this.requestBody = {
            member_id: this.memberId,
        };
        if (this.getSortOrder()) {
            this.requestBody['ordering'] = this.getSortOrder();
        }
        const start_date = 'appealandgrievance_received_date_gte';
        const end_date = 'appealandgrievance_received__date_lte';

        if (this.appealsFormGroup?.value) {
            for (const key of Object.keys(this.appealsFormGroup?.value)) {
                if (key === 'dates' && (this.appealsFormGroup?.value[key].start || this.appealsFormGroup?.value[key].end)) {
                    this.requestBody[start_date] = this.appealsFormGroup?.value[key].start;
                    this.requestBody[end_date] = this.appealsFormGroup?.value[key].end;
                } else if (key !== 'dates' && this.appealsFormGroup?.value[key]) {
                    const trimmedValue = this.appealsFormGroup?.value[key].trim();
                    if (key === 'appealandgrievance_status' || key === 'appealandgrievance_category') {
                        this.requestBody[key] = [];
                        this.requestBody[key].push(trimmedValue);
                    } else {
                        this.requestBody[key] = trimmedValue;
                    }
                }
            }
        }
    }

    getSortOrder(): string {
        var sortOrder;
        const configSorting = this.config?.sorting;
        if (this.sortingElement) {
            sortOrder = this.sortingElement;
        } else if (configSorting?.key && configSorting?.order) {
            sortOrder = configSorting.order === 'asc' ? configSorting.key : `-${configSorting.key}`;
        }
        return sortOrder;
    }

    handleKeySearch(event) {
        if (event.keyCode === 13) {
            this.getAppeals();
        }
    }

    clearForm(): void {
        this.appealsFormGroup.reset();
        this.setCurrentPage(Paginator.FirstPage);
    }

    submitForm(): void {
        this.setCurrentPage(Paginator.FirstPage);
    }

    openSSODetails(target): void {
        let queryParams = this.claimsService.getSSOQueryParams(this.config?.datagrid, target, this.route.snapshot.params?.member);
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Sso_initiated }, this.route.snapshot.params?.member, {
            SSO_initiated: validSSOCXEventDictonaryValue.AppealAndGrievance,
        });
        if (target?.route) {
            const ssoUrl = `${target.route}?${queryParams}`;
            this.window.nativeWindow.open(ssoUrl, '_blank');
        }
    }

    setCurrentPage(page: number): void {
        this.pagingConfig.currentPage = page;

        this.pagingConfig.showingStart = page === 1 ? 1 : this.config.paginator * (page - 1) + 1;

        this.pagingConfig.showingEnd =
            page === this.pagingConfig.totalPages ? this.pagingConfig.totalResults : this.config.paginator * page;

        this.getAppeals();
    }

    navigateToDetails(event) {
        const auth_id = event.id;
    }

    downloadAppeals(event) {
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.AppealGrievanceList_downloaded }, this.memberId);
        window.open(`/api/member-360/documents/${event.row.document_ids[0]}/`);
    }

    sortTable(element) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.getAppeals();
    }
}
