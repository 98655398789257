import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { FormattingService, PaginatorConfiguration, ErrorEmptyConfig } from '@zipari/design-system';
import { FormManagerService } from 'src/app/icx/shared/services/form-manager.service';
import { PaginatorService } from 'src/app/icx/shared/services/paginator.service';
import { httpErrorToErrorEmpty, WindowService } from '@zipari/web-services';
import Claim from '../../../../shared/models/shared/Claim.model';
import { APIService, ConfigService } from '../../../../shared/services';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { validCXEvents, validSSOCXEventDictonaryValue } from '../../../../shared/constants/analytics';
import { SidePanelService } from './../../../shared/services/side-panel.service';
import { GenericType } from './../../../shared/services/icx.service';
import { APIResponse } from './../../../../shared/services/api.service';
import { DateRange, SSOLinkValue } from './claims.constant';
import { SearchFilterService } from '../../../shared/services/search-filter.service';
import { camelCase, camelToSnakeCase } from '../../../../shared/modules/zip-busy/utils';
import { ClaimsService } from '../../../shared/services/claims.service';

export interface Filter extends FormControl {
    format?: string;
    type: string;
    label: string;
    prop?: string;
    value: string;
}

@Component({
    selector: 'claims',
    templateUrl: './claims.component.html',
    styleUrls: ['./claims.component.scss'],
})
export class ClaimsComponent implements OnInit {
    // TODO give this a type
    pageConfigs: GenericType;
    public pagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    claims: Claim[];
    filterClaims: Claim[] = [];
    endpoint: string;
    claimFilters: any[];
    queryParams: Params;
    sortingElement: string = '';
    formGroup: FormGroup;
    panelFilterParams: string = '';
    public isLoadingData: boolean = true;
    showErrorScreen: boolean = false;
    errorConfig: ErrorEmptyConfig;

    constructor(
        private api: APIService,
        private configService: ConfigService,
        private route: ActivatedRoute,
        private analyticsService: AnalyticsService,
        private panelService: SidePanelService,
        private formattingService: FormattingService,
        private router: Router,
        private formManagerService: FormManagerService,
        private paginatorService: PaginatorService,
        public searchFilterService: SearchFilterService,
        private claimsService: ClaimsService,
        private window: WindowService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.pageConfigs = this.configService.getControlPanelConfig(this.route.snapshot.data.pageName);

        this.endpoint = this.pageConfigs?.endpoint;
        this.formGroup = this.formManagerService.populateFormGroup(this.pageConfigs?.pageFilters.controls);
        if (this.formGroup?.controls['exclude_negative_adjusted_claims']) {
            this.formGroup.controls['exclude_negative_adjusted_claims'].setValue(true);
        }
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.ClaimsList_viewed }, this.route.snapshot.params.member);
        if (this.searchFilterService?.searchFilterValue && this.pageConfigs?.retainClaimSearchFilters) {
            this.formGroup.patchValue(camelToSnakeCase(this.searchFilterService.searchFilterValue));
        }
        this.getClaims();
    }

    updateFormServiceDate() {
        if (this.searchFilterService?.searchFilterValue && this.pageConfigs?.retainClaimSearchFilters) {
            this.formGroup.get('service_date')?.patchValue({
                end: this.searchFilterService.searchFilterValue?.serviceDate?.end,
                start: this.searchFilterService.searchFilterValue?.serviceDate?.start,
            });
        }
    }

    addIcons() {
        setTimeout(() => {
            const docs_listing = document.querySelectorAll('.datagrid__row .datagrid__cell:last-child p.ui-description');
            const icon_tags = document.querySelectorAll('.datagrid__row .datagrid__cell:last-child p i');
            // Type shouldn't be changed as this is HTML selector
            if (icon_tags.length < 1) {
                Array.from(docs_listing).forEach((listing: GenericType) => {
                    const docId = listing?.innerText?.split(': ')[1]?.split(' ')[0]?.trim();
                    if (docId === 'N/A') {
                        return;
                    }
                    const icon = document.createElement('i');
                    icon.innerHTML = 'download';
                    icon.className = 'icon';
                    icon.style.color = '#2567ed';
                    icon.style.cursor = 'pointer';
                    icon.addEventListener('click', () => {
                        if (docId !== 'N/A') {
                            this.analyticsService.dispatchAnalytics(
                                { CXKey: validCXEvents.ClaimDoc_downloaded },
                                this.route.snapshot.params.member
                            );
                            window.open(`/api/member-360/documents/${docId}/`);
                        }
                    });
                    listing.appendChild(icon);
                });
            }
        }, 300);
    }

    submitForm() {
        this.setCurrentPage(1);
    }

    getClaims() {
        this.isLoadingData = true;
        const query = this.buildQueryParams();

        this.api.get(query).subscribe(
            (res: APIResponse<Claim>) => {
                if (res?.results) {
                    this.showErrorScreen = false;
                    this.filterClaims = res.results.map((claim: Claim) => this.formatClaim(claim));
                    this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, res.count, this.pageConfigs?.paginator);
                    this.addIcons();
                    this.isLoadingData = false;
                }
            },
            (err) => {
                this.isLoadingData = false;
                this.showErrorScreen = true;
                this.errorConfig = httpErrorToErrorEmpty(err);
                this.errorConfig.title = 'Claim Information not available';
            }
        );
    }

    formatClaim(claim: Claim): Claim {
        const newClaim = Object.assign({}, claim);

        if (newClaim.provider && newClaim.provider.name) {
            newClaim.provider_name = [
                newClaim.provider.name.prefix,
                newClaim.provider.name.first_name,
                newClaim.provider.name.middle_name,
                newClaim.provider.name.last_name,
                newClaim.provider.name.suffix,
            ].join(' ');
        }
        if (newClaim.patient && newClaim.patient.name) {
            newClaim.patient_full_name = [
                newClaim.patient.name.prefix,
                newClaim.patient.name.first_name,
                newClaim.patient.name.middle_name,
                newClaim.patient.name.last_name,
                newClaim.patient.name.suffix,
            ].join(' ');
        }
        newClaim.patient.gender_relation = `${newClaim.patient.gender} / ${newClaim.patient.relationship_to_subscriber}`;
        newClaim.service_date = this.formattingService.restructureValueBasedOnFormat(newClaim.service_date, { format: 'DATE' });
        newClaim.service_dates = `${this.formattingService.restructureValueBasedOnFormat(newClaim.service_date, { format: 'DATE' })}${
            newClaim.service_end_date
                ? ` - ${this.formattingService.restructureValueBasedOnFormat(newClaim.service_end_date, { format: 'DATE' })}`
                : ''
        }`;

        newClaim.document_ids = [
            `EOB: ${newClaim.eob_document_id || 'N/A'}`,
            `DCN: ${newClaim.document_control_number || 'N/A'}`,
            `NOP: ${newClaim.payment_notice_document_id || 'N/A'}`,
        ];

        newClaim.document_id = `EOB: ${newClaim.eob_document_id || 'N/A'}`;

        newClaim.sso_link = SSOLinkValue;
        return newClaim;
    }

    buildQueryParams(): string {
        let queryString = `${this.endpoint}/?member_id=${this.route.snapshot.params.member}&`;
        const searchParamsUsed: string[] = [];
        let initialSort = '';
        this.searchFilterService.searchFilterValue = this.pageConfigs?.retainClaimSearchFilters ? camelCase(this.formGroup.value) : null;

        for (const key of Object.keys(this.formGroup.value)) {
            if (typeof this.formGroup.value[key] === 'boolean' || this.formGroup.value[key]) {
                if (typeof this.formGroup.value[key] === 'object') {
                    if (this.formGroup.value[key].start || this.formGroup.value[key].end)
                        queryString += `${DateRange.service_from_date}=${this.formGroup.value[key].start}&${DateRange.service_to_date}=${this.formGroup.value[key].end}&`;
                } else {
                    const trimmedValue =
                        typeof this.formGroup.value[key] === 'string' ? this.formGroup.value[key].trim() : this.formGroup.value[key];
                    queryString += `${key}=${trimmedValue}&`;
                }
            }
        }

        queryString += `page=${this.pagingConfig.currentPage}&`;
        if (this.pageConfigs?.paginator) {
            queryString += `page_size=${this.pageConfigs?.paginator}&`;
        }

        if (this.pageConfigs?.sorting && this.pageConfigs?.sorting.key && this.pageConfigs?.sorting.order && !this.sortingElement) {
            if (this.pageConfigs?.sorting.order === 'asc') {
                initialSort = this.pageConfigs?.sorting.key;
            } else if (this.pageConfigs?.sorting.order === 'desc') {
                initialSort = `-${this.pageConfigs?.sorting.key}`;
            }
            queryString += `ordering=${initialSort}&`;
        } else if (this.sortingElement) {
            queryString += `ordering=${this.sortingElement}&`;
        }

        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Claims_searched }, this.route.snapshot.params.member, {
            document_search_keys_searched: searchParamsUsed,
        });
        if (
            this.searchFilterService?.panelFilterValue?.queryParams &&
            !this.panelFilterParams &&
            this.pageConfigs?.retainClaimSearchFilters
        ) {
            queryString += this.searchFilterService.panelFilterValue.queryParams;
        }

        if (!!this.panelFilterParams) {
            queryString += this.panelFilterParams;
        }

        return queryString.slice(0, -1);
    }

    setCurrentPage(page: number) {
        this.pagingConfig.currentPage = page;

        this.pagingConfig.showingStart = page === 1 ? 1 : this.pageConfigs?.paginator * (page - 1) + 1;

        this.pagingConfig.showingEnd =
            page === this.pagingConfig?.totalPages ? this.pagingConfig.totalResults : this.pageConfigs?.paginator * page;
        this.getClaims();
    }

    sortTable(element: string) {
        this.sortingElement = element;
        this.filterClaims = [];
        this.getClaims();
    }

    clearForm() {
        this.formGroup.reset();
        this.searchFilterService.searchFilterValue = this.pageConfigs?.retainClaimSearchFilters ? this.formGroup.value : null;
        this.filterClaims = Object.assign([], this.claims);
        this.getClaims();
    }

    clearPanelFilterParams() {
        this.panelFilterParams = '';
        this.getClaims();
    }

    handleKeySearch(event: KeyboardEvent) {
        if (event.keyCode === 13) {
            this.submitForm();
        }
    }

    openDetails(target: Claim) {
        // TODO simplify this URL
        const navigationURL = `icx/${this.route.snapshot.data.marketSegment}/${this.route.snapshot.params.member}/${
            this.pageConfigs?.resultsOptions.baseUrl
        }/${target[this.pageConfigs?.resultsOptions.targetDetailPageLocation]}`;
        this.router.navigate([navigationURL]);
    }

    openSSODetails(target): void {
        let queryParams = this.claimsService.getSSOQueryParams(this.pageConfigs?.datagrid, target, this.route.snapshot.params?.member);
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Sso_initiated }, this.route.snapshot.params?.member, {
            SSO_initiated: validSSOCXEventDictonaryValue.Claims,
        });
        if (target?.route) {
            const ssoUrl = `${target.route}?${queryParams}`;
            this.window.nativeWindow.open(ssoUrl, '_blank');
        }
    }

    updateEndpoint(queryParam: string) {
        this.pageConfigs?.retainClaimSearchFilters ? (this.searchFilterService.panelFilterValue.queryParams = queryParam) : null;
        this.panelFilterParams = queryParam;
        this.getClaims();
    }
}
