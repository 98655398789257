export const DateRange = {
    service_from_date: 'service_from_date',
    service_to_date: 'service_to_date',
};

export const SSOLinkValue = 'SSO Link Goes Here';

// will add more sso props.
export const SSOProp = {
    member_id: 'member_id',
};
