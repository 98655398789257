import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { FormGroupModule } from '../../modules';
import { MessagesComponent } from './messages.component';
import { MessageFilterComponent } from './message-filter/message-filter.component';
import { NewMessageComponent } from './new-message/new-message.component';

@NgModule({
    declarations: [MessagesComponent, MessageFilterComponent, NewMessageComponent],
    imports: [CommonModule, ButtonModule, FormControlModule, FormGroupModule],
})
export class MessagesModule {}
