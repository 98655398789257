import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { PaginatorModule } from '../../modules/paginator/paginator.module';
import { ZipBusyModule } from '../../modules/zip-busy';
import { SharedModule } from '../../shared.module';

import { FormGroupModule } from '../form-group/form-group.module';
import { MapModule } from '../map/map.module';

import { ProviderSearchComponent } from './provider-search/provider-search.component';
import { SearchBarComponent } from './search-bar/search-bar.component';

@NgModule({
    declarations: [ProviderSearchComponent, SearchBarComponent],
    exports: [ProviderSearchComponent, SearchBarComponent],
    imports: [CommonModule, ButtonModule, FormControlModule, FormGroupModule, MapModule, SharedModule, PaginatorModule, ZipBusyModule],
    providers: [],
})
export class SearchModule {}
