import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, ValidationErrors } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';

import { fade } from './../../../animations/fade';
import { BaseCVAComponent } from './../../custom-form-element/base-cva.component';

@Component({
    selector: 'conditional-toggle',
    templateUrl: './conditional-toggle.component.html',
    animations: [fade],
    styleUrls: ['./conditional-toggle.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ConditionalToggleComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => ConditionalToggleComponent),
            multi: true,
        },
    ],
})
export class ConditionalToggleComponent extends BaseCVAComponent implements OnInit, OnDestroy {
    @Input() config;
    @Input() direction;
    @Input() parentForm: FormGroup;
    toggleConfig;
    showDependents = false;

    constructor(private formControlService: FormControlService) {
        super();
    }

    ngOnInit() {
        this.toggleConfig = {
            ...this.config,
            type: 'toggle',
        };

        this.formGroup = new FormGroup({});
        this.formGroup.setParent(this.parentForm);
        this.formControlService.addControlToFormGroup(this.formGroup, this.config);

        this.formGroup.get(this.config.prop).valueChanges.subscribe(value => {
            if (this.config.invert) {
                this.showDependents = value === false;
            } else {
                this.showDependents = value;
            }
            this.addOrRemoveControls({ [this.config.prop]: this.showDependents });
        });
    }

    addOrRemoveControls(values: any) {
        const val = values[this.config.prop];

        if (val || (this.config.invert && val === false)) {
            this.config.controls.forEach(control => {
                const value = values[control.prop] || null;
                this.formControlService.addControlToFormGroup(this.formGroup, control, { [control.prop]: value });
            });
        } else {
            this.config.controls.forEach(control => {
                this.formGroup.removeControl(control.prop);
            });
        }
    }

    writeValue(values: any) {
        if (values && values[this.config.prop] !== undefined) {
            // Patch value of the toggle
            this.formGroup.patchValue({ [this.config.prop]: values[this.config.prop] }, { emitEvent: false });
            // Show and add nested controls if toggle is truthy
            if (this.config.invert) {
                this.showDependents = values[this.config.prop] === false;
            } else {
                this.showDependents = values[this.config.prop];
            }
            this.addOrRemoveControls(values);
        }
    }

    validate(control: AbstractControl): ValidationErrors | null {
        return this.formGroup.invalid ? { custom: true } : null;
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
