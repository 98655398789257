import { Injectable } from '@angular/core';
import { stringBuilder } from '@zipari/web-utils';

@Injectable({
    providedIn: 'root',
})
export class DetailService {
    constructor() {}

    cellClicked(action, context, router, actionData = null) {
        context.actionData = actionData;

        if (action.route) {
            router.navigate([stringBuilder(action.route, context)]);
        }
    }
}
