import { Component, Input, OnChanges, OnInit, Output, EventEmitter, ElementRef, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormattingService } from '@zipari/design-system';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { LinkParamPlaceholder } from '../../constants/link-placeholder';
import { ProgressBarService } from '../../services/progress-bar.service';

@Component({
    selector: 'tab-view',
    templateUrl: './tab-view.component.html',
    styleUrls: ['./tab-view.component.scss'],
})
export class TabViewComponent implements OnChanges, OnInit {
    @Input() selectedTab;
    @Input() config;
    @Input() data;
    @Input() pageLevelControls?;
    @Input() noDataConfig?;
    @Output() changeCardData: EventEmitter<string> = new EventEmitter<string>();
    @Output() pageSelected: EventEmitter<any> = new EventEmitter();

    activeTab;
    targetTableLocation;
    datagridData: Object[] = [];
    memberID: string;

    constructor(
        private formatService: FormattingService,
        private route: ActivatedRoute,
        private router: Router,
        private progressBarService: ProgressBarService,
        private analyticsService: AnalyticsService,
        private elementRef: ElementRef,
        private cdref: ChangeDetectorRef
    ) {}

    ngOnChanges(change) {
        if (!!this.config) {
            if (change.selectedTab && this.config.tabConfigs) {
                if (change.selectedTab.firstChange) {
                    this.activeTab = this.config.tabConfigs[0];
                } else {
                    this.activeTab = this.config.tabConfigs[change.selectedTab.currentValue];
                }
            }
            this.formatTabSections();
        }
        this.cdref.detectChanges();
        this.addSSoLinKClickListener();
    }

    ngOnInit() {
        this.memberID = this.route.snapshot.params.member;
        this.formatTabSections();
    }

    private formatTabSections(): void {
        this.config.tabConfigs?.forEach((tab) => {
            if (tab === this.activeTab) {
                tab.sections.forEach((section) => {
                    if (section.title && section.title.includes(LinkParamPlaceholder.MemberId) && this.memberID) {
                        section.title = section.title.replace(LinkParamPlaceholder.MemberId, this.memberID);
                    }

                    if (section.fields && section.fields.length) {
                        section.fields = this.formatFieldsData(section.fields);
                    }
                    if (section.fields && section.fields.attributes) {
                        section.fields = this.formatFieldsData(section.fields.attributes);
                    }

                    if (section.table) {
                        if (section.targetLocation) {
                            this.formatTable(section.targetLocation);
                        }
                    }

                    if (section.datagrid) {
                        if (section.targetLocation) {
                            this.datagridData = [...this.data[section.targetLocation]];
                            if (!section.pagingConfig.totalPages) {
                                section.pagingConfig.totalPages = Math.ceil(this.datagridData.length / section.pagingConfig.rowsPerPage);
                            }
                            if (!section.pagingConfig.totalResults) {
                                section.pagingConfig.totalResults = this.datagridData.length;
                            }
                        }
                    }

                    if (section.progressBars) {
                        section.progressBars = this.formatFieldsData(section.progressBars).map((config) => {
                            this.progressBarService.determineProgressDataBasedOnValue(config);

                            return config;
                        });
                    }
                });
            }
        });
    }

    formatFieldsData(fieldArr: any) {
        return fieldArr
            .map((field) => this.formatService.formatConfigValueAsTemplate(field, this.data))
            .map(
                (config) =>
                    // config.value = this.formatService.restructureValueBasedOnFormat(config.value, config);

                    config
            );
    }

    formatTable(target) {
        const locationArry = target.split('.');
        this.targetTableLocation = { ...this.data };

        locationArry.forEach((elem) => {
            this.targetTableLocation = this.targetTableLocation[elem];
        });
        this.targetTableLocation = this.targetTableLocation;
    }

    navigateToDetail(target) {
        if (this.activeTab.targetRouteObj.isExternal) {
            const url = target.row[this.activeTab.targetRouteObj.innerTargetId];
            window.open(url, '_blank');
        } else {
            const targetRoute = this.getDetailsUrl(target);
            this.router.navigate([targetRoute]);
        }
    }

    dataGridDetails(row) {
        if (this.activeTab.targetRouteObj.isExternal) {
            const url = row[this.activeTab.targetRouteObj.innerTargetId];
            window.open(url, '_blank');
        } else {
            const targetRoute = this.getDetailsUrlFromRow(row);
            this.router.navigate([targetRoute]);
        }
    }

    getDetailsUrl(target) {
        const targetUrl = this.activeTab.targetRouteObj.base;
        const targetBaseUrl = `icx/${this.activeTab.marketSegment}`;
        const memberId = this.route.snapshot.params.member;
        let targetVal = target.val;
        let detailRoute = `${targetBaseUrl}/${memberId}/${targetUrl}/`;
        if (this.activeTab.targetRouteObj.targetId) {
            detailRoute += `${this.data[this.activeTab.targetRouteObj.targetId]}/`;
        }
        if (this.activeTab.targetRouteObj.deepTarget) {
            detailRoute += `${this.activeTab.targetRouteObj.deepTarget}/`;
        }
        if (this.activeTab.targetRouteObj.deepTargetId) {
            targetVal = target.row[this.activeTab.targetRouteObj.deepTargetId];
        }
        detailRoute += `${targetVal}`;

        return detailRoute;
    }

    getDetailsUrlFromRow(row) {
        const targetUrl = this.activeTab.targetRouteObj.base;
        const targetBaseUrl = `icx/${this.activeTab.marketSegment}`;
        const memberId = this.route.snapshot.params.member;
        let targetVal = '';
        let detailRoute = `${targetBaseUrl}/${memberId}/${targetUrl}/`;
        if (this.activeTab.targetRouteObj.targetId) {
            detailRoute += `${this.data[this.activeTab.targetRouteObj.targetId]}/`;
        }
        if (this.activeTab.targetRouteObj.deepTarget) {
            detailRoute += `${this.activeTab.targetRouteObj.deepTarget}/`;
        }
        if (this.activeTab.targetRouteObj.deepTargetId) {
            targetVal = row[this.activeTab.targetRouteObj.deepTargetId];
        }
        detailRoute += `${targetVal}`;

        return detailRoute;
    }

    setCurrentPage(page: number) {
        this.pageSelected.emit({ page: page, tab: this.activeTab.tab });
    }

    updateCardData(selectedDropDownValue) {
        this.changeCardData.emit(selectedDropDownValue);
    }

    ssoLinkClicked(): void {
        this.analyticsService.sendSSOCxEvent(this.memberID);
    }

    addSSoLinKClickListener(): void {
        this.elementRef.nativeElement.querySelectorAll('.subTitle a')?.forEach((item) => {
            item.addEventListener('click', () => {
                this.ssoLinkClicked();
            });
        });
    }

    ngAfterViewInit(): void {
        this.addSSoLinKClickListener();
    }
}
