import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalConfig, ModalTypes, ModalSizes, ModalDirections, ModelAttributeConfig } from '@zipari/design-system';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import * as _ from 'lodash';
import { ConfigService } from 'src/app/shared/services';
import { BenefitService } from '../../benefits.service';
import { BenefitsServiceItem, UtilizationsAPIResponse, ViewClaimsModalData } from '../../benefits.model';
interface UtilizationsPageLevelData {
    benefitPeriod: string;
    planName: string;
    serviceName: string;
}

@Component({
    selector: 'utilizations',
    templateUrl: './utilizations.component.html',
    styleUrls: ['./utilizations.component.scss'],
})
export class UtilizationsComponent implements OnInit {
    pageConfig;
    showClaimsModal: boolean = false;
    claimsModalData: ViewClaimsModalData;
    src: string = 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-3.svg';
    illustration_text: string = 'There is no service usage information at the moment.';
    @Input() showModal: boolean;
    @Input() currentService: BenefitsServiceItem;
    utilizations: UtilizationsAPIResponse[];
    @Output() hideModal: EventEmitter<boolean> = new EventEmitter();
    public modalConfig: ModalConfig = {
        type: ModalTypes.flyout,
        size: ModalSizes.large,
        direction: ModalDirections.right,
        backdrop: true,
        clickOutside: true,
        header: {
            title: 'Service Usages',
            showClose: true,
            icon: '',
        },
        altStyle: false,
    };
    public pageLevelData: UtilizationsPageLevelData;
    public pageLevelDataConfig: ModelAttributeConfig[] = [
        {
            label: 'Benefit Period',
            value: '${benefitPeriod}',
        },
        {
            label: 'Plan Name',
            value: '${planName}',
        },
        {
            label: 'Service Name',
            value: '${serviceName}',
        },
    ];
    public serviceUsages: any = [];
    private memberId: string;
    private benefitServiceId: string;

    constructor(
        private benefitService: BenefitService,
        private analyticsService: AnalyticsService,
        private route: ActivatedRoute,
        private configService: ConfigService
    ) {}

    ngOnInit(): void {
        this.pageConfig = this.configService.getControlPanelConfig(this.route.snapshot.data['pageName']);
        this.benefitServiceId = this.route.snapshot.params.serviceId;
        this.memberId = this.route.snapshot.params.member;

        this.benefitService.getBenefitUtilizations(this.memberId, this.benefitServiceId).subscribe(
            (res) => {
                this.utilizations = res.results;
                this.modifyUtilizations();
            },
            (err) => {
                const errorContents = err?.error?.errors;
                const userError =
                    errorContents?.length && errorContents[0]?.user_error?.comments?.length && errorContents[0]?.user_error?.comments[0];

                this.illustration_text = userError || 'Unknown Error. Please try again later.';
            }
        );

        this.pageLevelData = {
            benefitPeriod: this.benefitService.selectedBenefitPeriod ? this.benefitService.selectedBenefitPeriod.label : '',
            planName: this.benefitService.selectedPlan ? this.benefitService.selectedPlan.label : '',
            serviceName: this.currentService ? this.currentService.display_name : '',
        };
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.BenenfitsUtilizations_viewed }, this.route.snapshot.params.member);
    }

    modifyUtilizations() {
        this.utilizations.forEach((usage) => {
            if (usage.place_of_service && usage.service_provider) {
                usage['accumulator_category'] = `${usage.place_of_service.display_name} - ${usage.service_provider.display_name}`;
            } else if (!usage.place_of_service && usage.service_provider) {
                usage['accumulator_category'] = `${usage.service_provider.display_name}`;
            } else if (usage.place_of_service && !usage.service_provider) {
                usage['accumulator_category'] = `${usage.place_of_service.display_name}`;
            }

            if (usage.benefit_applies_to) {
                usage['accumulator_benefit_type'] = `${usage.benefit_applies_to} - ${usage.accumulator_type ? usage.accumulator_type : ''}`;
            } else {
                usage['accumulator_benefit_type'] = usage.accumulator_type ? usage.accumulator_type : '';
            }
        });

        const serviceUsagesByCat = this.utilizations.filter((su) => su.accumulator_category != null);
        let accSection: any = {};

        if (serviceUsagesByCat.length > 0) {
            const categoryGroup = _.groupBy(serviceUsagesByCat, (su) => su.accumulator_category);
            Object.keys(categoryGroup).forEach((cg) => {
                accSection = {
                    category: cg,
                    accumulatorTypes: this.getAccumulatorTypes(categoryGroup[cg]),
                };

                this.serviceUsages.push(accSection);
            });
        }
    }

    getAccumulatorTypes(usage) {
        const typeGroup = _.groupBy(usage, (acc) => acc.accumulator_benefit_type);

        const accumulators = [];

        Object.keys(typeGroup).forEach((acc) => {
            const accumulator = {
                type: acc,
                data: typeGroup[acc],
            };

            accumulators.push(accumulator);
        });

        return accumulators;
    }

    handleCancel() {
        this.hideModal.emit(true);
    }

    openCLaimsModal(data) {
        this.showClaimsModal = true;
        this.claimsModalData = {
            accumulator_type: data[0].accumulator_type,
            accumulator_type_id: data[0].accumulator_type_id,
            accumulators: this.getFormattedAccumulators(data),
            benefit_applies_to: data[0].benefit_applies_to,
            benefit_period_id: data[0].benefit_period.id,
            benefit_service_id: data[0].benefit_service_id,
            policy_id: this.benefitService.currentPolicyId || '',
            recipient: data[0].title,
        };
    }

    getFormattedAccumulators(data) {
        const dataCopy = [...data];

        return dataCopy.map((data) => ({
            participation_level: data.participation_level,
            remaining: data.remaining,
            total: data.total,
            used: data.used,
        }));
    }

    hideClaims() {
        this.showClaimsModal = false;
    }
}
