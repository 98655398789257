import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormControlModule, ModelAttributeModule, ProgressBarModule } from '@zipari/design-system';
import { ProgressBarGridComponent } from './progress-bar-grid.component';

@NgModule({
    declarations: [ProgressBarGridComponent],
    imports: [CommonModule, FormControlModule, ModelAttributeModule, ProgressBarModule],
    exports: [ProgressBarGridComponent],
})
export class ProgressBarGridModule {}
