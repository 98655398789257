import { Component, OnInit } from '@angular/core';
import { PaginatorConfiguration, FormattingService } from '@zipari/design-system';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { ConfigService, APIService } from 'src/app/shared/services';
import { sortTabs } from 'src/app/icx/shared/utils/sorting-tabs';
import { PaginatorService } from 'src/app/icx/shared/services/paginator.service';

import { BillingPaymentsPageConfig, BillingPaymentsDatagridTab } from './billing-and-payments.model';
import { ClaimsService } from '../../../shared/services/claims.service';
import { WindowService } from '@zipari/web-services';
import { SSOLinkValue } from '../claims/claims.constant';
import { AnalyticsService } from '../../../../shared/services/analytics.service';
import { validCXEvents, validSSOCXEventDictonaryValue } from '../../../../shared/constants/analytics';

@Component({
    selector: 'billing-and-payments',
    templateUrl: './billing-and-payments.component.html',
    styleUrls: ['./billing-and-payments.component.scss'],
})
export class BillingAndPaymentsComponent implements OnInit {
    public billingPaymentsPageConfig: BillingPaymentsPageConfig;
    public tabLabels: string[] = [];
    public activeTab: BillingPaymentsDatagridTab;
    public allTabs: BillingPaymentsDatagridTab[] = [];
    public filtersForm: FormGroup = new FormGroup({});
    public pagingConfigs: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    public currentTabIndex: number = 0;
    public tabData: any[] = [];
    public isLoadingData: boolean = true;
    public isLoadingTab: boolean = true;
    public sortingElement: string = '';

    constructor(
        private route: ActivatedRoute,
        private panelService: SidePanelService,
        private configService: ConfigService,
        private apiService: APIService,
        private router: Router,
        private formattingService: FormattingService,
        private paginatorService: PaginatorService,
        private claimsService: ClaimsService,
        private window: WindowService,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit(): void {
        this.billingPaymentsPageConfig = this.configService.getControlPanelConfig('billing-payments');
        this.transformConfig();
        this.currentTabIndex = this.route.snapshot.url[0].path === 'billing' ? 0 : 1;
        this.selectedTab(this.currentTabIndex);
    }

    selectedTab(tabIndex: number) {
        this.isLoadingTab = true;
        this.isLoadingData = true;
        this.currentTabIndex = tabIndex;
        this.activeTab = this.allTabs[tabIndex];

        // reset
        this.pagingConfigs = {
            totalPages: 1,
            showingStart: 0,
            showingEnd: 0,
            totalResults: 0,
            currentPage: 1,
        };
        this.filtersForm.reset();
        this.getData();
    }

    getData() {
        if (this.currentTabIndex === 0) {
            this.getBillingData();
        } else {
            this.getPaymentData();
        }
    }
    clearFormClicked() {
        this.filtersForm.reset();
    }

    submitFormClicked() {
        this.isLoadingData = true;
        this.getData();
    }

    buildQueryParams(endpoint: string): string {
        let queryString = `${endpoint}/?member_id=${this.route.snapshot.params?.member}&`;
        const searchParamsUsed: string[] = [];

        for (const key of Object.keys(this.filtersForm.value)) {
            if (this.filtersForm.value[key]) {
                if (key === 'dates' && (this.filtersForm.value[key].start || this.filtersForm.value[key].end)) {
                    // billing uses issued_date vs payment uses payment_date
                    if (this.currentTabIndex === 0) {
                        queryString += `issued_date_gte=${this.filtersForm.value[key].start}&`;
                        queryString += `issued_date_lte=${this.filtersForm.value[key].end}&`;
                    } else {
                        queryString += `payment_date_gte=${this.filtersForm.value[key].start}&`;
                        queryString += `payment_date_lte=${this.filtersForm.value[key].end}&`;
                    }
                } else if (key !== 'dates') {
                    const trimmedValue =
                        typeof this.filtersForm.value[key] === 'string' ? this.filtersForm.value[key].trim() : this.filtersForm.value[key];
                    queryString += `${key}=${trimmedValue}&`;
                    searchParamsUsed.push(key);
                }
            }
        }

        queryString += `page=${this.pagingConfigs.currentPage}&`;
        if (this.activeTab.paginator) {
            queryString += `page_size=${this.activeTab.paginator}&`;
        }

        if (this.sortingElement) {
            queryString += `ordering=${this.sortingElement}&`;
        }

        return queryString.slice(0, -1);
    }

    setCurrentPage(page: number) {
        this.pagingConfigs.currentPage = page;
        this.getData();
    }

    sortColumnData(element: string) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.getData();
    }

    openSSODetails(target): void {
        let queryParams = '';
        if (target?.billing_number) {
            queryParams = this.claimsService.getSSOQueryParams(
                this.billingPaymentsPageConfig.tabs?.billing?.datagrid,
                target,
                this.route.snapshot.params?.member
            );
        } else {
            queryParams = this.claimsService.getSSOQueryParams(
                this.billingPaymentsPageConfig.tabs?.payments?.datagrid,
                target,
                this.route.snapshot.params?.member
            );
        }
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Sso_initiated }, this.route.snapshot.params?.member, {
            SSO_initiated: validSSOCXEventDictonaryValue.BillingAndPayment,
        });
        if (target?.route) {
            const ssoUrl = `${target.route}?${queryParams}`;
            this.window.nativeWindow.open(ssoUrl, '_blank');
        }
    }

    getBillingData() {
        const url = this.buildQueryParams('/api/member-360/bills');
        this.apiService.get(url).subscribe((res) => {
            let billingResults = res.results;
            billingResults = billingResults.map((bill) => {
                bill['billing_period'] = `${this.formattingService.restructureValueBasedOnFormat(bill.period_start, {
                    format: 'DATE',
                })} - ${this.formattingService.restructureValueBasedOnFormat(bill.period_end, { format: 'DATE' })}`;

                bill['bill_status'] = this.billingPaymentsPageConfig.enums[bill['bill_status']];
                if (bill.products_billed?.length) {
                    bill['coverage_names'] = bill.products_billed.map((product) => ` ${product.external_plan_name}`);
                }

                return (bill = {
                    ...bill,
                    sso_link: SSOLinkValue,
                });
            });
            this.pagingConfigs = this.paginatorService.setPagingConfig(this.pagingConfigs, res.count, this.activeTab.paginator);
            this.tabData = billingResults;

            this.activeTab.filters.controls.forEach((control) => {
                if (control.prop === 'billing_account_id') {
                    const options = {};
                    billingResults.forEach((bill) => {
                        options[bill['billing_account_id']] = bill['billing_account_name'];
                    });

                    control.options = Object.keys(options).map((key) => ({
                        value: key,
                        label: options[key],
                    }));
                }
            });
            this.isLoadingData = false;
            this.isLoadingTab = false;
        });
    }

    getPaymentData() {
        const url = this.buildQueryParams('/api/member-360/payments');
        this.apiService.get(url).subscribe((res) => {
            let paymentResults = res.results;
            paymentResults = paymentResults.map((payment) => {
                payment['payment_status'] = this.billingPaymentsPageConfig.enums[payment['payment_status']];
                if (payment.product_payments?.length) {
                    payment['coverage_names'] = payment.product_payments.map((product) => ` ${product.external_plan_name}`);
                }

                return (payment = {
                    ...payment,
                    sso_link: SSOLinkValue,
                });
            });
            this.pagingConfigs = this.paginatorService.setPagingConfig(this.pagingConfigs, res.count, this.activeTab.paginator);
            this.tabData = paymentResults;
            this.activeTab.filters.controls.forEach((control) => {
                if (control.prop === 'billing_account_id') {
                    const options = {};
                    paymentResults.forEach((pay) => {
                        options[pay['billing_account_id']] = pay['billing_account_name'];
                    });

                    control.options = Object.keys(options).map((key) => ({
                        value: key,
                        label: options[key],
                    }));
                }
            });
            this.isLoadingData = false;
            this.isLoadingTab = false;
        });
    }

    navigateToDetails(data) {
        this.router.navigate([`/icx/member-360/${this.route.params['value'].member}/${this.activeTab.detailsRoute}/${data.id}`]);
    }

    private transformConfig(): void {
        this.allTabs = Object.keys(this.billingPaymentsPageConfig.tabs).map((key: string) => this.billingPaymentsPageConfig.tabs[key]);
        this.allTabs.sort(sortTabs);
        this.tabLabels = this.allTabs.map((tab: BillingPaymentsDatagridTab) => tab.label);
    }
}
