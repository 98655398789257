import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigService } from '../../../../shared/services/config.service';

interface GlobalErrorConfig {
    src: string;
    illustration: string;
    title: string;
    description: string;
}

@Component({
    selector: 'global-error',
    templateUrl: './global-error.component.html',
    styleUrls: ['./global-error.component.scss'],
})
export class GlobalErrorComponent implements OnInit {
    pageConfig: GlobalErrorConfig;

    constructor(private configService: ConfigService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.pageConfig = this.configService.getPageConfig(this.route.snapshot.data['pageName']);
    }
}
