import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'file-uploader-progress',
    templateUrl: './file-uploader-progress.component.html',
    styleUrls: ['./file-uploader-progress.component.scss'],
})
export class FileUploaderProgressComponent {
    @Input() file: File;
    @Input() loaded: number;
    @Input() total: number;
    @Input() error: boolean;

    @Output() closeClicked = new EventEmitter();

    get size() {
        const kB = 1000;

        return `${Math.floor(this.file.size / kB)}KB`;
    }

    get percentComplete(): string {
        const perc = 100;

        return `${Math.round((this.loaded * perc) / this.total)}%`;
    }

    get progressWidth(): any {
        return { width: this.percentComplete };
    }
}
