import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailTabsModule } from 'src/app/icx/shared/modules/detail-tabs/detail-tabs.module';
import { ButtonModule, ModelAttributeModule, DatagridModule } from '@zipari/design-system';
import { BadgeModule } from '../../../shared/modules/badge/badge.module';
import { ReferralDetailComponent } from './referral-detail.component';

@NgModule({
    declarations: [ReferralDetailComponent],
    imports: [CommonModule, ButtonModule, DetailTabsModule, BadgeModule, ModelAttributeModule, DatagridModule],
})
export class ReferralDetailModule {}
