import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';

import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { FormattingService, PaginatorConfiguration } from '@zipari/design-system';

import { getAllCodeDescription } from 'src/app/icx/shared/utils/code-description';
import { sortTabs } from 'src/app/icx/shared/utils/sorting-tabs';
import { ConfigService } from '../../../../shared/services';
import { MemberDataService } from '../../../shared/services/member-data.service';
import { ClaimDetailsResponse, ClaimDetails, ClaimLine, Specialties } from '../claim-line/claim-line.model';
import { PaginatorService } from './../../../shared/services/paginator.service';
import { ClaimDetailConfig } from './claim-detail.model';
import { SortTypes } from './claim-detail.constant';

@Component({
    selector: 'claim-detail',
    templateUrl: './claim-detail.component.html',
    styleUrls: ['./claim-detail.component.scss'],
})
export class ClaimDetailComponent implements OnInit {
    claimDetail: ClaimDetailsResponse;
    claimLines: ClaimLine[] = [];
    claimDetailPageConfig: ClaimDetailConfig;
    surgical_procedures_codes: string;
    billed_condition_codes: string;
    occurrence_codes: string;
    value_codes: string;
    drg: string;
    total_non_covered: number;
    procedures_and_dates: string;
    end_diagnosis: string;
    tabLabels: any[];
    claimId: string;
    memberId: string;
    isLoading: boolean = true;
    public sortingElement: string = '';
    illustrationSrc: string = 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/empty-4.svg';
    claimLinePagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    paymentSegmentsPagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    billingProviderProgramsPaginator: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    renderingProviderProgramsPaginator: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private configService: ConfigService,
        private formattingService: FormattingService,
        private panelService: SidePanelService,
        private memberDataService: MemberDataService,
        private analyticsService: AnalyticsService,
        private paginatorService: PaginatorService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.claimDetailPageConfig = this.configService.getControlPanelConfig('claim-detail');

        this.memberId = this.route.snapshot.params.member;
        this.claimId = this.route.snapshot.params.claim_id;
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.ClaimsDetail_viewed }, this.memberId);

        this.getClaimDetailsFromAPI();
    }

    getClaimDetailsFromAPI() {
        this.isLoading = true;
        this.memberDataService.getClaimDetails(this.claimId).subscribe(
            (resp: ClaimDetails) => {
                let newDetails = resp.details;

                if (newDetails?.claim_status && this.claimDetailPageConfig.claim_status_enum[newDetails.claim_status]) {
                    newDetails.claim_status = this.claimDetailPageConfig.claim_status_enum[newDetails.claim_status];
                }
                if (!newDetails['multi_payment_segments']) {
                    newDetails['multi_payment_segments'] = [];
                }

                newDetails.claimLines = [];
                newDetails.total_lines = '${total_lines}';
                newDetails.service_dates = `${this.formattingService.restructureValueBasedOnFormat(newDetails.service_date, {
                    format: 'DATE',
                })}${
                    newDetails.service_end_date
                        ? ` - ${this.formattingService.restructureValueBasedOnFormat(newDetails.service_end_date, {
                              format: 'DATE',
                          })}`
                        : ''
                }`;

                if (newDetails.patient) {
                    if (newDetails.patient.name && !newDetails.patient?.name?.full_name) {
                        newDetails.patient.name.full_name = [
                            newDetails.patient.name.first_name,
                            newDetails.patient.name.middle_name,
                            newDetails.patient.name.last_name,
                        ].join(' ');
                    }

                    if (newDetails.patient.gender && newDetails.patient.relationship_to_subscriber) {
                        if (this.claimDetailPageConfig.gender_enum[newDetails.patient.gender]) {
                            newDetails.patient.gender = this.claimDetailPageConfig.gender_enum[newDetails.patient.gender];
                        }
                        if (this.claimDetailPageConfig.relationship_enum[newDetails.patient.relationship_to_subscriber]) {
                            newDetails.patient.relationship_to_subscriber =
                                this.claimDetailPageConfig.relationship_enum[newDetails.patient.relationship_to_subscriber];
                        }
                        newDetails.patient.gender_relation = `${newDetails.patient.gender} / ${newDetails.patient.relationship_to_subscriber}`;
                    } else {
                        newDetails.patient.gender_relation = '-';
                    }
                }
                if (newDetails.subscriber?.address) {
                    newDetails.subscriber.formatted_address = [
                        newDetails.subscriber.address.street_name_1,
                        newDetails.subscriber.address.street_name_2,
                        newDetails.subscriber.address.city_name,
                        newDetails.subscriber.address.state,
                        newDetails.subscriber.address.zip_code,
                    ]
                        .filter((line) => !!line)
                        .join('\n');
                }

                if (newDetails['multi_payment_segments'] && newDetails['multi_payment_segments'].length) {
                    this.paymentSegmentsPagingConfig = this.paginatorService.setPagingConfig(
                        this.paymentSegmentsPagingConfig,
                        newDetails['multi_payment_segments'].length,
                        this.claimDetailPageConfig.tabs.financial.paginator
                    );
                }

                if (newDetails.claim_subtype === 'institutional') {
                    if (newDetails.facility_information?.condition_codes?.length) {
                        newDetails.billed_condition_codes = newDetails.facility_information.condition_codes.join('\n');
                    }

                    if (newDetails.facility_admitting_diagnoses?.length) {
                        newDetails.facility_admitting_diagnoses_display = getAllCodeDescription(
                            newDetails.facility_admitting_diagnoses,
                            'code',
                            'description'
                        );
                    }

                    if (newDetails.facility_information?.occurence_codes?.length) {
                        newDetails.occurrence_codes = newDetails.facility_information.occurence_codes
                            .map((oc) => {
                                const formattedDate = this.formattingService.restructureValueBasedOnFormat(oc.date, { format: 'DATE' });

                                return `${oc.code} - ${formattedDate}\n`;
                            })
                            .join('');
                    }

                    if (newDetails.facility_information?.value_codes) {
                        newDetails.value_codes = getAllCodeDescription(newDetails.facility_information.value_codes, 'code', 'amount');
                    }

                    if (newDetails.facility_information?.reasons_for_visit?.length) {
                        newDetails.facility_information.reasons_for_visit.forEach((diagnosis, i) => {
                            this.claimDetailPageConfig.tabs.facility?.attributeConfig.sections[1].attributes.push({
                                value: `\${facility_information.reasons_for_visit.${i}.code} - \${facility_information.reasons_for_visit.${i}.description}`,
                                label: `Reason Code ${i + 1}`,
                            });
                        });
                    }

                    if (newDetails.facility_end_diagnoses?.length) {
                        newDetails.end_diagnosis = getAllCodeDescription(newDetails.facility_end_diagnoses, 'code', 'description');
                    }

                    if (newDetails.procedures_information?.length) {
                        newDetails.surgical_procedures = newDetails.procedures_information
                            .filter((pi) => pi.procedure && pi.procedure.category === 'surgery')
                            .map(
                                (val) =>
                                    `${val.procedure.code} - ${
                                        val.procedure.description
                                    } - ${this.formattingService.restructureValueBasedOnFormat(val.performed_date, { format: 'DATE' })}\n`
                            )
                            .join('');
                    }

                    newDetails.total_non_covered = newDetails.total_billed_amount - newDetails.total_allowed_amount;
                } else {
                    delete this.claimDetailPageConfig.tabs.facility;
                }

                newDetails = this.setupProviderData(newDetails);

                this.claimDetail = newDetails;

                this.getClaimLinesList();
                this.setTabLabels();
            },
            () => {
                this.isLoading = false;
            }
        );
    }

    getClaimLinesList() {
        // clearing out preivous claim lines records
        this.claimLines = [];

        this.memberDataService
            .getClaimLinesList(this.claimId, true, this.claimLinePagingConfig?.currentPage, this.getClaimLineSortOrder())
            .subscribe(
                (response: any) => {
                    this.claimLinePagingConfig = this.paginatorService.setPagingConfig(
                        this.claimLinePagingConfig,
                        response.details.count,
                        this.claimDetailPageConfig.tabs.claimLine.paginator
                    );
                    response.details.results.forEach((line: ClaimLine) => {
                        line['service_dates'] = `${this.formattingService.restructureValueBasedOnFormat(line.service_from_date, {
                            format: 'DATE',
                        })}${
                            line.service_to_date
                                ? ` - ${this.formattingService.restructureValueBasedOnFormat(line.service_to_date, { format: 'DATE' })}`
                                : ''
                        }`;
                        line['rendering_provider'] = this.claimDetail['rendering_providers'];
                        this.claimLines.push(line);
                    });
                    this.claimLines = [...this.claimLines];
                    this.claimDetail.total_lines = response.details.count;
                    this.isLoading = false;
                },
                () => {
                    this.isLoading = false;
                }
            );
    }

    setTabLabels() {
        Object.keys(this.claimDetailPageConfig.tabs).forEach((key) => {
            if (!this.claimDetailPageConfig.tabs[key]) {
                delete this.claimDetailPageConfig.tabs[key];
            }
        });
        this.tabLabels = Object.keys(this.claimDetailPageConfig.tabs)
            .map((key) => ({
                text: this.claimDetailPageConfig.tabs[key].tabLabel,
                key,
                position: this.claimDetailPageConfig.tabs[key].position,
            }))
            .sort(sortTabs);
    }

    routeBack() {
        this.router.navigate([`/icx/member-360/${this.memberId}/claims`]);
    }

    keepOrder(a, b) {
        return a.value.position - b.value.position;
    }

    setCurrentPageForClaimLines(page: number) {
        this.claimLinePagingConfig.currentPage = page;

        this.claimLinePagingConfig.showingStart = page === 1 ? 1 : this.claimDetailPageConfig.tabs.claimLine.paginator * (page - 1) + 1;

        this.claimLinePagingConfig.showingEnd =
            page === this.claimLinePagingConfig.totalPages
                ? this.claimLinePagingConfig.totalResults
                : this.claimDetailPageConfig.tabs.claimLine.paginator * page;
        this.getClaimLinesList();
    }
    navigateToClaimLineDetails(claimLine: ClaimLine) {
        this.router.navigate([`/icx/member-360/${this.memberId}/claims/${this.claimId}/claim-line/${claimLine?.id}`]);
    }

    // Comment this, as function doesn't contain anything
    // setCurrentPageForPaymentSegments() {
    //     // TODO we need a ticket for payment segments pagination
    // }

    public setupProviderData(newDetails: ClaimDetailsResponse) {
        if (newDetails?.billing_providers?.name && !newDetails.billing_providers?.name?.full_name) {
            newDetails.billing_providers.name.full_name = [
                newDetails.billing_providers.name.first_name,
                newDetails.billing_providers.name.middle_name,
                newDetails.billing_providers.name.last_name,
            ].join(' ');
        }

        if (newDetails?.rendering_providers?.name && !newDetails.rendering_providers?.name?.full_name) {
            newDetails.rendering_providers.name.full_name = [
                newDetails.rendering_providers.name.first_name,
                newDetails.rendering_providers.name.middle_name,
                newDetails.rendering_providers.name.last_name,
            ].join(' ');
        }

        if (newDetails?.referring_providers?.name && !newDetails.referring_providers?.name?.full_name) {
            newDetails.referring_providers.name.full_name = [
                newDetails.referring_providers.name.first_name,
                newDetails.referring_providers.name.middle_name,
                newDetails.referring_providers.name.last_name,
            ].join(' ');
        }

        if (newDetails.billing_providers?.address) {
            newDetails.billing_providers.formatted_address = [
                newDetails.billing_providers.address.street_name_2,
                newDetails.billing_providers.address.city_name,
                newDetails.billing_providers.address.state,
                newDetails.billing_providers.address.zip_code,
            ].join(', ');
        }

        if (newDetails.billing_providers?.specialties?.length) {
            newDetails.billing_providers.specialties_names = [];
            newDetails.billing_providers.specialties_names = newDetails.billing_providers.specialties.map((sp: Specialties) => {
                const e_date = this.formattingService.restructureValueBasedOnFormat(sp.effective_date, { format: 'DATE' });
                const t_date = this.formattingService.restructureValueBasedOnFormat(sp.termination_date, { format: 'DATE' });
                const dateRange = `${e_date ? e_date : 'n/a'} - ${t_date ? t_date : 'n/a'}`;
                return e_date || t_date ? `${sp.name}    ${dateRange}` : sp.name;
            });
        }

        if (newDetails.rendering_providers?.programs?.length) {
            this.renderingProviderProgramsPaginator = this.paginatorService.setPagingConfig(
                this.renderingProviderProgramsPaginator,
                newDetails.rendering_providers.programs.length || 0,
                this.claimDetailPageConfig.tabs.cfProvider.paginator
            );
        }

        if (newDetails.billing_providers?.programs?.length) {
            this.billingProviderProgramsPaginator = this.paginatorService.setPagingConfig(
                this.billingProviderProgramsPaginator,
                newDetails.billing_providers.programs.length || 0,
                this.claimDetailPageConfig.tabs.cfProvider.paginator
            );
        }

        return newDetails;
    }

    getClaimLineSortOrder(): string {
        var sortOrder;
        const configSorting = this.claimDetailPageConfig?.tabs.claimLine.sorting;
        if (this.sortingElement) {
            sortOrder = this.sortingElement;
        } else if (configSorting?.key && configSorting?.order) {
            sortOrder = configSorting.order === SortTypes.asc ? configSorting.key : `-${configSorting.key}`;
        }
        return sortOrder;
    }

    sortTable(element): void {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.getClaimLinesList();
    }
}
