export const Badges = [
    {
        label: 'Total',
        value: '',
        type: 'info',
        size: 'large',
    },
    {
        label: 'Used',
        value: '',
        type: 'info',
        size: 'large',
    },
    {
        label: 'Remaining',
        value: '',
        type: 'info',
        size: 'large',
    },
];

export const InitialPagingConfig = {
    totalPages: 1,
    showingStart: 0,
    showingEnd: 0,
    totalResults: 0,
    currentPage: 1,
};

export const TabLabels = ['In Network', 'Out of Network'];
