import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ModelAttributeModule } from '@zipari/design-system';
import { SidePanelComponent } from './side-panel.component';

@NgModule({
    declarations: [SidePanelComponent],
    imports: [CommonModule, ModelAttributeModule],
    exports: [SidePanelComponent],
})
export class SidePanelModule {}
