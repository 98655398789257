import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { RippleModule } from '@zipari/design-system';
import { SpinnerComponent } from './spinner.component';
import { SpinnerService } from './spinner.service';
import { ZipBusyComponent } from './zip-busy.component';
import { ZipBusyDirective } from './zip-busy.directive';

@NgModule({
    imports: [CommonModule, OverlayModule, RippleModule],
    exports: [ZipBusyDirective],
    declarations: [ZipBusyComponent, ZipBusyDirective, SpinnerComponent],
    providers: [SpinnerService],
})
export class ZipBusyModule {}
