import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import qs from 'qs';
import { ConfigService, APIService } from 'src/app/shared/services';
import { GenericType } from 'src/app/icx/shared/services/icx.service';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ActivatedRoute } from '@angular/router';
import { PaginatorConfiguration, FormControlConfiguration, Datagrid, AllControlsConfiguration } from '@zipari/design-system';
import { PaginatorService } from 'src/app/icx/shared/services/paginator.service';
import { sortTabs } from 'src/app/icx/shared/utils/sorting-tabs';
import { SidePanelService } from './../../../shared/services/side-panel.service';

interface QueryObject {
    [key: string]: any;
}

export interface DocumentsPageConfig {
    tabs: {
        Coverage: DocumentsTabsConfig;
        Claims: DocumentsTabsConfig;
        Forms: DocumentsTabsConfig;
        Financial: DocumentsTabsConfig;
        MemberCommunication: DocumentsTabsConfig;
    };
    header: string;
    endpoint: string;
    paginator: number;
    filters: { controls: FormControlConfiguration[] };
    datagrid: Datagrid;
    sorting: {
        key: string;
        order: string;
    };
}

export interface DocumentsTabsConfig {
    tabLabel: string;
    sections: DocumentsSections[];
}

export type DocumentsSections = GenericType;

export interface DocumentsDisplayConfig {
    tabConfigs: DocumentsSections;
    labels: string[];
}
@Component({
    selector: 'document-center',
    templateUrl: './document-center.component.html',
    styleUrls: ['./document-center.component.scss'],
})
export class DocumentCenterComponent implements OnInit {
    config: DocumentsPageConfig;
    filters: AllControlsConfiguration[];
    documents;
    filteredDocuments;
    formGroup: FormGroup;
    selectedTabIndex: number;
    selectedTabName: string;
    filtersChanged: boolean = false;
    sortingElement: string = '';
    loadingDatagrid: boolean = true;

    displayConfig: DocumentsDisplayConfig = {
        tabConfigs: [],
        labels: [],
    };

    public pagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };

    constructor(
        private configService: ConfigService,
        private apiService: APIService,
        private panelService: SidePanelService,
        private analyticsService: AnalyticsService,
        private route: ActivatedRoute,
        private paginatorService: PaginatorService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.config = this.configService.getControlPanelConfig('documents');

        if (this.config.filters) {
            this.setupFilters(this.config.filters.controls);
        }

        this.modifyPageConfig();
        this.selectedTabIndex = 0;
        this.selectedTabName = this.displayConfig.labels[this.selectedTabIndex];
        this.searchDocuments();
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Documents_viewed }, this.route.snapshot.params.member);
    }

    tabSelected(selectedTabIndex) {
        this.selectedTabIndex = selectedTabIndex;
        this.selectedTabName = this.displayConfig.labels[selectedTabIndex];
        this.filtersChanged = true;

        // reset paging config
        this.pagingConfig = {
            totalPages: 1,
            showingStart: 0,
            showingEnd: 0,
            totalResults: 0,
            currentPage: 1,
        };

        this.clearForm();
        this.searchDocuments();
    }

    setupFilters(filters) {
        this.formGroup = new FormGroup({});
        this.filters = filters.map(this.createFormControl.bind(this));
    }

    createFormControl(config) {
        const control = new FormControl('', []);
        const newFormControl = Object.assign(config, {
            control: control,
        });

        this.formGroup.addControl(newFormControl.prop, control);

        if (config.value) {
            this.formGroup.get(newFormControl.prop).setValue(config.value);
        }

        return newFormControl;
    }

    openDocument(event) {
        if (event.row.url) {
            window.open(event.row.url, '_blank');
        }
    }

    clearForm() {
        this.formGroup.reset();
        this.filtersChanged = true;
        this.searchDocuments();
    }

    submitForm() {
        this.filtersChanged = true;
        this.searchDocuments();
    }

    buildQueryParams() {
        let endpoint = `${this.config.endpoint}?`;

        if (this.selectedTabName) {
            const tabName = this.selectedTabName.replace(/\s/g, '');
            const categories = this.config.tabs[tabName].tabCategories.toString().split(',');

            endpoint += categories.map((category) => `document_category=${category}&`).join('');
        }

        const queryObject: QueryObject = {
            member_id: this.route.snapshot.params.member,
            page: this.pagingConfig ? this.pagingConfig.currentPage : null,
            page_size: this.pagingConfig ? this.config.paginator : null,
        };
        const searchParamsUsed: string[] = [];

        for (const key of Object.keys(this.formGroup.value)) {
            if (key === 'dates' && (this.formGroup.value[key].start || this.formGroup.value[key].end)) {
                queryObject.document_date_gte = this.formGroup.value[key].start !== '' ? this.formGroup.value[key].start : null;
                queryObject.document_date_lte = this.formGroup.value[key].end !== '' ? this.formGroup.value[key].end : null;
            } else if (key !== 'dates' && this.formGroup.value[key]) {
                queryObject[key] = this.formGroup.value[key].trim();
            }
        }

        let initialSort = '';
        if (this.sortingElement) {
            queryObject.ordering = this.sortingElement;
        } else if (this.config.sorting && this.config.sorting.key && this.config.sorting.order) {
            if (this.config.sorting.order === 'asc') {
                initialSort = this.config.sorting.key;
            } else if (this.config.sorting.order === 'desc') {
                initialSort = `-${this.config.sorting.key}`;
            }
            queryObject.ordering = initialSort;
        }

        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Documents_searched }, this.route.snapshot.params.member, {
            document_search_keys_searched: searchParamsUsed,
        });

        return endpoint + qs?.stringify(queryObject, { skipNulls: true });
    }

    searchDocuments() {
        this.loadingDatagrid = true;
        this.documents = [];
        this.filteredDocuments = [];
        if (this.filtersChanged) {
            this.pagingConfig.currentPage = 1;
            this.pagingConfig.showingStart = 0;
        }

        const query = this.buildQueryParams();

        this.apiService.get(query).subscribe(
            (res) => {
                this.documents = res.results;
                this.filteredDocuments = Object.assign([], this.documents);
                this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, res.count, this.config.paginator);
                this.loadingDatagrid = false;
                this.filtersChanged = false;
            },
            (err) => {
                this.loadingDatagrid = false;
                this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, 0, this.config.paginator);
            }
        );
    }

    setCurrentPage(page: number) {
        this.pagingConfig.currentPage = page;

        this.pagingConfig.showingStart = page === 1 ? 1 : this.config.paginator * (page - 1) + 1;

        this.pagingConfig.showingEnd =
            page === this.pagingConfig.totalPages ? this.pagingConfig.totalResults : this.config.paginator * page;

        this.searchDocuments();
    }

    downloadDocument(target: GenericType) {
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Documents_downloaded }, this.route.snapshot.params.member);

        window.open(`/api/member-360/documents/${target.row.id}/`);
    }

    // downloading document on click event
    navigateToDetails(data) {
        const docId = data.id;
        const docUrl = data.url;
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Documents_downloaded }, this.route.snapshot.params.member);
        window.open(docId ? `/api/member-360/documents/${docId}/` : docUrl);
    }

    sortTable(element: any) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.documents = [];
        this.searchDocuments();
    }

    private modifyPageConfig() {
        this.displayConfig.tabConfigs = Object.keys(this.config.tabs).map((tabKey: string) => {
            // Convert sections objects to sections list
            this.config.tabs[tabKey].sections = Object.keys(this.config.tabs[tabKey].sections).map(
                (sectionKey: string) => this.config.tabs[tabKey].sections[sectionKey]
            );

            return this.config.tabs[tabKey];
        });
        this.displayConfig.tabConfigs = this.displayConfig.tabConfigs.sort(sortTabs);
        this.displayConfig.labels = this.displayConfig.tabConfigs.map((tab: DocumentsTabsConfig) => tab.tabLabel);
    }
}
