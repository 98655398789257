import { ButtonConfig } from '@zipari/design-system';
import {
    schemaClass,
    schemaElementEnumTypes,
    schemaElementOptional,
    schemaElementPropType,
    schemaElementUnionTypes,
    schemaProp,
} from '@zipari/json-decorators';

import { Column2 } from './column.model';

export enum sortTypes {
    asc = 'asc',
    desc = 'desc',
}

export enum ZipFilterViewOptions {
    rows = 'rows',
    columns = 'columns',
}

export enum ZipSidebarOrHeader {
    header = 'header',
    sidebar = 'sidebar',
}

/** la
 * INTERNAL USE: normal map in structure --> {sort prop: sort direction}
 */
export class ZipTableSorts2 {}

export class ZipTableFilters2 {
    type: string;
    prop: string;

    constructor(options) {
        Object.assign(this, options);
    }
}

@schemaClass()
export class ZipTableDetailConfig2 {
    @schemaProp({ description: 'The minimum amount of columns that must be shown.', readOnly: true })
    minColumns: number = 2;

    @schemaProp({ description: 'Whether or not the detail section is enabled.' })
    enabled: boolean = true;

    @schemaProp({ description: 'Whether or not multiple detail contact are allowed to be open.' })
    multi: boolean = true;

    template = null;

    @schemaProp({ description: 'How to handle the detail on init' })
    init: DetailInitModel;

    constructor(options) {
        Object.assign(this, options);
    }
}

@schemaClass()
export class ZipTableSelectionInit {
    [prop: string]: string;
}

@schemaClass()
export class DetailInitModel {
    @schemaElementUnionTypes([Boolean, Number])
    @schemaProp({
        description:
            'How to handle selection on init. Providing a number will only open that specific table item while providing a boolean will handle all of the items at once (EX. true - all will open)',
    })
    startOpen: boolean | number;
}

@schemaClass()
export class ZipTableInit {
    @schemaProp({ description: 'How to handle selection on init' })
    selection: any;

    @schemaProp({ description: 'How to handle sorting on init' })
    sorting: any;

    @schemaProp({ description: 'How to handle the detail on init' })
    detail: DetailInitModel;

    constructor(options) {
        Object.assign(this, options);
    }
}

@schemaClass()
export class ZipTable2Messages {
    @schemaElementOptional()
    @schemaProp({ description: 'A title for the table displayed at the top' })
    title?: string = '';

    @schemaProp({ description: 'Message for when there is no data found' })
    noData: string = 'No Data Found';

    @schemaElementOptional()
    @schemaProp({ description: 'Special tooltip that can be shown when mousing over normal rows', readOnly: true })
    tooltip?: string;

    constructor(options) {
        Object.assign(this, options);
    }
}

@schemaClass()
export class ZipTablePagingConfig2 {
    /** INTERNAL USE: the total count of rows for the table */
    count?: number = 0;

    @schemaProp({ description: 'Whether or not paging should be enabled' })
    enabled: boolean = true;

    @schemaProp({ description: 'Which page the table is on... you can set the starting page by providing a number here' })
    pageIndex: number = 0;

    @schemaProp({ description: 'How many items should be displayed per page' })
    pageSize: number = 10;

    constructor(options) {
        if (options.enabled === false) {
            this.enabled = false;
            this.pageIndex = undefined;
            this.pageSize = undefined;
        } else {
            Object.assign(this, options || {}, { enabled: true });
        }
    }
}

@schemaClass()
export class ZipTableSelectionConfig2 {
    @schemaProp({ description: 'Whether or not selection should be enabled' })
    enabled: boolean = false;

    @schemaProp({ description: 'Whether or not there is a border on selection' })
    border: boolean = true;

    @schemaProp({ description: 'Whether or not the arrow should show on selection' })
    arrow: boolean = false;

    init: any;

    @schemaProp({ description: 'Whether or not a user can make multiple selections... will utilize checkboxes' })
    multiple: boolean = false;

    @schemaProp({ description: 'persist selections between search, pagination...' })
    persist: boolean = false;

    editHeader: boolean = false;

    constructor(options) {
        let enabled = this.enabled;

        if (options.hasOwnProperty('enabled')) {
            enabled = options.enabled;
        } else if (options.border || options.arrow || options.multiple || options.editHeader) {
            enabled = true;
        }

        if (options.editHeader) {
            options.multiple = true;
        }

        Object.assign(this, options, { enabled });
    }
}

@schemaClass()
export class ZipTableGroupPropConfig2 {
    @schemaProp({ description: 'A label for the group prop' })
    name: string;
}

@schemaClass()
export class ZipTableGroupConfig2 {
    @schemaProp({ description: 'Which prop should be grouped on' })
    prop: string = null;

    @schemaProp({ description: 'Whether or not the grouping should be enabled' })
    enabled: boolean = null;

    @schemaElementOptional()
    @schemaProp({ description: 'Which props values should get rolled up' })
    rollup?: any = {};

    @schemaElementOptional()
    @schemaProp({ description: 'Whether or not to show the group prop within the detail view.' })
    showInDetail?: boolean = true;

    @schemaElementOptional()
    @schemaProp({ description: 'Configuration for the group prop.' })
    groupPropConfig?: ZipTableGroupPropConfig2;

    @schemaElementEnumTypes(sortTypes)
    @schemaElementOptional()
    @schemaProp({ description: 'Which direction to sort' })
    sort?: sortTypes = sortTypes.asc;

    constructor(options) {
        Object.assign(this, options, { enabled: options.prop && !options.hasOwnProperty('enabled') ? true : options.enabled });
    }
}

@schemaClass()
export class ExportConfiguration {
    @schemaProp({ description: 'A material icon name' })
    icon: string = 'download';

    @schemaProp({ description: 'Text to display for the export area' })
    text: string = '';

    @schemaProp({ description: 'Whether or not the export is enabled' })
    enabled: boolean = false;

    exportAsyncLimit: any;
    /** INTERNAL USE: the link to be used for doing an export of a table */
    exportLink?: string;

    constructor(options: any = {}) {
        let enabled = this.enabled;
        if (!options.hasOwnProperty('enabled') && (options.icon || options.text)) {
            enabled = true;
        } else if (options.hasOwnProperty('enabled')) {
            enabled = options.enabled;
        }
        Object.assign(this, options, { enabled });
    }
}

@schemaClass()
export class ZipTableFooter2 {
    @schemaProp({ description: 'Only show if paginated' })
    onlyShowIfPaginated: boolean;

    @schemaProp({ description: 'If true, prevents pagination from appearing if there is only one page' })
    doNotShowCountUnlessPaginated: boolean = false;

    @schemaProp({ description: 'If true, numbered paginated buttons are visible (instead of just arrows)' })
    areNumberedButtonsPresent: boolean = true;

    @schemaProp({ description: 'If true, the numbers for the current set of elements out of the overall is displayed' })
    isLabelPresent: boolean = true;

    /** For ease of use this class constructor is utilized in order to handle the defaults
     * @param options - the raw options provided by the user
     * */
    constructor(options) {
        Object.assign(this, options);
    }
}

@schemaClass()
export class ZipTableFilterViewOptions2 {
    @schemaElementEnumTypes(ZipSidebarOrHeader)
    @schemaProp({ description: 'Whether or not to show the filters as a header or a sidebar' })
    sidebarOrHeader: ZipSidebarOrHeader = ZipSidebarOrHeader.header;

    @schemaElementEnumTypes(ZipFilterViewOptions)
    @schemaProp({ description: 'Whether or not to display the filters as columns or rows' })
    rowsOrColumns: ZipFilterViewOptions = ZipFilterViewOptions.columns;

    /** For ease of use this class constructor is utilized in order to handle the defaults
     * @param options - the raw options provided by the user
     * */
    constructor(options) {
        Object.assign(this, options);
    }
}

@schemaClass()
export class ZipTablePage2 {
    pageSize: number = 10;
    count: number = 0;
    totalPages: number = 0;
    pageNumber: number = 0;
    offset: number = 0;
    next?: string;
    previous?: string;

    constructor(count, page?) {
        Object.assign(this, page || {});
        this.count = count;
        this.totalPages = this.count / this.pageSize;
    }
}

@schemaClass()
export class ZipTablePagedData2 {
    data: any[] = [];
    page: ZipTablePage2 = new ZipTablePage2(0);

    constructor(data, page?) {
        Object.assign(this, { page: new ZipTablePage2(data.count, page) });
        Object.assign(this, { data: data.results });
    }
}

@schemaClass()
export class ZipTable2MaxHeight {
    @schemaElementOptional()
    @schemaProp({ description: 'Whether or not the table\'s body should have a maximum height', readOnly: true })
    enabled?: boolean = false;

    @schemaProp({ description: 'A specific number that the height should be set to', readOnly: true })
    height: number;

    constructor(options) {
        Object.assign(this, options);

        if (options.height) {
            this.enabled = true;
        }
    }
}

@schemaClass()
export class ZipTable2Visuals {
    @schemaProp({ description: 'A theme that can be applied throughout the table', readOnly: true })
    theme: string;

    @schemaProp({
        description: 'Configuration for how the table should handle giving itself a maximum height for the table body',
        readOnly: true,
    })
    maxHeight: ZipTable2MaxHeight;

    @schemaProp({
        description: 'Configuration for table filters to be placed below the table title',
        readOnly: true,
    })
    filtersOwnRow: boolean;

    constructor(options) {
        Object.assign(this, options);
    }
}

export class ZipTable2Sorting {
    init: any;
}

@schemaClass()
export class ZipTable2 {
    showSpinner: boolean;

    @schemaProp({ description: 'Sets up sorting for the table', readOnly: true })
    sorting: ZipTable2Sorting;

    @schemaElementOptional()
    @schemaProp({ description: 'Configuration to handle different visual values of the table', readOnly: true })
    visuals?: ZipTable2Visuals;

    @schemaElementOptional()
    @schemaProp({ description: 'Configures the messages displayed throughout the table' })
    messages?: ZipTable2Messages;

    /** INTERNAL USE: non query filters */
    nonQueryFilters = [];

    /** INTERNAL USE: only query filters */
    queryFilters = [];

    @schemaElementPropType(ZipTableFilters2)
    @schemaElementOptional()
    @schemaProp({ description: 'Configuration for the filters to be displayed above the table', readOnly: true })
    filters?: ZipTableFilters2[];

    @schemaProp({ description: 'Configuration for the export icon and text if that is wanted', readOnly: true })
    exportConfig: ExportConfiguration;

    @schemaProp({ description: 'Configuration for a button in the table header.' })
    button?: any;

    @schemaProp({ description: 'Configuration for how to do selection for table rows', readOnly: true })
    selection: ZipTableSelectionConfig2;

    @schemaProp({ description: 'Configuration for how to do grouping for the table', readOnly: true })
    grouping: ZipTableGroupConfig2;

    @schemaProp({
        description: 'Configuration for whether or not the detail view should be shown. Default is true',
        readOnly: true,
    })
    detail: ZipTableDetailConfig2;

    @schemaProp({ description: 'configuration for paging', readOnly: true })
    paging: ZipTablePagingConfig2;

    @schemaElementOptional()
    @schemaProp({ description: 'Configures the footer (if applicable)', readOnly: true })
    footer?: ZipTableFooter2;

    @schemaElementPropType(Column2)
    @schemaProp({ description: 'Configuration for each of the columns in the table' })
    columns: Column2[];

    @schemaProp({ description: 'An endpoint to be used for all external pagination/sorting', readOnly: true })
    endpoint: string;

    /** whether or not the zip-table should try to stop recalling the same endpoint.
     * Before making an http call if this is enabled it will make sure that a
     * change to the endpoint has been made before making another call. */
    @schemaProp({
        description: 'Whether or not the zip-table should try to stop continuously calling the same endpoint',
        readOnly: true,
    })
    endpointCache: boolean = true;

    /** INTERNAL USE: keeps track of what props are being sorted on */
    sorts?: ZipTableSorts2;

    /** INTERNAL USE: keeps track of what props are being filtered on and what their values are */
    filterValues: any;

    /** INTERNAL USE: object to help keep track of the weights of the columns */
    columnPriorities?: any = {};

    title: string;

    /** For ease of use this class constructor is utilized in order to handle the defaults for the parts of the table
     * handles the set configurations and then assigns whatever the rest of the configuration for the table is
     * @param options - the raw options provided by the user
     * */
    constructor(options) {
        let filters;
        if (options.filters) {
            filters = options.filters.map(filter => {
                const formattedFilter = new ZipTableFilters2(filter);
                if (formattedFilter.type === 'query') {
                    this.queryFilters.push(filter);
                } else {
                    this.nonQueryFilters.push(filter);
                }

                return;
            });
        }

        const exportConfig = new ExportConfiguration(options.exportConfig || {});
        const paging = new ZipTablePagingConfig2(options.paging || {});
        const selection = new ZipTableSelectionConfig2(options.selection || {});
        const grouping = new ZipTableGroupConfig2(options.grouping || {});
        const detail = new ZipTableDetailConfig2(options.detail || {});
        const newFooter = new ZipTableFooter2(options.footer || {});
        const messages = new ZipTable2Messages(options.messages || {});
        const button = new ButtonConfig(options.button);
        const newColumns = options.columns.map((col, ind) => {
            const builtCol = new Column2(col, ind);

            // set the group prop config to be passed around
            if (grouping.enabled && col.prop === grouping.prop) {
                grouping.groupPropConfig = col;
            }

            // make sure that you store the column priorities within the full table
            this.columnPriorities[builtCol.prop] = builtCol.priority;

            return builtCol;
        });

        Object.assign(this, options, {
            filters: filters,
            button: button,
            export: options.export,
            pagination: options.pagination,
            exportConfig: exportConfig,
            footer: newFooter,
            detail: detail,
            grouping: grouping,
            selection: selection,
            paging: paging,
            columns: newColumns,
            messages: messages,
        });

        if (options.hasOwnProperty('showSpinner')) {
            this.showSpinner = options.showSpinner;
        } else {
            this.showSpinner = false;
        }
    }
}
