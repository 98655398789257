import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormattingService, StatusType } from '@zipari/design-system';
import { BadgeConfig, BadgeTypes, BadgeSizes, BadgeColors } from './badge.constants';

@Component({
    selector: 'badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent implements OnInit, OnChanges {
    @Input() config: BadgeConfig;
    @Input() context: any;

    @Output() linkClicked = new EventEmitter();

    badgeConfig: BadgeConfig;
    statusType = StatusType;
    BadgeTypes = BadgeTypes;
    BadgeSizes = BadgeSizes;
    BadgeColors = BadgeColors;

    constructor(private formatService: FormattingService) {}

    ngOnInit() {
        this.badgeConfig = new BadgeConfig(this.config);
        this.badgeConfig.fields.attributes = this.badgeConfig.fields.attributes
            .map((attr) => this.formatService.formatConfigValueAsTemplate(attr, this.context))
            .map((config) => {
                config.value = this.formatService.restructureValueBasedOnFormat(config.value, config);

                return config;
            });
    }

    /* istanbul ignore next */
    ngOnChanges() {
        this.badgeConfig = new BadgeConfig(this.config);
    }
}
