import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { getValue } from '@zipari/web-utils';
import { AuthService, ConfigService } from '../shared/services';
import { SidePanelService } from './shared/services/side-panel.service';
import { ProfileSearchService } from './shared/services/profile-search.service';
import { IcxAppRoutes } from './icx.constants';

@Component({
    selector: 'icx',
    templateUrl: './icx.component.html',
    styleUrls: ['./icx.component.scss'],
})
export class IcxComponent implements OnInit, AfterViewChecked {
    id;
    token;
    config;
    showProfileSearchModal = false;
    loadMemberSearchModal = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public authService: AuthService,
        private panelService: SidePanelService,
        private profileSearchService: ProfileSearchService,
        private configService: ConfigService
    ) {}

    ngOnInit() {
        this.handleIcxRoutes();
    }

    ngAfterViewChecked(): void {
        this.panelService.sidebarVisChange.subscribe((value) => {
            if (value) {
                this.id = this.getPortalUserId();
            }
        });

        this.profileSearchService.show$.subscribe((show: boolean) => {
            if (this.configService.activeRoute !== IcxAppRoutes.Member360) return;

            this.loadMemberSearchModal = show;
            this.showProfileSearchModal = this.loadMemberSearchModal;
        });
    }

    handleToolBarLinkClick(link): void {
        if (link.openTargetAsModal) {
            this.loadMemberSearchModal = true;
            this.showProfileSearchModal = link.openTargetAsModal;
        }
    }

    handleIcxRoutes(): void {
        const token = getValue(this.route.snapshot, 'queryParams.token');
        if (token) {
            this.router.navigate(['/icx/member/reset-password'], { queryParamsHandling: 'merge' });
        }

        if (!this.authService.loggedInUser) {
            // let angular routing handle` this scenario
            if (window.location.pathname.includes('oauth')) {
                return;
            }

            // case when no user
            if (!window.location.pathname.includes('auth') || !window.location.pathname.includes('login')) {
                this.authService.replayPath = window.location.pathname;
            }

            const icxApp: string = this.router.parseUrl(this.router.url).root.children.primary.segments[1].path;

            const loginEndpoint: any = getValue(this.authService.appConfig, 'login.loginEndpoint');
            if (loginEndpoint) {
                window.location = loginEndpoint;
            } else {
                this.router.navigate([`/icx/${icxApp}/auth/login`]);
            }
        } else {
            // case when there is a logged in user.
            if (this.authService.replayPath) {
                this.router.navigate([this.authService.replayPath]);
                this.authService.replayPath = null;
            } else if (this.getPortalUserId()) {
                this.id = this.getPortalUserId();
                if (window.location.pathname.includes('login') || window.location.pathname.includes('auth')) {
                    // Route home when logged in and no member.
                    this.router.navigate(['icx/member-360/home']);
                }
            } else if (window.location.pathname.includes('provider-details')) {
                return;
            } else {
                this.router.navigate(['icx/member-360/home']);
            }
        }
    }

    getPortalUserId(): string | boolean {
        switch (this.configService.activeRoute) {
            case IcxAppRoutes.Member360:
                let memberId: string;
                try {
                    memberId = this.route.snapshot.children[0].children[0].params.member;
                } catch (e) {
                    console.warn('no member id found', e);
                }

                if (memberId && memberId.indexOf(':') === -1) {
                    return memberId;
                }

                break;
            default:
                return false;
        }
    }
}
