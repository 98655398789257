import { Component, Input } from '@angular/core';

@Component({
    selector: 'zip-page-count',
    templateUrl: './page-count.component.html',
    styleUrls: ['./page-count.component.scss'],
})
export class PageCountComponent {
    @Input() firstShowing: number;
    @Input() lastShowing: number;
    @Input() totalCount: number;
}
