import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { NavigationModule } from '../../../modules/navigation/navigation.module';
import { ReadMoreModule } from '../../../modules/read-more/read-more.module';
import { ZipBusyModule } from '../../../modules/zip-busy';

import { CoverageEffectiveDateComponent } from './coverage-effective-date.component';

@NgModule({
    declarations: [CoverageEffectiveDateComponent],
    imports: [CommonModule, FormsModule, ButtonModule, FormControlModule, NavigationModule, ZipBusyModule, ReadMoreModule],
    exports: [CoverageEffectiveDateComponent],
})
export class CoverageEffectiveDateModule {}
