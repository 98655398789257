import { AllControlsConfiguration, ButtonConfig } from '@zipari/design-system';

export enum AuthenticationLayouts {
    step = 'step',
    banner = 'banner',
}

export enum AuthenticationPages {
    login = 'auth',
    register = 'register',
    'forgot-password' = 'forgot-password',
    'reset-password' = 'reset-password',
}

export class StepOptions {}

export class BannerOptions {
    heroImg: string;
    header?: any;
    sections: any[];
}

export class AuthCardFooterLinkOptions {
    label: string;
    prop: string;
}

export class AuthCardFooterOptions {
    link: AuthCardFooterLinkOptions;
    button: ButtonConfig;
}

export class AuthCardOptions {
    title: string;
    subtitle: string;
    form: {
        controls: AllControlsConfiguration[];
    };
    footer: AuthCardFooterOptions;
    successTitle?: string;
    successSubtitle?: string;
}

export class RouteAfterSuccessConfig {
    route?: string;
    id?: any;
    checkApplication?: boolean;
    disableAfterSuccess?: boolean;
}

export class AuthenticationConfig {
    routeAfterSuccessConfig?: RouteAfterSuccessConfig;
    layout?: AuthenticationLayouts;
    page?: AuthenticationPages;
    layoutOptions?: StepOptions | BannerOptions;
    authCardOptions?: AuthCardOptions;
    endpoint?: string;
}

export const validConfigs = ['page', 'layout', 'layoutOptions', 'authCardOptions', 'routeAfterSuccessConfig'];

export const authenticationRoutes = {
    login: '${app}/auth',
    register: '${app}/register',
    'forgot-password': '${app}/forgot-password',
    'reset-password': '${app}/reset-password',
};

export const defaultErrorMessages = {
    login: 'Failed to login, please try again',
};
