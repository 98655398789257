import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService, ConfigService } from '../../shared/services';

@Component({
    selector: 'providers',
    templateUrl: './provider.component.html',
    styleUrls: ['./provider.component.scss'],
})
export class ProviderComponent implements OnInit {
    pageConfig;
    member;
    provider;
    providerCallMade;
    sidebarModelAttributeConfig = [
        {
            label: 'Specialty',
            value: 'Internist',
        },
        {
            label: 'Affiliated Network',
            value: 'NewBrighton Plus',
        },
        {
            label: 'Phone',
            value: '(039) 123-4932',
            format: 'PHONE',
        },
        {
            label: 'Email',
            value: 'j.smith2323@gmail.com',
            format: 'EMAIL',
        },
        {
            label: 'Primary Address',
            value: '323 Main Street, Suite 3493 New York, NY 10002',
        },
        {
            label: 'Board Certifications',
            value: 'American Board of Internal Medicine',
        },
        {
            label: 'About',
            value: 'Dr. Jasmine is a board certified Internist serving downtown Manhattan.',
        },
    ];

    generalInfoConfiguration = [
        {
            label: 'Gender',
            value: 'Female',
        },
        {
            label: 'NPI',
            value: '1851483754',
        },
        {
            label: 'Professional Designation',
            value: 'MD',
        },
        {
            label: 'HMO',
            value: 'False',
        },
        {
            label: 'PCP',
            value: 'False',
        },
        {
            label: 'Languages Spoken',
            value: 'English, Spanish, Russian',
        },
    ];

    contactInfoConfiguration = [
        {
            label: 'Phone',
            value: '(039) 123-4932',
            format: 'PHONE',
        },
        {
            label: 'Email',
            value: 'j.smith2323@gmail.com',
            format: 'EMAIL',
        },
        {
            label: 'Fax',
            value: '(329) 248-4824',
        },
        {
            label: 'Primary Address',
            value: '323 Main Street, Suite 3493 New York, NY 10002',
        },
    ];

    constructor(private configService: ConfigService, private route: ActivatedRoute, private api: APIService) {}

    ngOnInit() {
        this.pageConfig = this.configService.getPageConfig(this.route.snapshot.data['pageName']);

        const memberId = this.route.snapshot.queryParams['member_id'];

        this.api.get(`/api/member-360/members/${memberId}/pcp`).subscribe(
            (resp) => {
                const providerId = resp.provider_id;
                this.getPCP(providerId);
            },
            (error) => {
                this.providerCallMade = true;
            }
        );
    }

    getPCP(id) {
        this.api.get(`/api/scapi/providers/${id}`).subscribe((resp) => {
            this.provider = resp;
            this.provider['hmo'] = false;
            this.providerCallMade = true;
        });
    }
}
