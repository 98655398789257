import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
    selector: 'read-more',
    templateUrl: './read-more.component.html',
    styleUrls: ['./read-more.component.scss'],
})
export class ReadMoreComponent implements OnInit {
    @Input() text: string;
    // eslint-disable-next-line @angular-eslint/no-output-native
    @Output() click: EventEmitter<any> = new EventEmitter();

    clicked: boolean = false;
    showButton: boolean = false;
    textCount: number;

    ngOnInit() {
        this.textCount = this.text.length;

        if (this.textCount) {
            this.showButton = this.textCount > this.pipeOptions.maxCharacters ? true : false;
        }
    }

    readClicked() {
        this.clicked = !this.clicked;
    }

    public get pipeOptions() {
        return {
            showAll: this.clicked,
            maxCharacters: 60,
        };
    }
}
