import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { ConfigService } from 'src/app/shared/services';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormattingService, PaginatorConfiguration } from '@zipari/design-system';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { MemberDataService } from './../../../shared/services/member-data.service';
import { PolicyData, FinancialData } from './policy-details.model';
import { PaginatorService } from '../../../shared/services/paginator.service';

@Component({
    selector: 'policy-details',
    templateUrl: './policy-details.component.html',
    styleUrls: ['./policy-details.component.scss'],
})
export class PolicyDetailsComponent implements OnInit {
    public paginatorConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    policyId: string;
    pageConfig: any;
    memberId: string;
    policyData: PolicyData;
    datagridData: any[] = [];
    titleValue: string;
    financialData: FinancialData[] = [];
    public sortingElement: string = '';
    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private configService: ConfigService,
        private memberDataService: MemberDataService,
        private panelService: SidePanelService,
        private formatService: FormattingService,
        private analyticsService: AnalyticsService,
        private paginatorService: PaginatorService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.setConfig();
        this.setPageData();
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.PolicyDetail_viewed }, this.memberId);
    }

    setConfig(): void {
        this.pageConfig = this.configService.getControlPanelConfig(this.route.snapshot.data['pageName']);
    }

    setPageData(): void {
        this.policyId = this.route.snapshot.params.policy_number;
        this.memberId = this.route.snapshot.params.member;
        this.memberDataService.getPolicyData(this.policyId, this.memberId).subscribe((res) => {
            this.policyData = res.details;
            this.titleValue = this.formatService.formatConfigValueAsTemplate(this.pageConfig.header, this.policyData).value;
            this.financialData = [
                ...this.policyData.member_financial_products.map((data: FinancialData) => ({
                    ...data,
                    is_CF_Product: data.is_owned_by_payer ? 'Yes' : 'No',
                })),
            ];
            this.pageConfig.badge = {
                ...this.pageConfig.badge,
                status: this.policyData.policy_status,
            };
            this.handleFinancialData(this.financialData);
        });
        this.memberDataService
            .getPolicyProductCoverages(
                this.memberId,
                this.policyId,
                this.paginatorConfig.currentPage,
                this.pageConfig.paginator,
                this.sortingElement
            )
            .subscribe((resp) => {
                this.paginatorConfig = this.paginatorService.setPagingConfig(
                    this.paginatorConfig,
                    resp.details.count,
                    this.pageConfig.paginator
                );
                this.datagridData = Object.assign([], resp.details.results);
                this.datagridData.forEach((pc, index) => {
                    pc.member_coverages.forEach((mc) => {
                        if (mc?.member?.member_number === this.memberId || mc?.member?.id === this.memberId) {
                            this.datagridData[index].member_display_identifier = mc.member.member_display_identifier;
                        }
                    });
                });
            });
    }

    setCurrentPage(page: number) {
        this.paginatorConfig.currentPage = page;

        this.paginatorConfig.showingStart = page === 1 ? 1 : this.pageConfig.paginator * (page - 1) + 1;

        this.paginatorConfig.showingEnd =
            page === this.paginatorConfig.totalPages ? this.paginatorConfig.totalResults : this.pageConfig.paginator * page;
        this.setPageData();
    }

    sortTable(element) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        console.log(this.sortingElement);

        this.setPageData();
    }

    handleFinancialData(finance_data: FinancialData[]): void {
        const accountTypes = ['hsa', 'fsa', 'hra'];
        const typesToBeAdded = accountTypes.filter((e) => finance_data.map((a) => a.account_type).indexOf(e) < 0);
        typesToBeAdded.forEach((type) => {
            const tempFinancialData: FinancialData = {
                member_id: '',
                account_type: '',
                effective_date: '',
                id: '',
                is_owned_by_payer: null,
                termination_date: '',
                name: '-',
                eligibility: '',
                is_CF_Product: '-',
            };
            switch (type) {
                case 'hsa':
                    tempFinancialData.account_type = 'hsa';
                    tempFinancialData.eligibility = 'No';
                    this.financialData.push(tempFinancialData);
                    break;

                case 'fsa':
                    tempFinancialData.account_type = 'fsa';
                    tempFinancialData.eligibility = 'No';
                    this.financialData.push(tempFinancialData);
                    break;

                case 'hra':
                    tempFinancialData.account_type = 'hra';
                    tempFinancialData.eligibility = 'No';
                    this.financialData.push(tempFinancialData);
                    break;
            }
        });
        this.financialData = [
            ...finance_data.map((f_data) => ({
                ...f_data,
                account_type: f_data.account_type.toUpperCase(),
                eligibility: f_data.eligibility || 'Yes',
            })),
        ];
    }

    navigateToDetail(target): void {
        const mcov_id = this.getMemberCoverageId(target);
        this.router.navigate([`/icx/member-360/${this.memberId}/policies/${this.policyId}/${target.product_coverage_id}/${mcov_id}`]);
    }

    routeBack(): void {
        this.router.navigate([`/icx/member-360/${this.memberId}/policies`]);
    }

    getMemberCoverageId(coverage): string {
        const mcov_id = coverage.member_coverages?.filter(
            (mcov) => mcov?.member?.member_number === this.memberId || mcov?.member?.id === this.memberId
        )[0]?.member_coverage_id;

        return mcov_id ? mcov_id : '';
    }
}
