import { Component, Input, OnInit } from '@angular/core';
import { getValue, stringBuilder } from '@zipari/web-utils';
import { FormattingService } from '../../../shared/services/formatting.service';

@Component({
    selector: 'pcp',
    templateUrl: './pcp.component.html',
    styleUrls: ['./pcp.component.scss'],
})
export class PcpComponent implements OnInit {
    tabConfig;

    @Input() config;
    @Input() provider;
    @Input() call;

    constructor(private formatService: FormattingService) {}

    ngOnInit() {
        this.tabConfig = { ...this.config };

        if (this.provider) {
            this.tabConfig.cards.forEach(card => {
                card.fields = card.fields.map(attr => this.formatService.formatConfigValueAsTemplate(attr, this.provider));
            });

            this.tabConfig.detailCards.forEach(card => {
                card.fields = card.fields.map(attr => this.formatService.formatConfigValueAsTemplate(attr, this.provider));
            });
        }

        // this.tabConfig.cards.forEach((card, idx) => {
        //     card.fields = this.config.cards[idx].fields.map(attr => this.reformat(attr, this.provider));
        // });

        // this.tabConfig.detailCards.forEach((card, idx) => {
        //     card.fields = this.config.detailCards[idx].fields.map(attr => this.reformat(attr, this.provider));
        // });
    }

    reformat(config, context) {
        if (!config.value || !config.value.includes('$')) {
            if (config.fields) {
                config.fields = config.fields.map(field => {
                    if (field.value && typeof field.value === 'string') {
                        if (field.value.indexOf('getValue:') >= 0) {
                            const prop = field.value.split('getValue:')[1];
                            field.value = getValue(context, prop);
                        } else {
                            field.value = stringBuilder(field.value, context);
                        }
                    }
                });
            }

            return config;
        } else {
            if (typeof config.value === 'string') {
                config.value = stringBuilder(config.value, context);
                if (config.fields) {
                    this.reformat(config, context);
                }
            }

            return config;
        }
    }
}
