import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TabsModule, ModelAttributeSectionsModule, DatagridModule, PaginatorModule, RippleModule, CardModule } from '@zipari/design-system';
import { ClaimDetailComponent } from './claim-detail.component';

@NgModule({
    declarations: [ClaimDetailComponent],
    imports: [CommonModule, TabsModule, ModelAttributeSectionsModule, DatagridModule, PaginatorModule, RippleModule, CardModule],
})
export class ClaimDetailModule {}
