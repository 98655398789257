import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpResponse } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

// import { RESPONSE_CODES } from '../../constants';
// import { ConfigurationService } from './../configuration/configuration.service';

// TODO bring this back when ready to implement
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    urlsThatDoNotRequireAuth: string[];

    constructor(private router: Router) {
        // const sitemapConfig = this.configurationService.getTemplateConfiguration('sitemap');
        // this.urlsThatDoNotRequireAuth = determineAllNonAuthenticatedRoutes(sitemapConfig);
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            tap(
                (event: HttpEvent<any>) => {
                    // eslint-disable-next-line no-empty
                    if (event instanceof HttpResponse) {
                    }
                },
                (err: unknown) => {
                    // const currentRoute = this.router.url;
                    // const isCurrentRouteProtected = !isUrlAnUnAuthenticatedRoute(currentRoute || '', this.urlsThatDoNotRequireAuth);
                    // const logoutIfCurrentRouteIsProtectedAndAPIResponseWasForbidden =
                    //     // err instanceof HttpErrorResponse && err.status === RESPONSE_CODES.FORBIDDEN && isCurrentRouteProtected;
                    // if (logoutIfCurrentRouteIsProtectedAndAPIResponseWasForbidden) {
                    //     this.store.dispatch(logout({}));
                    // }
                }
            )
        );
    }
}
