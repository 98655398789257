import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ButtonModule, FormControlModule } from '@zipari/design-system';

import { PanelFilterComponent } from './panel-filter.component';

@NgModule({
    declarations: [PanelFilterComponent],
    imports: [CommonModule, FormControlModule, ButtonModule],
    exports: [PanelFilterComponent],
})
export class PanelFilterModule {}
