import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { ConfigService } from 'src/app/shared/services';
import { FormManagerService } from 'src/app/icx/shared/services/form-manager.service';
import { ButtonConfig, DropdownConfiguration, TypeaheadConfiguration, FormattingService, ErrorEmptyConfig } from '@zipari/design-system';
import { MemberDataService } from 'src/app/icx/shared/services/member-data.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { FormConfiguration } from '../../billing-and-payments/billing-and-payments.model';
import { BenefitService } from '../benefits.service';
import { PoliciesResponse, PoliciesProductCoverage, PoliciesMemberCoverages } from '../../policies/policies.constants';

export interface BenefitsSearchPageConfig {
    title: string;
    illustration: string;
    illustration_text: string;
    src: string;
    pageLevelFilters: FormConfiguration;
    searchFilters: FormConfiguration;
    submit: ButtonConfig;
    clear: ButtonConfig;
    filterOnPolicyStatus: string;
}
@Component({
    selector: 'benefits-search',
    templateUrl: './benefits-search.component.html',
    styleUrls: ['./benefits-search.component.scss'],
})
export class BenefitsSearchComponent implements OnInit {
    public pageConfig: BenefitsSearchPageConfig;
    public pageLevelForm: FormGroup = new FormGroup({});
    public searchForm: FormGroup = new FormGroup({});
    public loadedPeriods: boolean = false;
    public loadedCoverage: boolean = false;
    public submitButtonDisabled: boolean = true;
    private memberId: string = '';
    private policiesList: PoliciesResponse[] = [];
    private filteredPolicies: PoliciesResponse[] = [];
    private coverageList: PoliciesProductCoverage[] = [];
    private benefitPeriods: any[] = [];
    private onDestroy$: Subject<any> = new Subject();
    private currentSearchByValue: string = null;
    private service_category_ID_selected: string = null;
    showErrorScreen: boolean = false;
    errorConfig: ErrorEmptyConfig;
    constructor(
        private panelService: SidePanelService,
        private configService: ConfigService,
        private memberDataService: MemberDataService,
        private formManagerService: FormManagerService,
        private benefitsService: BenefitService,
        private route: ActivatedRoute,
        private router: Router,
        private formattingService: FormattingService,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit(): void {
        this.memberId = this.route.snapshot.params.member;
        this.pageConfig = this.configService.getControlPanelConfig('benefits-search');
        this.requestCoveragesData();

        this.searchForm = this.formManagerService.populateFormGroup(this.pageConfig?.searchFilters.controls);
        this.searchForm.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
            this.handleSearchUpdates(value);
        });

        this.benefitsService
            .typeaheadListUpdated()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.handleTypeaheadUpdate(res);
            });
    }

    ngOnDestroy() {
        this.onDestroy$.next();
    }

    setupListeners() {
        this.pageLevelForm.controls['benefit_periods'].valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((value) => {
            this.saveBenefitPeriodData(value);
        });
        this.pageLevelForm.controls['coverage_options'].valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe((planId) => {
            // Disable and clear bene dropdown
            this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
                if (control.prop === 'benefit_periods') {
                    control.isDisabled = true;
                }
            });
            this.pageConfig.pageLevelFilters.controls = [...this.pageConfig.pageLevelFilters.controls];
            this.pageLevelForm.controls['benefit_periods'].reset(null, { emitEvent: false });
            this.searchForm.reset(undefined);
            this.enableDisableSubmit();
            if (planId) {
                this.requestBenefitPeriods(planId);
                this.requestCategoriesAndServicesData(planId);
            }
        });
    }

    requestCoveragesData() {
        this.policiesList = [];
        this.coverageList = [];
        this.filteredPolicies = [];
        this.memberDataService
            .getPoliciesList(this.memberId)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(
                (res) => {
                    this.policiesList = res.details?.results;

                    // filtering policies list based on configuration
                    if (this.policiesList?.length) {
                        if (this.pageConfig?.filterOnPolicyStatus) {
                            this.filteredPolicies = this.policiesList.filter(
                                (p: PoliciesResponse) => p.policy_status === this.pageConfig.filterOnPolicyStatus
                            );
                        } else {
                            this.filteredPolicies = [...this.policiesList];
                        }
                    }

                    this.filteredPolicies.forEach((policy: PoliciesResponse) => {
                        policy.product_coverages.forEach((product_coverage: PoliciesProductCoverage) => {
                            const foundMemberCoverage = product_coverage.member_coverages.find(
                                (memberCoverage: PoliciesMemberCoverages) =>
                                    memberCoverage?.member?.member_number === this.memberId || memberCoverage?.member?.id === this.memberId
                            );
                            if (foundMemberCoverage) {
                                product_coverage.policy_id = policy.policy_number;
                                this.coverageList.push(product_coverage);
                            }
                        });
                    });
                    this.populateCoverageDropdownOptions();
                },
                (err) => {
                    this.handleError(err);
                }
            );
    }

    requestBenefitPeriods(planId: number) {
        const mcovid = this.benefitsService.getMemeberCoverageID(planId, this.coverageList, this.memberId);

        this.benefitsService
            .getBenefitPeriods(this.memberId, mcovid)
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((res) => {
                this.benefitPeriods = res.results;
                this.populateBenefitPeriodsDropdowns();
            });
    }

    requestCategoriesAndServicesData(planId: string) {
        this.savePlanData(planId);
        this.benefitsService.getAllBenefitsServices();
        this.benefitsService.getAllBenefitsCategories();
    }

    savePlanData(planId: string) {
        this.benefitsService.planId = planId;
        this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
            if (control.prop === 'coverage_options') {
                control.options.forEach((option) => {
                    if (option.value === planId) {
                        this.benefitsService.selectedPlan = {
                            label: option.label,
                            value: option.value,
                        };
                    }
                });
            }
        });
    }

    populateCoverageDropdownOptions() {
        this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
            if (control.prop === 'coverage_options') {
                this.coverageList.forEach((coverage: PoliciesProductCoverage) => {
                    if (coverage.external_plan_name) {
                        const effective_date = this.formattingService.restructureValueBasedOnFormat(coverage.effective_date, {
                            format: 'DATE',
                        });

                        const termination_date = this.formattingService.restructureValueBasedOnFormat(coverage.termination_date, {
                            format: 'DATE',
                        });

                        let label = `${coverage.external_plan_name}`;
                        if (effective_date && termination_date) {
                            label += ` (${effective_date} - ${termination_date})`;
                        }
                        control.options.push({
                            label,
                            value: coverage.plan_id,
                            name: coverage.policy_id,
                        });
                    }
                });
            }
        });

        this.pageLevelForm = this.formManagerService.populateFormGroup(this.pageConfig.pageLevelFilters.controls);
        this.setupListeners();
        this.loadedCoverage = true;
    }

    populateBenefitPeriodsDropdowns() {
        this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
            if (control.prop === 'benefit_periods') {
                control.isDisabled = false;
                control.options = this.benefitPeriods.map((period) => ({
                    label: `${this.formattingService.restructureValueBasedOnFormat(period.start_date, {
                        format: 'DATE',
                    })} - ${this.formattingService.restructureValueBasedOnFormat(period.end_date, { format: 'DATE' })}`,
                    value: period.id,
                }));
            }
        });
        this.pageConfig.pageLevelFilters.controls = [...this.pageConfig.pageLevelFilters.controls];
    }

    clearFormClicked() {
        this.pageLevelForm.reset(undefined);
        this.searchForm.reset(undefined);
        this.submitButtonDisabled = true;
    }

    submitFormClicked() {
        const currentSearchValues = this.searchForm.value;

        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Benefits_searched }, this.memberId, {
            benefit_search_type: currentSearchValues['search_by'],
        });

        if (!currentSearchValues['search_by']) {
            return;
        }
        if (!this.service_category_ID_selected) {
            return;
        }

        if (this.pageLevelForm.value['coverage_options']) {
            this.benefitsService.currentPolicyId = this.getPolicyId();
        }

        if (currentSearchValues['search_by'] === 'category') {
            this.router.navigate([`/icx/member-360/${this.memberId}/benefits/categories/${this.service_category_ID_selected}`]);
        }

        // get service and send to service page when ready
        if (currentSearchValues['search_by'] === 'service') {
            this.router.navigate([`/icx/member-360/${this.memberId}/benefits/services/${this.service_category_ID_selected}`]);
        }
    }

    saveBenefitPeriodData(benefitPeriodId: string) {
        this.benefitsService.benefitPeriodId = benefitPeriodId;
        this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
            if (control.prop === 'benefit_periods') {
                control.options.forEach((option) => {
                    if (option.value === benefitPeriodId) {
                        this.benefitsService.selectedBenefitPeriod = {
                            label: option.label,
                            value: option.value,
                        };
                    }
                });
            }
        });
        this.searchForm.reset(undefined);
        this.enableDisableSubmit();
    }

    handleSearchUpdates(value) {
        if (value['search_by'] && value['search_by'] !== this.currentSearchByValue) {
            this.benefitsService.updateTypeahead(value['search_by']);
            this.currentSearchByValue = value['search_by'];
            this.searchForm.controls['search_term'].setValue(null, { emitEvent: false });
        }
        if (!value['search_term']) {
            this.enableDisableSubmit();
            this.service_category_ID_selected = null;
        } else {
            this.enableDisableSubmit();
        }
    }

    handleTypeaheadUpdate(updatedOptions: any[]) {
        this.pageConfig.searchFilters.controls.forEach((control: TypeaheadConfiguration) => {
            if (control.prop === 'search_term') {
                control.options = updatedOptions.map((item) => ({
                    label: item.display_name,
                    name: item.id,
                    value: item.display_name,
                }));
            }
        });
    }

    typeaheadSelected(typeaheadEvent) {
        this.service_category_ID_selected = typeaheadEvent.event.name;
    }

    enableDisableSubmit() {
        const benefit_period = this.pageLevelForm.controls['benefit_periods'].value;
        const plan_name = this.pageLevelForm.controls['coverage_options'].value;
        const searchVal = this.searchForm.value['search_term'];
        const searchBy = this.searchForm.value['search_by'];
        this.submitButtonDisabled = plan_name && benefit_period && searchBy && searchVal ? false : true;
    }

    handleError(err) {
        this.loadedCoverage = true;
        this.showErrorScreen = true;
        const errorContents = err?.error?.errors;
        const userError =
            errorContents?.length &&
            (errorContents[0]?.user_error || (errorContents[0]?.user_error?.comments?.length && errorContents[0]?.user_error?.comments[0]));

        this.errorConfig = {
            image_url: 'https://d32ul9oyxvd2n5.cloudfront.net/illustrations/error-1.svg',
            alt: 'Error Image',
            title: 'Benefit Information failed to load',
            description: userError || 'Unknown Error. Please try again later.',
        };
    }

    getPolicyId() {
        let policy_id;
        this.pageConfig.pageLevelFilters.controls.forEach((control: DropdownConfiguration) => {
            if (control.prop === 'coverage_options') {
                policy_id = control.options.filter((option) => option.value === this.pageLevelForm.controls['coverage_options'].value)[0]
                    .name;
            }
        });

        return policy_id;
    }
}
