import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'detail-tabs',
    templateUrl: './detail-tabs.component.html',
    styleUrls: ['./detail-tabs.component.scss'],
})
export class DetailTabsComponent implements OnInit {
    @Input() tabConfig;
    @Input() tabData;
    @Input() pageLevelControls?;
    @Input() noDataConfig?;
    @Output() changeCardData: EventEmitter<string> = new EventEmitter<string>();
    @Output() selectedTab: EventEmitter<any> = new EventEmitter<any>();
    @Output() pageSelected: EventEmitter<any> = new EventEmitter();
    activeTab;

    constructor() {}

    ngOnInit() {}

    tabSelected(ev) {
        this.activeTab = ev;
        this.selectedTab.emit(this.activeTab);
    }

    setCurrentPage(page) {
        this.pageSelected.emit(page);
    }

    updateCardData(selectedDropDownValue) {
        this.changeCardData.emit(selectedDropDownValue);
    }
}
