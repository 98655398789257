import { Injectable } from '@angular/core';

import { HTTP_INTERCEPTORS, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';

import { SessionService } from './session.service';

@Injectable()
export class SessionKeepAliveInterceptor implements HttpInterceptor {
    constructor(private session: SessionService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.session.resetTimer();

        return next.handle(req);
    }
}

export const SESSION_KEEP_ALIVE_PROVIDER: any = {
    provide: HTTP_INTERCEPTORS,
    useClass: SessionKeepAliveInterceptor,
    multi: true,
};
