import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

export interface LinkList {
    title: string;
    item: LinkListItem[];
}

export interface LinkListItem {
    label: string;
    route?: string;
    href?: string;
}

@Component({
    selector: 'link-list',
    templateUrl: './link-list.component.html',
    styleUrls: ['./link-list.component.scss'],
})
export class LinkListComponent {
    @Input() config: LinkList;

    constructor(public router: Router) {}

    linkClicked(val) {
        if (val && val.route) {
            this.router.navigate(val.route);
        } else if (val && val.href) {
            window.open(val.href, '_blank');
        }
    }
}
