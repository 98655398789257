import { Component, Input, Output, EventEmitter } from '@angular/core';
import { SingleTabData } from '../../finanicial-accumulator.model';

@Component({
    selector: 'progress-bar-grid',
    templateUrl: './progress-bar-grid.component.html',
    styleUrls: ['./progress-bar-grid.component.scss'],
})
export class ProgressBarGridComponent {
    // todo: create types when API integration
    @Input() data: SingleTabData;
    @Input() modalText: string;
    @Output() openClaimsModal: EventEmitter<any> = new EventEmitter();

    claimsClicked(card) {
        this.openClaimsModal.emit(card);
    }
}
