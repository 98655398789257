import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'product-usage',
    templateUrl: './product-usage.component.html',
    styleUrls: ['./product-usage.component.scss'],
})
export class ProductUsageComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
