import { HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';

import { Subscription } from 'rxjs';
import { DocumentsService } from '../../services';

import { errorDuration, FileRestrictions } from './file-uploader.constants';
import { FileUploaderService } from './file-uploader.service';

@Component({
    selector: 'file-uploader',
    templateUrl: './file-uploader.component.html',
    styleUrls: ['./file-uploader.component.scss'],
    providers: [FileUploaderService],
})
export class FileUploaderComponent implements OnInit, OnChanges, OnDestroy {
    @Input() restrictions: FileRestrictions;
    @Input() endpoint: string;
    @Input() fileMetadata: any;
    @Input() disabled: any;

    @Output() fileFailedUpload = new EventEmitter();
    @Output() fileUploaded = new EventEmitter();

    uploadErr;
    errorMessage;
    file;
    loaded = 0;
    total = 0;

    newFileSub: Subscription;
    uploadErrorSub: Subscription;
    clearFileTimeout;
    clearErrMsg;

    constructor(public fileUploadService: FileUploaderService, private documentsService: DocumentsService) {}

    ngOnInit() {
        if (!this.endpoint) {
            throw new Error('You must provide an endpoint to the file uploader');
        }

        this.buildForm();

        this.newFileSub = this.fileUploadService.newFile.subscribe((newFile: File) => {
            this.onNewFile(newFile);
        });

        this.uploadErrorSub = this.fileUploadService.uploadError.subscribe((errorMessage: string) => {
            this.errorMessage = errorMessage;

            this.clearErrMsg = setTimeout(() => {
                this.errorMessage = null;
            }, errorDuration);
        });
    }

    buildForm() {}

    onNewFile(file: File) {
        this.uploadErr = false;
        this.file = file;
        this.loaded = 0;
        this.total = 0;

        this.documentsService.uploadFile(this.endpoint, file, this.fileMetadata).subscribe(
            response => {
                if (response.type === HttpEventType.UploadProgress) {
                    this.loaded = (<HttpProgressEvent>response).loaded;
                    this.total = (<HttpProgressEvent>response).total;
                }

                if (response instanceof HttpResponse) {
                    this.fileUploaded.emit(response.body);
                    this.clearFile();
                }
            },
            err => {
                console.error(err);
                this.uploadErr = true;
                this.clearFileTimeout = setTimeout(() => {
                    this.clearFile();
                }, errorDuration);

                this.fileFailedUpload.emit({
                    cancel: () => {
                        this.clearFileTimeout = null;
                    },
                });
            }
        );
    }

    clearFile() {
        this.file = null;
    }

    ngOnChanges() {
        this.fileUploadService.restrictions = new FileRestrictions(this.restrictions);
        this.fileUploadService.disabled = this.disabled;
    }

    ngOnDestroy() {
        this.uploadErrorSub.unsubscribe();
        this.newFileSub.unsubscribe();
    }
}
