import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { AuthCardOptions } from '../authentication.constants';

@Component({
    selector: 'auth-card',
    templateUrl: './auth-card.component.html',
    styleUrls: ['./auth-card.component.scss'],
})
export class AuthCardComponent {
    @Input() formGroup: FormGroup;
    @Input() config: AuthCardOptions;
    @Input() errorMessage: string[];
    @Input() success: boolean;
    @Input() disableButton: boolean;

    @Output() linkClicked = new EventEmitter<any>();
    @Output() buttonClicked = new EventEmitter<any>();

    constructor(public route: ActivatedRoute) {}
}
