import { Component, OnInit } from '@angular/core';
import { ConfigService, AuthService } from 'src/app/shared/services';
import { ActivatedRoute } from '@angular/router';
import { SidePanelService } from '../../services/side-panel.service';

@Component({
    selector: 'login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    config;

    constructor(
        private panelService: SidePanelService,
        private configService: ConfigService,
        public authService: AuthService,
        private route: ActivatedRoute
    ) {
        this.panelService.toggleSidePanel(false);
    }

    ngOnInit() {
        this.config = this.configService.configs[this.route.snapshot.data.marketSegment];
    }
}
