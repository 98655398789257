import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'error-screen',
    templateUrl: './error-screen.component.html',
    styleUrls: ['./error-screen.component.scss'],
})
export class ErrorScreenComponent implements OnInit {
    @Input() config;
    @Input() error;

    updatedConfig;

    constructor() {}

    ngOnInit(): void {
        this.updateConfig();
    }

    updateConfig() {
        const errorContents = this.error?.error?.errors;
        const userError =
            errorContents?.length && errorContents[0]?.user_error?.comments?.length && errorContents[0]?.user_error?.comments[0];

        this.updatedConfig = {
            ...this.config,
            description: userError || this.config.description,
        };
    }
}
