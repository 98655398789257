import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { SharedModule } from '../../shared.module';
import { CoverageEffectiveDateModule } from '../../templates/enrollment/coverage-effective-date/coverage-effective-date.module';
import { ZipBusyModule } from '../zip-busy';

import { AddressComponent } from './address/address.component';
import { AutopayComponent } from './autopay/autopay.component';
import { BasicAddressComponent } from './basic-address/basic-address.component';
import { BrandColorComponent } from './brand-color/brand-color.component';
import { BrokerAssistanceComponent } from './broker-assistance/broker-assistance.component';
import { CitiesComponent } from './cities/cities.component';
import { CompanyAddressComponent } from './company-address/company-address.component';
import { CountiesComponent } from './counties/counties.component';
import { CoverageEffectiveControlComponent } from './coverage-effective-control/coverage-effective-control.component';
import { CustomFormElementComponent } from './custom-form-element.component';
import { DropdownOtherComponent } from './dropdown-other/dropdown-other.component';
import { FormArrayComponent } from './form-array/form-array.component';
import { HiddenComponent } from './hidden/hidden.component';
import { IndustriesComponent } from './industries/industries.component';
import { InputNaComponent } from './input-na/input-na.component';
import { OfficeHoursComponent } from './office-hours/office-hours.component';
import { OptionalDateComponent } from './optional-date/optional-date.component';
import { PasswordsComponent } from './passwords/passwords.component';
import { SsnMultiFieldComponent } from './ssn-multi-field/ssn-multi-field.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { WhoseCoveredComponent } from './whose-covered/whose-covered.component';

@NgModule({
    declarations: [
        CustomFormElementComponent,
        AddressComponent,
        OfficeHoursComponent,
        FormArrayComponent,
        BasicAddressComponent,
        IndustriesComponent,
        DropdownOtherComponent,
        OptionalDateComponent,
        CompanyAddressComponent,
        CountiesComponent,
        InputNaComponent,
        WhoseCoveredComponent,
        PasswordsComponent,
        BrokerAssistanceComponent,
        AutopayComponent,
        TermsAndConditionsComponent,
        CoverageEffectiveControlComponent,
        HiddenComponent,
        SsnMultiFieldComponent,
        BrandColorComponent,
        CitiesComponent,
    ],
    imports: [CommonModule, FormControlModule, ButtonModule, ZipBusyModule, SharedModule, ReactiveFormsModule, CoverageEffectiveDateModule],
    exports: [CustomFormElementComponent, OptionalDateComponent],
})
export class CustomFormElementModule {}
