import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { slideRight, fade } from '../../../../shared/animations';

@Component({
    selector: 'new-message',
    templateUrl: './new-message.component.html',
    styleUrls: ['./new-message.component.scss'],
    animations: [slideRight, fade],
})
export class NewMessageComponent implements OnInit {
    @Input() message;
    @Output() cancelDraft = new EventEmitter<boolean>();
    messageSent: boolean = false;

    category = [
        {
            type: 'dropdown',
            icon: 'chevron_down',
            message_dropdown: true,
            options: [
                {
                    label: 'Lost Card',
                    value: 'lost_card',
                    key: 'lost_card',
                },
                {
                    label: 'Coverage Question',
                    value: 'coverage',
                    key: 'coverage',
                },
                {
                    label: 'Claims',
                    value: 'claims',
                    key: 'claims',
                },
            ],
        },
    ];

    categories = [
        {
            key: 'lost_card',
            prop: 'lost_card',
            type: 'dropdown',
            label: 'Category',
            default: 'Lost_card',
            required: true,
            options: [
                {
                    label: 'Lost Card',
                    value: 'lost_card',
                    key: 'lost_card',
                },
                {
                    label: 'Coverage Question',
                    value: 'coverage',
                    key: 'coverage',
                },
                {
                    label: 'Claims',
                    value: 'claims',
                    key: 'claims',
                },
            ],
        },
    ];

    constructor() {}

    ngOnInit() {}

    sendMessage() {
        this.messageSent = !this.messageSent;
    }

    closeMessage() {
        this.messageSent = false;
        this.cancelDraft.emit();
        this.message = false;
    }
}
