import { Component, OnInit, Input } from '@angular/core';
import { Datagrid } from '@zipari/design-system';
import * as _ from 'lodash';
import { BenefitDetailsBenefits } from '../../benefits.model';

export enum BenefitParticipationLevel {
    in_network = 'In Network',
    out_of_network = 'Out of Network',
}
@Component({
    selector: 'benefits-table',
    templateUrl: './benefits-table.component.html',
    styleUrls: ['./benefits-table.component.scss'],
})
export class BenefitsTableComponent implements OnInit {
    @Input() benefits: BenefitDetailsBenefits[];
    public datagridConfig: Datagrid = {
        columns: [],
        statuses: {},
        noData: {
            message: 'No Data Available',
        },
    };

    public datagridData: {
        benefit_category_display_text: string;
        [key: string]: string;
    }[] = [];
    constructor() {}

    ngOnInit(): void {
        this.generateTableConfig();
    }

    generateTableConfig() {
        this.benefits = this.benefits?.filter((benefit) => benefit.participation_level || benefit.benefit_category_display_text);
        if (!this.benefits?.length) {
            return;
        }
        const headerGroups = _.groupBy(this.benefits, (benefit) => benefit.participation_level);

        const colNames = Object.keys(headerGroups);
        this.datagridConfig.columns.push({
            label: '',
            prop: 'benefit_category_display_text',
            format: 'text',
            sortable: false,
            isLeftAligned: true,
            isCenterAligned: false,
            isRightAligned: false,
        });

        colNames.forEach((col) => {
            this.datagridConfig.columns.push({
                label: BenefitParticipationLevel[col] || col,
                prop: col,
                tooltipProp: `${col}_tooltip`,
                format: 'text',
                sortable: false,
                isLeftAligned: true,
                isCenterAligned: false,
                isRightAligned: false,
            });
        });

        const categories = _.groupBy(this.benefits, (benefit: BenefitDetailsBenefits) => benefit.benefit_category_display_text);

        Object.keys(categories).forEach((category) => {
            const rowData = {
                benefit_category_display_text: category,
            };

            colNames.forEach((col) => {
                categories[category].forEach((item: BenefitDetailsBenefits) => {
                    if (item.participation_level === col) {
                        rowData[col] = item.benefit_display_text;
                        if (item.benefit_category_display_text) {
                            rowData[`${col}_tooltip`] = item.limitation_display;
                        }
                    }
                });
            });
            this.datagridData.push(rowData);
        });
        this.datagridData = [...this.datagridData];
    }
}
