import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { ConfigService } from 'src/app/shared/services';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { controlTypes, DropdownConfiguration, FormattingService } from '@zipari/design-system';
import { MemberDataService } from 'src/app/icx/shared/services/member-data.service';
import { FormManagerService } from 'src/app/icx/shared/services/form-manager.service';
import { cloneObject } from '@zipari/web-utils';
import { getAllCodeDescription, getSingleCodeDescription } from 'src/app/icx/shared/utils/code-description';
import { sortTabs } from 'src/app/icx/shared/utils/sorting-tabs';
import { ClaimLineConfig, ClaimLine, ClaimLineTab } from './claim-line.model';

@Component({
    selector: 'claim-line',
    templateUrl: './claim-line.component.html',
    styleUrls: ['./claim-line.component.scss'],
})

// Claim Line Details
export class ClaimLineComponent implements OnInit {
    public lineSelectorFilters: FormGroup = new FormGroup({});
    public lineSelectorControls: DropdownConfiguration[] = [
        {
            ariaLabel: 'Select Claim Line',
            label: null,
            placeholder: 'Select Claim Line',
            prop: 'line_options',
            type: controlTypes.dropdown,
            options: [],
        },
    ];

    public isLoading: boolean;
    public tabLabels: string[];
    private memberNumber: string;
    private claimId: string;
    public claimLineId: string;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private configService: ConfigService,
        private panelService: SidePanelService,
        private analyticsService: AnalyticsService,
        private memberDataService: MemberDataService,
        private formattingService: FormattingService,
        private formManagerService: FormManagerService,
        private cdr: ChangeDetectorRef
    ) {
        this.panelService.toggleSidePanel(false);
    }
    claim: any;
    claimLines: ClaimLine[];
    claimLineConfig: ClaimLineConfig;
    updatedClaimLineConfig: ClaimLineConfig;
    currentLine: ClaimLine;
    pageTabData: {
        line: any;
        lineDetail: any;
        claimDetail: any;
    } = {
        line: {},
        lineDetail: {},
        claimDetail: {},
    };

    ngOnInit() {
        this.claimLineConfig = this.configService.getControlPanelConfig<any>('claim-line');
        this.updatedClaimLineConfig = cloneObject(this.claimLineConfig);
        this.setupTablabels();

        this.memberNumber = this.route.snapshot.params.member;
        this.claimId = this.route.snapshot.params.claim_id;
        this.claimLineId = this.route.snapshot.params.claim_line;
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.ClaimsLineDetail_viewed }, this.memberNumber);

        this.getData();
        this.lineSelectorFilters = this.formManagerService.populateFormGroup(this.lineSelectorControls);
        this.lineSelectorFilters.controls['line_options'].valueChanges.subscribe((event: any) => {
            if (event) {
                const targetRoute = `/icx/member-360/${this.memberNumber}/claims/${this.claimId}/claim-line/${event}`;
                this.router.navigate([targetRoute]);
                this.claimLineId = event;
                this.getData();
            }
        });
    }

    setupTablabels() {
        this.tabLabels =
            this.updatedClaimLineConfig?.tabs &&
            Object.keys(this.updatedClaimLineConfig?.tabs)
                .map((key) => this.updatedClaimLineConfig?.tabs[key])
                .sort(sortTabs)
                .map((tab: ClaimLineTab) => tab.tabLabel);
    }

    routeBack() {
        const targetRoute = `/icx/member-360/${this.memberNumber}/claims/${this.claimId}`;
        this.router.navigate([targetRoute]);
    }

    keepOrder(a, b) {
        return a.value.position - b.value.position;
    }

    getData() {
        this.isLoading = true;
        this.updatedClaimLineConfig = cloneObject(this.claimLineConfig);
        this.memberDataService.getClaimDetails(this.claimId).subscribe((response) => {
            this.claim = response.details;
            this.pageTabData.claimDetail = this.claim;

            if (this.claim.claim_type && this.updatedClaimLineConfig?.claim_type_enum[this.claim.claim_type]) {
                this.claim.claim_type = this.updatedClaimLineConfig.claim_type_enum[this.claim.claim_type];
            }

            this.memberDataService.getClaimLinesList(this.claimId, false).subscribe((res: any) => {
                this.claimLines = res.details.results;

                // create options for line selector with configurable values
                this.claimLines.forEach((line: ClaimLine, i: number) => {
                    line['line_index'] = i;
                    this.lineSelectorControls[0].options.push({
                        label: `Viewing Claim Line ${line['line_index'] + 1} of ${this.claimLines.length}`,
                        value: line[this.updatedClaimLineConfig.routeParam],
                    });
                    if (
                        line[this.updatedClaimLineConfig.routeParam] &&
                        line[this.updatedClaimLineConfig.routeParam].toString() === this.claimLineId.toString()
                    ) {
                        this.currentLine = line;
                    }
                });

                if (this.currentLine) {
                    this.lineSelectorFilters.controls['line_options'].setValue(this.currentLine[this.updatedClaimLineConfig.routeParam], {
                        emitEvent: false,
                    });
                    if (this.currentLine.procedures?.length) {
                        this.currentLine.procedureCodeDescription = getSingleCodeDescription(
                            this.currentLine.procedures[0],
                            'code',
                            'description'
                        );
                        this.currentLine.procedures.forEach((procedure) => {
                            if (procedure.coding_system?.toLowerCase() === 'hcpcs') {
                                this.currentLine.hcpcs_value = getSingleCodeDescription(procedure, 'code', 'description');
                            }
                        });
                    }

                    if (this.currentLine.modifiers?.length) {
                        this.currentLine.modifierValueDescription = getAllCodeDescription(
                            this.currentLine.modifiers,
                            'value',
                            'description'
                        );
                    }

                    if (this.currentLine.diagnoses?.length) {
                        this.currentLine.diagnosisCodeDescription = getAllCodeDescription(
                            this.currentLine.diagnoses,
                            'code',
                            'description'
                        );
                    }
                    this.pageTabData.line = this.currentLine;
                }

                this.setSidebarData();

                this.memberDataService.getClaimLinesDetail(this.claimId, this.claimLineId).subscribe((claimLineDetailRes) => {
                    const currentClaimLineDetail = claimLineDetailRes.details;
                    if (currentClaimLineDetail.provider_additional_detail) {
                        currentClaimLineDetail.provider_additional_detail.forEach((detail) => {
                            if (detail.provider_type === 'rendering') {
                                currentClaimLineDetail.rendering_provider_status = detail.participating_status;
                            } else if (detail.provider_type === 'referring') {
                                currentClaimLineDetail.referring_provider_status = detail.participating_status;
                            }
                        });
                    }
                    if (currentClaimLineDetail?.rendering_providers?.is_covering_provider) {
                        this.updatedClaimLineConfig.tabs.provider.attributeConfig.sections[0].statusText = 'covering';
                    }
                    this.pageTabData.lineDetail = currentClaimLineDetail;
                    this.cdr.detectChanges();
                    this.isLoading = false;
                });
            });
        });
    }

    setSidebarData() {
        if (this.currentLine) {
            this.claim.serviceDates = `${this.formattingService.restructureValueBasedOnFormat(this.currentLine.service_from_date, {
                format: 'DATE',
            })}${
                this.currentLine.service_to_date
                    ? ` - ${this.formattingService.restructureValueBasedOnFormat(this.currentLine.service_to_date, {
                          format: 'DATE',
                      })}`
                    : ''
            }`;
            this.claim = { ...this.claim };
        }
    }
}
