import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, isDevMode, OnInit, Output, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';
import { BuildQueryPipe, cloneObject } from '@zipari/web-utils';
import { Subscription } from 'rxjs';
import { AnalyticsService } from '../../../services/analytics.service';
import { validCXEvents, validGAEvents } from '../../../constants/analytics';

import { accordionX, fade, slideRight } from '../../../animations';
import { ConfigService } from '../../../services';
import { ZipGoogleMapService } from '../../map/map.service';
import { SearchService } from '../search.service';

@Component({
    selector: 'provider-search',
    templateUrl: './provider-search.component.html',
    styleUrls: ['./provider-search.component.scss'],
    animations: [accordionX, fade, slideRight],
})
export class ProviderSearchComponent implements OnInit {
    @Input() queryParams: string;
    @Input() stepConfig;
    @Input() workflow;
    @Input() initialDistance: number;

    @Output() previousStep = new EventEmitter();
    @Output() completeStep = new EventEmitter();

    @ViewChild('scrollToSearchResults') scrollToSearchResults: ElementRef;

    preFiltersResponse: any;
    backendResponse: any;
    backendResults: any[] = [];
    isAPILoading: boolean = true;
    selectedProviders;
    showDetails = false;
    busy: Subscription;

    public config;
    public searchBarConfig;
    asideHeaderConfig;

    public filtersForm: FormGroup;
    public showFilters = false;

    selectedResult: any;
    selectedResultIdx: number;
    pins: any[] = [];

    membersArray: any[] = [];
    specialtiesConfig;

    zipcode;
    distance: number;
    name: string = '';
    plan_id: string;
    specialty: string;

    zoom = 15;
    centerLat = 40.703039;
    centerLng = -73.990457;
    searchLat;
    searchLng;

    currentPage = 1;
    pageLength = 10;
    lastPage: number;

    workflowId;
    workflowValues;
    CXContext;

    public get selectedResultDirectionsUrl() {
        return this.searchService.getDirections(
            [
                this.selectedResult.location.street_name_1,
                this.selectedResult.location.city_name,
                this.selectedResult.location.state,
                this.selectedResult.location.zip_code,
            ].join(' ')
        );
    }

    public get resultIsSelected(): boolean {
        return !!this.backendResults.find(result => result.selected);
    }

    constructor(
        private http: HttpClient,
        private formControlService: FormControlService,
        private configService: ConfigService,
        private searchService: SearchService,
        private buildQuery: BuildQueryPipe,
        private zipMapService: ZipGoogleMapService,
        public analyticsService: AnalyticsService
    ) {}

    ngOnInit() {
        this.config = this.stepConfig || this.configService.getPageConfig('doctorSearch');
        this.backendResponse = {};
        this.preFiltersResponse = {};
        this.initFields();
        this.setupFiltersForm();
    }

    initFields() {
        this.workflowValues = this.workflow.values;
        this.workflowId = this.workflow.id;
        this.workflowValues.pcp ? (this.selectedProviders = this.workflowValues.pcp) : (this.selectedProviders = {});
        this.zipcode = this.workflowValues.permanent_address.zipcode;

        this.searchBarConfig = this.config.searchBarConfig;
        this.specialtiesConfig = this.config.filters['groups'].filter(control => control.prop === 'specialties');
        this.asideHeaderConfig = this.config.asideHeaderConfig;
        this.setMemberDetails();
        this.applySelectedPCP();

        if (!this.workflowValues.child_only) {
            this.getPrefliterResults(this.workflowId, 'subscriber');
        }

        if (this.workflowValues.child_only) {
            this.getPrefliterResults(this.workflowId, 'dependent_0');
        }
    }

    getPrefliterResults(workflowId, memberType) {
        const prefilterEndpoint = `${this.config.preFilterEndpoint}?workflow_id=${workflowId}&member=${memberType}`;

        this.busy = this.http.get(prefilterEndpoint).subscribe(response => {
            this.preFiltersResponse = response;

            this.specialtiesConfig[0].controls[0].options = [];

            this.preFiltersResponse.results.forEach(result => {
                result.value = result.id;
                result.label = result.name;
                delete result.id;
                delete result.name;
                this.specialtiesConfig[0].controls[0].options.push(result);
            });

            this.zipMapService.IsGoogleMapAPILoaded().then(() => {
                this.zipMapService.GetLatLngFromAddress(`zipcode +${this.zipcode}`).then(latlng => {
                    this.searchLat = latlng.lat();
                    this.searchLng = latlng.lng();
                    this.specialty = this.preFiltersResponse['results'][0].label;
                    this.plan_id = this.preFiltersResponse.external_id;
                    this.updateResults();
                });
            });
        });
    }

    updateResults(queryParams: string = '') {
        this.isAPILoading = true;
        this.busy = this.http.get(this.fullRequest(queryParams)).subscribe(
            response => {
                this.backendResponse = response;
                this.backendResults = this.buildDoctorResults(this.backendResponse['results']);
                this.pins = this.buildDoctorPins(this.backendResults);
                this.backendResults = this.determineSelectedPCP(this.backendResults, this.selectedProviders);
                this.isAPILoading = false;
            },
            error => {
                if (isDevMode()) {
                    console.error(error);
                }
                this.isAPILoading = false;
                this.backendResults = [];
                this.pins = [];
            }
        );
    }

    fullRequest(queryParams: string = ''): string {
        let request: string = this.stepConfig.endpoint.slice(0, -1);
        const params = [
            { page: this.currentPage },
            { page_size: this.pageLength },
            { latitude: this.searchLat },
            { longitude: this.searchLng },
            { distance: this.distance },
            { specialties: this.specialty },
            { plan_id: this.plan_id },
        ];

        request = this.buildQuery.transform(request, params);

        !!this.name ? (request += `&name=${this.name}`) : request;

        if (queryParams && queryParams.length > 0) {
            request += queryParams;
        }

        return request;
    }

    toggleFilters() {
        this.showFilters = !this.showFilters;
    }

    setupFiltersForm(): void {
        this.filtersForm = new FormGroup({});
        this.stepConfig.filters.groups.forEach(group => this.formControlService.addFormGroupToFormGroup(this.filtersForm, group));
        this.filtersForm.valueChanges.subscribe(change => {
            let filterParamString: string = '';
            if (!!change.specialties.specialties) {
                this.specialtiesConfig[0].controls[0].options.forEach(specialty => {
                    if (!!specialty.value) {
                        specialty.value.toString() === change.specialties.specialties
                            ? (this.specialty = specialty.label)
                            : change.specialties.specialties;
                    }
                });
            }

            const new_patients: string = change.filters.accepting_new_patients
                ? `&accepting_new_patients=${change.filters.accepting_new_patients}`
                : '';
            const gender: string = change.filters.gender ? `&gender=${change.filters.gender}` : '';
            const language: string = change.filters.language ? `&language=${change.filters.language}` : '';
            const ordering: string = change.sort_by.sort_by ? `&ordering=${change.sort_by.sort_by}` : '';
            const medical_group_affiliations: string = change.filters.medical_group_affiliations
                ? `&medical_group=${change.filters.medical_group_affiliations}`
                : '';
            const hospital_affiliations: string = change.filters.hospital_affiliations
                ? `&hospital=${change.filters.hospital_affiliations}`
                : '';

            const filterParamsArray: any[] = [ordering, new_patients, gender, hospital_affiliations, language, medical_group_affiliations];

            filterParamsArray.forEach((param: string) => {
                if (param !== '') {
                    filterParamString += param;
                }
            });

            this.currentPage = 1;
            this.updateResults(filterParamString);
        });
    }

    resetFilters() {
        this.currentPage = 1;
        this.filtersForm.reset();
    }

    buildDoctorResults(rawResults: any[]) {
        return rawResults.map(result => ({
            ...result,
            gender: result.gender,
            location: {
                street_name_1: `${result.address.street_name_1} ${result.address.street_name_2 ? result.address.street_name_2 : ''}`,
                city_name: result.address.city_name,
                state: result.address.state,
                zip_code: result.address.zip_code,
                coordinates: {
                    latlng: this.getDoctorCoordinates(result.address.location),
                },
            },
            accepting_new_patients: true,
            network_specific_id: result.network_specific_id,
            pcpName: {
                first: result.first_name,
                last: result.last_name,
            },
            phone: result.phone ? result.phone : '(212) 555-1234',
            picture:
                result.gender === 'M'
                    ? 'https://d32ul9oyxvd2n5.cloudfront.net/assets/maleDoctor.svg'
                    : 'https://d32ul9oyxvd2n5.cloudfront.net/assets/femaleDoctor.svg',
            selected: false,
        }));
    }

    getDoctorCoordinates(doctorLocation: { latitude: number; longitude: number }) {
        if (doctorLocation) {
            const lnglat = {
                lat: doctorLocation.latitude,
                lng: doctorLocation.longitude,
            };

            return this.searchService.areValidCoordinates(lnglat) ? lnglat : null;
        }

        return null;
    }

    buildDoctorPins(results: any[]) {
        return results.map((result, idx) => ({
            infoWindow: `
                    <div class="popup-container">
                        <img src="${result.picture}">
                        <div class="popup-content">
                            <p class="t-data t-bold">${result.first_name} ${result.last_name}</p>
                            <p class="t-caption">${result.specialties}</p>
                        </div>
                    </div>
                `,
            resultIndex: idx,
            latlng: result.location.coordinates.latlng,
        }));
    }

    setSelectedPCP() {
        const finalFormat: any = {};

        this.membersArray
            .filter(member => member.selectedProvider)
            .forEach(member => {
                finalFormat[member.type] = member.selectedProvider;

                if (member.type.indexOf('dependent') >= 0) {
                    if (!finalFormat.dependents) {
                        finalFormat.dependents = [];
                    }

                    const depInd = member.type.split('_')[1];

                    finalFormat.dependents[depInd] = member.selectedProvider;
                } else {
                    finalFormat[member.type] = member.selectedProvider;
                }
            });
        this.selectedProviders = finalFormat;
    }

    determineSelectedPCP(results: any[], selectedPCPs) {
        // determine if any providers have been selected
        if (Object.entries(selectedPCPs).length > 0) {
            // find current member type
            const currentMember = this.membersArray.find(member => member.isMemberSelected);

            // account for dependents array in this.selectedProviders
            if (!!selectedPCPs['dependents']) {
                selectedPCPs['dependents'].forEach((dependent, index) => {
                    selectedPCPs[`dependent_${index}`] = dependent;
                });
            }

            // assign pcp for current member
            const pcpSelectedForMember = selectedPCPs[currentMember.type];

            // IF pcp was already selected for the member.... select specific pcp id that was already selected
            if (!!pcpSelectedForMember) {
                results = results.map(result => {
                    result.selected = result.provider_id === pcpSelectedForMember.provider_id;

                    // if a pcp was selected... then store the appropriate values on the member
                    if (result.selected) {
                        currentMember.selectedProvider = result;
                        currentMember.isPCPSelected = true;
                    }

                    return result;
                });
            }
        }

        return results;
    }

    applySelectedPCP() {
        // determine if any providers have been selected
        if (!!this.selectedProviders) {
            this.membersArray.forEach(member => {
                if (!!this.selectedProviders[member.type]) {
                    member.selectedProvider = this.selectedProviders[member.type];
                    member.isPCPSelected = true;
                }
                if (!!this.selectedProviders['dependents']) {
                    this.selectedProviders['dependents'].forEach((dependent, index) => {
                        if (member.type === `dependent_${index}`) {
                            member.selectedProvider = dependent;
                            member.isPCPSelected = true;
                        }
                    });
                }
            });
        }
    }

    viewSelectedProfile(index: number) {
        this.showDetails = false;
        this.selectedResultIdx = index;
        this.selectedResult = this.backendResults[index];
        this.busy = this.http.get(`/api/scapi/providers/${this.selectedResult.provider_id}`).subscribe((response: any) => {
            this.selectedResult = {
                ...this.selectedResult,
                ...response,
                boardCertification: response.board_certifications.length > 0 ? response.board_certifications[0].board_certification : '',
                picture:
                    response.gender === 'M'
                        ? 'https://d32ul9oyxvd2n5.cloudfront.net/assets/maleDoctor.svg'
                        : 'https://d32ul9oyxvd2n5.cloudfront.net/assets/femaleDoctor.svg',
                pcpName: {
                    first: response.first_name,
                    last: response.last_name,
                },
                medical_groups: response.medical_groups.length > 0 ? response.medical_groups : '',
                hospital_affiliations: response.hospital_affiliations.length > 0 ? response.hospital_affiliations : '',
                languages: response.languages.length > 0 ? response.languages : '',
                degree: response.degrees.length > 0 ? response.degrees[0] : '',
            };
            this.showDetails = true;

            this.backendResults[index] = this.selectedResult;
        });
    }

    updateZipcodeSearch($event) {
        this.searchLat = $event.lat;
        this.searchLng = $event.lng;
        this.zipcode = $event.zipcode;
        this.currentPage = 1;
        this.updateResults();
    }

    updateDistance($event) {
        this.distance = $event;
        this.currentPage = 1;
        this.updateResults();
    }

    searchByName($event) {
        this.name = $event;
        this.currentPage = 1;
        this.updateResults();
    }

    setMemberDetails() {
        if (!!this.workflowValues.demographics) {
            const consumersArray: string[] = Object.keys(this.workflowValues.demographics);
            consumersArray.sort().reverse();
            consumersArray.forEach(consumer => {
                const member = {
                    name: '',
                    date_of_birth: '',
                    isMemberSelected: false,
                    isPCPSelected: false,
                    type: '',
                };

                switch (consumer) {
                    case 'subscriber':
                        member.name = `${this.workflowValues.subscriber.first_name} ${this.workflowValues.subscriber.last_name}`;
                        member.date_of_birth = this.workflowValues.subscriber.date_of_birth;
                        member.type = 'subscriber';
                        member.isMemberSelected = true;
                        this.membersArray.push(member);
                        break;
                    case 'spouse':
                        member.name = `${this.workflowValues.spouse.first_name} ${this.workflowValues.spouse.last_name}`;
                        member.date_of_birth = this.workflowValues.spouse.date_of_birth;
                        member.type = 'spouse';
                        this.membersArray.push(member);
                        break;
                    case 'dependents':
                        this.workflowValues.dependents.forEach((dependent, index) => {
                            const dependentMember = cloneObject(member);
                            dependentMember.name = `${dependent.first_name} ${dependent.last_name}`;
                            dependentMember.date_of_birth = dependent.date_of_birth;
                            dependentMember.type = `dependent_${index}`;
                            if (this.workflowValues.child_only) dependentMember.isMemberSelected = true;
                            this.membersArray.push(dependentMember);
                        });
                        break;
                }
            });
        }

        if (!!this.selectedProviders) {
            this.membersArray.forEach(member => {
                if (!!this.selectedProviders[member.type]) {
                    member.selectedProvider = this.selectedProviders[member.type];
                }
            });
        }
    }

    selectConsumer(memberArray: any[], selectedIndex) {
        if (!memberArray[selectedIndex].isMemberSelected) {
            memberArray.forEach(member => {
                member.isMemberSelected = false;
            });

            memberArray[selectedIndex].isMemberSelected = true;
            this.getPrefliterResults(this.workflowId, memberArray[selectedIndex].type);
        }
    }

    removePCP(clickedMember) {
        this.membersArray.forEach(member => {
            if (member === clickedMember) {
                member.isPCPSelected = false;
                delete member.selectedProvider;

                this.backendResults.forEach(result => {
                    result.selected = false;
                });
            }
        });
    }

    addResult(result) {
        this.membersArray.forEach(member => {
            if (member.isMemberSelected) {
                member.isPCPSelected = true;
                member.selectedProvider = result;
                this.selectedProviders[member.type] = member.selectedProvider;
            }
        });

        this.analyticsService.dispatchAnalytics({
            GAKey: validGAEvents['pcp_selection'],
            CXKey: validCXEvents['pcp_selection'],
        });

        result.selected = true;
    }

    removeResult(result) {
        this.membersArray.forEach(member => {
            if (member.isMemberSelected) {
                member.isPCPSelected = false;
                delete member.selectedProvider;
            }
        });

        this.analyticsService.dispatchAnalytics({
            GAKey: validGAEvents['pcp_remove_selection'],
            CXKey: validCXEvents['pcp_remove_selection'],
        });

        result.selected = false;
    }

    getDirections = (address: any) => this.searchService.getDirections(address);

    submit(): void {
        this.setSelectedPCP();
        this.completeStep.emit({ pcp: this.selectedProviders });
    }

    goToPrevious(): void {
        this.previousStep.emit();
    }

    onSkip(): void {
        this.completeStep.emit({ pcp: {} });
    }

    // ******* profile page methods..
    selectPin(pin: any) {
        this.viewSelectedProfile(pin.resultIndex);
    }

    selectResult(index) {
        this.selectedResultIdx = index;
        this.selectedResult = this.backendResults[index];
        this.viewSelectedProfile(this.selectedResultIdx);
    }

    viewAllResults() {
        delete this.selectedResult;
        delete this.selectedResultIdx;
    }

    // end of profile page methods.... ************

    goToPage(page) {
        this.currentPage = page;
        this.scrollToSearchResults.nativeElement.scrollIntoView({
            alignToTop: true,
            behavior: 'smooth',
        });
        this.updateResults();
    }
}
