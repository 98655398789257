import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DetailService } from 'src/app/shared/templates/detail/detail.service';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ConfigService, APIResponse, APIService } from 'src/app/shared/services';
import { SidePanelService } from 'src/app/icx/shared/services/side-panel.service';
import { PaginatorConfiguration } from '@zipari/design-system';
import { PaginatorService } from 'src/app/icx/shared/services/paginator.service';
import { PCPsResponse } from './providers.constants';
export interface CellClicked<T> {
    row: T;
    col: {
        format: string;
        name: string;
        originalIndex: number;
        priority: number;
        prop: string;
        sortable: boolean;
        width: string;
    };
    val: string;
}
export interface ProviderListPageConfig {
    [key: string]: any;
}
@Component({
    selector: 'providers',
    templateUrl: './providers.component.html',
    styleUrls: ['./providers.component.scss'],
})
export class ProvidersComponent implements OnInit {
    pageConfig: ProviderListPageConfig;
    pcpHistory: PCPsResponse[];
    pagingConfig: PaginatorConfiguration = {
        totalPages: 1,
        showingStart: 0,
        showingEnd: 0,
        totalResults: 0,
        currentPage: 1,
    };
    memberId: string;
    sortingElement: string = '';

    constructor(
        private configService: ConfigService,
        private route: ActivatedRoute,
        public detailService: DetailService,
        public router: Router,
        private panelService: SidePanelService,
        private api: APIService,
        private analyticsService: AnalyticsService,
        private paginatorService: PaginatorService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.pageConfig = this.configService.getControlPanelConfig(this.route.snapshot.data && this.route.snapshot.data['pageName']);
        this.setCurrentPage(1);
    }

    getProviders() {
        this.memberId = this.route.snapshot.params.member;
        const query = this.buildQueryParams();
        this.api.get(query).subscribe(
            (res: APIResponse<PCPsResponse>) => {
                if (res?.results) {
                    this.pcpHistory = [
                        ...res.results.map((data: PCPsResponse) => ({
                            ...data,
                            full_name: data.pcp_full_name,
                            dates: `${data.effective_date} - ${data.termination_date}`,
                        })),
                    ];
                    this.pagingConfig = this.paginatorService.setPagingConfig(this.pagingConfig, res.count, this.pageConfig?.paginator);
                }
            },
            () => {
                this.pcpHistory = [];
            }
        );
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.ProvidersList_viewed }, this.memberId);
    }

    buildQueryParams(): string {
        let query = `/api/member-360/pcps/?member_id=${this.memberId}&page=${this.pagingConfig.currentPage}`;
        if (this.sortingElement) {
            query += `&ordering=${this.sortingElement}`;
        }
        if (this.pageConfig?.paginator) {
            query += `&page_size=${this.pageConfig?.paginator}`;
        }
        return query;
    }

    navigateToDetails(event: PCPsResponse) {
        const url = `/icx/member-360/${this.route.snapshot.params.member}/providers/${event.provider_id}`;
        this.router.navigate([url]);
    }

    setCurrentPage(page: number) {
        this.pagingConfig.currentPage = page;

        this.pagingConfig.showingStart = page === 1 ? 1 : this.pageConfig?.paginator * (page - 1) + 1;

        this.pagingConfig.showingEnd =
            page === this.pagingConfig.totalPages ? this.pagingConfig.totalResults : parseInt(this.pageConfig?.paginator, 10) * page;

        this.getProviders();
    }

    sortTable(element) {
        this.sortingElement = this.sortingElement === element ? `-${this.sortingElement}` : element;
        this.getProviders();
    }
}
