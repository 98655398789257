import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';

import { ButtonModule, FormControlModule } from '@zipari/design-system';
import { AddNewModalModule } from '../../modules/add-row-modal/add-new-modal.module';
import { SharedModule } from '../../shared.module';

import { FormGroupModule, TableModule } from './../../modules';
import { ListComponent } from './list.component';
import { LIST_SERVICE, ListService, ListServiceInterface } from './list.service';

export interface ListConfig {
    listService: Type<ListServiceInterface>;
}

@NgModule({
    declarations: [ListComponent],
    imports: [CommonModule, ButtonModule, TableModule, FormControlModule, FormGroupModule, SharedModule, AddNewModalModule],
    exports: [ListComponent],
    providers: [ListService],
})
export class ListModule {
    static forRoot(listConfig?: ListConfig): ModuleWithProviders<ListModule> {
        return {
            ngModule: ListModule,
            providers: [
                {
                    provide: LIST_SERVICE,
                    useClass: (listConfig && listConfig.listService) || ListService,
                },
            ],
        };
    }
}
