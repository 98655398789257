import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    TableModule,
    TabsModule,
    ModelAttributeModule,
    ButtonModule,
    CardModule,
    ExpandingArticleModule,
    DatagridModule,
} from '@zipari/design-system';
import { ResultsModule } from '../../../shared/modules/results/results.module';
import { BadgeModule } from '../../../shared/modules/badge/badge.module';
import { CoverageDetailsComponent } from './coverage-details.component';
import { CobComponent } from './cob/cob.component';
import { WellnessComponent } from './wellness/wellness.component';

@NgModule({
    declarations: [CoverageDetailsComponent, CobComponent, WellnessComponent],
    imports: [
        CommonModule,
        TabsModule,
        ModelAttributeModule,
        ButtonModule,
        TableModule,
        ResultsModule,
        BadgeModule,
        CardModule,
        ExpandingArticleModule,
        DatagridModule,
    ],
})
export class CoverageDetailsModule {}
