import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { cloneObject } from '@zipari/web-utils';
import { AuthService, ConfigService } from '../../../shared/services';
import { fade, slideRight } from '../../animations';
import { validSSOCXEventDictonaryValue, validCXEvents, validGAEvents } from '../../constants/analytics';
import { AnalyticsService } from '../../services/analytics.service';
import { BrokerAssistanceService } from '../../services/broker-assistance.service';
import { MemberDataService } from '../../../icx/shared/services/member-data.service';

import { NavigationService } from './navigation.service';
import { NavItem, NavigationConfig } from './navigation.configuration';
import { NavKey } from './navigation.constants';

@Component({
    selector: 'navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    animations: [fade, slideRight],
})
export class NavigationComponent implements OnInit {
    public isLoggedIn = false;
    public hasMemberContext = false;
    public queryParams;
    public modalOpen;
    public contactConfigs;
    public brokerAssistanceConfigs;
    public memberId;

    public routes = {
        zshop: 'shopping',
    };

    @Output() query = new EventEmitter<string>();
    @Output() toolBarLinkClicked = new EventEmitter<any>();

    constructor(
        public brokerAssistanceService: BrokerAssistanceService,
        public authService: AuthService,
        public navigationService: NavigationService,
        private router: Router,
        private route: ActivatedRoute,
        public analyticsService: AnalyticsService,
        public configService: ConfigService,
        public memberDataService: MemberDataService
    ) {}

    newConfigCheck;
    brokerSub;
    brokerAssistanceInfoCheck;

    ngOnInit() {
        /*   when a user is logging in with the url they from their email like this
         *    http://dtc3-fhp.local.zipari.net:8000/shopping/individual/48776?fromEmail=true,
         *    configService.getPageConfigs tries to grab navigation configs on a parent level so it becomes undefined
         */
        const configs: any = this.configService.getPageConfig('navigation') || this.configService.configs.navigation;

        if (configs && configs.contact_us) {
            this.contactConfigs = cloneObject(configs.contact_us);
        }

        this.authService.appUserData.subscribe((response) => {
            this.isLoggedIn = !!response;
        });
        this.queryParams = this.route.snapshot.queryParams;

        this.newConfigCheck = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd && this.configService.marketSegmentIsValidInApp(this.route.snapshot)) {
                this.navigationService.initConfig();
            }
        });

        try {
            this.memberId = this.route.snapshot.children[0].children[0].params.member;
            this.hasMemberContext = !!this.memberId;
            this.updateNavLinks();
            this.memberDataService.memberData.subscribe((response) => {
                // update nav links when member data is updated
                if (response) {
                    this.updateNavLinks();
                }
            });
        } catch (e) {
            console.warn('navigation component member id not yet established', e);

            this.memberDataService.memberData.subscribe((response) => {
                if (!!response && response.details && response.details.member_number) {
                    this.memberId = response.details.member_number;
                    this.updateNavLinks();
                    this.hasMemberContext = !!response;
                }
            });
        }
    }

    private updateNavLinks() {
        const isHomeHost = this.memberDataService.getMemberHomehostStatus();
        if (isHomeHost) {
            const navHomeHostConfig: NavigationConfig = this.configService.getControlPanelConfig('navigation-home-host');
            this.navigationService.config.links = this.navigationService.modifyConfig(navHomeHostConfig.links);
        }

        this.navigationService.config.links.forEach((link: NavItem) => {
            if (link.route && link.route.includes(':member')) {
                link.route = link.route.replace(':member', this.memberId);
            }

            if (link.items) {
                link.items.forEach((item: NavItem) => {
                    if (item.route && item.route.includes(':member')) {
                        item.route = item.route.replace(':member', this.memberId);
                    }
                });
            }
        });
    }

    public get isNavHidden() {
        return this.navigationService.getNavHidden();
    }

    toggleIconMenu(icon) {
        event.stopPropagation();

        if (!!icon.items) {
            if (!icon.open) {
                this.navigationService.config.icons.desktop.forEach((_icon) => {
                    _icon.open = false;
                });
            }

            icon.open = !icon.open;
        }
    }

    onNavButtonClick() {
        this.modalOpen = !this.modalOpen;

        // dispatch analytics
        if (this.modalOpen) {
            this.analyticsService.dispatchAnalytics({
                GAKey: validGAEvents['contact-us_viewed'],
                CXKey: validCXEvents['contact-us_viewed'],
            });
        }
    }

    search(query: string) {
        this.query.emit(query);
    }

    closeLink(link) {
        link.open = false;
    }

    closeIcon(icon) {
        icon.open = false;
    }

    handleLogin() {
        this.router.navigate([this.configService.appRoute, 'auth']);
    }

    handleLogout() {
        if (this.route.snapshot.data.indexRoute === 'icx') {
            window.location.assign(`/logout?next=${window.location.origin}/icx/${this.configService.activeRoute}/login`);
        } else {
            window.location.assign(`/logout?next=${window.location.origin}/${this.configService.appRoute}/login`);
        }
    }

    handleRegister() {
        this.router.navigate([this.configService.appRoute, 'register']);
    }

    handleToolBarLinkClick(event) {
        this.toolBarLinkClicked.emit(event);
    }

    modalClose() {
        this.modalOpen = false;
    }

    logoClicked() {
        // Member Profile being the first link
        this.router.navigate([this.navigationService.config.links[0].route]);
    }

    linkClicked(link: NavItem): void {
        if (link.key && link.key === NavKey.SSO) {
            this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.Sso_initiated }, this.memberId, {
                SSO_initiated: validSSOCXEventDictonaryValue.Navigation,
            });
        }
    }
}
