import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { isObservable, Observable, of, Subject } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

export class ProductOption {
    id?: number;
    carrier?: string;
    on_exchange?: boolean;
    plan_type?: string;
    state?: string;
}

export enum validEnrollmentTypes {
    OE = 'OE',
    SEP = 'SEP',
}

export enum validCoverageEffectiveRetrievalTypes {
    OE = 'OE',
    SEP = 'SEP',
    SEP_ESTIMATE = 'SEP_ESTIMATE',
}

@Injectable({
    providedIn: 'root',
})
export class CoverageEffectiveDateService {
    formattedQLEs;
    cache = {};

    private dateSource = new Subject<any>();
    date$ = this.dateSource.asObservable();
    storedDate: string;

    constructor(private http: HttpClient) {}

    retrieveCoverageEffectiveDateTest(enrollmentPeriod: validEnrollmentTypes, planType = 'health') {
        const enrollmentPeriodMap = {
            [validEnrollmentTypes.OE]: 'open',
            [validEnrollmentTypes.SEP]: 'special',
        };

        const queryParams = {
            plan_type: planType,
            enrollment_period: enrollmentPeriodMap[enrollmentPeriod],
        };

        const cache = JSON.stringify(queryParams);

        return this.handleCache(cache, this.http.get('/api/product_changes/product_options', { params: queryParams }).pipe(shareReplay()));
    }

    public retrieveQLEs() {
        if (this.formattedQLEs) {
            return of(this.formattedQLEs);
        } else {
            return this.http.get('api/product_changes/qle/?page_size=20').pipe(
                shareReplay(),
                map(response => {
                    this.formattedQLEs = this.reformatQLEBasedOnCode(response['results'] || []);

                    return this.formattedQLEs;
                })
            );
        }
    }

    public reformatQLEBasedOnCode(results) {
        const formattedResults = {};

        results.forEach(result => {
            if (!formattedResults[result.code]) {
                formattedResults[result.code] = result;
            }
        });

        return formattedResults;
    }

    handleCache(url, defaultObservable: Observable<any>) {
        if (this.cache[url]) {
            return isObservable(this.cache[url]) ? this.cache[url] : of(this.cache[url]);
        } else {
            this.cache[url] = defaultObservable.pipe(
                tap(response => {
                    this.cache[url] = response;
                })
            );

            return this.cache[url];
        }
    }

    emitDate(date: any) {
        this.dateSource.next(date);
    }
}
