import { Injectable } from '@angular/core';

const BarColors = {
    Orange: '#FD6240',
    Yellow: '#FDCA40',
    Green: '#1DC291',
};

const BarLevels = {
    Low: 'Low',
    Medium: 'Medium',
    High: 'High',
};

@Injectable({
    providedIn: 'root',
})
export class ProgressBarService {
    constructor() {}

    determineProgressDataBasedOnValue(barConfig) {
        const barValue = barConfig.value > 0 ? barConfig.value : 0;

        if (barConfig.value >= 1 && barConfig.value <= 33) {
            barConfig.color = barConfig.color ? barConfig.color : BarColors.Orange;
            barConfig.labelRight = BarLevels.Low;
        } else if (barConfig.value > 33 && barConfig.value <= 67) {
            barConfig.color = barConfig.color ? barConfig.color : BarColors.Yellow;
            barConfig.labelRight = BarLevels.Medium;
        } else {
            barConfig.color = barConfig.color ? barConfig.color : BarColors.Green;
            barConfig.labelRight = BarLevels.High;
        }

        barConfig.labelLeft = `${barValue}/100`;
    }
}
