import { Component, Input, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs/Subject';

export interface AsideMenuItem {
    prop: string;
    header: string;
}

@Component({
    selector: 'aside-menu',
    templateUrl: './aside-menu.component.html',
    styleUrls: ['./aside-menu.component.scss'],
})
export class AsideMenuComponent implements OnInit {
    @Input() menu: AsideMenuItem[];
    // html element where scrolling takes place
    // eslint-disable-next-line @angular-eslint/no-input-rename
    @Input('watchScroll') watchScrollEl: HTMLElement;
    activeItem;
    scrollSub = new Subject();
    scrollDelay = 250;
    bottom = 100;

    constructor() {}

    // if were watching scroll and updating menu item
    ngOnInit() {
        if (this.watchScrollEl) {
            this.activeItem = this.menu[0].prop;
            this.watchScroll();
        }
    }

    // update active element after scroll and debounce
    private watchScroll() {
        this.watchScrollEl.onscroll = () => {
            this.scrollSub.next('scroll');
        };

        this.scrollSub.pipe(debounceTime(this.scrollDelay)).subscribe(() => this.setActiveGroup());
    }

    // set active element by position of element mayching id
    private setActiveGroup() {
        const _activeItem = this.menu.find(item => document.querySelector(`#${item.prop}`).getBoundingClientRect().bottom > this.bottom);
        this.activeItem = _activeItem.prop;
    }

    // scroll on menu click
    scrollToSection(group) {
        this.activeItem = group.prop;
        document.querySelector(`#${group.prop}`).scrollIntoView();
    }
}
