import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FileUploaderService } from '../file-uploader.service';

@Component({
    selector: 'file-uploader-button',
    templateUrl: './file-uploader-button.component.html',
})
export class FileUploaderButtonComponent {
    @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;
    @Input() disabled: boolean;

    constructor(public fileUploadService: FileUploaderService) {}

    onUploadDocumentClick() {
        this.fileInput.nativeElement.click();
    }

    onFileSelected(event: { target: HTMLInputElement }) {
        this.fileUploadService.handleFileInput(event.target.files);
        this.fileInput.nativeElement.value = null;
    }
}
