import { FormControlService } from '@zipari/design-system';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { OktaAuthModule } from '@okta/okta-angular';
import { NavigationModule, TableModule } from '../shared/modules';
import { AuthenticationModule } from '../shared/modules/authentication/authentication.module';
import { AuthGuardService } from '../shared/services/auth-guard.service';
import { ZipEndpointService } from '../shared/services/zip-endpoint.service';
import { ListModule } from '../shared/templates';

import { BrokerModule } from './broker/broker.module';
import { GroupsModule } from './groups/groups.module';
import { IcxRoutingModule } from './icx-routing.module';
import { IcxComponent } from './icx.component';
import { ClaimDetailModule } from './member/modules/claim-detail/claim-detail.module';
import { ClaimLineModule } from './member/modules/claim-line/claim-line.module';
import { ClaimsModule } from './member/modules/claims/claims.module';
import { FinancialAccumulatorModule } from './member/modules/financial-accumulator/financial-accumulator.module';
import { MemberProfileModule } from './member/modules/member-profile/member-profile.module';
import { MemberSearchModule } from './member/modules/member-search/member-search.module';
import { PcpDetailModule } from './member/modules/pcp-detail/pcp-detail.module';
import { PoliciesModule } from './member/modules/policies/policies.module';

import { PreauthorizationsModule } from './member/modules/preauthorizations/preauthorizations.module';
import { ProvidersModule } from './member/modules/providers/providers.module';
import { ReferralDetailModule } from './member/modules/referral-detail/referral-detail.module';
import { ReferralsModule } from './member/modules/referrals/referrals.module';
import { SidePanelModule } from './member/modules/shared/side-panel/side-panel.module';
import { ProductUsageComponent } from './product-usage/product-usage.component';
import { ProviderModule } from './provider/provider.module';
import { PanelFilterModule } from './shared/modules/panel-filter/panel-filter.module';
import { ProfileSearchModalModule } from './member/modules/shared/profile-search/profile-search-modal.module';
import { DocumentCenterModule } from './member/modules/document-center/document-center.module';
import { LoginModule } from './shared/modules/login/login.module';
import { PolicyDetailsModule } from './member/modules/policy-details/policy-details.module';
import { LinkListModule } from './shared/modules/link-list/link-list.module';
import { CoveragesModule } from './member/modules/coverages/coverages.module';
import { CoverageDetailsModule } from './member/modules/coverage-details/coverage-details.module';
import { HomePageModule } from './member/modules/home-page/home-page.module';
import { MemberEngagementModule } from './member/modules/member-engagement/member-engagement.module';
import { NotFoundModule } from './shared/modules/not-found/not-found.module';
import { AppealsModule } from './member/modules/appeals/appeals.module';
import { BillingAndPaymentsModule } from './member/modules/billing-and-payments/billing-and-payments.module';
import { BenefitsSearchModule } from './member/modules/benefits/benefits-search/benefits-search.module';
import { BenefitsCategoriesModule } from './member/modules/benefits/benefits-categories/benefits-categories.module';
import { PreauthorizationDetailModule } from './member/modules/preauthorization-detail/preauthorization-detail.module';
import { BenefitsDetailsModule } from './member/modules/benefits/benefits-details/benefits-details.module';
import { NewAuthModule } from './shared/modules/auth/auth.module';
import { BillingDetailsModule } from './member/modules/billing-details/billing-details.module';
import { PaymentDetailsModule } from './member/modules/payment-details/payment-details.module';
import { GlobalErrorModule } from './shared/modules/global-error/global-error.module';

@NgModule({
    declarations: [IcxComponent, ProductUsageComponent],
    imports: [
        CommonModule,
        OktaAuthModule,
        IcxRoutingModule,
        ListModule,
        NavigationModule,
        MemberProfileModule,
        MemberSearchModule,
        ClaimsModule,
        ClaimDetailModule,
        ClaimLineModule,
        PoliciesModule,
        PreauthorizationsModule,
        PreauthorizationDetailModule,
        RouterModule,
        ReferralDetailModule,
        ReferralsModule,
        FinancialAccumulatorModule,
        TableModule,
        ProviderModule,
        ProvidersModule,
        BrokerModule,
        GroupsModule,
        SidePanelModule,
        PanelFilterModule,
        AuthenticationModule,
        PcpDetailModule,
        MemberSearchModule,
        ProfileSearchModalModule,
        DocumentCenterModule,
        LoginModule,
        NewAuthModule,
        PolicyDetailsModule,
        LinkListModule,
        CoveragesModule,
        CoverageDetailsModule,
        HomePageModule,
        MemberEngagementModule,
        NotFoundModule,
        BillingAndPaymentsModule,
        AppealsModule,
        BenefitsSearchModule,
        BenefitsCategoriesModule,
        BenefitsDetailsModule,
        BillingDetailsModule,
        PaymentDetailsModule,
        GlobalErrorModule,
    ],
    exports: [IcxComponent],
    providers: [ZipEndpointService, AuthGuardService, FormControlService],
})
export class IcxModule {}
