import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { BehaviorSubject, Observable } from 'rxjs';

import { ConfigService } from '../../shared/services';

import { Configuration, PortalBuilderStore } from '../portal-builder/portal-builder-store.service';
import { initControlPanelForm } from '../shared/utilities/form';

import { Theme } from './models/theme';

@Injectable()
export class ThemeBuilderStore {
    // Behavior Subjects
    private _theme: BehaviorSubject<Theme> = new BehaviorSubject<Theme>(null);
    private _form: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(new FormGroup({}));
    private _resetForm: BehaviorSubject<FormGroup> = new BehaviorSubject<FormGroup>(new FormGroup({}));
    // Observables
    public get theme$(): Observable<Theme> {
        return this._theme.asObservable();
    }
    public get form$(): Observable<FormGroup> {
        return this._form.asObservable();
    }
    public get resetForm$(): Observable<FormGroup> {
        return this._resetForm.asObservable();
    }
    // Value Getters
    public get theme(): Theme {
        return this._theme.getValue();
    }
    public get form(): FormGroup {
        return this._form.getValue();
    }
    public get resetForm(): FormGroup {
        return this._resetForm.getValue();
    }

    private configuration;

    constructor(private configService: ConfigService, private portalBuilderStore: PortalBuilderStore) {
        this.portalBuilderStore.tenant$.subscribe(response => {
            this.configService.getAppConfigs(this.portalBuilderStore.tenant.name, 'demo-app').subscribe(res => {
                if (res.count > 0) {
                    this.init(res.results[0]);
                }
            });
        });
    }

    init(configuration: Configuration) {
        this.configuration = configuration;
        this._theme.next(configuration.config);

        this._form.next(new FormGroup({}));
        this._resetForm.next(new FormGroup({}));

        initControlPanelForm(this.form, this.resetForm, this.theme);

        this._form.next(this.form);
        this._resetForm.next(this.resetForm);
        this._theme.next(this.theme);
    }

    reset() {
        this.form.setValue(this.resetForm.value);
        this.form.markAsPristine();
    }

    preview() {}

    save() {
        const payload: Configuration = this.configuration;
        delete payload.version;
        payload.config = this.form.value;

        this.configService.updateConfig(payload).subscribe(response => {
            this.init(response);
        });
    }
}
