import { sortTabs } from 'src/app/icx/shared/utils/sorting-tabs';
import { Router } from '@angular/router';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { FormattingService, formatTypes, ModelAttributeConfig } from '@zipari/design-system';
import { MemberData, MemberDataService, MemberAddress, ResponseType } from 'src/app/icx/shared/services/member-data.service';
import { ConfigService, APIResponse } from 'src/app/shared/services';
import { PoliciesResponse, PoliciesProductCoverage, PoliciesMemberCoverages } from '../../policies/policies.constants';
import { Data, ProductCoverageData, MemberCoverageData } from './side-panel.constants';
import { LinkParamPlaceholder } from '../../../../shared/constants/link-placeholder';
import { AnalyticsService } from '../../../../../shared/services/analytics.service';

export interface SidePanelConfig {
    [key: string]: any;
}
@Component({
    selector: 'side-panel',
    templateUrl: './side-panel.component.html',
    styleUrls: ['./side-panel.component.scss'],
})
export class SidePanelComponent implements OnInit {
    config: SidePanelConfig;
    member: MemberData;
    data: Data = {};
    private homeHostConfig: SidePanelConfig;
    @Input() id: any;
    isHomeHostMember: boolean = false;

    constructor(
        private configService: ConfigService,
        private formatService: FormattingService,
        private memberDataService: MemberDataService,
        private router: Router,
        private analyticsService: AnalyticsService,
        private elementRef: ElementRef
    ) {}

    ngOnInit() {
        this.getAndModifyConfig();

        this.data.member_cov = [];
        this.data.product_cov = [];

        this.getMemberData(this.id);
    }

    getMemberData(id: string) {
        this.memberDataService.getMemberData(id).subscribe((resp: ResponseType<MemberData>) => {
            if (resp && resp.details) {
                this.member = resp.details;
                // use homehost config for home host member
                this.isHomeHostMember = resp?.details?.is_external_plan_member;
                this.isHomeHostMember ? (this.config.sections = this.homeHostConfig.sections) : this.getPolicies(this.id);

                if (this.member.gender && this.config.gender_enum[this.member.gender]) {
                    this.member.gender = this.config.gender_enum[this.member.gender];
                }
                const physical_address = this.member?.addresses?.filter((address: MemberAddress) => address.address_type === 'physical')[0];
                this.member.name.middle_name = this.member?.name?.middle_name ? this.member?.name?.middle_name : '';
                const full_name = this.formatService.restructureValueBasedOnFormat(this.member?.name, { format: formatTypes.FULL_NAME });

                this.data.member = {
                    full_name: this.member?.name?.full_name || full_name,
                    physical_address,
                    ...this.member,
                };
                this.addMemberConfigData();
            }
        });
    }

    getPolicies(member_id: string) {
        if (!member_id) {
            return;
        }
        this.memberDataService.getPoliciesList(member_id).subscribe((resp: ResponseType<APIResponse<PoliciesResponse>>) => {
            if (resp.details.results) {
                this.loadCoverageData(resp.details.results);
            }
        });
    }

    loadCoverageData(policies: PoliciesResponse[]) {
        policies
            .filter((p: PoliciesResponse) => p.policy_status === 'active')
            .forEach((policy: PoliciesResponse) => {
                // load Product Cov data
                this.loadProductCovData(policy.product_coverages);
                policy.product_coverages.forEach((product: PoliciesProductCoverage) => {
                    // load Member Cov data
                    this.loadMemberCovData(product);
                });
            });
    }

    loadMemberCovData(productCoverage: PoliciesProductCoverage) {
        productCoverage.member_coverages.forEach((member_coverage: PoliciesMemberCoverages) => {
            const existingMember = this.data.member_cov.find(
                (member: MemberCoverageData) =>
                    member.member_number === member_coverage?.member?.member_number || member.member_number === member_coverage?.member?.id
            );

            const e_date = this.formatService.restructureValueBasedOnFormat(member_coverage.effective_date, { format: 'DATE' });
            const t_date = this.formatService.restructureValueBasedOnFormat(member_coverage.termination_date, { format: 'DATE' });
            const full_name = `${member_coverage.member.name.first_name} ${member_coverage.member.name.last_name}`;

            const currentMember: MemberCoverageData = {
                full_name: full_name,
                member_number: member_coverage.member?.member_number || member_coverage.member?.id,
                relationship_to_subscriber: member_coverage.member.relationship_to_subscriber,
                dates: `${e_date} - ${t_date}`,
            };
            // if member does not exist in member_cov list then add it
            if (!existingMember) {
                this.data.member_cov.push(currentMember);
            }
        });
    }

    loadProductCovData(products: PoliciesProductCoverage[]) {
        products.forEach((product: PoliciesProductCoverage) => {
            const e_date = this.formatService.restructureValueBasedOnFormat(product.effective_date, { format: 'DATE' });
            const t_date = this.formatService.restructureValueBasedOnFormat(product.termination_date, { format: 'DATE' });
            const currentProduct: ProductCoverageData = {
                external_plan_name: product.external_plan_name,
                product_coverage_type: product.product_coverage_type,
                dates: `${e_date} - ${t_date}`,
            };
            this.data.product_cov.push(currentProduct);
        });
    }

    addMemberConfigData() {
        this.config.sections.forEach((attr: any) => {
            if (attr && attr.label && attr.label.indexOf('$') !== -1) {
                const labelVal = this.formatService.formatConfigValueAsTemplate({ value: attr.label }, this.data.member);
                attr.label = labelVal.value;
                attr.fields.attributes = attr.fields.attributes.map((attribute: any) =>
                    this.formatService.formatConfigValueAsTemplate(attribute, this.data.member)
                );
            }
        });
    }

    iconClickHeader(pageRef: string) {
        this.router.navigate([`/icx/member-360/${this.id}/${pageRef}`]);
    }

    iconClick(memberRow: any) {
        const memberId = this.data.member_cov[memberRow].member_number;
        window.open(`/icx/member-360/${memberId}/profile`, '_blank');
    }

    getAndModifyConfig() {
        this.config = this.configService.getControlPanelConfig('side-panel');
        this.homeHostConfig = this.configService.getControlPanelConfig('side-panel-home-host');

        Object.keys(this.config.sections).forEach((sectionKey: string) => {
            if (!this.config.sections[sectionKey]) {
                delete this.config.sections[sectionKey];
            }
        });
        this.config.sections = Object.keys(this.config.sections).map((sectionKey: string) => this.config.sections[sectionKey]);
        this.config.sections = this.config.sections.sort(sortTabs);

        if (this.homeHostConfig && this.homeHostConfig.sections) {
            Object.keys(this.homeHostConfig.sections).forEach((sectionKey: string) => {
                if (!this.homeHostConfig.sections[sectionKey]) {
                    delete this.homeHostConfig.sections[sectionKey];
                }
            });
            this.homeHostConfig.sections = Object.keys(this.homeHostConfig.sections).map(
                (sectionKey: string) => this.homeHostConfig.sections[sectionKey]
            );
        }
        this.UpdateSSOLinks();
    }

    UpdateSSOLinks(): void {
        this.config.sections?.map((section) => {
            if (section.ssoLinks?.attributes?.length) {
                section.ssoLinks.attributes.map((attribute: ModelAttributeConfig) => {
                    if (attribute?.value.includes(LinkParamPlaceholder.MemberId)) {
                        attribute.value = attribute.value.replace(LinkParamPlaceholder.MemberId, this.id);
                    }
                });
            }
        });
    }

    ssoLinkClicked(): void {
        this.analyticsService.sendSSOCxEvent(this.id);
    }

    ngAfterViewInit(): void {
        this.elementRef.nativeElement.querySelectorAll('.side-panel__div a').forEach((item) => {
            item.addEventListener('click', () => {
                this.ssoLinkClicked();
            });
        });
    }
}
