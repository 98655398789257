import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormControlService, TextConfiguration, ToggleConfiguration, TypeaheadConfiguration } from '@zipari/design-system';
import { concat, of, Subscription } from 'rxjs';

import { BrokerAssistanceService } from '../../../services/broker-assistance.service';

import { brokerAssistedControlConfig, brokerUnassistedControlConfig, toggleConfig } from './broker-assistance.constants';

@Component({
    selector: 'broker-assistance',
    templateUrl: './broker-assistance.component.html',
    styleUrls: ['./broker-assistance.component.scss'],
})
export class BrokerAssistanceComponent implements OnInit, OnDestroy {
    @Input() form: FormGroup;
    @Input() config: TypeaheadConfiguration;

    brokerUnassistedControlConfig: TextConfiguration = brokerUnassistedControlConfig;
    brokerAssistedControlConfig: TypeaheadConfiguration = brokerAssistedControlConfig;
    toggleConfig: ToggleConfiguration = toggleConfig;
    toggleSub: Subscription;
    brokerSub: Subscription;

    constructor(public brokerAssistanceService: BrokerAssistanceService, public formControlService: FormControlService) {}

    ngOnInit() {
        this.brokerAssistedControlConfig = this.handleOverridingDefault(this.brokerAssistedControlConfig);
        this.addBrokerToggle();
        this.watchBrokerToggle();
        this.watchAssistingBroker();
    }

    watchAssistingBroker() {
        this.brokerSub = this.brokerAssistanceService.assistingBroker$.subscribe(broker => {
            if (broker) {
                this.form.get(this.toggleConfig.prop).patchValue(true);
                this.form.get(this.brokerAssistedControlConfig.prop).patchValue(broker.broker_id);

                // only disable the form controls if we know that the broker info came from a query param
                // this will allow a user to make changes as needed if they provided the broker but keep the broker url
                // be source of truth when used
                if (broker.source === 'query' || broker.source === 'user') {
                    this.toggleConfig.isDisabled = true;
                    this.brokerAssistedControlConfig.isDisabled = true;
                }
            }
        });
    }

    ngOnDestroy() {
        if (this.toggleSub) {
            this.toggleSub.unsubscribe();
        }

        if (this.brokerSub) {
            this.brokerSub.unsubscribe();
        }
    }

    addBrokerToggle() {
        this.formControlService.addControlToFormGroup(this.form, this.toggleConfig, {
            [this.toggleConfig.prop]: !!this.brokerAssistanceService.assistingBroker,
        });
    }

    /**
     * Watch for changes to Did a broker assist you toggle
     *
     * If true, add broker typeahead to the form with required validator
     * If false, add an alternate control in its place w/o validations
     *
     * Form structure stays the same but we avoid lifecycle errors from setting validators dynamically
     */
    watchBrokerToggle() {
        const brokerAssist = this.form.get(this.toggleConfig.prop);
        const brokerId = this.brokerAssistanceService.assistingBroker && this.brokerAssistanceService.assistingBroker.broker_id;

        this.toggleSub = concat(of(brokerAssist.value), brokerAssist.valueChanges).subscribe(isBrokerAssist => {
            if (isBrokerAssist) {
                this.form.removeControl(this.brokerUnassistedControlConfig.prop),
                    this.formControlService.addControlToFormGroup(this.form, this.brokerAssistedControlConfig, {
                        broker_assistance: brokerId || null,
                    });
            } else {
                this.form.removeControl(this.brokerAssistedControlConfig.prop);
                this.formControlService.addControlToFormGroup(this.form, this.brokerUnassistedControlConfig);
            }
        });
    }

    handleOverridingDefault(defaultConfig) {
        if (this.config.label) {
            defaultConfig.label = this.config.label;
        }

        if (this.config.description) {
            defaultConfig.description = this.config.description;
        }

        if (this.config.prop) {
            defaultConfig.prop = this.config.prop;
        }

        if (this.config.validators) {
            defaultConfig.validators = this.config.validators;
        }

        return defaultConfig;
    }

    handleBrokerSelected(broker) {
        this.brokerAssistanceService.retrieveBrokerByBrokerId(broker.value, 'user');
        this.brokerTypeahead.setErrors(null);
    }

    public get toggle() {
        return this.form.get(this.toggleConfig.prop);
    }

    public get brokerTypeahead() {
        return this.form.get(this.brokerAssistedControlConfig.prop);
    }
}
