import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule, FormControlModule, DatagridModule, PaginatorModule, FormGroupModule } from '@zipari/design-system';

import { ResultCardModule } from '../result-card/result-card.module';

import { ResultsComponent } from './results.component';

@NgModule({
    declarations: [ResultsComponent],
    imports: [CommonModule, ResultCardModule, FormControlModule, ButtonModule, DatagridModule, PaginatorModule, FormGroupModule],
    exports: [ResultsComponent],
})
export class ResultsModule {}
