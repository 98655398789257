import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { slideRight } from '../../../shared/animations';

@Component({
    selector: 'messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.scss'],
    animations: [slideRight],
    encapsulation: ViewEncapsulation.None,
})
export class MessagesComponent implements OnInit {
    filter: boolean;
    message: boolean = false;

    dropdownConfig = [
        {
            key: 'inbox',
            prop: 'inbox',
            type: 'dropdown',
            default: 'inbox',
            placeholder: 'inbox',
            value: '',
            options: [
                {
                    label: 'Inbox',
                    value: 'Inbox',
                    key: 'Inbox',
                },
                {
                    label: 'Sent',
                    value: 'Sent',
                    key: 'Sent',
                },
                {
                    label: 'Draft',
                    value: 'Draft',
                    key: 'Draft',
                },
                {
                    label: 'Archive',
                    value: 'Archive',
                    key: 'Archive',
                },
            ],
        },
    ];

    messages = [
        {
            name: 'Sender',
            subject: 'Subject Goes Here ...',
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ...',
            date: 'May 5',
            showThread: false,
            thread: [
                {
                    name: 'Sender',
                    date: 'May 1',
                    message: 'Thread 1 nt ut labore et dolore magna aliqua. ',
                },
                {
                    name: 'Sender',
                    date: 'May 2',
                    message:
                        'Thread 2 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
                },
                {
                    name: 'Sender',
                    date: 'May 2',
                    message:
                        'Thread 3 ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
                },
            ],
        },
        {
            name: 'Sender',
            subject: 'Subject Goes Here ...',
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ...',
            date: 'May 5',
            showThread: false,
            thread: [
                {
                    name: 'Sender',
                    date: 'May 5',
                    message: '"Thread 1, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
                },
                {
                    name: 'Sender',
                    date: 'May 6',
                    message: 'Thread 2, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
                },
            ],
        },
        {
            name: 'Sender',
            subject: 'Subject Goes Here ...',
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ...',
            date: 'May 5',
            showThread: false,
            thread: [
                {
                    name: 'Sender',
                    message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ...',
                    date: 'May 5',
                },
            ],
        },
        {
            name: 'Sender',
            subject: 'Subject Goes Here ...',
            message: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit ...',
            date: 'May 5',
            showThread: false,
            thread: [
                {
                    name: 'Sender',
                    date: 'May 3',
                    message: 'Thread 1, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
                },
                {
                    name: 'Sender',
                    date: 'May 6',
                    message: 'Thread 2, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ',
                },
            ],
        },
    ];

    constructor() {}

    ngOnInit() {}

    filterMessages() {
        this.filter = !this.filter;
    }

    newMessage() {
        this.message = !this.message;
    }

    closeMessage() {
        this.message = false;
    }

    goBack(message) {
        message.showThread = false;
    }

    messageDetails(message) {
        message.showThread = !message.showThread;
    }

    messageThread(message) {
        this.messages.map(message => {
            message.showThread = false;
        });
        message.showThread = true;
    }
}
