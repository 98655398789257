import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    CardModule,
    ModelAttributeModule,
    TabsModule,
    ModelAttributeSectionsModule,
    DatagridModule,
    PaginatorModule,
    RippleModule,
} from '@zipari/design-system';
import { PaymentDetailsComponent } from './payment-details.component';

@NgModule({
    declarations: [PaymentDetailsComponent],
    imports: [
        CommonModule,
        CardModule,
        ModelAttributeModule,
        TabsModule,
        ModelAttributeSectionsModule,
        DatagridModule,
        PaginatorModule,
        RippleModule,
    ],
})
export class PaymentDetailsModule {}
