import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, of, Subscription } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { cloneObject } from '@zipari/web-utils';
import { isEmptyObj } from '@zipari/web-utils';

import { ConfigService } from './config.service';

export class ZipBackendError {
    user_error: string;
}

export class ZipBackendErrorResponse {
    status?: any;
    error: {
        errors: ZipBackendError[];
    };
}

export class ZipFrontendError extends Array<string> {}

@Injectable()
export class ZipEndpointService {
    public busy: Subscription;
    public currentDetail$: BehaviorSubject<any> = new BehaviorSubject({});

    constructor(public http: HttpClient, public configService: ConfigService) {}

    /**
     * helper function to attempt to handle error messages coming back from zipari BE
     * @param zipBackendError error that was passed down to the browser from an api failing
     * @param defaultErrorMessage an error message to default back to if no error messages are found.
     * @param callback that can be ran to handle an error message
     * @return ZipFrontendError (array of strings)
     */
    handleErrorMessages(zipBackendError: ZipBackendErrorResponse, defaultErrorMessage: string, callback = null): ZipFrontendError {
        let errorMessage: ZipFrontendError = [];

        if (
            zipBackendError &&
            zipBackendError.error &&
            zipBackendError.error.errors &&
            Array.isArray(zipBackendError.error.errors) &&
            zipBackendError.error.errors[0]
        ) {
            zipBackendError.error.errors.forEach(err => {
                this.formatErrors(errorMessage, err.user_error);
            });
        } else {
            errorMessage = [defaultErrorMessage];
        }

        if (callback) {
            callback(errorMessage);
        }

        return errorMessage;
    }

    /**
     * recursively tries to find errors off of an error response from the zipari services
     * @param errorArr an array that is going to be used as a way to recursively collect multiple errors
     * @param error the error that was provided by any zip endpoint
     * @return void
     */
    formatErrors(errorArr: ZipFrontendError, error: string | any) {
        if (typeof error === 'string') {
            errorArr.push(error);
        } else if (typeof error === 'object') {
            const keys = Object.keys(error);

            keys.forEach(key => {
                this.formatErrors(errorArr, error[key]);
            });
        }
    }

    /**
     * set a detail in the cache keyed by the pagename so that the data gets passed between pages
     * @param detail `list` item that will serve as an initial data element for the detail page to load faster
     * @param page which page is trying to retrieve from the cache
     * @param identifier which id is trying to retrieve from the cache, if not provided, only page will be used
     * @return void
     */
    setDetail(detail: any, page: string, identifier?: string): void {
        const key = !!identifier ? `${page}/${identifier}` : page;
        const currentDetailCache = {
            [key]: cloneObject(detail),
        };

        this.currentDetail$.next(currentDetailCache);
    }

    /**
     * retrieve a detail that was just provided to the cache by passing in the page name
     * @param page which page is trying to retrieve from the cache
     * @param identifier which id is trying to retrieve from the cache, if not provided, only page will be used
     * @return if detail is in cache then returns detail otherwise returns null
     */
    retrieveDetail(page: string, identifier?: string): any {
        const key = !!identifier ? `${page}/${identifier}` : page;

        return this.currentDetail$.value && this.currentDetail$.value[key] ? this.currentDetail$.value[key] : null;
    }

    /**
     * helper function to wrap functionality to setup a detail page AND utilize that page's cache
     * @param page which page is trying to retrieve from the cache
     * @param endpoint to call for the detail page
     * @param useDataCallback a callback for what the detail page needs to do in order to setup its page once it has
     *     the correct data
     * @param router angular router... services shouldn't pull in the router themselves
     * @param route angular activatedRoute... services shouldn't pull in the activatedRoute themselves
     * @param identifier which identifier should be used to retrieve from cache, if not provided, only page will be used
     * @return void
     */
    setupDetailPageAndUseCacheIfExists(
        page: string,
        endpoint: string,
        useDataCallback: Function,
        router: Router,
        route: ActivatedRoute,
        identifier?: string
    ): void {
        this.busy = new Subscription();

        // retrieve the cached data
        const cachedData: any = cloneObject(this.retrieveDetail(page, identifier));

        // setup the page once using any stored data that we have from the list view
        if (!isEmptyObj(cachedData)) {
            useDataCallback(cachedData);
            // If the cache data exists, don't show spinner or busy
            this.busy.unsubscribe();
            // If the cache data exists, still fetch latest data from server
            this.http
                .get(endpoint)
                .pipe(
                    map(response => response),
                    catchError(err => {
                        console.error(err);

                        return of(cachedData || null);
                    }),
                    tap(data => {
                        if (!isEmptyObj(data)) {
                            useDataCallback(data);
                            this.setDetail(data, page, identifier);
                        } else {
                            router.navigate(['../'], { relativeTo: route });
                        }
                    })
                )
                .subscribe();
        } else {
            // make the detail call and return the response... but if error default back to the cached data
            this.busy = this.http
                .get(endpoint)
                .pipe(
                    map(response => response),
                    catchError(err => {
                        console.error(err);

                        return of(cachedData || null);
                    }),
                    tap(data => {
                        if (!isEmptyObj(data)) {
                            useDataCallback(data);
                            this.setDetail(data, page, identifier);
                        } else {
                            router.navigate(['../'], { relativeTo: route });
                        }
                    })
                )
                .subscribe();
        }
    }
}
