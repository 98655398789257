import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormattingService } from '@zipari/design-system';
import { LinkList, LinkListItem } from '../link-list/link-list.component';

@Component({
    selector: 'result-card',
    templateUrl: './result-card.component.html',
    styleUrls: ['./result-card.component.scss'],
})
export class ResultCardComponent implements OnInit {
    queryParams;
    showDetails: boolean;
    detailTarget;
    memberId;
    linkListConfig = {} as LinkList;

    @Input() data;
    @Input() config;
    @Input() options;
    @Input() params;
    @Input() grid;

    constructor(private router: Router, private route: ActivatedRoute, private formatService: FormattingService) {}

    ngOnInit() {
        this.showDetails = false;
        this.config = this.config.map((attr) => this.formatService.formatConfigValueAsTemplate(attr, this.data));
        this.formatLinkListData();
    }

    formatOptionsData(config, data) {
        const value = this.formatService.formatConfigValueAsTemplate(config, data).value;

        if (value) {
            return this.formatService.restructureValueBasedOnFormat(value, config);
        }
    }

    formatLinkListData() {
        this.linkListConfig.title = 'Who\'s covered';
        this.linkListConfig.item = [] as LinkListItem[];
        if (this.data.whosCovered) {
            this.data.whosCovered.nameIdMappings.forEach((name) => {
                let item: LinkListItem;
                const URL: string = `${window.location.origin}/icx/member-360/${name.id}/profile`;
                item = {
                    label: name.nameText,
                    href: URL,
                };
                this.linkListConfig.item.push(item);
            });
        }
    }

    openDetails(target) {
        this.memberId = this.route.params['value'].member;
        if (this.options && this.options.details && this.options.details.base) {
            const param = this.formatService.formatConfigValueAsTemplate(this.options.details, target);

            switch (this.options.details.base) {
                case 'profile':
                    window.open(`${window.location.origin}/icx/member-360/${param.value}/profile`, '_blank');
                    break;

                case 'coverage_detail':
                    this.router.navigate([
                        `/icx/member-360/${this.memberId}/coverages/${target.policy_number}/${target.product_coverage_id}/${target.member_coverage_id}`,
                    ]);
                    break;

                default:
                    this.router.navigate([`/icx/member-360/${this.memberId}/${this.options.details.base}/${param.value}`]);
                    break;
            }
        }
    }
}
