import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModelAttributeModule, TabsModule } from '@zipari/design-system';
import { MapModule } from '../../shared/modules';
import { BrokerComponent } from './broker.component';

@NgModule({
    declarations: [BrokerComponent],
    imports: [CommonModule, TabsModule, ModelAttributeModule, MapModule],
})
export class BrokerModule {}
