import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { BadgeConfig, Datagrid } from '@zipari/design-system';
import { AttributeList } from 'src/app/control-panel/portal-builder/models/attribute-list';
import { validCXEvents } from 'src/app/shared/constants/analytics';
import { AnalyticsService } from 'src/app/shared/services/analytics.service';
import { ConfigService, APIService } from '../../../../shared/services';
import { SidePanelService } from './../../../shared/services/side-panel.service';

export interface ReferralDetailPageConfig {
    details: AttributeList;
    endpoint: string;
    diagnosis_grid: {
        label: string;
        datagrid: Datagrid;
    };
    dates: AttributeList;
    procedure_grid: {
        label: string;
        datagrid: Datagrid;
    };
    procedure: AttributeList;
    header: string;
    badgeConfig: BadgeConfig;
    illustrationSrc: string;
}
export interface ReferralDetailsAPIResponse {
    number: string;
    referring_provider_full_name: string;
    servicing_provider_full_name: string;
    effective_date: string;
    termination_date: string;
    decision_date: string;
    status_updated_date: string;
    status: string;
    member_display_identifier: string;
    member_full_name: string;
    documents: ReferralDetailsDocuments;
    service_type: string;
    service_category: string;
    priority: string;
    diagnoses: ReferralDetailsDiagnoses[];
    procedures: ReferralDetailsProcedures[];
    service_unit_type: string;
    service_unit_quantity: string;
}
export interface ReferralDetailsDocuments {
    file_type: string;
    file_name: string;
    document_category: string;
    type: string;
}

export interface ReferralDetailsProcedures {
    code: string;
    description: string;
}

export interface ReferralDetailsDiagnoses {
    code: string;
    description: string;
}
@Component({
    selector: 'referral-detail',
    templateUrl: './referral-detail.component.html',
    styleUrls: ['./referral-detail.component.scss'],
})
export class ReferralDetailComponent implements OnInit {
    public pageConfig: ReferralDetailPageConfig;
    public referralDetails: ReferralDetailsAPIResponse;
    public referralId: string;
    public memberId: string;
    public apiCallSuccess: boolean = false;
    constructor(
        private configService: ConfigService,
        private router: Router,
        private route: ActivatedRoute,
        private panelService: SidePanelService,
        private api: APIService,
        private analyticsService: AnalyticsService
    ) {
        this.panelService.toggleSidePanel(true);
    }

    ngOnInit() {
        this.pageConfig = this.configService.getControlPanelConfig('referral-details');
        this.referralId = this.route.snapshot.params.id;
        this.memberId = this.route.snapshot.params.member;
        this.getReferralDetails();
        this.analyticsService.dispatchAnalytics({ CXKey: validCXEvents.ReferralsDetail_viewed }, this.memberId);
    }

    getReferralDetails(): void {
        const query = `${this.pageConfig.endpoint}${this.referralId}/`;
        this.api.get(query).subscribe((res: ReferralDetailsAPIResponse) => {
            this.apiCallSuccess = true;
            this.referralDetails = res;
        });
    }

    routeBack(): void {
        this.router.navigate([`/icx/member-360/${this.memberId}/referrals`]);
    }
}
