import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TabsModule } from '@zipari/design-system';

import { TabViewModule } from '../tab-view/tab-view.module';

import { DetailTabsComponent } from './detail-tabs.component';

@NgModule({
    declarations: [DetailTabsComponent],
    imports: [CommonModule, TabsModule, TabViewModule],
    exports: [DetailTabsComponent],
})
export class DetailTabsModule {}
