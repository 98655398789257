import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { Observable, of } from 'rxjs';

// import { AppState } from '../../reducers';
// import { determineAllNonAuthenticatedRoutes, isUrlAnUnAuthenticatedRoute } from './auth.utils';
// import { ConfigurationService } from './../configuration/configuration.service';

// import { isLoggedIn } from './store';

@Injectable()
export class AuthGuard implements CanActivate {
    urlsThatDoNotRequireAuth: string[];
    config: any;

    // TODO bring this back when ready to implement
    // constructor(private store: Store<AppState>, private router: Router, private configurationService: ConfigurationService) {
    // const sitemapConfig = this.configurationService.getTemplateConfiguration('sitemap');
    // this.urlsThatDoNotRequireAuth = determineAllNonAuthenticatedRoutes(sitemapConfig);
    // this.config = this.configurationService.getTemplateConfiguration('auth');
    // }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return of(true);
        // this.store.pipe(
        //     select(isLoggedIn),
        //     tap((loggedIn: boolean) => {
        //         const currentRoute = this.router.url;
        //         const isCurrentRouteProtected = !isUrlAnUnAuthenticatedRoute(currentRoute || '', this.urlsThatDoNotRequireAuth);
        //         if (!loggedIn && isCurrentRouteProtected) this.routeToLoginUrl();
        //     }),
        // );
    }

    // routeToLoginUrl() {
    //     this.config.idp === 'okta' ? this.router.navigateByUrl('/auth/okta') : this.router.navigateByUrl('/auth/login');
    // }
}
