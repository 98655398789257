import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { FormControlService } from '@zipari/design-system';

import {
    ABBREVIATED_STATE_OPTIONS,
    address1Config,
    address2Config,
    cityConfig,
    cityDropdownConfig,
    countyCodeConfig,
    countyConfig,
    STATE_OPTIONS,
    stateConfig,
    zipCodeConfig,
} from './../address/address.constants';
import { BaseCVAComponent } from './../base-cva.component';

@Component({
    selector: 'company-address',
    templateUrl: './company-address.component.html',
    styleUrls: ['./company-address.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CompanyAddressComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => CompanyAddressComponent),
            multi: true,
        },
    ],
})
export class CompanyAddressComponent extends BaseCVAComponent implements OnInit, OnDestroy, Validator {
    @Input() config: {
        isDisabled: boolean;
        label: string;
        [key: string]: any;
    };
    @Input() direction = '';

    address1Config = address1Config;
    address2Config = address2Config;
    cityConfig = cityConfig;
    cityDropdownConfig = cityDropdownConfig;
    zipCodeConfig = zipCodeConfig;
    stateConfig = stateConfig;
    countyConfig = countyConfig;
    countyCodeConfig = countyCodeConfig;

    constructor(private formControlService: FormControlService) {
        super();
    }

    ngOnInit() {
        this.formGroup = new FormGroup({});

        if (this.config.configs) {
            this.address1Config = { ...address1Config, ...this.mergeConfig('address1') };
            this.address2Config = { ...address2Config, ...this.mergeConfig('address2') };
            this.cityConfig = { ...cityConfig, ...this.mergeConfig('city') };
            this.zipCodeConfig = { ...zipCodeConfig, ...this.mergeConfig('zipCode') };
            this.stateConfig = {
                ...stateConfig,
                options:
                    this.config.configs['state'] && this.config.configs['state'].abbreviated ? ABBREVIATED_STATE_OPTIONS : STATE_OPTIONS,
                ...this.mergeConfig('state'),
            };
        }

        [this.address1Config, this.address2Config, this.cityConfig, this.zipCodeConfig, this.stateConfig, this.countyConfig].forEach(
            config => {
                this.formControlService.addControlToFormGroup(this.formGroup, config);
            }
        );
    }

    setDisabledState(isDisabled: boolean) {
        if (isDisabled) {
            this.formGroup.disable();
        } else {
            this.formGroup.enable();
        }
    }

    private mergeConfig(configSection): any {
        if (!this.config.configs[configSection]) {
            return {};
        }

        return this.config.configs[configSection];
    }

    //  Propagate invalidity to parent form group
    public validate(control): ValidationErrors {
        // Only validate when child controls rendered
        if (control.controls) {
            return this.formGroup.valid ? null : [{ companyAddress: true }];
        }
    }

    ngOnDestroy() {
        super.ngOnDestroy();
    }
}
