import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
    FormControlModule,
    ModelAttributeModule,
    TabsModule,
    FormGroupModule,
    RippleModule,
    ErrorEmptyModule,
} from '@zipari/design-system';
import { FinancialAccumulatorComponent } from './financial-accumulator.component';
import { ProgressBarGridModule } from './components/progress-bar-grid/progress-bar-grid.module';
import { ViewClaimsModalModule } from './components/view-claims-modal/view-claims-modal.module';
@NgModule({
    declarations: [FinancialAccumulatorComponent],
    imports: [
        CommonModule,
        TabsModule,
        FormControlModule,
        ModelAttributeModule,
        ProgressBarGridModule,
        FormGroupModule,
        RippleModule,
        ViewClaimsModalModule,
        ErrorEmptyModule,
    ],
})
export class FinancialAccumulatorModule {}
