import { Component, OnInit, Input } from '@angular/core';
import { FormattingService } from '@zipari/design-system';
import { COBResults } from '../../policies/policies.constants';
import { COBInfo } from '../coverage-details.model';
import { AttributeList, ModelAttribute } from './../../../../../control-panel/portal-builder/models/attribute-list';

@Component({
    selector: 'cob',
    templateUrl: './cob.component.html',
    styleUrls: ['./cob.component.scss'],
})
export class CobComponent implements OnInit {
    @Input() config: COBInfo;
    @Input() data: COBResults[];
    cob_partial_array: AttributeList[] = [];
    cob_more_array: AttributeList[] = [];
    cob_title_array: string[] = [];
    viewMoreToggle: boolean[] = [];
    cobAction: string[] = [];

    constructor(private formatService: FormattingService) {}

    ngOnInit(): void {
        this.setCOBSection();
    }

    setCOBSection() {
        this.data.forEach((cob: COBResults, i) => {
            const cob_partial = { attributes: [] };
            const cob_more = { attributes: [] };
            let cob_title = '';
            cob.order_indicator = cob.order_indicator || 'other';

            cob_title = this.config.cob_indicator_enum[cob.order_indicator]
                ? this.config.cob_indicator_enum[cob.order_indicator]
                : cob.order_indicator;
            this.cob_title_array[i] = cob_title;

            this.config.cob_attributes.attributes.forEach((attribute: ModelAttribute) => {
                if (this.formatService.formatConfigValueAsTemplate(attribute, cob).value) {
                    if (cob_partial.attributes.length < 4) {
                        cob_partial.attributes.push(attribute);
                    } else {
                        cob_more.attributes.push(attribute);
                    }
                }
            });
            if (cob.is_medicare_coverage && cob.medicare_information) {
                cob.medicare_information.medicare_coverages = [];
                cob.medicare_information.medicare_effective_dates = [];
                cob.medicare_information.medicare_termination_dates = [];

                ['a', 'b', 'c', 'd'].forEach((partName: string) => {
                    if (cob.medicare_information[`has_medicare_part_${partName}`]) {
                        cob.medicare_information.medicare_coverages.push(`Part ${partName.toUpperCase()}`);

                        const effectiveDate = `Part ${partName.toUpperCase()}: ${this.formatService.restructureValueBasedOnFormat(
                            cob.medicare_information[`medicare_part_${partName}_eligible_date`],
                            {
                                format: 'DATE',
                            }
                        )}`;
                        cob.medicare_information.medicare_effective_dates.push(effectiveDate);

                        const terminationDate = `Part ${partName.toUpperCase()}: ${this.formatService.restructureValueBasedOnFormat(
                            cob.medicare_information[`medicare_part_${partName}_cancel_date`],
                            {
                                format: 'DATE',
                            }
                        )}`;
                        cob.medicare_information.medicare_termination_dates.push(terminationDate);
                    }
                });
                this.config.cob_medicare.attributes.forEach((attribute: ModelAttribute) => {
                    if (this.formatService.formatConfigValueAsTemplate(attribute, cob).value) {
                        if (cob_partial.attributes.length < 4) {
                            cob_partial.attributes.push(attribute);
                        } else {
                            cob_more.attributes.push(attribute);
                        }
                    }
                });
            }
            this.cob_partial_array[i] = cob_partial;
            this.cob_more_array[i] = cob_more;
            this.viewMoreToggle[i] = false;
            this.cobAction[i] = 'View More';
        });
    }

    toggleCOB(i) {
        this.viewMoreToggle[i] = !this.viewMoreToggle[i];
        if (this.viewMoreToggle[i]) {
            this.cobAction[i] = 'View Less';
        } else {
            this.cobAction[i] = 'View More';
        }
    }
}
