import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Subject, Subscription } from 'rxjs';

import 'rxjs/add/operator/debounceTime';
import { debounceTime } from 'rxjs/operators';

import { cloneObject, deepCompare } from '@zipari/web-utils';

import { TableFilter } from './table-filter.models';

@Component({
    selector: 'table-filters',
    templateUrl: './table-filter.component.html',
})
export class TableFilterComponent implements OnChanges, OnInit {
    zipFilters;
    formGroup: FormGroup;
    formGroupSubscription: Subscription;
    prevFilters;
    filterSub: Subscription;
    setupFilter = new Subject<TableFilter[]>();
    debounceTime = 250;
    filtersSame = true;

    @HostListener('document:keydown', ['$event'])
    handleKeyboardEvent(event: KeyboardEvent) {
        // when enter is clicked submit the form as a filter
        if (event.which === 13) {
            this.cleanAndSubmitForm();
        }
    }

    @Input() filters: TableFilter[];
    @Output() value: EventEmitter<TableFilter[]> = new EventEmitter<TableFilter[]>();

    constructor() {}

    ngOnInit() {}

    ngOnChanges(changes) {
        // checks to see if the filters have actually changed or if we have just added the 'value' onto the object
        // if things have changed then setup the filters to work properly
        // IMPORTANT: make sure that we use the clone object function on the previous filters otherwise funky stuff is
        // going to happen
        if ('filters' in changes && changes.filters.currentValue) {
            if (!this.prevFilters || (this.prevFilters && !deepCompare(this.prevFilters, changes.filters.currentValue, { value: true }))) {
                this.prevFilters = changes.filters.currentValue;
                this.setupFilters(cloneObject(this.prevFilters));
            }
        }

        this.formGroupSubscription = this.formGroup.valueChanges.debounceTime(100).subscribe(response => {
            this.cleanAndSubmitForm();
        });

        this.filterSub = this.setupFilter.pipe(debounceTime(this.debounceTime)).subscribe((newFilters: TableFilter[]) => {
            this.value.emit(newFilters);
        });
    }

    setupFilters(filters) {
        this.formGroup = new FormGroup({});
        this.zipFilters = filters.map(this.createZipFormControl.bind(this));

        this.formGroup.valueChanges.subscribe(() => {
            this.cleanAndSubmitForm();
        });

        this.filtersSame = filters.every(filter => filter.prop === filters[0].prop);
    }

    cleanAndSubmitForm() {
        const newFilters = this.zipFilters.map(filter => {
            filter.value = filter.control.value;

            return filter;
        });

        this.setupFilter.next(newFilters);
    }

    createZipFormControl(config) {
        // special backwards compatible things
        if (config.type === 'searchField') {
            config.type = 'text';
        }
        if (config.type === 'checkbox' || config.type === 'checkbox') {
            config.type = 'boolean';
        }
        if ((config.type === 'select' || config.type === 'select') && config.options) {
            config.type = 'dropdown';
            config.options = config.options.map(option => {
                option['title'] = option.name;

                return option;
            });
        }
        const control = new FormControl('', []);
        const newZipFormControl = Object.assign(config, {
            prop: config.key || config.prop,
            key: config.key || config.prop,
            label: config.label || config.text,
            controlNameFull: true,
            control: control,
        });
        newZipFormControl.controlProp = newZipFormControl.key.split('.').pop();
        this.formGroup.addControl(newZipFormControl.controlProp, control);

        return newZipFormControl;
    }

    resetFilters() {
        this.formGroup.reset();

        this.cleanAndSubmitForm();
    }
}
