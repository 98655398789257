import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
    OktaComponent,
    LoginComponent,
    RegistrationComponent,
    ForgotPasswordComponent,
    ForgotUsernameComponent,
} from '@zipari/design-system';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { AuthenticationComponent } from '../shared/modules/authentication/authentication.component';

import { BrokerComponent } from './broker/broker.component';
import { GroupsComponent } from './groups/groups.component';
import { IcxComponent } from './icx.component';
import { ClaimDetailComponent } from './member/modules/claim-detail/claim-detail.component';
import { ClaimLineComponent } from './member/modules/claim-line/claim-line.component';
import { ClaimsComponent } from './member/modules/claims/claims.component';
import { FinancialAccumulatorComponent } from './member/modules/financial-accumulator/financial-accumulator.component';
import { MemberProfileComponent } from './member/modules/member-profile/member-profile.component';
import { PcpDetailComponent } from './member/modules/pcp-detail/pcp-detail.component';
import { PoliciesComponent } from './member/modules/policies/policies.component';
import { PolicyDetailsComponent } from './member/modules/policy-details/policy-details.component';
import { PreauthorizationsComponent } from './member/modules/preauthorizations/preauthorizations.component';
import { ProvidersComponent } from './member/modules/providers/providers.component';
import { ReferralDetailComponent } from './member/modules/referral-detail/referral-detail.component';
import { ReferralsComponent } from './member/modules/referrals/referrals.component';
import { ProductUsageComponent } from './product-usage/product-usage.component';
import { ProviderComponent } from './provider/provider.component';
import { DocumentCenterComponent } from './member/modules/document-center/document-center.component';

import { CoveragesComponent } from './member/modules/coverages/coverages.component';
import { CoverageDetailsComponent } from './member/modules/coverage-details/coverage-details.component';
import { HomePageComponent } from './member/modules/home-page/home-page.component';
import { MemberEngagementComponent } from './member/modules/member-engagement/member-engagement.component';
import { NotFoundComponent } from './shared/modules/not-found/not-found.component';
import { AppealsComponent } from './member/modules/appeals/appeals.component';

import { BillingAndPaymentsComponent } from './member/modules/billing-and-payments/billing-and-payments.component';
import { BenefitsSearchComponent } from './member/modules/benefits/benefits-search/benefits-search.component';
import { BenefitsCategoriesComponent } from './member/modules/benefits/benefits-categories/benefits-categories.component';
import { PreauthorizationDetailComponent } from './member/modules/preauthorization-detail/preauthorization-detail.component';
import { BenefitsDetailsComponent } from './member/modules/benefits/benefits-details/benefits-details.component';
import { AuthComponent } from './shared/modules/auth/auth.component';
import { RouteGuard } from './shared/services/route-guard.service';
import { BillingDetailsComponent } from './member/modules/billing-details/billing-details.component';
import { PaymentDetailsComponent } from './member/modules/payment-details/payment-details.component';
import { GlobalErrorComponent } from './shared/modules/global-error/global-error.component';

const routes: Routes = [
    {
        path: '',
        component: IcxComponent,
        data: { pageName: 'global' },
        children: [
            {
                path: 'product-usage',
                data: { marketSegment: 'product-usage' },
                children: [
                    {
                        path: 'profile',
                        component: ProductUsageComponent,
                        data: { pageName: 'profile' },
                    },
                ],
            },
            {
                path: 'provider-360',
                data: { marketSegment: 'provider-360' },
                children: [
                    {
                        path: 'login',
                        children: [
                            {
                                path: '',
                                component: LoginComponent,
                                data: { pageName: 'login' },
                            },
                            {
                                path: ':reset_password',
                                component: AuthenticationComponent,
                                data: { pageName: 'reset-password' },
                            },
                        ],
                    },
                    {
                        path: ':provider/profile',
                        component: ProviderComponent,
                        data: { pageName: 'providers' },
                    },
                    {
                        path: '**',
                        redirectTo: '',
                        pathMatch: 'full',
                    },
                ],
            },
            {
                path: 'group-360',
                data: { marketSegment: 'group-360' },
                children: [
                    {
                        path: 'profile',
                        component: GroupsComponent,
                        data: { pageName: 'profile' },
                    },
                ],
            },
            {
                path: 'broker-360',
                data: { marketSegment: 'broker-360' },
                children: [
                    {
                        path: 'profile',
                        component: BrokerComponent,
                        data: { pageName: 'profile' },
                    },
                ],
            },
            {
                path: 'member-360',
                data: { marketSegment: 'member-360' },

                children: [
                    {
                        path: 'oauth',
                        component: OktaCallbackComponent,
                    },
                    {
                        path: 'auth',
                        component: AuthComponent,
                        children: [
                            {
                                path: 'login',
                                component: LoginComponent,
                            },
                            {
                                path: 'okta',
                                component: OktaComponent,
                            },
                            {
                                path: 'registration',
                                component: RegistrationComponent,
                            },
                            {
                                path: 'forgot-password',
                                component: ForgotPasswordComponent,
                            },
                            {
                                path: 'forgot-username',
                                component: ForgotUsernameComponent,
                            },
                            {
                                path: '**',
                                redirectTo: 'login',
                                pathMatch: 'full',
                            },
                        ],
                    },
                    {
                        path: 'home',
                        component: HomePageComponent,
                        data: { pageName: 'home-page' },
                    },
                    {
                        path: 'provider-details/:id',
                        component: PcpDetailComponent,
                        data: { pageName: 'generic-provider-detail' },
                        canActivate: [RouteGuard],
                    },
                    {
                        path: ':member',
                        children: [
                            {
                                path: 'profile',
                                component: MemberProfileComponent,
                                data: { pageName: 'member-profile' },
                            },
                            {
                                path: 'financial-accumulator',
                                component: FinancialAccumulatorComponent,
                                data: { pageName: 'financial-accumulator' },
                                canActivate: [RouteGuard],
                            },

                            {
                                path: 'billing',
                                component: BillingAndPaymentsComponent,
                                data: { pageName: 'billing-payments' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'billing/:bill_id',
                                component: BillingDetailsComponent,
                                data: { pageName: 'billing-details' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'payments',
                                component: BillingAndPaymentsComponent,
                                data: { pageName: 'billing-payments' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'payments/:payment_id',
                                component: PaymentDetailsComponent,
                                data: { pageName: 'payment-details' },
                                canActivate: [RouteGuard],
                            },

                            {
                                path: 'claims',
                                component: ClaimsComponent,
                                data: { pageName: 'claims' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'claims/:claim_id',
                                component: ClaimDetailComponent,
                                data: { pageName: 'claim-detail' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'claims/:claim_id/claim-line/:claim_line',
                                component: ClaimLineComponent,
                                data: { pageName: 'claim-line' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'policies',
                                component: PoliciesComponent,
                                data: { pageName: 'policies' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'policies/:policy_number',
                                component: PolicyDetailsComponent,
                                data: { pageName: 'policy-details' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'policies/:policy_number/:pcov_id/:mcov_id',
                                component: CoverageDetailsComponent,
                                data: { pageName: 'policy-coverage' },
                                canActivate: [RouteGuard],
                            },

                            {
                                path: 'coverages',
                                component: CoveragesComponent,
                                data: { pageName: 'coverages' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'coverages/:policy_number/:pcov_id/:mcov_id',
                                component: CoverageDetailsComponent,
                                data: { pageName: 'coverage-details' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'benefits',
                                component: BenefitsSearchComponent,
                                data: { pageName: 'benefits-search' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'benefits/categories/:categoryId',
                                component: BenefitsCategoriesComponent,
                                data: { pageName: 'benefits-category' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'benefits/services/:serviceId',
                                component: BenefitsDetailsComponent,
                                data: { pageName: 'benefits-details' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'benefits/:categoryId/:serviceId',
                                component: BenefitsDetailsComponent,
                                data: { pageName: 'benefits-details' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'referrals',
                                component: ReferralsComponent,
                                data: { pageName: 'referrals' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'referrals/:id',
                                component: ReferralDetailComponent,
                                data: { pageName: 'referral-details' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'preauthorizations',
                                component: PreauthorizationsComponent,
                                data: { pageName: 'preauthorizations' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'preauthorization/:id',
                                component: PreauthorizationDetailComponent,
                                data: { pageName: 'preauthorization-details' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'providers',
                                component: ProvidersComponent,
                                data: { pageName: 'providers' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'providers/:id',
                                component: PcpDetailComponent,
                                data: { pageName: 'pcp-detail' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'documents',
                                component: DocumentCenterComponent,
                                data: { pageName: 'document-center' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'engagement',
                                component: MemberEngagementComponent,
                                data: { pageName: 'member-engagement' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: 'appeals',
                                component: AppealsComponent,
                                data: { pageName: 'appeals' },
                                canActivate: [RouteGuard],
                            },
                            {
                                path: '404',
                                component: NotFoundComponent,
                                data: { pageName: 'not-found' },
                            },
                            {
                                path: 'global-error',
                                component: GlobalErrorComponent,
                                data: { pageName: 'global-error' },
                            },
                            {
                                path: '**',
                                redirectTo: 'profile',
                            },
                        ],
                    },
                    {
                        path: '**',
                        redirectTo: 'home',
                        pathMatch: 'full',
                    },
                ],
            },
            {
                path: '**',
                redirectTo: '',
                pathMatch: 'full',
            },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class IcxRoutingModule {}
