import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { DatagridModule, PaginatorModule } from '@zipari/design-system';

import { CoveragesComponent } from './coverages.component';

@NgModule({
    declarations: [CoveragesComponent],
    imports: [CommonModule, DatagridModule, PaginatorModule],
})
export class CoveragesModule {}
