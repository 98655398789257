import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CXCaptureService {
    constructor(private http: HttpClient) {}

    capture(payload) {
        this.http.post('http://cx-capture.dev.zipari.net/events/', payload).subscribe(response => {
            console.log(response);
        });
    }
}
