import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { DetailTabsModule } from 'src/app/icx/shared/modules/detail-tabs/detail-tabs.module';

import { ButtonModule, ModelAttributeModule, DatagridModule, RippleModule } from '@zipari/design-system';
import { BadgeModule } from '../../../shared/modules/badge/badge.module';
import { PreauthorizationDetailComponent } from './preauthorization-detail.component';

@NgModule({
    declarations: [PreauthorizationDetailComponent],
    imports: [CommonModule, ButtonModule, DetailTabsModule, BadgeModule, ModelAttributeModule, DatagridModule, RippleModule],
})
export class PreauthorizationDetailModule {}
