import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

@Injectable()
export class PortalBuilderAPIService {
    private configEndpoint = 'api/admin/frontend/';
    private tenantEndpoint = 'api/admin/tenants/';

    constructor(private http: HttpClient) {}

    public getTenants(): Observable<any> {
        return this.http.get<any>(this.tenantEndpoint);
    }
}
