import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ButtonModule, CardModule } from '@zipari/design-system';
import { FormGroupModule } from '../..';
import { AuthCardComponent } from './auth-card.component';

@NgModule({
    declarations: [AuthCardComponent],
    imports: [CommonModule, CardModule, FormGroupModule, ButtonModule],
    exports: [AuthCardComponent],
})
export class AuthCardModule {}
