import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
    ButtonModule,
    ExpandingArticleModule,
    TabsModule,
    MessageBannerModule,
    DatagridModule,
    RippleModule,
    ModalModule,
    ModelAttributeModule,
    FormGroupModule,
} from '@zipari/design-system';
import { ViewClaimsModalModule } from '../../financial-accumulator/components/view-claims-modal/view-claims-modal.module';
import { BenefitsDetailsComponent } from './benefits-details.component';
import { BenefitsTableComponent } from './benefits-table/benefits-table.component';
import { UtilizationsComponent } from './utilizations/utilizations.component';
import { UtilizationsTableComponent } from './utilizations/utilizations-table/utilizations-table.component';
@NgModule({
    declarations: [BenefitsDetailsComponent, BenefitsTableComponent, UtilizationsComponent, UtilizationsTableComponent],
    imports: [
        CommonModule,
        ButtonModule,
        ExpandingArticleModule,
        TabsModule,
        MessageBannerModule,
        DatagridModule,
        RippleModule,
        ModalModule,
        FormGroupModule,
        ModelAttributeModule,
        ViewClaimsModalModule,
    ],
    exports: [UtilizationsComponent],
})
export class BenefitsDetailsModule {}
