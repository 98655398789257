import { Component, EventEmitter, Input, isDevMode, OnInit, Output } from '@angular/core';

import { accordion } from '../../../animations';
import { ZipGoogleMapService } from '../../map/map.service';

import { SearchBarConfig, SearchBarTab } from './search-bar-config.interface';

@Component({
    animations: [accordion],
    selector: 'search-bar',
    templateUrl: './search-bar.component.html',
    styleUrls: ['./search-bar.component.scss'],
})
export class SearchBarComponent implements OnInit {
    @Input() config: SearchBarConfig;
    @Input() initialZipcode: number;
    @Output() search = new EventEmitter<any>();
    @Output() initialDistance = new EventEmitter<any>();
    @Output() distanceChanged = new EventEmitter<any>();
    @Output() nameSearch = new EventEmitter<any>();

    searchConfig;
    searchDistance: number;
    name;
    lat;
    lng;

    linkedTab: SearchBarTab;
    linkedLabel: string;
    linkedOptions: string[];

    constructor(private zipMapService: ZipGoogleMapService) {}

    ngOnInit() {
        try {
            if (!this.config) {
                throw this.cannotInitError('SearchBarConfig not found');
            }
            this.initFields(this.config);
        } catch (error) {
            if (isDevMode()) {
                throw this.cannotInitError(error);
            }
        }
    }

    cannotInitError = (error: any): Error => Error(`SearchBarComponent cannot initialize: ${error}`);

    initFields(config: SearchBarConfig): void {
        this.searchConfig = config.searchConfig;
        this.searchDistance = this.searchConfig.locationConfig['within'];
        this.initialDistance.emit(this.searchDistance[0]);
    }

    onWithinDistanceChange(distance: string) {
        const number: string = distance.match(/\d+/)[0];
        this.distanceChanged.emit(number);
    }

    updateCurrentValue = (nextValue: any) => (this.initialZipcode = nextValue);

    getLatLng(address: string) {
        this.zipMapService.GetLatLngFromAddress(address).then(latlng => {
            this.lat = latlng.lat();
            this.lng = latlng.lng();
            const emitLatLng = {
                lat: this.lat,
                lng: this.lng,
                zipcode: address,
            };
            this.search.emit(emitLatLng);
        });
    }

    onSearchAreaInputEnter(inputValue) {
        this.getLatLng(inputValue);
        this.updateCurrentValue(inputValue);
    }

    onSearchProviderName($event) {
        this.name = $event;
        this.nameSearch.emit(this.name);
    }

    // TODO(cstroh): We don't have geolocation enabled for our Google Maps API key https://jira.zipari.net/browse/FE-1078
    onUseMyCurrentLocationClick() {
        // console.log('use current location');
        // this.updateCurrentValue();
    }
}

interface Query {
    parameter: any;
    value: any;
}
