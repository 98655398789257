import { Component, OnInit, Input } from '@angular/core';
import { Datagrid } from '@zipari/design-system';

import { GenericType } from 'src/app/icx/shared/services/icx.service';
import * as _ from 'lodash';
import { BenefitParticipationLevel } from '../../benefits-table/benefits-table.component';
export const utilizationsParams = ['used', 'remaining', 'total'];
export enum utilizationsParamsDisplayNames {
    used = 'Used',
    remaining = 'Remaining',
    total = 'Total',
}

@Component({
    selector: 'utilizations-table',
    templateUrl: './utilizations-table.component.html',
    styleUrls: ['./utilizations-table.component.scss'],
})
export class UtilizationsTableComponent implements OnInit {
    @Input() rows: GenericType[];
    public datagridConfig: Datagrid = {
        columns: [],
        statuses: {},
        noData: {
            message: 'No Data Available',
        },
    };

    public datagridData: {
        utilization_display_name: string;
        [key: string]: string;
    }[] = [];

    ngOnInit(): void {
        const headerGroups = _.groupBy(this.rows, (row) => row.participation_level);

        this.datagridConfig.columns.push({
            label: '',
            prop: 'utilization_display_name',
            format: 'text',
            sortable: false,
            isLeftAligned: true,
            isRightAligned: false,
            isCenterAligned: false,
        });

        Object.keys(headerGroups).forEach((header: string) => {
            this.datagridConfig.columns.push({
                label: BenefitParticipationLevel[header] || header,
                prop: header,
                format: 'text',
                sortable: false,
                isLeftAligned: true,
                isCenterAligned: false,
                isRightAligned: false,
            });
        });

        this.rows &&
            Object.keys(this.rows[0]).forEach((param) => {
                // TODO refactor later
                if (param === 'used') {
                    this.addToGroup(param, headerGroups);
                }

                if (param === 'remaining') {
                    this.addToGroup(param, headerGroups);
                }

                if (param === 'total') {
                    this.addToGroup(param, headerGroups);
                }
            });
        this.datagridData = [...this.datagridData];
    }

    addToGroup(param, headerGroups) {
        const rowData = {
            utilization_display_name: utilizationsParamsDisplayNames[param],
        };
        Object.keys(headerGroups).forEach((header: string) => {
            headerGroups[header].forEach((item) => {
                rowData[header] = item[param];
            });
        });
        this.datagridData.push(rowData);
    }
}
